.react-tabs {
    -webkit-tap-highlight-color: transparent;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
    padding-top: 10px;
    
    &__tab-list {
        //border-bottom: 1px solid #aaa;
        margin: 0 0 10px;
        padding: 0;
    }
  
    &__tab {
        display: inline-block;
        border: 1px solid #aaa;
        background-color: rgb(238, 238, 238);
        bottom: -1px;
        position: relative;
        margin-left: -1px;
        list-style: none;
        padding: 6px 12px;
        cursor: pointer;
        font-family: "San Francisco Display Medium";
        font-size: 11pt;

        &--selected {
            background: white;
            color: black;
            border-top: 1px solid orangered;
            //border-bottom: 1px solid white;

            &:after {
                content: "";
                position: absolute;
                height: 3px;
                left: 0px;
                right: 0px;
                bottom: -2px;
                background: white;
            }
        }

        &--disabled {
            color: GrayText;
            cursor: default;
        }

        &:focus {
            outline: none;
        }
    }
  
    &__tab-panel {
        display: none;
        border: 1px solid #aaa;
        margin-top: -10px;
        margin-left: -1px;

        &--selected {
            display: block;
        }
    }
  }
