.container {
    position: fixed;
    width: 80%;
    text-align: center;
    top: 50%;
    left: 48%;
    transform: translate(-50%, -50%);

    input {
        padding: 12px 12px;
        outline: none;
        border: 0;
        border-bottom: 4px solid #283044;
        font-size: 22pt;
        color: #283044;
        font-family: "San Francisco Display Regular";
        width: 50%;
        border-radius: 0;

        &::placeholder {
            color: #605856;
        }

        &:hover {
            border-bottom: 4px solid orangered;
        }
    }

    textarea {
        max-height: 200px;
        outline: none;
        resize: none;
        width: 50%;
        padding: 12px 12px;
        border: 0;
        border-bottom: 4px solid #283044;
        font-size: 22pt;
        color: #283044;
        font-family: "San Francisco Display Regular";
        border-radius: 0;

        &:focus {
            outline: none;
        }

        &:hover {
            border-bottom: 4px solid orangered;
        }
    }

    .iconStyle {
        color: #283044;
        cursor: pointer;
        position: fixed;
        text-align: center;
        top: 50%;
        left: 80%;
        transform: translate(-50%, -50%);

        &:hover {
            color: orangered;
        }
    }
}

@media all and (max-width: 890px) {
    .container {
        input {
            font-size: 15pt;

            &::placeholder {
                font-size: 15pt;
            }
        }

        textarea {
            font-size: 15pt;
        }
    }
}