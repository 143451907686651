#scroll_bar::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0px 0px 0px rgba(0,0,0,0.3) !important;
    box-shadow: inset 0px 0px 0px rgba(0,0,0,0.3) !important;
    background-color: none;
}

#scroll_bar::-webkit-scrollbar{
    width: 10px !important;
    background-color: none !important;
    -webkit-overflow-scrolling: auto !important;
}

#scroll_bar::-webkit-scrollbar-thumb{
    background-color: #0ae !important;
	background-image: -webkit-gradient(linear, 0 0, 0 100%, color-stop(.5, rgba(255, 255, 255, .2)), color-stop(.5, transparent), to(transparent)) !important;
}