@keyframes appear {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
@keyframes slideIn {
    0% {
        transform: translateX(-2%);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes shrink {
    0% {
        width: 95%;
    }
    100% {
        width: 90%;
    }
}

.hideMenu {
    visibility: visible;
}

@media all and (min-width: 891px){
    .hideMenu {
        visibility: hidden;
    }
}

a {
    text-decoration: none;
    color: #fafafa;
}