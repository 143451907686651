.navigationMenu {
    background-color: transparent;
    font-family: "San Francisco Display Semibold";
    font-size: 17pt;
    
    ul {
        display: block;
        padding: 0px 0px 0px 0px;
        line-height: 1;

        li {
            display: inline-block;
            margin: 0px 1px 0px 1px;

            a {
                position: relative;
                text-decoration: none;
                padding: 15px 25px;
                color: black;
        
                &:hover {
                    &:before {
                        width: 100%;
                    }
                }
                
                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -3px;
                    height: 3px;
                    width: 0;
                    background: orangered;
                    -webkit-transition: width .25s;
                    -moz-transition: width .25s;
                    -ms-transition: width .25s;
                    -o-transition: width .25s;
                    transition: width .25s;
                }
            }
        }
    }
}

.topRight {
    float: right;
    right: 0;
    top: 0;
    position: fixed;
}

.topLeft {
    float: left;
    left: 0;
    top: 0;
    position: fixed;
}

.activeLink {
    border-bottom: 3px solid orangered;
}

@media all and (max-width: 890px) {
    .navigationMenu {
        visibility: hidden;
    }
}