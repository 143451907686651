@font-face {
	font-family: "San Francisco Display Light";
	src: url("./fonts/SanFranciscoDisplay-Light.eot");
	src: url("./fonts/SanFranciscoDisplay-Light.eot?#iefix") format("embedded-opentype"), url("./fonts/SanFranciscoDisplay-Light.woff") format("woff");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "San Francisco Display Regular";
	src: url("./fonts/SanFranciscoDisplay-Regular.eot");
	src: url("./fonts/SanFranciscoDisplay-Regular.eot?#iefix") format("embedded-opentype"), url("./fonts/SanFranciscoDisplay-Regular.woff") format("woff");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "San Francisco Display Black";
	src: url("./fonts/SanFranciscoDisplay-Black.eot");
	src: url("./fonts/SanFranciscoDisplay-Black.eot?#iefix") format("embedded-opentype"), url("./fonts/SanFranciscoDisplay-Black.woff") format("woff");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "San Francisco Display Heavy";
	src: url("./fonts/SanFranciscoDisplay-Heavy.eot");
	src: url("./fonts/SanFranciscoDisplay-Heavy.eot?#iefix") format("embedded-opentype"), url("./fonts/SanFranciscoDisplay-Heavy.woff") format("woff");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "San Francisco Display Semibold";
	src: url("./fonts/SanFranciscoDisplay-Semibold.eot");
	src: url("./fonts/SanFranciscoDisplay-Semibold.eot?#iefix") format("embedded-opentype"), url("./fonts/SanFranciscoDisplay-Semibold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "San Francisco Display Medium";
	src: url("./fonts/SanFranciscoDisplay-Medium.eot");
	src: url("./fonts/SanFranciscoDisplay-Medium.eot?#iefix") format("embedded-opentype"), url("./fonts/SanFranciscoDisplay-Medium.woff") format("woff");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "San Francisco Display Ultralight";
	src: url("./fonts/SanFranciscoDisplay-Ultralight.eot");
	src: url("./fonts/SanFranciscoDisplay-Ultralight.eot?#iefix") format("embedded-opentype"), url("./fonts/SanFranciscoDisplay-Ultralight.woff") format("woff");
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: "San Francisco Display Bold";
	src: url("./fonts/SanFranciscoDisplay-Bold.eot");
	src: url("./fonts/SanFranciscoDisplay-Bold.eot?#iefix") format("embedded-opentype"), url("./fonts/SanFranciscoDisplay-Bold.woff") format("woff");
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: "San Francisco Display Thin";
	src: url("./fonts/SanFranciscoDisplay-Thin.eot");
	src: url("./fonts/SanFranciscoDisplay-Thin.eot?#iefix") format("embedded-opentype"), url("./fonts/SanFranciscoDisplay-Thin.woff") format("woff");
	font-weight: 100;
	font-style: normal;
}

@media screen and (max-width: 767px) and (orientation: landscape) {
    .blockLandscape {
		transform: rotate(-90deg);
		transform-origin: left top;
		width: 100vh;
		overflow-x: hidden;
		position: absolute;
		top: 100%;
		left: 0;
    }
}

.bringToFront {
	position: relative;
	z-index: 10000;
}