.position {
    margin-left: auto;
    margin-right: auto;
    margin-top: 130px;
    position: relative;
    z-index: 1000;
}

.containerBox {
    width: 800px;
    height: auto;
}

.nameText {
    color: #283044;
    font-size: 60pt;
    font-family: "San Francisco Display Medium";
}

.descriptionText {
    color: #605856;
    font-size: 30pt;
    padding-top: 30px;
    padding-bottom: 50px;
    font-family: "San Francisco Display Regular";
}

.iconStyle {
    color: blue;
    margin-right: 45px;

    &:hover {
        color: orangered;
    }
}

@media all and (min-width: 530px) and (max-width: 959px) { 
    .containerBox {
        width: 80%;
        padding-top: 30px;
    }

    .nameText {
        font-size: 10vw;
    }

    .descriptionText {
        font-size: 4vw;
    }
}

@media all and (max-width: 530px) {
    .containerBox {
        background-color: transparent;
        width: 100%;
    }

    .nameText {
        font-size: 10vw;
        padding-left: 30px;
        padding-right: 30px;
        padding-top: 30px;
        padding-bottom: 30px;
    }

    .descriptionText {
        font-size: 5vw;
        padding-top: 10px;
        padding-left: 30px;
        padding-right: 30px;
    }

    .iconStyle {
        margin-left: 30px;
        margin-right: 15px;
    }
}