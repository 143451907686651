.card {
    border-radius: 5px;
    background: white;
    box-shadow: rgba(0, 0, 0, 0.2) 0px 0px 8px;
    width: 310px;
    position: relative;
    z-index: 1000;

    .cardHeader {
        color: white;
        padding: 20px;
        border-radius: 5px;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        text-align: left;
    }

    .title {
        font-family: "San Francisco Display Bold";
        color: white;
        font-size: 15pt;
    }

    .subTitle {
        font-family: "San Francisco Display Medium";
    }

    .dateTitle {
        font-family: "San Francisco Display Medium";
        font-size: 10pt;
    }

    .textBox {
        font-family: "San Francisco Display Regular";
        font-size: 11pt;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        text-align: justify;
    }
}

