.masonry_grid{
    margin-top: 100px;
    margin-left: auto;
    margin-right: auto;
    width: 995px;
    position: relative;
    z-index: 6000;
}

@media all and (min-width: 1350px){
    .masonry_grid{
        width: 995px;
    } 
}

@media all and (min-width: 1000px) and (max-width: 1099px){
    .masonry_grid{
        width: 995px;
    } 
}

@media all and (min-width: 664px) and (max-width: 999px){
    .masonry_grid{
        width: 664px;
    } 
}

@media all and (max-width: 663px){
    .masonry_grid {
        width: 333px;
    } 
}

@media all and (max-width: 891px){
    .masonry_grid {
        margin-top: 130px;
    } 
}

.filterText{
    font-family: "San Francisco Display Semibold";
    display: inline-block;
    margin-left: 10px;
    color: #283044;
}

.filterBox{
    padding-top: 20px;
    padding-bottom: 20px;
}

.filterButton{
    display: inline-block;
    color: #444444;
    background: #F3F3F3;
    border: 1px #DADADA solid;
    //padding: 5px 10px;
    padding: 9px 10px 10px 9px;
    border-radius: 2px;
    font-family: "San Francisco Display Medium";
    font-size: 9pt;
    outline: none;
    margin-left: 11px;

    &:hover {
        border: 1px #C6C6C6 solid;
        box-shadow: 1px 1px 1px #EAEAEA;
        border-bottom: 1px orangered solid;
        color: #333333;
        background: #F7F7F7;
        cursor: pointer;
    }

    &:active {
        box-shadow: inset 1px 1px 1px #DFDFDF;   
    }
}

.activeFilterButton {
    border: 1px #C6C6C6 solid;
    border-bottom: 1px orangered solid;
    box-shadow: 1px 1px 1px #EAEAEA;
    color: #333333;
    background: #F7F7F7;
}

.documentContainer{
    display: inline-block;
    margin-left: 20px;
    margin-bottom: 20px;
    margin-top: 10px;

    .icon {
        color: #3A2E39;
        font-size: 15pt;

        &:hover {
            color: red;
        }
    }
}
