.masonry_grid_education{
    margin-top: 110px;
    margin-left: auto;
    margin-right: auto;
    width: 660px;
    position: relative;
    z-index: 6000;
}

@media all and (max-width: 660px){
    .masonry_grid_education{
        width: 330px;
    }
}

@media all and (max-width: 890px){
    .masonry_grid_education {
        margin-top: 150px;
    }
}