.mbsc-cloak {
    visibility: hidden !important;
  }
  .mbsc-empty {
    text-align: center;
    margin: 3em;
    color: inherit;
  }
  .mbsc-empty h3 {
    margin: 0.666666em 0;
    padding: 0;
    color: inherit;
    font-size: 1.5em;
    font-weight: normal;
    font-family: inherit;
  }
  .mbsc-empty p {
    margin: 1em 0;
    padding: 0;
    font-size: 1em;
    line-height: 1.5;
  }
  .mbsc-anim-trans .mbsc-fr-scroll {
    overflow: hidden;
  }
  .mbsc-anim-trans-flip .mbsc-fr-persp,
  .mbsc-anim-trans-swing .mbsc-fr-persp {
    -webkit-perspective: 1000px;
    perspective: 1000px;
  }
  .mbsc-anim-trans .mbsc-fr-popup,
  .mbsc-anim-trans .mbsc-fr-overlay {
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
  }
  .mbsc-anim-in .mbsc-fr-popup,
  .mbsc-anim-in .mbsc-fr-overlay {
    -webkit-animation-timing-function: ease-out;
    -webkit-animation-duration: 225ms;
    animation-timing-function: ease-out;
    animation-duration: 225ms;
  }
  .mbsc-anim-out .mbsc-fr-popup,
  .mbsc-anim-out .mbsc-fr-overlay {
    -webkit-animation-timing-function: ease-in;
    -webkit-animation-duration: 195ms;
    animation-timing-function: ease-in;
    animation-duration: 195ms;
  }
  .mbsc-anim-in .mbsc-fr-overlay {
    -webkit-animation-name: mbsc-anim-f-in;
    animation-name: mbsc-anim-f-in;
  }
  .mbsc-anim-out .mbsc-fr-overlay {
    -webkit-animation-name: mbsc-anim-f-out;
    animation-name: mbsc-anim-f-out;
  }
  .mbsc-anim-flip,
  .mbsc-anim-swing,
  .mbsc-anim-slidehorizontal,
  .mbsc-anim-slidevertical,
  .mbsc-anim-slidedown,
  .mbsc-anim-slideup,
  .mbsc-anim-fade {
    -webkit-backface-visibility: hidden;
    -webkit-transform: translateX(0);
    backface-visibility: hidden;
    transform: translateX(0);
  }
  .mbsc-anim-swing,
  .mbsc-anim-slidehorizontal,
  .mbsc-anim-slidevertical,
  .mbsc-anim-slidedown,
  .mbsc-anim-slideup,
  .mbsc-anim-fade {
    -webkit-transform-origin: 0 0;
    transform-origin: 0 0;
  }
  .mbsc-anim-flip,
  .mbsc-anim-pop {
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  .mbsc-anim-in .mbsc-anim-pop {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-p-in;
    -webkit-animation-duration: 100ms;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-p-in;
    animation-duration: 100ms;
    transform: scale(1);
  }
  .mbsc-anim-out .mbsc-anim-pop {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-p-out;
    -webkit-animation-duration: 150ms;
    animation-name: mbsc-anim-p-out;
    animation-duration: 150ms;
  }
  .mbsc-anim-trans-pop .mbsc-fr-overlay {
    -webkit-animation-duration: 150ms;
    animation-duration: 150ms;
  }
  .mbsc-anim-in .mbsc-anim-flip {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-fl-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-fl-in;
    transform: scale(1);
  }
  .mbsc-anim-out .mbsc-anim-flip {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-fl-out;
    animation-name: mbsc-anim-fl-out;
  }
  .mbsc-anim-in .mbsc-anim-swing {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sw-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-sw-in;
  }
  .mbsc-anim-out .mbsc-anim-swing {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sw-out;
    animation-name: mbsc-anim-sw-out;
  }
  .mbsc-anim-in .mbsc-anim-slidehorizontal {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sh-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-sh-in;
  }
  .mbsc-anim-out .mbsc-anim-slidehorizontal {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sh-out;
    animation-name: mbsc-anim-sh-out;
  }
  .mbsc-anim-in .mbsc-anim-slidevertical {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-sv-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-sv-in;
    transform: scale(1);
  }
  .mbsc-anim-out .mbsc-anim-slidevertical {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-sv-out;
    animation-name: mbsc-anim-sv-out;
  }
  .mbsc-anim-in .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-in;
    -webkit-transform: scale(1);
    animation-name: mbsc-anim-sd-in;
    transform: scale(1);
  }
  .mbsc-anim-out .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-out;
    -webkit-transform: translateY(-100%);
    animation-name: mbsc-anim-sd-out;
  }
  .mbsc-anim-in .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-in;
    -webkit-transform: scale(1);
    transform: scale(1);
    animation-name: mbsc-anim-su-in;
  }
  .mbsc-anim-out .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-out;
    -webkit-transform: translateY(100%);
    animation-name: mbsc-anim-su-out;
  }
  .mbsc-anim-in .mbsc-anim-fade {
    opacity: 1;
    -webkit-animation-name: mbsc-anim-f-in;
    animation-name: mbsc-anim-f-in;
  }
  .mbsc-anim-out .mbsc-anim-fade {
    opacity: 0;
    -webkit-animation-name: mbsc-anim-f-out;
    animation-name: mbsc-anim-f-out;
  }
  .mbsc-fr-pointer.mbsc-anim-in .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
    animation-name: mbsc-anim-sd-in, mbsc-anim-f-in;
  }
  .mbsc-fr-pointer.mbsc-anim-out .mbsc-anim-slidedown {
    -webkit-animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
    animation-name: mbsc-anim-sd-out, mbsc-anim-f-out;
  }
  .mbsc-fr-pointer.mbsc-anim-in .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
    animation-name: mbsc-anim-su-in, mbsc-anim-f-in;
  }
  .mbsc-fr-pointer.mbsc-anim-out .mbsc-anim-slideup {
    -webkit-animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
    animation-name: mbsc-anim-su-out, mbsc-anim-f-out;
  }
  @keyframes mbsc-anim-f-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @-webkit-keyframes mbsc-anim-f-in {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  @keyframes mbsc-anim-f-out {
    from {
      visibility: visible;
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @-webkit-keyframes mbsc-anim-f-out {
    from {
      visibility: visible;
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  @keyframes mbsc-anim-p-in {
    from {
      opacity: 0;
      transform: scale(0.8);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  @-webkit-keyframes mbsc-anim-p-in {
    from {
      opacity: 0;
      -webkit-transform: scale(0.8);
    }
    to {
      opacity: 1;
      -webkit-transform: scale(1);
    }
  }
  @keyframes mbsc-anim-p-out {
    from {
      opacity: 1;
      transform: scale(1);
    }
    to {
      opacity: 0;
      transform: scale(0.8);
    }
  }
  @-webkit-keyframes mbsc-anim-p-out {
    from {
      opacity: 1;
      -webkit-transform: scale(1);
    }
    to {
      opacity: 0;
      -webkit-transform: scale(0.8);
    }
  }
  @keyframes mbsc-anim-fl-in {
    from {
      opacity: 0;
      transform: rotateY(90deg);
    }
    to {
      opacity: 1;
      transform: rotateY(0);
    }
  }
  @-webkit-keyframes mbsc-anim-fl-in {
    from {
      opacity: 0;
      -webkit-transform: rotateY(90deg);
    }
    to {
      opacity: 1;
      -webkit-transform: rotateY(0);
    }
  }
  @keyframes mbsc-anim-fl-out {
    from {
      opacity: 1;
      transform: rotateY(0deg);
    }
    to {
      opacity: 0;
      transform: rotateY(-90deg);
    }
  }
  @-webkit-keyframes mbsc-anim-fl-out {
    from {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }
    to {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }
  }
  @keyframes mbsc-anim-sw-in {
    from {
      opacity: 0;
      transform: rotateY(-90deg);
    }
    to {
      opacity: 1;
      transform: rotateY(0deg);
    }
  }
  @-webkit-keyframes mbsc-anim-sw-in {
    from {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }
    to {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }
  }
  @keyframes mbsc-anim-sw-out {
    from {
      opacity: 1;
      transform: rotateY(0deg);
    }
    to {
      opacity: 0;
      transform: rotateY(-90deg);
    }
  }
  @-webkit-keyframes mbsc-anim-sw-out {
    from {
      opacity: 1;
      -webkit-transform: rotateY(0deg);
    }
    to {
      opacity: 0;
      -webkit-transform: rotateY(-90deg);
    }
  }
  @keyframes mbsc-anim-sh-in {
    from {
      opacity: 0;
      transform: translateX(-100%);
    }
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
  @-webkit-keyframes mbsc-anim-sh-in {
    from {
      opacity: 0;
      -webkit-transform: translateX(-100%);
    }
    to {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
  }
  @keyframes mbsc-anim-sh-out {
    from {
      opacity: 1;
      transform: translateX(0);
    }
    to {
      opacity: 0;
      transform: translateX(100%);
    }
  }
  @-webkit-keyframes mbsc-anim-sh-out {
    from {
      opacity: 1;
      -webkit-transform: translateX(0);
    }
    to {
      opacity: 0;
      -webkit-transform: translateX(100%);
    }
  }
  @keyframes mbsc-anim-sv-in {
    from {
      opacity: 0;
      transform: translateY(-100%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  @-webkit-keyframes mbsc-anim-sv-in {
    from {
      opacity: 0;
      -webkit-transform: translateY(-100%);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }
  @keyframes mbsc-anim-sv-out {
    from {
      opacity: 1;
      transform: translateY(0);
    }
    to {
      opacity: 0;
      transform: translateY(100%);
    }
  }
  @-webkit-keyframes mbsc-anim-sv-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
    to {
      opacity: 0;
      -webkit-transform: translateY(100%);
    }
  }
  @keyframes mbsc-anim-sd-in {
    from {
      transform: translateY(-100%);
    }
    to {
      transform: translateY(0);
    }
  }
  @-webkit-keyframes mbsc-anim-sd-in {
    from {
      opacity: 1;
      -webkit-transform: translateY(-100%);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }
  @keyframes mbsc-anim-sd-out {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-100%);
    }
  }
  @-webkit-keyframes mbsc-anim-sd-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(-100%);
    }
  }
  @keyframes mbsc-anim-su-in {
    from {
      transform: translateY(100%);
    }
    to {
      transform: translateY(0);
    }
  }
  @-webkit-keyframes mbsc-anim-su-in {
    from {
      opacity: 1;
      -webkit-transform: translateY(100%);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
  }
  @keyframes mbsc-anim-su-out {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(100%);
    }
  }
  @-webkit-keyframes mbsc-anim-su-out {
    from {
      opacity: 1;
      -webkit-transform: translateY(0);
    }
    to {
      opacity: 1;
      -webkit-transform: translateY(100%);
    }
  }
  /*@font-face {
    font-family: "icons_mobiscroll";
    src: url("icons_mobiscroll.woff?vtxdtu") format("woff"), url("icons_mobiscroll.woff") format("woff"),
      url("icons_mobiscroll.ttf?vtxdtu") format("truetype");
    font-weight: normal;
    font-style: normal;
  }
  .mbsc-ic:before {
    font-family: "icons_mobiscroll";
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }*/
  
  .mbsc-ic-aid::before {
    content: "\ea01";
  }
  .mbsc-ic-airplane::before {
    content: "\ea02";
  }
  .mbsc-ic-alarm2::before {
    content: "\ea03";
  }
  .mbsc-ic-arrow-down2::before {
    content: "\ea04";
  }
  .mbsc-ic-arrow-down5::before {
    content: "\ea05";
  }
  .mbsc-ic-arrow-left2::before {
    content: "\ea06";
  }
  .mbsc-ic-arrow-left4::before {
    content: "\ea07";
  }
  .mbsc-ic-arrow-left5::before {
    content: "\ea08";
  }
  .mbsc-ic-arrow-left6::before {
    content: "\ea09";
  }
  .mbsc-ic-arrow-right2::before {
    content: "\ea0a";
  }
  .mbsc-ic-arrow-right4::before {
    content: "\ea0b";
  }
  .mbsc-ic-arrow-right5::before {
    content: "\ea0c";
  }
  .mbsc-ic-arrow-right6::before {
    content: "\ea0d";
  }
  .mbsc-ic-arrow-up2::before {
    content: "\ea0e";
  }
  .mbsc-ic-arrow-up5::before {
    content: "\ea0f";
  }
  .mbsc-ic-attachment::before {
    content: "\ea10";
  }
  .mbsc-ic-backspace::before {
    content: "\ea11";
  }
  .mbsc-ic-backspace3::before {
    content: "\ea12";
  }
  .mbsc-ic-backspace4::before {
    content: "\ea13";
  }
  .mbsc-ic-bars::before {
    content: "\ea14";
  }
  .mbsc-ic-book::before {
    content: "\ea15";
  }
  .mbsc-ic-brightness-contrast::before {
    content: "\ea16";
  }
  .mbsc-ic-bubble::before {
    content: "\ea17";
  }
  .mbsc-ic-bubbles::before {
    content: "\ea18";
  }
  .mbsc-ic-bullhorn::before {
    content: "\ea19";
  }
  .mbsc-ic-calendar::before {
    content: "\ea1a";
  }
  .mbsc-ic-camera::before {
    content: "\ea1b";
  }
  .mbsc-ic-cart::before {
    content: "\ea1c";
  }
  .mbsc-ic-checkmark::before {
    content: "\ea1d";
  }
  .mbsc-ic-clock::before {
    content: "\ea1e";
  }
  .mbsc-ic-close::before {
    content: "\ea1f";
  }
  .mbsc-ic-cloud-download::before {
    content: "\ea20";
  }
  .mbsc-ic-cloud-upload::before {
    content: "\ea21";
  }
  .mbsc-ic-cloud::before {
    content: "\ea22";
  }
  .mbsc-ic-cogs::before {
    content: "\ea23";
  }
  .mbsc-ic-compass::before {
    content: "\ea24";
  }
  .mbsc-ic-connection::before {
    content: "\ea25";
  }
  .mbsc-ic-copy2::before {
    content: "\ea26";
  }
  .mbsc-ic-copy3::before {
    content: "\ea27";
  }
  .mbsc-ic-credit::before {
    content: "\ea28";
  }
  .mbsc-ic-disk::before {
    content: "\ea29";
  }
  .mbsc-ic-download::before {
    content: "\ea2a";
  }
  .mbsc-ic-drawer::before {
    content: "\ea2b";
  }
  .mbsc-ic-droplet::before {
    content: "\ea2c";
  }
  .mbsc-ic-earth::before {
    content: "\ea2d";
  }
  .mbsc-ic-eye-blocked::before {
    content: "\ea2e";
  }
  .mbsc-ic-eye::before {
    content: "\ea2f";
  }
  .mbsc-ic-fa-dollar::before {
    content: "\ea30";
  }
  .mbsc-ic-fa-ellipsis-h::before {
    content: "\ea31";
  }
  .mbsc-ic-fa-facebook::before {
    content: "\ea32";
  }
  .mbsc-ic-fa-globe::before {
    content: "\ea33";
  }
  .mbsc-ic-fa-google-plus::before {
    content: "\ea34";
  }
  .mbsc-ic-fa-google::before {
    content: "\ea35";
  }
  .mbsc-ic-fa-leaf::before {
    content: "\ea36";
  }
  .mbsc-ic-fa-mail-reply::before {
    content: "\ea37";
  }
  .mbsc-ic-fa-retweet::before {
    content: "\ea38";
  }
  .mbsc-ic-fa-rotate-left::before {
    content: "\ea39";
  }
  .mbsc-ic-fa-twitter::before {
    content: "\ea3a";
  }
  .mbsc-ic-feed2::before {
    content: "\ea3b";
  }
  .mbsc-ic-file4::before {
    content: "\ea3c";
  }
  .mbsc-ic-film::before {
    content: "\ea3d";
  }
  .mbsc-ic-flag::before {
    content: "\ea3e";
  }
  .mbsc-ic-folder::before {
    content: "\ea3f";
  }
  .mbsc-ic-forward::before {
    content: "\ea40";
  }
  .mbsc-ic-foundation-mail::before {
    content: "\ea41";
  }
  .mbsc-ic-foundation-minus-circle::before {
    content: "\ea42";
  }
  .mbsc-ic-gift::before {
    content: "\ea43";
  }
  .mbsc-ic-globe::before {
    content: "\ea44";
  }
  .mbsc-ic-heart::before {
    content: "\ea45";
  }
  .mbsc-ic-heart2::before {
    content: "\ea46";
  }
  .mbsc-ic-history::before {
    content: "\ea47";
  }
  .mbsc-ic-home::before {
    content: "\ea48";
  }
  .mbsc-ic-image2::before {
    content: "\ea49";
  }
  .mbsc-ic-ion-android-social-user::before {
    content: "\ea4a";
  }
  .mbsc-ic-ion-android-system-windows::before {
    content: "\ea4b";
  }
  .mbsc-ic-ion-bluetooth::before {
    content: "\ea4c";
  }
  .mbsc-ic-ion-close-circled::before {
    content: "\ea4d";
  }
  .mbsc-ic-ion-email::before {
    content: "\ea4e";
  }
  .mbsc-ic-ion-ios7-arrow-back::before {
    content: "\ea4f";
  }
  .mbsc-ic-ion-ios7-arrow-forward::before {
    content: "\ea50";
  }
  .mbsc-ic-ion-ios7-checkmark-empty::before {
    content: "\ea51";
  }
  .mbsc-ic-ion-ios7-close-empty::before {
    content: "\ea52";
  }
  .mbsc-ic-ion-ios7-close-outline::before {
    content: "\ea53";
  }
  .mbsc-ic-ion-ios7-email::before {
    content: "\ea54";
  }
  .mbsc-ic-ion-ios7-information-outline::before {
    content: "\ea55";
  }
  .mbsc-ic-ion-ios7-plus-empty::before {
    content: "\ea56";
  }
  .mbsc-ic-ion-iphone::before {
    content: "\ea57";
  }
  .mbsc-ic-ion-navigate::before {
    content: "\ea58";
  }
  .mbsc-ic-ion-social-facebook::before {
    content: "\ea59";
  }
  .mbsc-ic-ion-usb::before {
    content: "\ea5a";
  }
  .mbsc-ic-ios-backspace::before {
    content: "\ea5b";
  }
  .mbsc-ic-key2::before {
    content: "\ea5c";
  }
  .mbsc-ic-library::before {
    content: "\ea5d";
  }
  .mbsc-ic-line-bubble::before {
    content: "\ea5f";
  }
  .mbsc-ic-line-calendar::before {
    content: "\ea5e";
  }
  .mbsc-ic-line-food::before {
    content: "\ea60";
  }
  .mbsc-ic-line-heart::before {
    content: "\ea61";
  }
  .mbsc-ic-line-key::before {
    content: "\ea63";
  }
  .mbsc-ic-line-mail::before {
    content: "\ea62";
  }
  .mbsc-ic-line-megaphone::before {
    content: "\ea64";
  }
  .mbsc-ic-line-music::before {
    content: "\ea65";
  }
  .mbsc-ic-line-note::before {
    content: "\ea66";
  }
  .mbsc-ic-line-paperplane::before {
    content: "\ea67";
  }
  .mbsc-ic-line-params::before {
    content: "\ea68";
  }
  .mbsc-ic-line-phone::before {
    content: "\ea69";
  }
  .mbsc-ic-line-settings::before {
    content: "\ea6c";
  }
  .mbsc-ic-line-star::before {
    content: "\ea6a";
  }
  .mbsc-ic-line-t-shirt::before {
    content: "\ea6b";
  }
  .mbsc-ic-line-tag::before {
    content: "\ea6d";
  }
  .mbsc-ic-line-user::before {
    content: "\ea6e";
  }
  .mbsc-ic-line-world::before {
    content: "\ea6f";
  }
  .mbsc-ic-link::before {
    content: "\ea71";
  }
  .mbsc-ic-location::before {
    content: "\ea73";
  }
  .mbsc-ic-lock::before {
    content: "\ea70";
  }
  .mbsc-ic-lock2::before {
    content: "\ea72";
  }
  .mbsc-ic-loop2::before {
    content: "\ea74";
  }
  .mbsc-ic-map::before {
    content: "\ea75";
  }
  .mbsc-ic-material-arrow-back::before {
    content: "\ea76";
  }
  .mbsc-ic-material-arrow-forward::before {
    content: "\ea77";
  }
  .mbsc-ic-material-backspace::before {
    content: "\ea79";
  }
  .mbsc-ic-material-brightness-medium::before {
    content: "\ea7a";
  }
  .mbsc-ic-material-check-box-outline-blank::before {
    content: "\ea78";
  }
  .mbsc-ic-material-check::before {
    content: "\ea7b";
  }
  .mbsc-ic-material-close::before {
    content: "\ea7c";
  }
  .mbsc-ic-material-crop::before {
    content: "\ea7e";
  }
  .mbsc-ic-material-email::before {
    content: "\ea7d";
  }
  .mbsc-ic-material-equalizer::before {
    content: "\ea7f";
  }
  .mbsc-ic-material-explore::before {
    content: "\ea80";
  }
  .mbsc-ic-material-filter::before {
    content: "\ea81";
  }
  .mbsc-ic-material-format-bold::before {
    content: "\ea82";
  }
  .mbsc-ic-material-format-ital::before {
    content: "\ea83";
  }
  .mbsc-ic-material-format-list-numbered::before {
    content: "\ea84";
  }
  .mbsc-ic-material-format-paint::before {
    content: "\ea85";
  }
  .mbsc-ic-material-format-underline::before {
    content: "\ea86";
  }
  .mbsc-ic-material-inbox::before {
    content: "\ea87";
  }
  .mbsc-ic-material-iso::before {
    content: "\ea88";
  }
  .mbsc-ic-material-keyboard-arrow-down::before {
    content: "\ea89";
  }
  .mbsc-ic-material-keyboard-arrow-left::before {
    content: "\ea8a";
  }
  .mbsc-ic-material-keyboard-arrow-right::before {
    content: "\ea8b";
  }
  .mbsc-ic-material-keyboard-arrow-up::before {
    content: "\ea8c";
  }
  .mbsc-ic-material-label::before {
    content: "\ea8d";
  }
  .mbsc-ic-material-language::before {
    content: "\ea8e";
  }
  .mbsc-ic-material-list::before {
    content: "\ea8f";
  }
  .mbsc-ic-material-menu::before {
    content: "\ea90";
  }
  .mbsc-ic-material-message::before {
    content: "\ea91";
  }
  .mbsc-ic-material-more-horiz::before {
    content: "\ea92";
  }
  .mbsc-ic-material-music-note::before {
    content: "\ea93";
  }
  .mbsc-ic-material-notifications::before {
    content: "\ea94";
  }
  .mbsc-ic-material-palette::before {
    content: "\ea95";
  }
  .mbsc-ic-material-pause::before {
    content: "\ea96";
  }
  .mbsc-ic-material-people::before {
    content: "\ea97";
  }
  .mbsc-ic-material-phone-iphone::before {
    content: "\ea98";
  }
  .mbsc-ic-material-photo-size-select-large::before {
    content: "\ea99";
  }
  .mbsc-ic-material-play-arrow::before {
    content: "\ea9a";
  }
  .mbsc-ic-material-repeat::before {
    content: "\ea9b";
  }
  .mbsc-ic-material-rotate-right::before {
    content: "\ea9c";
  }
  .mbsc-ic-material-search::before {
    content: "\ea9d";
  }
  .mbsc-ic-material-share::before {
    content: "\ea9e";
  }
  .mbsc-ic-material-shuffle::before {
    content: "\ea9f";
  }
  .mbsc-ic-material-skip-next::before {
    content: "\eaa0";
  }
  .mbsc-ic-material-skip-previous::before {
    content: "\eaa1";
  }
  .mbsc-ic-material-star-outline::before {
    content: "\eaa2";
  }
  .mbsc-ic-material-star::before {
    content: "\eaa3";
  }
  .mbsc-ic-material-stop::before {
    content: "\eaa4";
  }
  .mbsc-ic-material-system-update::before {
    content: "\eaa5";
  }
  .mbsc-ic-material-texture::before {
    content: "\eaa9";
  }
  .mbsc-ic-material-tune::before {
    content: "\eaa8";
  }
  .mbsc-ic-material-tv::before {
    content: "\eaa6";
  }
  .mbsc-ic-material-vertical-align-bottom::before {
    content: "\eaa7";
  }
  .mbsc-ic-material-wb-auto::before {
    content: "\eaaa";
  }
  .mbsc-ic-meteo-Celsius::before {
    content: "\eaab";
  }
  .mbsc-ic-meteo-Fahrenheit::before {
    content: "\eaad";
  }
  .mbsc-ic-meteo-cloud::before {
    content: "\eaac";
  }
  .mbsc-ic-meteo-cloud2::before {
    content: "\eaae";
  }
  .mbsc-ic-meteo-cloud3::before {
    content: "\eab0";
  }
  .mbsc-ic-meteo-cloud4::before {
    content: "\eaaf";
  }
  .mbsc-ic-meteo-cloud5::before {
    content: "\eab1";
  }
  .mbsc-ic-meteo-cloudy::before {
    content: "\eab2";
  }
  .mbsc-ic-meteo-cloudy2::before {
    content: "\eab3";
  }
  .mbsc-ic-meteo-cloudy3::before {
    content: "\eab4";
  }
  .mbsc-ic-meteo-cloudy4::before {
    content: "\eab6";
  }
  .mbsc-ic-meteo-compass::before {
    content: "\eab5";
  }
  .mbsc-ic-meteo-lightning::before {
    content: "\eab7";
  }
  .mbsc-ic-meteo-lightning2::before {
    content: "\eab8";
  }
  .mbsc-ic-meteo-lightning3::before {
    content: "\eab9";
  }
  .mbsc-ic-meteo-lightning4::before {
    content: "\eabb";
  }
  .mbsc-ic-meteo-lightning5::before {
    content: "\eaba";
  }
  .mbsc-ic-meteo-lines::before {
    content: "\eabd";
  }
  .mbsc-ic-meteo-moon::before {
    content: "\eabc";
  }
  .mbsc-ic-meteo-moon2::before {
    content: "\eabf";
  }
  .mbsc-ic-meteo-none::before {
    content: "\eabe";
  }
  .mbsc-ic-meteo-rainy::before {
    content: "\eac0";
  }
  .mbsc-ic-meteo-rainy2::before {
    content: "\eac1";
  }
  .mbsc-ic-meteo-rainy3::before {
    content: "\eac2";
  }
  .mbsc-ic-meteo-rainy4::before {
    content: "\eac3";
  }
  .mbsc-ic-meteo-snowflake::before {
    content: "\eac4";
  }
  .mbsc-ic-meteo-snowy::before {
    content: "\eac5";
  }
  .mbsc-ic-meteo-snowy2::before {
    content: "\eac6";
  }
  .mbsc-ic-meteo-snowy3::before {
    content: "\eac7";
  }
  .mbsc-ic-meteo-snowy4::before {
    content: "\eac8";
  }
  .mbsc-ic-meteo-snowy5::before {
    content: "\eac9";
  }
  .mbsc-ic-meteo-sun::before {
    content: "\eaca";
  }
  .mbsc-ic-meteo-sun2::before {
    content: "\eacb";
  }
  .mbsc-ic-meteo-sun3::before {
    content: "\eacc";
  }
  .mbsc-ic-meteo-sunrise::before {
    content: "\eacd";
  }
  .mbsc-ic-meteo-thermometer::before {
    content: "\eace";
  }
  .mbsc-ic-meteo-weather::before {
    content: "\eacf";
  }
  .mbsc-ic-meteo-weather2::before {
    content: "\ead0";
  }
  .mbsc-ic-meteo-weather3::before {
    content: "\ead1";
  }
  .mbsc-ic-meteo-weather4::before {
    content: "\ead2";
  }
  .mbsc-ic-meteo-weather5::before {
    content: "\ead4";
  }
  .mbsc-ic-meteo-wind::before {
    content: "\ead3";
  }
  .mbsc-ic-meteo-windy::before {
    content: "\ead5";
  }
  .mbsc-ic-meteo-windy2::before {
    content: "\ead7";
  }
  .mbsc-ic-meteo-windy3::before {
    content: "\ead6";
  }
  .mbsc-ic-meteo-windy4::before {
    content: "\ead8";
  }
  .mbsc-ic-meteo-windy5::before {
    content: "\ead9";
  }
  .mbsc-ic-minus::before {
    content: "\eada";
  }
  .mbsc-ic-mobile::before {
    content: "\eadb";
  }
  .mbsc-ic-music::before {
    content: "\eadc";
  }
  .mbsc-ic-neutral::before {
    content: "\eadd";
  }
  .mbsc-ic-newspaper::before {
    content: "\eade";
  }
  .mbsc-ic-office::before {
    content: "\eadf";
  }
  .mbsc-ic-pause2::before {
    content: "\eae0";
  }
  .mbsc-ic-pencil::before {
    content: "\eae2";
  }
  .mbsc-ic-phone::before {
    content: "\eae1";
  }
  .mbsc-ic-play::before {
    content: "\eae3";
  }
  .mbsc-ic-play3::before {
    content: "\eae4";
  }
  .mbsc-ic-plus::before {
    content: "\eae5";
  }
  .mbsc-ic-print::before {
    content: "\eae6";
  }
  .mbsc-ic-redo2::before {
    content: "\eae7";
  }
  .mbsc-ic-remove::before {
    content: "\eae8";
  }
  .mbsc-ic-reply::before {
    content: "\eae9";
  }
  .mbsc-ic-sad::before {
    content: "\eaea";
  }
  .mbsc-ic-sad2::before {
    content: "\eaeb";
  }
  .mbsc-ic-share::before {
    content: "\eaec";
  }
  .mbsc-ic-smiley::before {
    content: "\eaed";
  }
  .mbsc-ic-smiley2::before {
    content: "\eaee";
  }
  .mbsc-ic-stack::before {
    content: "\eaef";
  }
  .mbsc-ic-star::before {
    content: "\eaf0";
  }
  .mbsc-ic-star2::before {
    content: "\eaf1";
  }
  .mbsc-ic-star3::before {
    content: "\eaf3";
  }
  .mbsc-ic-stop2::before {
    content: "\eaf2";
  }
  .mbsc-ic-stopwatch::before {
    content: "\eaf4";
  }
  .mbsc-ic-stream-sync::before {
    content: "\eaf5";
  }
  .mbsc-ic-support::before {
    content: "\eaf6";
  }
  .mbsc-ic-tag::before {
    content: "\eaf7";
  }
  .mbsc-ic-thumbs-up::before {
    content: "\eaf8";
  }
  .mbsc-ic-thumbs-up2::before {
    content: "\eaf9";
  }
  .mbsc-ic-undo2::before {
    content: "\eafa";
  }
  .mbsc-ic-unlocked::before {
    content: "\eafb";
  }
  .mbsc-ic-upload::before {
    content: "\eafc";
  }
  .mbsc-ic-user4::before {
    content: "\eafd";
  }
  .mbsc-ic-volume-high::before {
    content: "\eafe";
  }
  .mbsc-ic-volume-medium::before {
    content: "\eaff";
  }
  .mbsc-ic-volume-mute2::before {
    content: "\eb00";
  }
  .mbsc-fr-w,
  .mbsc-fr-overlay {
    -webkit-transform: translateZ(0);
  }
  .mbsc-fr {
    pointer-events: none;
    z-index: 99998;
    font-weight: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
  }
  .mbsc-fr-lock-ctx {
    position: relative;
  }
  .mbsc-fr-lock.mbsc-fr-lock-ios {
    overflow: hidden;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: auto;
  }
  .mbsc-fr-pos {
    visibility: hidden;
  }
  .mbsc-fr-scroll {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
  .mbsc-fr-popup {
    max-width: 98%;
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    font-size: 12px;
    text-shadow: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }
  .mbsc-rtl {
    direction: rtl;
  }
  .mbsc-fr-popup,
  .mbsc-fr-btn-cont,
  .mbsc-fr-arr {
    box-sizing: border-box;
  }
  .mbsc-fr .mbsc-fr-w {
    box-sizing: content-box;
  }
  .mbsc-fr-w {
    min-width: 200px;
    max-width: 100%;
    overflow: hidden;
    text-align: center;
    font-family: arial, verdana, sans-serif;
  }
  .mbsc-fr,
  .mbsc-fr-persp,
  .mbsc-fr-overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
  .mbsc-fr-lock .mbsc-fr-persp {
    -ms-touch-action: none;
    touch-action: none;
  }
  .mbsc-fr-lock-ctx > .mbsc-fr,
  .mbsc-fr-lock-ctx .mbsc-fr-persp,
  .mbsc-fr-lock-ctx .mbsc-fr-overlay {
    position: absolute;
  }
  .mbsc-fr-persp {
    pointer-events: auto;
    overflow: hidden;
  }
  .mbsc-fr-overlay {
    z-index: 1;
    background: rgba(0, 0, 0, 0.7);
  }
  .mbsc-fr-liq .mbsc-fr-popup {
    max-width: 100%;
  }
  .mbsc-fr-top .mbsc-fr-popup,
  .mbsc-fr-bottom .mbsc-fr-popup {
    width: 100%;
    max-width: 100%;
  }
  .mbsc-fr-top .mbsc-fr-w,
  .mbsc-fr-bottom .mbsc-fr-w {
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }
  .mbsc-fr-bottom .mbsc-fr-w {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
  .mbsc-fr-top .mbsc-fr-popup {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }
  .mbsc-fr-inline {
    position: relative;
    pointer-events: auto;
    z-index: 0;
  }
  .mbsc-fr-inline .mbsc-fr-popup {
    position: static;
    max-width: 100%;
  }
  .mbsc-fr-bubble,
  .mbsc-fr-bubble .mbsc-fr-persp {
    position: absolute;
  }
  .mbsc-fr-bubble .mbsc-fr-arr-w {
    position: absolute;
    z-index: 1;
    left: 0;
    width: 100%;
    overflow: hidden;
  }
  .mbsc-fr-bubble-top .mbsc-fr-arr-w {
    top: 100%;
  }
  .mbsc-fr-bubble-bottom .mbsc-fr-arr-w {
    bottom: 100%;
  }
  .mbsc-fr-bubble .mbsc-fr-arr-i {
    margin: 0 1.75em;
    position: relative;
    direction: ltr;
  }
  .mbsc-fr-bubble .mbsc-fr-arr {
    display: block;
  }
  .mbsc-fr-arr {
    display: none;
    position: relative;
    left: 0;
    width: 2em;
    height: 2em;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    margin-left: -1em;
  }
  .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: 1.333334em;
  }
  .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: -1.333334em;
  }
  .mbsc-fr-hdn {
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
    border: 0;
    overflow: hidden;
  }
  .mbsc-fr-hdr {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }
  .mbsc-fr-btn {
    overflow: hidden;
    display: block;
    text-decoration: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    vertical-align: top;
  }
  .mbsc-fr-btn-e {
    cursor: pointer;
  }
  .mbsc-fr-btn.mbsc-disabled {
    cursor: not-allowed;
  }
  .mbsc-fr-btn-cont {
    display: table;
    width: 100%;
    text-align: center;
    white-space: normal;
  }
  .mbsc-fr-btn-cont .mbsc-disabled {
    opacity: 0.3;
  }
  .mbsc-fr-btn-w {
    vertical-align: top;
    display: table-cell;
    position: relative;
    z-index: 5;
  }
  .mbsc-fr-btn-w .mbsc-fr-btn:before {
    padding: 0.375em;
  }
  .mbsc-fr-pointer.mbsc-fr .mbsc-fr-w .mbsc-fr-inline .mbsc-fr-w {
    box-shadow: none;
    border-radius: 0;
  }
  .mbsc-fr-pointer .mbsc-ltr .mbsc-fr-w,
  .mbsc-fr-pointer .mbsc-ltr .mbsc-sc-whl .mbsc-sel-gr {
    text-align: left;
  }
  .mbsc-fr-pointer .mbsc-rtl .mbsc-fr-w,
  .mbsc-fr-pointer .mbsc-rtl .mbsc-sc-whl .mbsc-sel-gr {
    text-align: right;
  }
  .mbsc-fr-pointer.mbsc-fr-top .mbsc-fr-w,
  .mbsc-fr-pointer.mbsc-fr-bottom .mbsc-fr-w {
    pointer-events: auto;
    display: inline-block;
    margin-top: 3em;
    margin-bottom: 3em;
    max-width: 98%;
  }
  .mbsc-fr-pointer.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-fr-pointer.mbsc-fr-bottom .mbsc-fr-popup {
    text-align: center;
    pointer-events: none;
  }
  .mbsc-fr-pointer.mbsc-fr-bubble .mbsc-fr-arr-w {
    display: none;
  }
  .mbsc-fr-pointer .mbsc-sel-empty {
    text-align: center;
  }
  .mbsc-sc-whl-o,
  .mbsc-sc-btn {
    -webkit-transform: translateZ(0);
  }
  .mbsc-sc .mbsc-sc-whl-gr-c,
  .mbsc-sc .mbsc-sc-whl-c,
  .mbsc-sc .mbsc-sc-whl-l,
  .mbsc-sc .mbsc-sc-whl {
    box-sizing: content-box;
  }
  .mbsc-sc-whl-gr-c,
  .mbsc-sc-itm {
    box-sizing: border-box;
  }
  .mbsc-sc-whl-gr-c {
    position: relative;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block;
    overflow: hidden;
  }
  .mbsc-fr-bottom .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-top .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-inline .mbsc-sc-whl-gr-c:first-child:last-child,
  .mbsc-fr-liq .mbsc-sc-whl-gr-c {
    display: block;
  }
  .mbsc-sc-whl-gr {
    margin: 0 auto;
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    justify-content: center;
  }
  .mbsc-sc-whl-w {
    max-width: 100%;
    position: relative;
    -ms-touch-action: none;
    touch-action: none;
  }
  .mbsc-fr-pointer .mbsc-sc-whl-w,
  .mbsc-fr-liq .mbsc-sc-whl-w {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }
  .mbsc-sc-whl-o {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    display: none;
  }
  .mbsc-sc-whl-l {
    display: none;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    pointer-events: none;
  }
  .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: block;
  }
  .mbsc-sc-whl {
    overflow: hidden;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
    margin: -1px 0;
  }
  .mbsc-sc-whl-c {
    position: relative;
    z-index: 1;
    top: 50%;
    border-top: 1px solid transparent;
    border-bottom: 1px solid transparent;
  }
  .mbsc-sc-whl-sc {
    position: relative;
  }
  .mbsc-sc-itm {
    position: relative;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mbsc-sc-itm-inv,
  .mbsc-sc-itm-inv-h {
    opacity: 0.3;
  }
  .mbsc-sc-lbl {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    display: none;
  }
  .mbsc-sc-lbl-v .mbsc-sc-lbl {
    display: block;
  }
  .mbsc-sc-btn {
    position: absolute;
    z-index: 2;
    left: 0;
    right: 0;
    cursor: pointer;
    opacity: 1;
    text-align: center;
    transition: opacity 0.2s linear;
  }
  .mbsc-sc-btn:before {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
  .mbsc-sc-whl-a .mbsc-sc-btn {
    opacity: 0;
  }
  .mbsc-sc-btn-plus {
    bottom: 0;
  }
  .mbsc-sc-btn-minus {
    top: 0;
  }
  .mbsc-sc-whl-gr-3d {
    -webkit-perspective: 1200px;
    perspective: 1200px;
  }
  .mbsc-sc-whl-gr-3d .mbsc-sc-whl {
    overflow: visible;
  }
  .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    overflow: hidden;
  }
  .mbsc-sc-whl-3d {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
  }
  .mbsc-sc-itm-3d {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .mbsc-sc-itm-del {
    display: none;
  }
  .mbsc-sc-itm-ml {
    width: 100%;
    height: auto;
    display: inline-block;
    vertical-align: middle;
    white-space: normal;
  }
  .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 1em;
    text-align: center;
  }
  .mbsc-fr-pointer .mbsc-fr-w .mbsc-sc-whl-gr-c,
  .mbsc-fr-pointer .mbsc-fr-w .mbsc-sc-whl-gr {
    padding: 0;
  }
  .mbsc-fr-pointer .mbsc-sc-whl-gr-c:first-child:last-child {
    display: block;
  }
  .mbsc-sc-bar-c {
    position: absolute;
    z-index: 2;
    top: 0;
    bottom: 0;
    right: 0;
    width: 10px;
    opacity: 0;
    background: rgba(0, 0, 0, 0.05);
    transform: translateZ(0);
    transition: opacity 0.2s;
  }
  .mbsc-sc-bar {
    position: absolute;
    right: 0;
    top: 0;
    width: 10px;
    height: 100%;
  }
  .mbsc-sc-bar:after {
    content: "";
    position: absolute;
    top: 2px;
    right: 2px;
    bottom: 2px;
    left: 2px;
    background: rgba(0, 0, 0, 0.5);
    border-radius: 3px;
  }
  .mbsc-sc-whl-w:hover .mbsc-sc-bar-c,
  .mbsc-sc-whl-anim .mbsc-sc-bar-c {
    opacity: 1;
  }
  .mbsc-cal-slide,
  .mbsc-cal-day,
  .mbsc-cal-day-i {
    box-sizing: border-box;
  }
  .mbsc-cal .mbsc-cal-day-date {
    text-align: center;
    box-sizing: content-box;
  }
  .mbsc-cal-hdr,
  .mbsc-cal-btn-w,
  .mbsc-cal-days-c,
  .mbsc-cal-days,
  .mbsc-cal-tabbed .mbsc-fr-c .mbsc-sc-whl-gr-c {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .mbsc-cal-btn-w,
  .mbsc-cal-month,
  .mbsc-cal-year,
  .mbsc-cal-days,
  .mbsc-cal-days > div {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }
  .mbsc-cal-c {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    text-align: center;
  }
  .mbsc-cal-btn-w {
    width: 50%;
  }
  .mbsc-cal-month,
  .mbsc-cal-year {
    width: 1%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
  }
  .mbsc-cal-btn.mbsc-disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
  .mbsc-cal-days {
    line-height: 1.875em;
    text-align: center;
  }
  .mbsc-cal-days > div {
    width: 14.285715%;
  }
  .mbsc-cal-body {
    position: relative;
  }
  .mbsc-cal-scroll-c {
    overflow: hidden;
    height: 100%;
  }
  .mbsc-cal-scroll {
    width: 100%;
    height: 100%;
  }
  .mbsc-cal-slide {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .mbsc-cal-slide:first-child {
    position: relative;
  }
  .mbsc-cal-table {
    display: table;
    width: 100%;
    height: 100%;
    table-layout: fixed;
  }
  .mbsc-cal-row {
    display: table-row;
  }
  .mbsc-cal-cell {
    display: table-cell;
    vertical-align: middle;
  }
  .mbsc-cal-cell:focus {
    outline: 0;
  }
  .mbsc-cal-picker {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: 0.9375em 0;
    transition: all 0.2s ease-in-out;
  }
  .mbsc-cal-picker .mbsc-cal-cell {
    width: 33.333333%;
    height: 25%;
    cursor: pointer;
  }
  .mbsc-cal-h {
    visibility: hidden;
    opacity: 0;
  }
  .mbsc-cal-day {
    max-width: 14.285715%;
    height: 16.666667%;
    position: relative;
    text-align: center;
    cursor: pointer;
  }
  .mbsc-cal-day-i {
    position: relative;
    height: 100%;
  }
  .mbsc-cal-day-diff .mbsc-cal-day-date {
    opacity: 0.5;
  }
  .mbsc-cal-week-view .mbsc-cal-day-date,
  .mbsc-cal-day-diff.mbsc-selected .mbsc-cal-day-date {
    opacity: 1;
  }
  .mbsc-cal-cell.mbsc-disabled {
    cursor: not-allowed;
  }
  .mbsc-cal-cell.mbsc-disabled .mbsc-cal-cell-txt {
    opacity: 0.2;
  }
  .mbsc-cal-hide-diff .mbsc-cal-day-diff {
    cursor: default;
  }
  .mbsc-cal-hide-diff .mbsc-cal-day-diff .mbsc-cal-day-i {
    visibility: hidden;
  }
  .mbsc-ltr .mbsc-cal-weeks .mbsc-cal-days {
    margin-left: 1.625em;
  }
  .mbsc-rtl .mbsc-cal-weeks .mbsc-cal-days {
    margin-right: 1.625em;
  }
  .mbsc-cal-week-nr {
    width: 1.625em;
    text-align: center;
  }
  .mbsc-cal-multi .mbsc-cal-hdr {
    display: block;
  }
  .mbsc-cal-multi .mbsc-cal-btn-w {
    width: auto;
  }
  .mbsc-cal-multi .mbsc-cal-days,
  .mbsc-cal-multi .mbsc-cal-slide {
    padding: 0 0.5em;
  }
  .mbsc-fr-liq .mbsc-cal-c {
    display: block;
  }
  .mbsc-calendar.mbsc-fr-liq.mbsc-fr-center .mbsc-fr-overlay {
    display: none;
  }
  .mbsc-calendar.mbsc-fr-liq.mbsc-fr-center .mbsc-fr-w {
    border-radius: 0;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
  .mbsc-cal-tabs-c {
    display: none;
  }
  .mbsc-cal-tabs {
    display: table;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style: none;
  }
  .mbsc-cal-tab {
    position: relative;
    width: 33.33%;
    display: table-cell;
    overflow: hidden;
    cursor: pointer;
    text-align: center;
  }
  .mbsc-cal-tabbed .mbsc-fr-c {
    position: relative;
  }
  .mbsc-cal-tabbed .mbsc-w-p {
    transition: opacity 0.2s ease-in-out;
  }
  .mbsc-cal-tabbed .mbsc-sc-whl-gr-c {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    max-width: none;
    height: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-box-align: center;
    align-items: center;
  }
  .mbsc-cal-tabbed .mbsc-cal-c,
  .mbsc-cal-tabbed .mbsc-cal-tabs-c {
    display: block;
  }
  .mbsc-cal-tabbed .mbsc-fr-pos .mbsc-sc-whl-gr-c {
    width: auto;
  }
  .mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: 0.25em;
  }
  .mbsc-cal-marks {
    position: absolute;
    left: 0;
    right: 0;
    height: 0.375em;
    margin-top: -0.125em;
    overflow: hidden;
    white-space: nowrap;
  }
  .mbsc-cal-mark {
    display: inline-block;
    vertical-align: top;
    width: 0.25em;
    height: 0.25em;
    margin: 0.0625em;
    border-radius: 0.25em;
  }
  .mbsc-cal-txt-w {
    position: absolute;
    z-index: 2;
    pointer-events: none;
  }
  .mbsc-ltr .mbsc-cal-txt {
    text-align: left;
  }
  .mbsc-rtl .mbsc-cal-txt {
    text-align: right;
  }
  .mbsc-cal-txt,
  .mbsc-cal-txt-ph,
  .mbsc-cal-txt-more {
    position: relative;
    height: 1.6em;
    line-height: 1.6em;
    overflow: hidden;
    padding: 0 0.3em;
    font-size: 0.625em;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin: 0 0.1em 0.2em 0.1em;
  }
  .mbsc-no-touch .mbsc-cal-txt:before {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transition: background-color 0.2s;
  }
  .mbsc-no-touch .mbsc-cal-txt.mbsc-hover:before {
    background-color: rgba(0, 0, 0, 0.2);
  }
  .mbsc-no-touch .mbsc-cal-txt-more:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
  .mbsc-cal-txt-more {
    text-align: left;
    transition: background-color 0.2s;
  }
  .mbsc-cal .mbsc-cal-btn {
    position: relative;
    z-index: 1;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  .mbsc-bootstrap .mbsc-cal-c {
    padding: 4px 2px 2px 2px;
  }
  .mbsc-bootstrap .mbsc-cal-hdr {
    font-size: 18px;
    line-height: 40px;
  }
  .mbsc-bootstrap .mbsc-cal .mbsc-cal-btn {
    width: 40px;
    height: 40px;
    background: none;
    color: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 40px;
  }
  .mbsc-bootstrap .mbsc-cal-picker {
    max-width: none;
    display: block;
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    text-align: center;
  }
  .mbsc-bootstrap .mbsc-cal-table {
    border-spacing: 2px;
  }
  .mbsc-bootstrap .mbsc-cal-cell:focus {
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-cal-cell:focus .mbsc-cal-cell-i {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-cal-day-i {
    padding: 2px;
    line-height: 34px;
  }
  .mbsc-bootstrap .mbsc-cal-hide-diff .mbsc-cal-day-diff {
    background: none;
  }
  .mbsc-bootstrap .mbsc-cal-today.mbsc-selected {
    color: #fff !important;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-cal-weeks .mbsc-cal-days-c {
    padding-left: 26px;
  }
  .mbsc-bootstrap .mbsc-rtl .mbsc-cal-weeks .mbsc-cal-days-c {
    padding-right: 26px;
  }
  .mbsc-bootstrap .mbsc-cal-week-nr {
    width: 26px;
    font-weight: bold;
  }
  .mbsc-bootstrap .mbsc-cal-tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 4px 4px 0 4px;
    box-sizing: border-box;
  }
  .mbsc-bootstrap .mbsc-cal-tab {
    -webkit-box-flex: 1 0;
    -webkit-flex: 1 0;
    -ms-flex: 1 0;
    flex: 1 0;
    margin-bottom: -1px;
  }
  .mbsc-bootstrap .mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: 0;
  }
  .mbsc-bootstrap .mbsc-cal-has-txt .mbsc-cal-day-i {
    line-height: 30px;
    padding-top: 5px;
    padding-bottom: 15px;
  }
  .mbsc-bootstrap .mbsc-cal-marks {
    height: 6px;
    margin-top: -9px;
  }
  .mbsc-bootstrap .mbsc-cal-mark {
    width: 4px;
    height: 4px;
    margin: 1px;
    border-radius: 4px;
    background: #aaa;
  }
  .mbsc-bootstrap .mbsc-cal-txt {
    left: 1px;
    right: 1px;
    height: 15px;
    margin-top: -1px;
    font-size: 10px;
    line-height: 15px;
  }
  .mbsc-bootstrap.mbsc-fr-liq.mbsc-fr-center .mbsc-fr-popup {
    border: 0;
    border-radius: 0;
  }
  @media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
    .mbsc-fr-hb.mbsc-ios.mbsc-fr-inline .mbsc-fr-w,
    .mbsc-fr-hb.mbsc-ios.mbsc-fr-inline .mbsc-fr-c,
    .mbsc-fr-hb.mbsc-ios .mbsc-fr-hdr,
    .mbsc-fr-hb.mbsc-ios .mbsc-fr-btn-cont,
    .mbsc-fr-hb.mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w,
    .mbsc-fr-hb.mbsc-ios.mbsc-fr-bottom .mbsc-fr-popup,
    .mbsc-fr-hb.mbsc-ios.mbsc-cal-liq.mbsc-fr-center .mbsc-fr-btn-cont,
    .mbsc-fr-hb.mbsc-ios.mbsc-calendar .mbsc-fr-popup .mbsc-fr-btn-cont,
    .mbsc-fr-hb.mbsc-ios .mbsc-cal-day,
    .mbsc-fr-hb.mbsc-ios .mbsc-cal-sc-m-cell,
    .mbsc-fr-hb.mbsc-ios .mbsc-range-btn-end,
    .mbsc-fr-hb.mbsc-ios .mbsc-range-btn-end .mbsc-range-btn:before,
    .mbsc-fr-hb.mbsc-ios .mbsc-np-btn,
    .mbsc-fr-hb.mbsc-ios.mbsc-ms-more .mbsc-ms-item:after,
    .mbsc-lv-hb.mbsc-ios .mbsc-lv-item:before,
    .mbsc-lv-hb.mbsc-ios .mbsc-lv-item:after,
    .mbsc-lv-hb.mbsc-ios .mbsc-lv-gr-title,
    .mbsc-lv-hb.mbsc-ios,
    .mbsc-form-hb.mbsc-ios .mbsc-control-w:before,
    .mbsc-form-hb.mbsc-ios .mbsc-control-w:after,
    .mbsc-form-hb.mbsc-ios .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w:before,
    .mbsc-form-hb.mbsc-ios .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w:after,
    .mbsc-form-hb.mbsc-ios .mbsc-divider,
    .mbsc-form-hb.mbsc-ios .mbsc-btn-group {
      border-width: 0.5px;
    }
  }
  .mbsc-ios.mbsc-fr-top .mbsc-fr-btn-cont,
  .mbsc-ios.mbsc-fr-bottom .mbsc-fr-btn-cont {
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }
  .mbsc-ios .mbsc-fr-w {
    position: relative;
    padding-top: 3.666667em;
    font-size: 12px;
    font-family: -apple-system, Helvetica Neue, Helvetica, Arial, sans-serif;
  }
  .mbsc-ios .mbsc-fr-has-hdr .mbsc-fr-w {
    min-width: 25em;
  }
  .mbsc-ios.mbsc-fr-nobtn .mbsc-fr-w,
  .mbsc-ios.mbsc-fr-center .mbsc-fr-w {
    min-width: 16.666667em;
  }
  .mbsc-ios .mbsc-fr-hdr {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    line-height: 1.25em;
    padding: 0.75em 4.375em;
    font-size: 1.333334em;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .mbsc-ios.mbsc-fr-nobtn .mbsc-fr-hdr,
  .mbsc-ios.mbsc-fr-center:not(.mbsc-cal-liq) .mbsc-fr-hdr {
    position: relative;
    padding: 0.75em 0.5em;
    margin-bottom: -1px;
    white-space: normal;
  }
  .mbsc-ios .mbsc-fr-btn-cont {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: block;
  }
  .mbsc-ios .mbsc-ltr .mbsc-fr-btn-cont {
    text-align: right;
  }
  .mbsc-ios .mbsc-rtl .mbsc-fr-btn-cont {
    text-align: left;
  }
  .mbsc-ios .mbsc-fr-btn {
    height: 2.75em;
    line-height: 2.75em;
    padding: 0 0.625em;
    text-align: center;
    font-size: 1.333334em;
  }
  .mbsc-ios .mbsc-fr-btn.mbsc-active {
    opacity: 0.5;
  }
  .mbsc-ios .mbsc-fr-btn-w {
    display: inline-block;
  }
  .mbsc-ios .mbsc-ltr .mbsc-fr-btn-c {
    float: left;
  }
  .mbsc-ios .mbsc-rtl .mbsc-fr-btn-c {
    float: right;
  }
  .mbsc-ios .mbsc-fr-btn-s .mbsc-fr-btn {
    font-weight: bold;
  }
  .mbsc-ios.mbsc-fr-bubble .mbsc-fr-w,
  .mbsc-ios.mbsc-fr-center .mbsc-fr-w {
    border-radius: 8px;
  }
  .mbsc-ios.mbsc-fr-inline .mbsc-fr-w {
    border-radius: 0;
  }
  .mbsc-ios .mbsc-fr-arr {
    border-radius: 6px;
  }
  .mbsc-ios.mbsc-fr-bottom .mbsc-fr-overlay,
  .mbsc-ios.mbsc-fr-top .mbsc-fr-overlay {
    background: none;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-fr-w {
    padding-top: 0;
    padding-bottom: 3.75em;
  }
  .mbsc-ios.mbsc-fr-center.mbsc-fr-btn-block .mbsc-fr-w {
    padding: 0;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-fr-btn-cont {
    display: table;
    top: auto;
    bottom: 0;
    border: 0;
    table-layout: fixed;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
    display: table-cell;
    float: none;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w:first-child {
    border-left: 0;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w {
    border-left: 0;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w:first-child {
    border-right: 0;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-ltr .mbsc-fr-btn-w:first-child .mbsc-fr-btn,
  .mbsc-ios.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w:last-child .mbsc-fr-btn {
    border-radius: 0 0 0 0.5em;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-ltr .mbsc-fr-btn-w:last-child .mbsc-fr-btn,
  .mbsc-ios.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w:first-child .mbsc-fr-btn {
    border-radius: 0 0 0.5em 0;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-fr-btn.mbsc-active {
    opacity: 1;
  }
  .mbsc-ios.mbsc-fr-center.mbsc-fr-btn-block .mbsc-fr-btn-cont {
    position: static;
    display: block;
  }
  .mbsc-ios.mbsc-fr-center.mbsc-fr-btn-block .mbsc-fr-btn-w {
    display: block;
    border-left: 0;
    border-right: 0;
  }
  .mbsc-ios.mbsc-fr-center.mbsc-fr-btn-block .mbsc-fr-w .mbsc-fr-btn-w .mbsc-fr-btn {
    border-radius: 0;
  }
  .mbsc-ios.mbsc-fr-center.mbsc-fr-btn-block .mbsc-fr-btn-w:last-child .mbsc-fr-btn {
    border-radius: 0 0 0.5em 0.5em;
  }
  .mbsc-ios.mbsc-fr-inline .mbsc-fr-w {
    margin-top: -1px;
  }
  .mbsc-ios.mbsc-fr-nobtn .mbsc-fr-w {
    padding: 0;
  }
  .mbsc-ios.mbsc-fr-nobtn.mbsc-fr-bottom .mbsc-fr-w {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
  .mbsc-ios.mbsc-fr-pointer.mbsc-fr .mbsc-fr-popup {
    border: 0;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-fr-hdr {
    font-size: 1.166667em;
    line-height: 1.5em;
  }
  .mbsc-ios.mbsc-fr-top .mbsc-fr-btn-cont,
  .mbsc-ios.mbsc-fr-bottom .mbsc-fr-btn-cont {
    background: #efefef;
  }
  .mbsc-ios .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-ios .mbsc-fr-w {
    background: #f7f7f7;
    color: #000;
  }
  .mbsc-ios .mbsc-fr-hdr {
    color: #000;
  }
  .mbsc-ios.mbsc-fr-nobtn .mbsc-fr-hdr,
  .mbsc-ios.mbsc-fr-center:not(.mbsc-cal-liq) .mbsc-fr-hdr,
  .mbsc-ios .mbsc-fr-btn-cont {
    border-bottom: 1px solid #ccc;
  }
  .mbsc-ios .mbsc-fr-btn {
    color: #007bff;
  }
  .mbsc-ios .mbsc-fr-arr {
    background: #f7f7f7;
  }
  .mbsc-ios.mbsc-fr-bottom .mbsc-fr-popup {
    border-top: 1px solid #8e8e8e;
  }
  .mbsc-ios.mbsc-fr-top .mbsc-fr-popup {
    border-bottom: 1px solid #8e8e8e;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-fr-btn-w {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w {
    border-right: 1px solid #ccc;
  }
  .mbsc-ios.mbsc-fr-center.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
  .mbsc-ios.mbsc-fr-center .mbsc-fr-btn.mbsc-active {
    background: #eaeaea;
  }
  .mbsc-ios.mbsc-fr-inline .mbsc-fr-w {
    background: #fff;
    border-top: 1px solid #ccc;
  }
  .mbsc-ios.mbsc-fr-inline .mbsc-fr-c {
    border-bottom: 1px solid #ccc;
  }
  .mbsc-ios.mbsc-fr-no-overlay .mbsc-fr-arr {
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
  }
  .mbsc-ios.mbsc-fr-no-overlay .mbsc-fr-w {
    box-shadow: 0 0.125em 1em rgba(0, 0, 0, 0.2);
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-fr-persp .mbsc-fr-popup .mbsc-fr-w,
  .mbsc-ios.mbsc-fr-pointer .mbsc-fr-persp .mbsc-cal-picker {
    background: #f7f7f7;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-fr-hdr {
    border-color: #ccc;
  }
  .mbsc-ios.mbsc-sc.mbsc-fr-top .mbsc-fr-btn-cont,
  .mbsc-ios.mbsc-sc.mbsc-fr-bottom .mbsc-fr-btn-cont {
    border-bottom: 0;
  }
  .mbsc-ios .mbsc-sc-lbl {
    line-height: 2.5em;
  }
  .mbsc-ios .mbsc-sc-whl-gr-c {
    padding: 0 3%;
  }
  .mbsc-ios .mbsc-sc-whl-gr {
    padding: 0.833333em;
  }
  .mbsc-ios .mbsc-sc-lbl-v {
    margin-bottom: -1.666667em;
  }
  .mbsc-ios .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 2.5em;
    padding-bottom: 2.5em;
  }
  .mbsc-ios .mbsc-sc-whl-l,
  .mbsc-ios.mbsc-calendar .mbsc-sc-whl-l {
    display: block;
    margin: 0 -0.833333em;
  }
  .mbsc-ios .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: none;
  }
  .mbsc-ios .mbsc-sc-whl-o,
  .mbsc-ios.mbsc-calendar .mbsc-sc-whl-o {
    display: block;
  }
  .mbsc-ios .mbsc-sc-itm {
    padding: 0 0.5em;
    font-size: 1.833333em;
  }
  .mbsc-ios.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-ios .mbsc-sc-itm:focus {
    outline: 0;
  }
  .mbsc-ios .mbsc-sc-whl-gr-3d-c .mbsc-sc-whl-l {
    z-index: 3;
  }
  .mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    margin: 0 -0.166667em;
  }
  .mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
    font-size: 1.666666em;
  }
  .mbsc-ios .mbsc-sc-cp .mbsc-sc-whl .mbsc-sc-itm {
    text-align: center;
  }
  .mbsc-ios .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 2.666667em 0;
  }
  .mbsc-ios .mbsc-sc-btn {
    height: 2.666667em;
    line-height: 2.666667em;
    opacity: 1;
  }
  .mbsc-ios .mbsc-sc-btn:before {
    font-size: 2em;
  }
  .mbsc-ios .mbsc-sc-btn.mbsc-active:before {
    opacity: 0.5;
  }
  .mbsc-ios.mbsc-sel-multi .mbsc-sc-whl-l {
    display: none;
  }
  .mbsc-ios .mbsc-sc-whl-multi .mbsc-sc-whl-o {
    display: none;
  }
  .mbsc-ios .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 1.818181em;
  }
  .mbsc-ios .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    font-size: 1.818181em;
  }
  .mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-whl-multi .mbsc-sc-whl-o {
    display: block;
  }
  .mbsc-ios.mbsc-sel-multi .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    visibility: hidden;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sc-whl-o {
    display: none;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sc-lbl-v {
    margin-bottom: -2.5em;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sc-lbl {
    padding: 0 1.666667em;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sc-itm {
    font-size: 1.333334em;
    padding: 0 1.25em;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 2.5em;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sc-itm-sel:before {
    font-size: 2em;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-ltr .mbsc-sc-itm-sel:before {
    left: 0.125em;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-rtl .mbsc-sc-itm-sel:before {
    right: 0.125em;
  }
  .mbsc-ios.mbsc-sc.mbsc-fr-top .mbsc-fr-w,
  .mbsc-ios.mbsc-sc.mbsc-fr-bottom .mbsc-fr-w {
    background: #d1d5db;
  }
  .mbsc-ios.mbsc-calendar .mbsc-fr-persp .mbsc-fr-w {
    background: #f7f7f7;
  }
  .mbsc-ios.mbsc-calendar.mbsc-fr-top .mbsc-fr-btn-cont,
  .mbsc-ios.mbsc-calendar.mbsc-fr-bottom .mbsc-fr-btn-cont {
    border-bottom: 1px solid #ccc;
  }
  .mbsc-ios.mbsc-fr-top .mbsc-sc-whl-l,
  .mbsc-ios.mbsc-fr-bottom .mbsc-sc-whl-l {
    border-top: 1px solid #abaaaa;
    border-bottom: 1px solid #abaaaa;
  }
  .mbsc-ios .mbsc-sc-whl-l,
  .mbsc-ios.mbsc-calendar .mbsc-sc-whl-l {
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  .mbsc-ios.mbsc-fr-top .mbsc-sc-whl-o,
  .mbsc-ios.mbsc-fr-bottom .mbsc-sc-whl-o {
    background: -webkit-linear-gradient(#d1d5db, rgba(209, 213, 219, 0) 52%, rgba(209, 213, 219, 0) 48%, #d1d5db);
    background: linear-gradient(#d1d5db, rgba(209, 213, 219, 0) 52%, rgba(209, 213, 219, 0) 48%, #d1d5db);
  }
  .mbsc-ios .mbsc-sc-whl-o,
  .mbsc-ios.mbsc-calendar .mbsc-sc-whl-o {
    background: -webkit-linear-gradient(#f7f7f7, rgba(247, 247, 247, 0) 52%, rgba(247, 247, 247, 0) 48%, #f7f7f7);
    background: linear-gradient(#f7f7f7, rgba(247, 247, 247, 0) 52%, rgba(247, 247, 247, 0) 48%, #f7f7f7);
  }
  .mbsc-ios.mbsc-fr-top .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c,
  .mbsc-ios.mbsc-fr-bottom .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    background: #d1d5db;
  }
  .mbsc-ios.mbsc-fr.mbsc-fr-inline .mbsc-sc-whl-o {
    background: -webkit-linear-gradient(#fff, rgba(255, 255, 255, 0) 52%, rgba(255, 255, 255, 0) 48%, #fff);
    background: linear-gradient(#fff, rgba(255, 255, 255, 0) 52%, rgba(255, 255, 255, 0) 48%, #fff);
  }
  .mbsc-ios.mbsc-fr.mbsc-fr-inline .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    background: #fff;
  }
  .mbsc-ios .mbsc-sc-lbl {
    color: #ababab;
  }
  .mbsc-ios .mbsc-sc-itm {
    color: #9e9e9e;
  }
  .mbsc-ios.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-ios .mbsc-sc-itm:focus {
    background: rgba(0, 123, 255, 0.15);
  }
  .mbsc-ios.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 123, 255, 0.2);
  }
  .mbsc-ios .mbsc-sc-itm-sel,
  .mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm {
    color: #000;
  }
  .mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c,
  .mbsc-ios.mbsc-calendar .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    background: #f7f7f7;
  }
  .mbsc-ios .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
    color: #a3a3a3;
  }
  .mbsc-ios .mbsc-sc-btn {
    color: #007bff;
  }
  .mbsc-ios.mbsc-sel-multi .mbsc-sc-itm {
    color: #000;
  }
  .mbsc-ios .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #007bff;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sc-whl-l {
    border-color: #ccc;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sc-itm {
    color: #000;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sc-itm-sel {
    color: #007bff;
  }
  .mbsc-ios .mbsc-cal {
    font-size: 1.333334em;
  }
  .mbsc-ios .mbsc-cal-hdr {
    padding-top: 0.5em;
  }
  .mbsc-ios .mbsc-cal .mbsc-cal-btn {
    width: 2.086956em;
    height: 2.086956em;
    padding: 0;
    line-height: 2.086956em;
    font-size: 1.4375em;
    margin: -0.173913em;
    border-radius: 2em;
  }
  .mbsc-ios .mbsc-cal-year,
  .mbsc-ios .mbsc-cal-month {
    font-size: 1.125em;
    line-height: 2.222223em;
  }
  .mbsc-ios .mbsc-cal-picker .mbsc-cal-cell-i {
    display: inline-block;
    height: 2em;
    padding: 0 1em;
    line-height: 2em;
    border-radius: 2em;
    white-space: nowrap;
  }
  .mbsc-ios .mbsc-cal-days > div {
    font-size: 0.75em;
  }
  .mbsc-ios .mbsc-cal-day-scroll-c .mbsc-cal-slide {
    padding-bottom: 0.5em;
  }
  .mbsc-ios .mbsc-cal-hide-diff .mbsc-cal-row:not(:first-child) .mbsc-cal-day-diff {
    border-top-color: transparent;
  }
  .mbsc-ios .mbsc-cal-day-date {
    display: inline-block;
    width: 1.625em;
    height: 1.625em;
    margin: 0.3125em 0;
    line-height: 1.625em;
    border: 2px solid transparent;
    border-radius: 2em;
  }
  .mbsc-ios .mbsc-cal-week-nr {
    width: 2.363636em;
    font-size: 0.6875em;
  }
  .mbsc-ios .mbsc-cal-tabs-c {
    padding: 0.666667em 0.666667em 0 0.666667em;
  }
  .mbsc-ios .mbsc-cal-tab {
    line-height: 1.928571em;
    font-size: 1.166667em;
    padding: 0 0.714285em;
  }
  .mbsc-ios .mbsc-ltr .mbsc-cal-tab:first-child {
    border-right: 0;
    border-radius: 0.285714em 0 0 0.285714em;
  }
  .mbsc-ios .mbsc-ltr .mbsc-cal-tab:last-child {
    border-left: 0;
    border-radius: 0 0.285714em 0.285714em 0;
  }
  .mbsc-ios .mbsc-rtl .mbsc-cal-tab:last-child {
    border-right: 0;
    border-radius: 0.285714em 0 0 0.285714em;
  }
  .mbsc-ios .mbsc-rtl .mbsc-cal-tab:first-child {
    border-left: 0;
    border-radius: 0 0.285714em 0.285714em 0;
  }
  .mbsc-ios .mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: 0.4375em;
  }
  .mbsc-ios .mbsc-cal-has-marks .mbsc-cal-day-date {
    margin: 0.1875em 0;
  }
  .mbsc-ios .mbsc-cal-marks {
    margin-top: -0.0625em;
  }
  .mbsc-ios .mbsc-cal-mark {
    width: 0.375em;
    height: 0.375em;
    border-radius: 0.375em;
    margin: 0 0.0625em;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-cal-c,
  .mbsc-ios.mbsc-fr-bubble .mbsc-cal-c {
    padding: 0 0.666667em;
  }
  .mbsc-ios.mbsc-fr-liq.mbsc-calendar.mbsc-fr-center .mbsc-fr-w {
    padding-bottom: 0;
    padding-top: 3.75em;
  }
  .mbsc-ios.mbsc-fr-liq.mbsc-calendar.mbsc-fr-center.mbsc-fr-nobtn .mbsc-fr-w {
    padding: 0;
  }
  .mbsc-ios.mbsc-fr-liq.mbsc-calendar.mbsc-fr-center .mbsc-cal-c {
    padding: 0;
  }
  .mbsc-ios.mbsc-fr-liq.mbsc-calendar.mbsc-fr-center .mbsc-cal-day-scroll-c {
    padding: 0;
  }
  .mbsc-ios.mbsc-fr-liq.mbsc-calendar.mbsc-fr-center .mbsc-fr-btn-cont {
    top: 0;
    bottom: auto;
    display: block;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }
  .mbsc-ios.mbsc-fr-liq.mbsc-calendar.mbsc-fr-center .mbsc-fr-btn-w {
    float: right;
    border: 0;
  }
  .mbsc-ios.mbsc-fr-liq.mbsc-calendar.mbsc-fr-center .mbsc-fr-w .mbsc-fr-btn-cont .mbsc-fr-btn-w .mbsc-fr-btn {
    background: none;
  }
  .mbsc-ios.mbsc-fr-liq.mbsc-calendar.mbsc-fr-center .mbsc-fr-btn-c {
    float: left;
  }
  .mbsc-ios.mbsc-fr-liq.mbsc-calendar.mbsc-fr-center .mbsc-fr-btn-s .mbsc-fr-btn {
    font-weight: bold;
  }
  .mbsc-ios.mbsc-fr-liq.mbsc-calendar.mbsc-fr-center .mbsc-fr-btn.mbsc-active {
    background: transparent;
    opacity: 0.5;
  }
  .mbsc-ios .mbsc-cal-picker {
    background: #f7f7f7;
  }
  .mbsc-ios .mbsc-cal-cell {
    border-top: 1px solid #ccc;
  }
  .mbsc-ios .mbsc-cal-today {
    color: #007bff;
  }
  .mbsc-ios .mbsc-cal-cell:focus .mbsc-cal-cell-txt,
  .mbsc-ios.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
  .mbsc-ios.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover {
    background: rgba(0, 123, 255, 0.3);
  }
  .mbsc-ios .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
    border-color: #007bff;
    background: #007bff;
    color: #fff;
  }
  .mbsc-ios .mbsc-cal-tab {
    border: 1px solid #007bff;
    color: #007bff;
  }
  .mbsc-ios .mbsc-fr-popup .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
    background: #007bff;
    color: #fff;
  }
  .mbsc-ios .mbsc-cal-mark {
    background: #ccc;
  }
  .mbsc-ios .mbsc-cal-txt {
    background: #c3d6ef;
    color: #000;
  }
  .mbsc-ios.mbsc-fr-inline .mbsc-cal-picker {
    background: #fff;
  }
  .mbsc-ios .mbsc-fr-btn-cont {
    border-bottom: 1px solid #ccc;
  }
  .mbsc-material .mbsc-ripple {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    border-radius: 1000em;
    pointer-events: none;
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transition: -webkit-transform 0.8s cubic-bezier(0.25, 0.8, 0.25, 1),
      opacity 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
    transition: transform 0.8s cubic-bezier(0.25, 0.8, 0.25, 1), opacity 0.8s cubic-bezier(0.25, 0.8, 0.25, 1);
  }
  .mbsc-material .mbsc-ripple-scaled {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .mbsc-material .mbsc-ripple-visible {
    opacity: 0.1;
  }
  .mbsc-material .mbsc-fr-w {
    border-radius: 0.1875em;
    min-width: 15em;
    font-size: 16px;
  }
  .mbsc-material .mbsc-fr-hdr {
    padding: 0 0.6666em;
    padding-top: 0.6666em;
    font-size: 0.75em;
    font-weight: bold;
    text-transform: uppercase;
    min-height: 2em;
    line-height: 2em;
  }
  .mbsc-material .mbsc-fr-btn-cont {
    display: block;
    overflow: hidden;
    padding: 0 0.5em 0.5em 0.5em;
  }
  .mbsc-material .mbsc-ltr .mbsc-fr-btn-cont {
    text-align: right;
  }
  .mbsc-material .mbsc-rtl .mbsc-fr-btn-cont {
    text-align: left;
  }
  .mbsc-material .mbsc-fr-btn-w {
    display: inline-block;
  }
  .mbsc-material .mbsc-fr-btn {
    position: relative;
    height: 2.4em;
    line-height: 2.4em;
    padding: 0 1em;
    border-radius: 2px;
    font-weight: bold;
    text-transform: uppercase;
  }
  .mbsc-material .mbsc-fr-btn-cont .mbsc-fr-btn {
    font-size: 0.9375em;
  }
  .mbsc-material .mbsc-fr-btn-e {
    transition: background-color 0.2s ease-out;
  }
  .mbsc-material.mbsc-fr-inline .mbsc-fr-w {
    box-shadow: none;
  }
  .mbsc-material.mbsc-fr-inline .mbsc-fr-w,
  .mbsc-material.mbsc-fr-top .mbsc-fr-w,
  .mbsc-material.mbsc-fr-bottom .mbsc-fr-w {
    border-radius: 0;
  }
  .mbsc-material .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.6);
  }
  .mbsc-material .mbsc-fr-w {
    background: #eee;
    color: #5b5b5b;
    box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.3);
  }
  .mbsc-material .mbsc-fr-hdr {
    color: #009688;
  }
  .mbsc-material .mbsc-fr-btn {
    color: #009688;
  }
  .mbsc-material.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
  .mbsc-material .mbsc-fr-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material .mbsc-fr-arr {
    box-shadow: 0 0 1.5em rgba(0, 0, 0, 0.2);
    background: #eee;
  }
  .mbsc-material .mbsc-sc-whl-gr {
    padding: 2em 0.25em;
  }
  .mbsc-material .mbsc-sc-cp {
    padding: 0.5em 0.25em;
  }
  .mbsc-material .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 2.5em;
    padding-bottom: 0;
  }
  .mbsc-material .mbsc-sc-lbl {
    line-height: 2.666666em;
    font-size: 0.75em;
    font-weight: bold;
    text-transform: uppercase;
  }
  .mbsc-material .mbsc-sc-whl-w {
    margin: 0 0.25em;
    padding: 0.5em 0;
  }
  .mbsc-material .mbsc-sc-itm {
    padding: 0 0.272727em;
    font-size: 1.375em;
  }
  .mbsc-material.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-material .mbsc-sc-itm:focus {
    outline: 0;
  }
  .mbsc-material.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
    border-radius: 2px;
  }
  .mbsc-material .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 2em 0;
  }
  .mbsc-material .mbsc-sc-btn {
    height: 2em;
    line-height: 2em;
    overflow: hidden;
  }
  .mbsc-material .mbsc-sc-btn:before {
    font-size: 1.5em;
  }
  .mbsc-material .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 1.818181em;
  }
  .mbsc-material .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 1.818181em;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sc-lbl {
    padding-left: 1.666667em;
    padding-right: 1.666667em;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sc-whl-w {
    margin: 0;
    padding: 0;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sc-itm {
    font-size: 1em;
    padding: 0 1.25em;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 2.5em;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 2em;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-ltr .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    left: 0.25em;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-rtl .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    right: 0.25em;
  }
  .mbsc-material .mbsc-sc-lbl {
    color: #009688;
  }
  .mbsc-material.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-material .mbsc-sc-itm:focus {
    background: rgba(0, 0, 0, 0.05);
  }
  .mbsc-material.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material .mbsc-sc-whl-l {
    border-top: 2px solid #009688;
    border-bottom: 2px solid #009688;
  }
  .mbsc-material .mbsc-sc-btn {
    color: #009688;
    background: #eee;
  }
  .mbsc-material.mbsc-no-touch .mbsc-sc-btn:hover,
  .mbsc-material .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    color: #009688;
  }
  .mbsc-material .mbsc-cal-c {
    padding: 0.5em;
  }
  .mbsc-material .mbsc-cal .mbsc-cal-btn {
    width: 1.5em;
    height: 1.5em;
    padding: 0;
    line-height: 1.5em;
    font-size: 2em;
    border-radius: 2em;
    margin: -0.125em;
  }
  .mbsc-material .mbsc-cal-year,
  .mbsc-material .mbsc-cal-month {
    font-size: 1.125em;
    font-weight: bold;
    line-height: 2.222223em;
  }
  .mbsc-material .mbsc-cal-picker .mbsc-cal-cell-i {
    display: inline-block;
    height: 2em;
    padding: 0 1em;
    line-height: 2em;
    border-radius: 2em;
    white-space: nowrap;
  }
  .mbsc-material .mbsc-cal-days > div {
    font-size: 0.75em;
    font-weight: bold;
  }
  .mbsc-material .mbsc-cal-day-date {
    display: inline-block;
    width: 2.461539em;
    height: 2.461539em;
    margin: 0.153846em 0;
    line-height: 2.461539em;
    font-size: 0.8125em;
    border: 2px solid transparent;
    border-radius: 2em;
  }
  .mbsc-material .mbsc-cal-week-nr {
    width: 2.363636em;
    font-size: 0.6875em;
    font-weight: bold;
  }
  .mbsc-material .mbsc-cal-tabs-c {
    line-height: 1.875em;
    padding: 0.5em 0.5em 0 0.5em;
    text-transform: uppercase;
  }
  .mbsc-material .mbsc-cal-tab {
    font-size: 0.75em;
    padding: 0 0.5em;
  }
  .mbsc-material.mbsc-cal-tabbed .mbsc-fr-hdr {
    padding-top: 0;
  }
  .mbsc-material.mbsc-cal-tabbed .mbsc-cal-c {
    padding-top: 0;
  }
  .mbsc-material .mbsc-cal-marks {
    margin-top: -0.5em;
    transition: transform 0.1s ease-out;
  }
  .mbsc-material.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled):hover .mbsc-cal-marks,
  .mbsc-material .mbsc-selected .mbsc-cal-marks {
    -webkit-transform: translate3d(0, 0.375em, 0);
    transform: translate3d(0, 0.375em, 0);
  }
  .mbsc-material .mbsc-cal-txt {
    border-radius: 0.2em;
  }
  .mbsc-material .mbsc-cal-picker {
    background: #eee;
  }
  .mbsc-material .mbsc-cal-today,
  .mbsc-material .mbsc-cal-tab.mbsc-selected {
    color: #009688;
  }
  .mbsc-material.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
  .mbsc-material.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
  .mbsc-material .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
    background: rgba(0, 150, 136, 0.3);
  }
  .mbsc-material .mbsc-cal-c .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
    border-color: #009688;
  }
  .mbsc-material .mbsc-cal-mark {
    background: #009688;
  }
  .mbsc-material .mbsc-cal-txt {
    color: #eee;
    background: #7c7c7c;
  }
  .mbsc-material .mbsc-cal-icons {
    color: #5b5b5b;
  }
  .mbsc-mobiscroll .mbsc-fr-w {
    min-width: 16em;
    font-size: 16px;
  }
  .mbsc-mobiscroll .mbsc-fr-hdr {
    padding: 0 0.6666em;
    padding-top: 0.6666em;
    font-size: 0.75em;
    text-transform: uppercase;
    min-height: 2em;
    line-height: 2em;
  }
  .mbsc-mobiscroll .mbsc-fr-btn-cont {
    display: block;
    overflow: hidden;
    text-align: right;
    padding: 0 0.5em 0.5em 0.5em;
  }
  .mbsc-mobiscroll .mbsc-ltr .mbsc-fr-btn-cont {
    text-align: right;
  }
  .mbsc-mobiscroll .mbsc-rtl .mbsc-fr-btn-cont {
    text-align: left;
  }
  .mbsc-mobiscroll .mbsc-fr-btn-w {
    display: inline-block;
  }
  .mbsc-mobiscroll .mbsc-fr-btn {
    height: 2.5em;
    line-height: 2.5em;
    padding: 0 1em;
    text-transform: uppercase;
  }
  .mbsc-mobiscroll.mbsc-fr-center .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-fr-bubble .mbsc-fr-w {
    border-radius: 0.25em;
  }
  .mbsc-mobiscroll.mbsc-fr-no-overlay .mbsc-fr-arr {
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.2);
  }
  .mbsc-mobiscroll.mbsc-fr-no-overlay .mbsc-fr-w {
    box-shadow: 0 0.125em 1em rgba(0, 0, 0, 0.3);
  }
  .mbsc-mobiscroll.mbsc-fr-no-overlay.mbsc-fr-bubble .mbsc-fr-w {
    border-radius: 0.25em;
  }
  .mbsc-mobiscroll .mbsc-fr-w {
    background: #f7f7f7;
    color: #454545;
  }
  .mbsc-mobiscroll .mbsc-fr-hdr,
  .mbsc-mobiscroll .mbsc-fr-btn {
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-fr-btn.mbsc-active,
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
    background: rgba(78, 204, 196, 0.3);
  }
  .mbsc-mobiscroll .mbsc-fr-arr {
    background: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-sc-whl-gr {
    padding: 0.5em 0.25em;
  }
  .mbsc-mobiscroll .mbsc-sc-whl-w {
    margin: 0 0.25em;
  }
  .mbsc-mobiscroll .mbsc-sc-lbl-v .mbsc-sc-whl-w {
    margin-top: 1.875em;
  }
  .mbsc-mobiscroll .mbsc-sc-lbl {
    font-size: 0.75em;
    line-height: 2.5em;
    text-transform: uppercase;
  }
  .mbsc-mobiscroll .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 2em 0;
  }
  .mbsc-mobiscroll .mbsc-sc-btn {
    height: 2em;
    line-height: 2em;
  }
  .mbsc-mobiscroll .mbsc-sc-btn:before {
    font-size: 1.5em;
  }
  .mbsc-mobiscroll .mbsc-sc-itm {
    padding: 0 0.25em;
    font-size: 1.375em;
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-mobiscroll .mbsc-sc-itm:focus {
    outline: 0;
  }
  .mbsc-mobiscroll .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 1.818181em;
  }
  .mbsc-mobiscroll .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    font-size: 1.818181em;
  }
  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-lbl {
    padding-left: 1.666667em;
    padding-right: 1.666667em;
  }
  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-whl-w {
    margin-left: 0;
    margin-right: 0;
  }
  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-itm {
    font-size: 1em;
    padding: 0 1.25em;
  }
  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 2.5em;
  }
  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    font-size: 2em;
  }
  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-ltr .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    left: 0.125em;
  }
  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-rtl .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    right: 0.125em;
  }
  .mbsc-mobiscroll .mbsc-sc-lbl {
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-sc-whl-l {
    border-top: 1px solid #4eccc4;
    border-bottom: 1px solid #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-sc-btn {
    color: #4eccc4;
    background: #f7f7f7;
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-mobiscroll .mbsc-sc-itm:focus {
    background: rgba(69, 69, 69, 0.1);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-sc-btn:hover:before,
  .mbsc-mobiscroll.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active,
  .mbsc-mobiscroll .mbsc-sc-btn.mbsc-active:before {
    background: rgba(78, 204, 196, 0.3);
  }
  .mbsc-mobiscroll .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-cal-c {
    padding: 0.5em;
  }
  .mbsc-mobiscroll .mbsc-cal-btn-w {
    font-size: 1.125em;
    line-height: 2.222223em;
  }
  .mbsc-mobiscroll .mbsc-cal .mbsc-cal-btn {
    width: 2.666667em;
    height: 2.666667em;
    line-height: 2.666667em;
    padding: 0;
    border-radius: 2em;
    margin: -0.222222em;
  }
  .mbsc-mobiscroll .mbsc-cal-picker {
    border-top: 1px solid transparent;
  }
  .mbsc-mobiscroll .mbsc-cal-picker .mbsc-cal-cell-i {
    display: inline-block;
    height: 2em;
    padding: 0 1em;
    line-height: 2em;
    border-radius: 2em;
    white-space: nowrap;
  }
  .mbsc-mobiscroll .mbsc-cal-days > div {
    font-size: 0.6875em;
  }
  .mbsc-mobiscroll .mbsc-cal-day-date {
    display: inline-block;
    width: 2.153847em;
    height: 2.153847em;
    margin: 0.307692em 0;
    line-height: 2.153847em;
    font-size: 0.8125em;
    border: 2px solid transparent;
    border-radius: 2em;
  }
  .mbsc-mobiscroll .mbsc-cal-week-nr {
    width: 2.363637em;
    font-size: 0.6875em;
  }
  .mbsc-mobiscroll .mbsc-cal-tabs-c {
    line-height: 1.875em;
    padding: 0.5em 0.5em 0 0.5em;
    text-transform: uppercase;
  }
  .mbsc-mobiscroll .mbsc-cal-tab {
    font-size: 0.7em;
    padding: 0 1.2em;
  }
  .mbsc-mobiscroll .mbsc-cal-picker {
    background: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-cal-days > div {
    border-bottom: 1px solid #4eccc4;
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-cal-today {
    color: #4eccc4;
  }
  .mbsc-mobiscroll.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
  .mbsc-mobiscroll .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
    background: rgba(78, 204, 196, 0.3);
  }
  .mbsc-mobiscroll .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
    background: #4eccc4;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
    border-color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-cal-week-nr {
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-cal-tab {
    border: 1px solid #4eccc4;
    color: #454545;
  }
  .mbsc-mobiscroll .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
    background: #4eccc4;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-cal-mark {
    background: #454545;
  }
  .mbsc-mobiscroll .mbsc-cal-txt {
    color: #f7f7f7;
    background: #454545;
  }
  .mbsc-mobiscroll .mbsc-cal-icons {
    color: #454545;
  }
  .mbsc-windows .mbsc-fr-w {
    font-size: 16px;
  }
  .mbsc-windows .mbsc-ltr .mbsc-fr-btn-w .mbsc-fr-btn:before {
    padding: 0 0.375em 0 0;
  }
  .mbsc-windows .mbsc-rtl .mbsc-fr-btn-w .mbsc-fr-btn:before {
    padding: 0 0 0 0.375em;
  }
  .mbsc-windows.mbsc-fr-inline .mbsc-fr-w {
    border: 0;
  }
  .mbsc-windows .mbsc-fr-hdr {
    padding: 0.5em;
    font-weight: bold;
  }
  .mbsc-windows .mbsc-fr-btn {
    height: 2.5em;
    line-height: 2.5em;
    text-align: center;
    padding: 0 0.375em;
  }
  .mbsc-windows .mbsc-fr-arr-w {
    margin: -1px 0;
  }
  .mbsc-windows .mbsc-fr-w {
    background: #f2f2f2;
    color: #262626;
    border: 1px solid #ccc;
  }
  .mbsc-windows .mbsc-fr-hdr {
    border-bottom: 2px solid #ccc;
  }
  .mbsc-windows .mbsc-fr-btn-cont {
    border-top: 2px solid #ccc;
  }
  .mbsc-windows .mbsc-fr-btn-w {
    background: #f2f2f2;
  }
  .mbsc-windows .mbsc-fr-btn {
    color: #262626;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
  .mbsc-windows .mbsc-fr-btn.mbsc-active {
    background: #d9d8d8;
  }
  .mbsc-windows .mbsc-fr-arr {
    background: #f2f2f2;
    border: 1px solid #ccc;
  }
  .mbsc-windows .mbsc-sc-whl-gr-c {
    margin-bottom: -2px;
  }
  .mbsc-windows .mbsc-ltr .mbsc-sc-whl-w:last-child,
  .mbsc-windows .mbsc-rtl .mbsc-sc-whl-w:first-child {
    border-right: 0;
  }
  .mbsc-windows .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding: 1.875em 0;
  }
  .mbsc-windows .mbsc-sc-lbl-v {
    border: 0;
    margin-bottom: -1.875em;
  }
  .mbsc-windows .mbsc-sc-lbl {
    font-size: 0.75em;
    line-height: 2.5em;
  }
  .mbsc-windows .mbsc-sc-whl-l {
    display: block;
    z-index: 1;
  }
  .mbsc-windows .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: none;
  }
  .mbsc-windows .mbsc-sc-itm {
    padding: 0 0.5em;
  }
  .mbsc-windows .mbsc-sc-btn {
    opacity: 0;
    height: 1.375em;
    line-height: 1.375em;
    overflow: hidden;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-sc-whl-w:hover .mbsc-sc-btn {
    opacity: 1;
  }
  .mbsc-windows.mbsc-sel-multi .mbsc-sc-whl-l {
    display: none;
  }
  .mbsc-windows .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 2.5em;
  }
  .mbsc-windows .mbsc-sc-whl-multi .mbsc-sc-btn {
    display: none;
  }
  .mbsc-windows .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 2.5em;
  }
  .mbsc-windows.mbsc-fr-pointer .mbsc-sc-lbl {
    padding: 0 1.666667em;
  }
  .mbsc-windows.mbsc-fr-pointer .mbsc-sc-itm {
    padding: 0 1.25em;
  }
  .mbsc-windows.mbsc-fr-pointer .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 2.5em;
  }
  .mbsc-windows.mbsc-fr-pointer .mbsc-ltr .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    left: 0;
  }
  .mbsc-windows.mbsc-fr-pointer .mbsc-rtl .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    right: 0;
  }
  .mbsc-windows .mbsc-sc-bar:after {
    border-radius: 0;
  }
  .mbsc-windows .mbsc-sc-whl-gr-c {
    border-bottom: 2px solid #ccc;
  }
  .mbsc-windows .mbsc-sc-whl-w {
    border-right: 2px solid #ccc;
  }
  .mbsc-windows .mbsc-sc-lbl-v .mbsc-sc-whl-w {
    background: #f2f2f2;
  }
  .mbsc-windows .mbsc-sc-lbl-v {
    background: #ccc;
  }
  .mbsc-windows .mbsc-sc-whl-l {
    background: rgba(0, 120, 215, 0.4);
  }
  .mbsc-windows.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-windows .mbsc-sc-itm:focus {
    background: rgba(38, 38, 38, 0.1);
  }
  .mbsc-windows.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
    background: rgba(38, 38, 38, 0.2);
  }
  .mbsc-windows .mbsc-sc-btn {
    background: #e5e5e5;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-sc-btn:hover,
  .mbsc-windows .mbsc-sc-btn.mbsc-active {
    background: #d9d8d8;
  }
  .mbsc-windows .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #0078d7;
  }
  .mbsc-windows .mbsc-cal-month,
  .mbsc-windows .mbsc-cal-year {
    font-size: 1.125em;
    line-height: 2.222223em;
  }
  .mbsc-windows .mbsc-cal .mbsc-cal-btn {
    width: 3em;
    height: 3em;
    line-height: 3em;
    padding: 0;
    margin: -0.25em;
  }
  .mbsc-windows .mbsc-cal-c {
    padding: 0.5em;
  }
  .mbsc-windows .mbsc-cal-picker {
    padding: 0.167em;
  }
  .mbsc-windows .mbsc-cal-days > div {
    font-size: 0.9375em;
  }
  .mbsc-windows .mbsc-cal-day {
    padding: 0;
  }
  .mbsc-windows .mbsc-cal-day-date {
    font-size: 0.875em;
    line-height: 2.285714em;
    border: 2px solid transparent;
    box-sizing: border-box;
  }
  .mbsc-windows .mbsc-cal-day-diff .mbsc-cal-day-i {
    opacity: 1;
  }
  .mbsc-windows .mbsc-cal-week-nr {
    width: 2.166667em;
    font-size: 0.75em;
    font-weight: bold;
  }
  .mbsc-windows .mbsc-cal-tabs {
    border-collapse: collapse;
  }
  .mbsc-windows .mbsc-cal-tabs .mbsc-cal-tab {
    top: 0;
    font-size: 0.75em;
    line-height: 2.333334em;
    border-bottom: 4px solid transparent;
    border-top: 4px solid transparent;
  }
  .mbsc-windows .mbsc-cal-marks {
    margin-top: -0.625em;
  }
  .mbsc-windows .mbsc-cal-picker {
    background: #f2f2f2;
  }
  .mbsc-windows .mbsc-cal-days > div {
    color: #737272;
  }
  .mbsc-windows .mbsc-cal-today .mbsc-cal-day-date {
    color: #0078d7;
  }
  .mbsc-windows .mbsc-cal-day-diff .mbsc-cal-day-i {
    background: #e5e5e5;
  }
  .mbsc-windows .mbsc-cal-cell:focus .mbsc-cal-day-i {
    background: #d9d8d8;
  }
  .mbsc-windows.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-day:not(.mbsc-disabled):hover .mbsc-cal-day-i,
  .mbsc-windows.mbsc-no-touch .mbsc-cal-picker .mbsc-cal-cell:not(.mbsc-disabled):hover {
    background: #d9d8d8;
  }
  .mbsc-windows .mbsc-cal .mbsc-cal-day.mbsc-selected:not(.mbsc-disabled) .mbsc-cal-cell-i.mbsc-cal-day-i,
  .mbsc-windows .mbsc-cal .mbsc-cal-picker .mbsc-cal-cell.mbsc-selected,
  .mbsc-windows.mbsc-no-touch .mbsc-cal-picker .mbsc-cal-cell.mbsc-selected:hover {
    background: rgba(0, 120, 215, 0.25);
  }
  .mbsc-windows.mbsc-no-touch .mbsc-cal-tab:hover {
    background: #d9d8d8;
    border-color: #d9d8d8;
  }
  .mbsc-windows .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected,
  .mbsc-windows.mbsc-no-touch .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected:hover {
    border-bottom-color: #0078d7;
    border-top-color: transparent;
    color: #0078d7;
    background: #f2f2f2;
  }
  .mbsc-windows .mbsc-cal-tabs-c {
    border-bottom: 1px solid #ccc;
  }
  .mbsc-windows .mbsc-cal-mark {
    background: #262626;
  }
  .mbsc-windows .mbsc-cal-txt {
    color: #000;
    background: #c4c4c4;
  }
  .mbsc-collapsible.mbsc-form-group {
    margin: 0;
  }
  .mbsc-collapsible-header {
    position: relative;
    padding-right: 3em;
  }
  .mbsc-collapsible .mbsc-collapsible-icon {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 50%;
    right: 1em;
    margin-top: -0.5em;
    line-height: 1em;
    font-size: 1em;
    transition: transform 0.125s ease-out;
  }
  .mbsc-collapsible .mbsc-collapsible-content {
    overflow: hidden;
    transition: height 0.125s ease;
    box-sizing: content-box;
  }
  .mbsc-collapsible:not(.mbsc-collapsible-open) .mbsc-collapsible-content {
    height: 0;
    padding-bottom: 0;
  }
  .mbsc-collapsible-open > .mbsc-collapsible-header .mbsc-collapsible-icon {
    -webkit-transform: rotateX(180deg);
    transform: rotateX(180deg);
  }
  .mbsc-rtl .mbsc-collapsible.mbsc-form-group .mbsc-collapsible-header,
  .mbsc-rtl .mbsc-collapsible.mbsc-form-group .mbsc-collapsible-header.mbsc-form-group-title,
  .mbsc-rtl .mbsc-collapsible.mbsc-card .mbsc-collapsible-header,
  .mbsc-rtl .mbsc-collapsible.mbsc-card .mbsc-collapsible-header.mbsc-form-group-title {
    padding-right: 1em;
    padding-left: 3em;
  }
  .mbsc-rtl .mbsc-collapsible.mbsc-form-group .mbsc-collapsible-header .mbsc-collapsible-icon,
  .mbsc-rtl .mbsc-collapsible.mbsc-form-group .mbsc-collapsible-header.mbsc-form-group-title .mbsc-collapsible-icon,
  .mbsc-rtl .mbsc-collapsible.mbsc-card .mbsc-collapsible-header .mbsc-collapsible-icon,
  .mbsc-rtl .mbsc-collapsible.mbsc-card .mbsc-collapsible-header.mbsc-form-group-title .mbsc-collapsible-icon {
    right: auto;
    left: 1em;
  }
  [mbsc-page]:not(.mbsc-page) {
    visibility: hidden;
  }
  .mbsc-page-ctx {
    margin: 0;
    padding: 0;
    height: 100%;
  }
  .mbsc-page {
    min-height: 100%;
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
  .mbsc-page,
  .mbsc-page * {
    box-sizing: border-box;
  }
  .mbsc-page:before,
  .mbsc-page:after {
    content: "";
    display: table;
  }
  .mbsc-rtl {
    direction: rtl;
  }
  .mbsc-page h1,
  .mbsc-page h2,
  .mbsc-page h3,
  .mbsc-page h4,
  .mbsc-page h5,
  .mbsc-page h6 {
    margin: 0;
    padding: 0;
    color: inherit;
    font-weight: normal;
    font-family: inherit;
  }
  .mbsc-page p {
    margin: 1em 0;
    padding: 0;
    line-height: 1.5;
  }
  .mbsc-page a {
    text-decoration: none;
  }
  .mbsc-page a:hover {
    text-decoration: underline;
  }
  .mbsc-page a.mbsc-btn:hover {
    text-decoration: none;
  }
  .mbsc-page h1 {
    margin: 0.347826em 0;
    font-size: 2.875em;
  }
  .mbsc-page h2 {
    margin: 0.470588em 0;
    font-size: 2.125em;
  }
  .mbsc-page h3 {
    margin: 0.666666em 0;
    font-size: 1.5em;
  }
  .mbsc-page h4 {
    margin: 0.8em 0;
    font-size: 1.25em;
  }
  .mbsc-page h5 {
    margin: 1.066666em 0;
    font-size: 0.9375em;
  }
  .mbsc-page h6 {
    margin: 1.333333em 0;
    font-size: 0.75em;
  }
  .mbsc-padding {
    padding: 1em;
  }
  .mbsc-padding > p:first-child {
    margin-top: 0;
  }
  .mbsc-padding > p:last-child {
    margin-bottom: 0;
  }
  .mbsc-margin {
    margin: 1em 0;
  }
  .mbsc-margin:first-child {
    margin-top: 0;
  }
  .mbsc-margin:last-child {
    margin-bottom: 0;
  }
  .mbsc-page ul,
  .mbsc-page ol {
    padding: 0;
    margin: 1em 0 1em 1.25em;
    line-height: 1.5;
  }
  .mbsc-page ul ul,
  .mbsc-page ol ol {
    margin: 0 0 0 1.25em;
  }
  .mbsc-txt-xs {
    font-size: 0.625em;
  }
  .mbsc-txt-s {
    font-size: 0.75em;
  }
  .mbsc-txt-m {
    font-size: 1.25em;
  }
  .mbsc-txt-l {
    font-size: 1.5em;
  }
  .mbsc-txt-xl {
    font-size: 2em;
  }
  .mbsc-txt-muted {
    opacity: 0.6;
  }
  .mbsc-ultra-bold {
    font-weight: 900;
  }
  .mbsc-bold {
    font-weight: bold;
  }
  .mbsc-medium {
    font-weight: 500;
  }
  .mbsc-light {
    font-weight: 300;
  }
  .mbsc-thin {
    font-weight: 100;
  }
  .mbsc-italic {
    font-style: italic;
  }
  .mbsc-align-left {
    text-align: left;
  }
  .mbsc-align-right {
    text-align: right;
  }
  .mbsc-align-center {
    text-align: center;
  }
  .mbsc-pull-right {
    float: right;
  }
  .mbsc-pull-left {
    float: left;
  }
  .mbsc-media-fluid {
    display: block;
    width: 100%;
  }
  .mbsc-img-thumbnail {
    width: 6em;
    height: 6em;
    margin: 1em;
  }
  .mbsc-avatar {
    width: 2.5em;
    height: 2.5em;
    padding: 0;
    border-radius: 1.25em;
  }
  .mbsc-note {
    position: relative;
    padding: 0.75em 1.25em;
    margin: 1em;
    border: 1px solid transparent;
    font-size: 0.875em;
  }
  @media screen and (max-width: 600px) {
    .mbsc-note {
      text-align: center;
    }
  }
  @keyframes autofill {
    from {
      opacity: 1;
    }
    to {
      opacity: 1;
    }
  }
  .mbsc-input {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .mbsc-input .mbsc-control:-webkit-autofill {
    animation-name: autofill;
  }
  .mbsc-input input,
  .mbsc-input select,
  .mbsc-input textarea {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .mbsc-input-wrap,
  .mbsc-input input,
  .mbsc-input textarea {
    box-sizing: border-box;
  }
  .mbsc-input input,
  .mbsc-input select,
  .mbsc-input textarea {
    display: block;
    width: 100%;
    font-size: 1em;
  }
  .mbsc-input input:focus,
  .mbsc-input select:focus,
  .mbsc-input textarea:focus {
    outline: 0;
  }
  .mbsc-input-wrap {
    position: relative;
    display: block;
  }
  .mbsc-input .mbsc-label {
    font-size: 0.875em;
    white-space: nowrap;
  }
  .mbsc-input-ic {
    position: absolute;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
  }
  .mbsc-input-toggle {
    cursor: pointer;
  }
  .mbsc-ic-right .mbsc-input-ic {
    right: 0.5em;
  }
  .mbsc-ic-left .mbsc-input-ic {
    left: 0.5em;
  }
  .mbsc-ic-right input {
    padding-right: 2.4em;
  }
  .mbsc-ic-left input {
    padding-left: 2.4em;
  }
  .mbsc-label-inline.mbsc-input.mbsc-control-w {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .mbsc-label-inline.mbsc-input.mbsc-control-w .mbsc-input-wrap {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }
  .mbsc-label-inline.mbsc-input.mbsc-control-w .mbsc-label {
    position: static;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 1em;
    width: 30%;
    max-width: 12.5em;
    overflow: hidden;
    text-overflow: ellipsis;
    box-sizing: content-box;
  }
  .mbsc-label-floating.mbsc-input .mbsc-label {
    pointer-events: none;
    transition: transform 0.2s;
  }
  .mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-control::-webkit-input-placeholder {
    opacity: 0;
  }
  .mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-control:-moz-placeholder {
    opacity: 0;
  }
  .mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-control::-moz-placeholder {
    opacity: 0;
  }
  .mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-control:-ms-input-placeholder {
    opacity: 0;
  }
  .mbsc-label-floating.mbsc-input.mbsc-label-floating-active
    .mbsc-label
    ~ .mbsc-input-wrap
    .mbsc-control::-webkit-input-placeholder {
    opacity: 1;
    transition: opacity 0.2s;
  }
  .mbsc-label-floating.mbsc-input.mbsc-label-floating-active
    .mbsc-label
    ~ .mbsc-input-wrap
    .mbsc-control:-moz-placeholder {
    opacity: 1;
    transition: opacity 0.2s;
  }
  .mbsc-label-floating.mbsc-input.mbsc-label-floating-active
    .mbsc-label
    ~ .mbsc-input-wrap
    .mbsc-control::-moz-placeholder {
    opacity: 1;
    transition: opacity 0.2s;
  }
  .mbsc-label-floating.mbsc-input.mbsc-label-floating-active
    .mbsc-label
    ~ .mbsc-input-wrap
    .mbsc-control:-ms-input-placeholder {
    opacity: 1;
    transition: opacity 0.2s;
  }
  .mbsc-ltr .mbsc-label-floating.mbsc-input .mbsc-label {
    -webkit-transform-origin: top left;
    transform-origin: top left;
  }
  .mbsc-rtl .mbsc-label-floating.mbsc-input .mbsc-label {
    -webkit-transform-origin: top right;
    transform-origin: top right;
  }
  .mbsc-input-wrap .mbsc-control[type="file"] {
    position: absolute;
    opacity: 0;
  }
  .mbsc-progress {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .mbsc-progress progress {
    display: none;
  }
  .mbsc-progress .mbsc-input-wrap {
    position: relative;
    display: block;
  }
  .mbsc-progress .mbsc-input-ic {
    position: absolute;
    height: 2em;
    width: 2em;
    line-height: 2em;
    text-align: center;
  }
  .mbsc-progress-cont {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
  }
  .mbsc-progress-track {
    position: relative;
    display: block;
    box-sizing: border-box;
  }
  .mbsc-progress-bar {
    position: absolute;
    top: 0;
    left: 0;
    width: 0;
    height: 100%;
  }
  .mbsc-rtl .mbsc-progress-bar {
    left: auto;
    right: 0;
  }
  .mbsc-progress-anim .mbsc-progress-bar {
    transition: width 0.1s ease-in-out;
  }
  .mbsc-progress-value {
    position: absolute;
    top: 50%;
    width: 3em;
    overflow: hidden;
    margin-top: -0.5em;
    line-height: 1em;
  }
  .mbsc-progress-value-right .mbsc-progress-value {
    right: 0;
    text-align: right;
  }
  .mbsc-rtl.mbsc-progress-value-right .mbsc-progress-value {
    right: auto;
    left: 0;
    text-align: left;
  }
  .mbsc-progress-value-left .mbsc-progress-value {
    left: 0;
    text-align: left;
  }
  .mbsc-rtl.mbsc-progress-value-left .mbsc-progress-value {
    left: auto;
    right: 0;
    text-align: right;
  }
  .mbsc-progress-step-label {
    position: absolute;
    top: 1em;
    width: 3.5em;
    font-size: 0.75em;
    text-align: center;
    overflow: hidden;
  }
  .mbsc-ltr .mbsc-progress-step-label {
    margin-left: -1.75em;
  }
  .mbsc-rtl .mbsc-progress-step-label {
    margin-right: -1.75em;
  }
  .mbsc-label-inline.mbsc-progress.mbsc-control-w {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
  }
  .mbsc-label-inline.mbsc-progress.mbsc-control-w .mbsc-input-wrap {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }
  .mbsc-label-inline.mbsc-progress.mbsc-control-w .mbsc-label {
    position: static;
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    font-size: 1em;
    line-height: 2.125em;
    width: 30%;
    max-width: 12.5em;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .mbsc-progress.mbsc-slider input {
    display: none;
  }
  .mbsc-ltr .mbsc-progress-anim .mbsc-slider-handle-cont {
    transition: left 0.1s ease-in-out;
  }
  .mbsc-rtl .mbsc-progress-anim .mbsc-slider-handle-cont {
    transition: right 0.1s ease-in-out;
  }
  .mbsc-slider-handle-cont {
    position: absolute;
    width: 2em;
    height: 2em;
    top: 0.0625em;
    margin-top: -1em;
    cursor: pointer;
  }
  .mbsc-ltr .mbsc-slider-handle-cont {
    right: -1em;
  }
  .mbsc-rtl .mbsc-slider-handle-cont {
    left: -1em;
  }
  .mbsc-ltr .mbsc-slider-handle-cont.mbsc-slider-handle-left {
    left: 0;
  }
  .mbsc-rtl .mbsc-slider-handle-cont.mbsc-slider-handle-left {
    right: 0;
  }
  .mbsc-ltr .mbsc-progress-track .mbsc-slider-handle-cont {
    margin-left: -1em;
  }
  .mbsc-rtl .mbsc-progress-track .mbsc-slider-handle-cont {
    margin-right: -1em;
  }
  .mbsc-slider-handle {
    position: absolute;
    top: 0.125em;
    right: 50%;
    margin: -0.5em -0.5em 0 0;
    z-index: 2;
  }
  .mbsc-slider-handle-cont .mbsc-slider-handle {
    box-sizing: content-box;
  }
  .mbsc-slider .mbsc-slider-handle:focus,
  .mbsc-slider .mbsc-active .mbsc-slider-handle {
    outline: 0;
    z-index: 15;
  }
  .mbsc-slider .mbsc-handle-curr {
    z-index: 16;
  }
  .mbsc-slider-tooltip {
    position: absolute;
    right: 50%;
    opacity: 0;
    color: #000;
  }
  .mbsc-slider-step {
    position: absolute;
    top: 0;
    width: 0.125em;
    height: 100%;
    margin-left: -0.0625em;
  }
  .mbsc-rating.mbsc-progress .mbsc-progress-cont {
    display: inline-block;
    width: auto;
    padding: 0 0.5em;
    margin: 0 -0.681818em;
    cursor: pointer;
  }
  .mbsc-rating .mbsc-progress-cont .mbsc-progress-track {
    height: auto;
    background: none;
  }
  .mbsc-rating .mbsc-ic {
    font-size: 1.375em;
    margin: 0 0.181818em;
  }
  .mbsc-rating.mbsc-progress input {
    display: none;
  }
  .mbsc-rating.mbsc-progress .mbsc-progress-cont .mbsc-slider-handle {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    margin: 0;
    top: 0;
    left: 0;
    right: 0;
    background: none;
    box-shadow: none;
    border: 0;
    -webkit-transform: none;
    transform: none;
  }
  .mbsc-rating .mbsc-progress-cont .mbsc-progress-bar {
    background: none;
    overflow: hidden;
    white-space: nowrap;
  }
  .mbsc-wdg .mbsc-wdg-c {
    position: relative;
    z-index: 0;
    font-size: 14px;
    white-space: normal;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
  }
  .mbsc-wdg .mbsc-ltr .mbsc-wdg-c {
    text-align: left;
  }
  .mbsc-wdg .mbsc-rtl .mbsc-wdg-c {
    text-align: right;
  }
  .mbsc-wdg .mbsc-wdg-c.mbsc-w-p {
    padding: 1em;
  }
  .mbsc-no-padding.mbsc-wdg .mbsc-wdg-c.mbsc-w-p {
    padding: 0;
  }
  .mbsc-toast .mbsc-fr-persp,
  .mbsc-snackbar .mbsc-fr-persp {
    pointer-events: none;
  }
  .mbsc-toast .mbsc-fr-overlay,
  .mbsc-snackbar .mbsc-fr-overlay {
    display: none;
  }
  .mbsc-toast.mbsc-fr .mbsc-fr-persp .mbsc-fr-popup,
  .mbsc-snackbar.mbsc-fr .mbsc-fr-persp .mbsc-fr-popup {
    border: 0;
    line-height: 1.428572;
  }
  .mbsc-snackbar.mbsc-fr .mbsc-fr-w {
    min-width: 18em;
    max-width: 36em;
    margin: 0 auto;
    pointer-events: auto;
    box-shadow: none;
  }
  .mbsc-snackbar-cont {
    display: -webkit-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .mbsc-snackbar-msg {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 0 1em;
  }
  .mbsc-snackbar .mbsc-snackbar-cont .mbsc-snackbar-btn {
    margin: -1em 0;
    padding: 1em;
    line-height: 1.428572;
    font-size: 1em;
    font-weight: 400;
  }
  .mbsc-snackbar.mbsc-no-touch.mbsc-fr .mbsc-fr-c .mbsc-btn-flat:not(:disabled):hover {
    background: none;
    box-shadow: none;
  }
  .mbsc-toast.mbsc-fr .mbsc-fr-w {
    background: none;
  }
  .mbsc-toast.mbsc-fr .mbsc-fr-c.mbsc-wdg-c {
    text-align: center;
    padding-bottom: 4em;
  }
  .mbsc-toast .mbsc-toast-msg {
    display: inline-block;
    min-width: 10em;
    max-width: 50em;
    padding: 0.857143em 2em;
  }
  .mbsc-alert.mbsc-fr .mbsc-fr-c.mbsc-wdg-c {
    padding: 1.714286em;
    max-width: 20em;
  }
  .mbsc-alert .mbsc-fr-c .mbsc-input {
    padding: 0;
    margin: 0;
  }
  .mbsc-alert.mbsc-fr h2 {
    margin: 0 0 1em 0;
    padding: 0;
    font-size: 1.428572em;
    font-weight: bold;
  }
  .mbsc-alert.mbsc-fr p {
    margin: 1em 0;
    padding: 0;
    font-size: 1em;
  }
  [mbsc-form]:not(.mbsc-form) {
    visibility: hidden;
  }
  .mbsc-form {
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
  }
  .mbsc-form:before,
  .mbsc-form:after {
    content: "";
    display: table;
  }
  .mbsc-form-group {
    margin: 1.5em 0;
  }
  .mbsc-form-group-inset {
    margin: 2em 1.5em;
  }
  .mbsc-form-group,
  .mbsc-form-group-inset {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .mbsc-wdg .mbsc-w-p .mbsc-form {
    background: none;
  }
  .mbsc-input .mbsc-fr-inline .mbsc-fr-popup {
    display: block;
  }
  .mbsc-err-msg {
    display: block;
    font-size: 0.75em;
  }
  .mbsc-checkbox,
  .mbsc-switch,
  .mbsc-radio,
  .mbsc-stepper-cont {
    line-height: 1.25em;
  }
  .mbsc-checkbox,
  .mbsc-switch,
  .mbsc-btn,
  .mbsc-radio,
  .mbsc-segmented,
  .mbsc-stepper-cont {
    position: relative;
    display: block;
    margin: 0;
    z-index: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .mbsc-checkbox input,
  .mbsc-switch input,
  .mbsc-radio input,
  .mbsc-segmented input,
  .mbsc-btn {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
  .mbsc-checkbox input,
  .mbsc-switch input,
  .mbsc-radio input,
  .mbsc-select select,
  .mbsc-segmented input {
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
    opacity: 0;
    margin: 0;
  }
  .mbsc-btn,
  .mbsc-checkbox-box,
  .mbsc-checkbox-box:after,
  .mbsc-radio-box,
  .mbsc-radio-box:after,
  .mbsc-switch-track,
  .mbsc-segmented,
  .mbsc-progress progress,
  .mbsc-stepper-cont {
    box-sizing: border-box;
  }
  .mbsc-segmented .mbsc-segmented-content,
  .mbsc-btn-flat .mbsc-btn-ic {
    box-sizing: content-box;
  }
  .mbsc-desc {
    display: block;
    font-size: 0.75em;
    opacity: 0.6;
  }
  label.mbsc-input,
  .mbsc-label {
    margin: 0;
    display: block;
    font-weight: normal;
  }
  .mbsc-control-w {
    max-width: none;
    margin: 0;
    font-size: 1em;
    font-weight: normal;
  }
  .mbsc-input textarea {
    resize: none;
    overflow: hidden;
    line-height: 1.5em;
  }
  .mbsc-input .mbsc-textarea-scroll {
    overflow: auto;
  }
  .mbsc-select select,
  .mbsc-select input {
    cursor: pointer;
  }
  .mbsc-select select option {
    color: initial;
  }
  .mbsc-select-ic {
    display: none;
  }
  .mbsc-checkbox {
    padding: 0.5em;
  }
  .mbsc-checkbox-box {
    position: absolute;
    top: 50%;
    display: block;
    width: 1.375em;
    height: 1.375em;
  }
  .mbsc-checkbox-box:after {
    content: "";
    position: absolute;
    display: block;
    opacity: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .mbsc-checkbox input:checked + .mbsc-checkbox-box:after {
    opacity: 1;
  }
  .mbsc-radio {
    padding: 0.5em;
  }
  .mbsc-radio-box {
    position: absolute;
    top: 50%;
    display: block;
    width: 1.25em;
    height: 1.25em;
    border-radius: 1.25em;
  }
  .mbsc-radio-box:after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0.625em;
    height: 0.625em;
    margin-top: -0.3125em;
    margin-left: -0.3125em;
    border-radius: 0.625em;
    opacity: 0;
  }
  .mbsc-radio input:checked + .mbsc-radio-box:after {
    opacity: 1;
  }
  .mbsc-switch {
    padding: 0.5em;
  }
  .mbsc-switch-track {
    position: absolute;
    top: 50%;
    display: block;
    width: 3.375em;
    height: 1.625em;
    transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out;
    z-index: 4;
  }
  .mbsc-switch .mbsc-switch-track .mbsc-progress-track {
    height: 100%;
    background: none;
  }
  .mbsc-switch .mbsc-switch-track .mbsc-slider-handle-cont {
    top: 50%;
  }
  .mbsc-switch-handle {
    position: absolute;
    display: block;
  }
  .mbsc-switch-txt-off,
  .mbsc-switch-txt-on {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: 100%;
    height: 100%;
    font-size: 0.625em;
    text-align: center;
    line-height: 2em;
  }
  .mbsc-segmented {
    display: table;
    table-layout: fixed;
    width: 100%;
  }
  .mbsc-segmented .mbsc-segmented-item {
    margin: 0;
    display: table-cell;
    position: relative;
    vertical-align: top;
    text-align: center;
    font-size: 1em;
  }
  .mbsc-segmented-content {
    position: relative;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 0.875em;
    font-weight: normal;
    z-index: 2;
  }
  .mbsc-segmented-item label {
    display: block;
    margin: 0;
  }
  .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-disabled .mbsc-segmented-content,
  .mbsc-segmented input:disabled + .mbsc-segmented-content {
    z-index: 0;
  }
  .mbsc-stepper {
    position: absolute;
    display: block;
    width: auto;
    right: 1em;
    top: 50%;
  }
  .mbsc-rtl .mbsc-stepper {
    right: auto;
    left: 1em;
  }
  .mbsc-stepper-cont .mbsc-label {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .mbsc-segmented-item:focus {
    outline: 0;
  }
  .mbsc-stepper input {
    position: absolute;
    left: 4.142857em;
    width: 4.142857em;
    height: 100%;
    padding: 0;
    margin: 0;
    border: 0;
    outline: 0;
    box-shadow: none;
    font-size: 0.875em;
    text-align: center;
    opacity: 1;
    z-index: 4;
    background: transparent;
    -webkit-appearance: none;
    -moz-appearance: textfield;
    appearance: none;
  }
  .mbsc-stepper input::-webkit-outer-spin-button,
  .mbsc-stepper input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  .mbsc-form .mbsc-stepper-val-right .mbsc-stepper input {
    left: auto;
    right: 0;
  }
  .mbsc-form.mbsc-rtl .mbsc-stepper-val-right .mbsc-stepper input {
    right: auto;
    left: 0;
  }
  .mbsc-form .mbsc-stepper-val-left .mbsc-stepper input {
    left: 0;
  }
  .mbsc-form.mbsc-rtl .mbsc-stepper-val-left .mbsc-stepper input {
    right: 0;
  }
  .mbsc-stepper .mbsc-segmented-item {
    width: 3.625em;
  }
  .mbsc-stepper-cont.mbsc-stepper-val-left .mbsc-stepper .mbsc-segmented-item:nth-child(2) .mbsc-segmented-content,
  .mbsc-stepper-cont.mbsc-stepper-val-right .mbsc-stepper .mbsc-segmented-item:last-child .mbsc-segmented-content {
    border: 0;
    background: transparent;
  }
  .mbsc-form .mbsc-stepper-cont .mbsc-stepper {
    padding: 0;
  }
  .mbsc-segmented-item .mbsc-control,
  .mbsc-stepper .mbsc-segmented-content {
    cursor: pointer;
  }
  .mbsc-disabled .mbsc-segmented-content,
  .mbsc-segmented input:disabled,
  .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content {
    cursor: not-allowed;
  }
  .mbsc-btn {
    position: relative;
    display: inline-block;
    overflow: hidden;
    vertical-align: middle;
    text-align: center;
    text-overflow: ellipsis;
    font-size: 1em;
    cursor: pointer;
  }
  .mbsc-btn:disabled {
    cursor: not-allowed;
  }
  .mbsc-btn:focus {
    outline: 0;
  }
  .mbsc-btn-ic {
    line-height: 1;
  }
  .mbsc-btn-group,
  .mbsc-btn-group-block,
  .mbsc-btn-group-justified {
    border: 1px solid transparent;
  }
  .mbsc-btn-group-block .mbsc-btn,
  .mbsc-btn-block {
    display: block;
    width: 100%;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .mbsc-btn-group-justified,
  .mbsc-btn-group-justified mbsc-button {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .mbsc-btn-group-justified .mbsc-btn,
  .mbsc-btn-group-justified mbsc-button {
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }
  input:disabled + span .mbsc-slider-handle-cont {
    cursor: not-allowed;
  }
  .mbsc-form .mbsc-form-grid .mbsc-form-group-title {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
  }
  [mbsc-card]:not(.mbsc-card) {
    visibility: hidden;
  }
  .mbsc-card {
    position: relative;
    z-index: 1;
    margin: 0.75em;
    border-radius: 2px;
    overflow: hidden;
  }
  .mbsc-card-header {
    padding: 1em;
    overflow: hidden;
  }
  .mbsc-card-subtitle {
    color: #7c7c7c;
  }
  .mbsc-card-content {
    padding: 1em;
    overflow: hidden;
  }
  .mbsc-card-content > p:first-child {
    margin-top: 0;
  }
  .mbsc-card-content > p:last-child {
    margin-bottom: 0;
  }
  .mbsc-card-content.mbsc-no-padding {
    padding: 0;
  }
  .mbsc-card-header + .mbsc-card-content {
    padding-top: 0;
  }
  .mbsc-card-footer {
    padding: 1em;
    border-top: 1px solid transparent;
    clear: both;
  }
  .mbsc-card h2.mbsc-card-title,
  .mbsc-card-title {
    font-size: 1.5em;
    font-weight: normal;
    margin: 0;
  }
  .mbsc-card h3.mbsc-card-subtitle,
  .mbsc-card-subtitle {
    font-size: 0.875em;
    font-weight: normal;
    margin: 0;
  }
  .mbsc-card h3.mbsc-card-subtitle + *,
  .mbsc-card-subtitle + *,
  .mbsc-card h2.mbsc-card-title + *,
  .mbsc-card-title + * {
    margin-top: 0.25em;
  }
  .mbsc-card video,
  .mbsc-card img:not(.mbsc-avatar):not(.mbsc-img-thumbnail) {
    display: block;
    width: 100%;
  }
  .mbsc-card.mbsc-ltr .mbsc-avatar {
    float: left;
    margin-right: 0.5em;
  }
  .mbsc-card.mbsc-rtl .mbsc-avatar {
    float: right;
    margin-left: 0.5em;
  }
  .mbsc-avatar ~ .mbsc-card-title,
  mbsc-avatar ~ .mbsc-card-title {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 600;
  }
  .mbsc-card .mbsc-card-footer .mbsc-btn {
    margin: -0.6875em 0 -0.6875em 0;
  }
  .mbsc-card .mbsc-card-content .mbsc-control-w {
    margin-left: 0;
    margin-right: 0;
  }
  .mbsc-lv .mbsc-lv-item.mbsc-card {
    margin: 0.75em;
    padding: 0;
  }
  .mbsc-card.mbsc-form .mbsc-lv-cont {
    margin: 0;
  }
  .mbsc-card .mbsc-card-content .mbsc-lv-cont {
    margin: 0 -1em;
  }
  .mbsc-card-list .mbsc-lv-cont .mbsc-lv,
  .mbsc-lv-cont .mbsc-card-list.mbsc-lv {
    background: none;
  }
  .mbsc-card-list .mbsc-lv-item:not(.mbsc-card) {
    padding: 0;
    background: none;
  }
  .mbsc-ios .mbsc-collapsible .mbsc-collapsible-header {
    padding-right: 3em;
  }
  .mbsc-ios .mbsc-collapsible .mbsc-form-group-title {
    padding-top: 2.5em;
    margin: 0;
  }
  .mbsc-ios .mbsc-collapsible .mbsc-form-group-title .mbsc-collapsible-icon {
    margin-top: 0.5em;
  }
  .mbsc-ios .mbsc-note {
    border-radius: 0.25em;
  }
  .mbsc-ios .mbsc-note,
  .mbsc-ios .mbsc-note-primary {
    color: #074b95;
    background-color: #a0ccfb;
  }
  .mbsc-ios .mbsc-note-secondary {
    color: #454b50;
    background-color: #c6cace;
  }
  .mbsc-ios .mbsc-note-success {
    color: #1b4d26;
    background-color: #8fd8a0;
  }
  .mbsc-ios .mbsc-note-danger {
    color: #a10b09;
    background-color: #faafaf;
  }
  .mbsc-ios .mbsc-note-warning {
    color: #9b6006;
    background-color: #fcd9a4;
  }
  .mbsc-ios .mbsc-note-info {
    color: #235b64;
    background-color: #a6d8e0;
  }
  .mbsc-ios .mbsc-note-light {
    color: #4d4c4c;
    background-color: #fff;
  }
  .mbsc-ios .mbsc-note-dark {
    color: #000;
    background-color: #797c7e;
  }
  .mbsc-ios.mbsc-page {
    background: #efeff4;
    color: #000;
  }
  .mbsc-ios a {
    color: #007bff;
  }
  .mbsc-ios .mbsc-input {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 0;
  }
  .mbsc-ios .mbsc-input input {
    height: 2.75em;
    padding: 0 1em;
    background: transparent;
  }
  .mbsc-ios .mbsc-input-wrap {
    position: static;
    -webkit-box-flex: 1;
    -webkit-flex: 1 auto;
    -moz-box-flex: 1;
    -moz-flex: 1 auto;
    -ms-flex: 1 auto;
    flex: 1 auto;
  }
  .mbsc-ios .mbsc-input-wrap .mbsc-input-fill {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: -1;
    border-radius: 8px;
  }
  .mbsc-ios .mbsc-textarea textarea {
    height: 3em;
    padding: 0 1em;
    background: transparent;
  }
  .mbsc-ios .mbsc-input .mbsc-label,
  .mbsc-ios .mbsc-progress .mbsc-label {
    -webkit-box-flex: 0;
    -webkit-flex: 0 auto;
    -moz-box-flex: 0;
    -moz-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: 30%;
    max-width: 12.5em;
    overflow: hidden;
    font-size: 1em;
    line-height: 2.75em;
    white-space: nowrap;
    text-overflow: ellipsis;
    box-sizing: content-box;
  }
  .mbsc-ios.mbsc-ltr .mbsc-input .mbsc-label {
    padding-left: 1em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-input .mbsc-label {
    padding-right: 1em;
  }
  .mbsc-ios .mbsc-input input:disabled,
  .mbsc-ios .mbsc-input textarea:disabled,
  .mbsc-ios .mbsc-input .mbsc-control:disabled ~ input,
  .mbsc-ios .mbsc-input .mbsc-control:disabled ~ .mbsc-ic {
    opacity: 0.5;
  }
  .mbsc-ios .mbsc-err-msg {
    padding: 0.5em 1.3333em;
  }
  .mbsc-ios.mbsc-ltr .mbsc-select input {
    padding-right: 2.25em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-select input {
    padding-left: 2.25em;
  }
  .mbsc-ios .mbsc-select-ic {
    position: absolute;
    display: block;
    height: 1.25em;
    width: 1.25em;
    top: 0.875em;
    right: 1em;
    text-align: center;
  }
  .mbsc-ios.mbsc-rtl .mbsc-select-ic {
    right: auto;
    left: 1em;
  }
  .mbsc-ios .mbsc-ic-right .mbsc-select-ic {
    right: 3.4em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-ic-right .mbsc-select-ic {
    right: auto;
    left: 3.4em;
  }
  .mbsc-ios .mbsc-select-inline .mbsc-select-ic {
    display: none;
  }
  .mbsc-ios .mbsc-textarea .mbsc-input-wrap {
    padding: 0.625em 0;
  }
  .mbsc-ios .mbsc-input-ic {
    top: 0.375em;
    margin-top: -1px;
  }
  .mbsc-ios .mbsc-ic-left {
    z-index: 1;
  }
  .mbsc-ios.mbsc-ltr .mbsc-ic-left .mbsc-input-wrap,
  .mbsc-ios.mbsc-rtl .mbsc-ic-right .mbsc-input-wrap {
    padding-left: 2.25em;
  }
  .mbsc-ios.mbsc-ltr .mbsc-ic-right .mbsc-input-wrap,
  .mbsc-ios.mbsc-rtl .mbsc-ic-left .mbsc-input-wrap {
    padding-right: 2.25em;
  }
  .mbsc-ios.mbsc-ltr .mbsc-ic-left .mbsc-label {
    padding-left: 3.25em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-ic-left .mbsc-label {
    padding-right: 3.25em;
  }
  .mbsc-ios.mbsc-ltr .mbsc-ic-left .mbsc-label ~ .mbsc-input-wrap {
    padding-left: 0;
  }
  .mbsc-ios.mbsc-rtl .mbsc-ic-left .mbsc-label ~ .mbsc-input-wrap {
    padding-right: 0;
  }
  .mbsc-ios .mbsc-ic-left .mbsc-left-ic,
  .mbsc-ios.mbsc-rtl .mbsc-ic-right .mbsc-right-ic {
    right: auto;
    left: 0.625em;
  }
  .mbsc-ios .mbsc-ic-right .mbsc-right-ic,
  .mbsc-ios.mbsc-rtl .mbsc-ic-left .mbsc-left-ic {
    left: auto;
    right: 0.625em;
  }
  .mbsc-ios .mbsc-control-w {
    margin-top: -1px;
  }
  .mbsc-ios .mbsc-control-w:before,
  .mbsc-ios .mbsc-control-w:after {
    content: "";
    position: absolute;
    right: 0;
    left: 1em;
  }
  .mbsc-ios .mbsc-control-w:before {
    top: 0;
  }
  .mbsc-ios .mbsc-control-w:after {
    bottom: 0;
  }
  .mbsc-ios .mbsc-form-group-title + .mbsc-control-w:before,
  .mbsc-ios .mbsc-control-w:first-child:before,
  .mbsc-ios .mbsc-control-w:last-child:after {
    left: 0;
  }
  .mbsc-ios .mbsc-control-ng .mbsc-control-w:before,
  .mbsc-ios .mbsc-control-ng .mbsc-control-w:after {
    left: 1em;
  }
  .mbsc-ios .mbsc-form-group-title + .mbsc-control-ng .mbsc-control-w:before,
  .mbsc-ios .mbsc-control-ng:first-child .mbsc-control-w:before,
  .mbsc-ios .mbsc-control-ng:last-child .mbsc-control-w:after {
    left: 0;
  }
  .mbsc-ios .mbsc-ic-left:before,
  .mbsc-ios .mbsc-ic-left:after {
    left: 3.25em;
  }
  .mbsc-ios .mbsc-err {
    z-index: 2;
  }
  .mbsc-ios .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-control-w,
  .mbsc-ios .mbsc-form-group-inset .mbsc-control-w:first-child {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  .mbsc-ios .mbsc-form-group-inset .mbsc-control-w:last-child {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
  .mbsc-ios .mbsc-form-group-inset .mbsc-form-group-title,
  .mbsc-ios .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-control-w:before,
  .mbsc-ios .mbsc-form-group-inset .mbsc-control-w:first-child:before,
  .mbsc-ios .mbsc-form-group-inset .mbsc-control-w:last-child:after {
    border-width: 0;
  }
  .mbsc-ios .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w {
    border-radius: 0;
  }
  .mbsc-ios .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w:before,
  .mbsc-ios .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w:after {
    border-width: 1px;
  }
  .mbsc-ios.mbsc-form .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-control-ng .mbsc-control-w:before,
  .mbsc-ios.mbsc-form .mbsc-form-group-inset .mbsc-control-ng:first-child .mbsc-control-w:before,
  .mbsc-ios.mbsc-form .mbsc-form-group-inset .mbsc-control-ng:last-child .mbsc-control-w:after {
    border-width: 0;
  }
  .mbsc-ios .mbsc-form-group-inset .mbsc-form-group-title + .mbsc-control-ng .mbsc-control-w,
  .mbsc-ios .mbsc-form-group-inset .mbsc-control-ng:first-child .mbsc-control-w {
    border-top-left-radius: 0.5em;
    border-top-right-radius: 0.5em;
  }
  .mbsc-ios .mbsc-form-group-inset .mbsc-control-ng:last-child .mbsc-control-w {
    border-bottom-left-radius: 0.5em;
    border-bottom-right-radius: 0.5em;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-input .mbsc-label {
    font-size: 0.75em;
    position: absolute;
    top: 1em;
    line-height: 1em;
    padding: 0;
    width: auto;
    max-width: none;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-input .mbsc-label ~ .mbsc-input-wrap input {
    height: 3.75em;
    padding-top: 1.5em;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 1.625em;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-input.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 1.625em;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-input.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
    top: 1.4375em;
  }
  .mbsc-ios.mbsc-ltr .mbsc-label-stacked.mbsc-input .mbsc-label {
    left: 1.333334em;
    right: auto;
  }
  .mbsc-ios.mbsc-ltr .mbsc-label-stacked.mbsc-input.mbsc-ic-left .mbsc-input-wrap {
    padding-left: 2.375em;
  }
  .mbsc-ios.mbsc-ltr .mbsc-label-stacked.mbsc-input.mbsc-ic-left .mbsc-label {
    left: 4.5em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-label-stacked.mbsc-input .mbsc-label {
    left: auto;
    right: 1.333334em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-label-stacked.mbsc-input.mbsc-ic-left .mbsc-input-wrap {
    padding-right: 2.375em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-label-stacked.mbsc-input.mbsc-ic-left .mbsc-label {
    right: 4.5em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-label-stacked.mbsc-input.mbsc-control-w:before,
  .mbsc-ios.mbsc-rtl .mbsc-label-stacked.mbsc-input.mbsc-control-w:after {
    right: 0;
  }
  .mbsc-ios .mbsc-label-floating.mbsc-input .mbsc-label {
    position: absolute;
    padding: 0;
    font-size: 1em;
    line-height: 2em;
    top: 0.875em;
    width: auto;
    transform-origin: 0 0;
    transition: transform 0.2s;
  }
  .mbsc-ios .mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label,
  .mbsc-ios .mbsc-label-floating.mbsc-input.mbsc-ic-right .mbsc-label {
    top: 1.625em;
  }
  .mbsc-ios .mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap input {
    height: 3.75em;
    padding-top: 1.5em;
  }
  .mbsc-ios .mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 1.625em;
  }
  .mbsc-ios .mbsc-label-floating.mbsc-input.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 1.625em;
  }
  .mbsc-ios .mbsc-label-floating.mbsc-input.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
    top: 1.4375em;
  }
  .mbsc-ios .mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    -webkit-transform: translateY(-0.5em) scale(0.75);
    transform: translateY(-0.5em) scale(0.75);
  }
  .mbsc-ios .mbsc-label-floating.mbsc-input.mbsc-label-floating-active.mbsc-ic-left .mbsc-label,
  .mbsc-ios .mbsc-label-floating.mbsc-input.mbsc-label-floating-active.mbsc-ic-right .mbsc-label {
    -webkit-transform: translateY(-1.25em) scale(0.75);
    transform: translateY(-1.25em) scale(0.75);
  }
  .mbsc-ios.mbsc-ltr .mbsc-label-floating.mbsc-input .mbsc-label {
    left: 1em;
  }
  .mbsc-ios.mbsc-ltr .mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-input-wrap {
    padding-left: 2.375em;
  }
  .mbsc-ios.mbsc-ltr .mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    left: 3.375em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-label-floating.mbsc-input .mbsc-label {
    right: 1em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-input-wrap {
    padding-right: 2.375em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    right: 3.375em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-label-floating.mbsc-input.mbsc-control-w:before,
  .mbsc-ios.mbsc-rtl .mbsc-label-floating.mbsc-input.mbsc-control-w:after {
    right: 0;
  }
  .mbsc-ios .mbsc-input-box.mbsc-control-w,
  .mbsc-ios .mbsc-input-outline.mbsc-control-w {
    margin-top: -1px;
    margin: 1.5em 1em;
    border-radius: 8px;
    border: 1px solid transparent;
  }
  .mbsc-ios .mbsc-input-box.mbsc-control-w input,
  .mbsc-ios .mbsc-input-outline.mbsc-control-w input {
    border-radius: 8px;
  }
  .mbsc-ios .mbsc-input-box.mbsc-control-w:before,
  .mbsc-ios .mbsc-input-box.mbsc-control-w:after,
  .mbsc-ios .mbsc-input-outline.mbsc-control-w:before,
  .mbsc-ios .mbsc-input-outline.mbsc-control-w:after {
    border-top: 0;
  }
  .mbsc-ios .mbsc-input-box .mbsc-err-msg,
  .mbsc-ios .mbsc-input-outline .mbsc-err-msg {
    position: absolute;
    bottom: -1.5em;
    padding: 0;
  }
  .mbsc-ios .mbsc-input-box.mbsc-control-w.mbsc-err + .mbsc-divider,
  .mbsc-ios .mbsc-input-outline.mbsc-control-w.mbsc-err + .mbsc-divider {
    border-top: 0;
  }
  .mbsc-ios .mbsc-input-box.mbsc-label-stacked.mbsc-input .mbsc-label,
  .mbsc-ios .mbsc-input-outline.mbsc-label-stacked.mbsc-input .mbsc-label {
    top: 0.75em;
  }
  .mbsc-ios .mbsc-input-box.mbsc-label-stacked.mbsc-input .mbsc-label ~ .mbsc-input-wrap input,
  .mbsc-ios .mbsc-input-outline.mbsc-label-stacked.mbsc-input .mbsc-label ~ .mbsc-input-wrap input {
    height: 3.375em;
    padding-top: 1.25em;
  }
  .mbsc-ios .mbsc-input-box.mbsc-label-stacked.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic,
  .mbsc-ios .mbsc-input-outline.mbsc-label-stacked.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 0.75em;
  }
  .mbsc-ios .mbsc-input-box.mbsc-label-stacked.mbsc-input.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap,
  .mbsc-ios .mbsc-input-outline.mbsc-label-stacked.mbsc-input.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 1.75em;
  }
  .mbsc-ios .mbsc-input-box.mbsc-label-stacked.mbsc-input.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic,
  .mbsc-ios .mbsc-input-outline.mbsc-label-stacked.mbsc-input.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
    top: 1.25em;
  }
  .mbsc-ios .mbsc-input-box.mbsc-label-floating.mbsc-input .mbsc-label,
  .mbsc-ios .mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label {
    top: 0.6875em;
  }
  .mbsc-ios .mbsc-input-box.mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap input,
  .mbsc-ios .mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap input {
    height: 3.375em;
    padding-top: 1.25em;
  }
  .mbsc-ios .mbsc-input-box.mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic,
  .mbsc-ios .mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 0.75em;
  }
  .mbsc-ios .mbsc-input-box.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label,
  .mbsc-ios .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    -webkit-transform: translateY(-0.5em) scale(0.75);
    transform: translateY(-0.5em) scale(0.75);
  }
  .mbsc-ios.mbsc-ltr .mbsc-input-box .mbsc-err-msg,
  .mbsc-ios.mbsc-ltr .mbsc-input-outline .mbsc-err-msg {
    left: 1.333334em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-input-box .mbsc-err-msg,
  .mbsc-ios.mbsc-rtl .mbsc-input-outline .mbsc-err-msg {
    right: 1.333334em;
  }
  .mbsc-ios .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w.mbsc-input-box,
  .mbsc-ios .mbsc-form-group-inset .mbsc-control-ng .mbsc-control-w.mbsc-input-outline {
    border-radius: 0.5em;
  }
  .mbsc-ios .mbsc-input-outline.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
    top: 0.875em;
  }
  .mbsc-ios .mbsc-input {
    background: #fff;
  }
  .mbsc-ios .mbsc-input .mbsc-control::-webkit-input-placeholder {
    color: #ccc;
  }
  .mbsc-ios .mbsc-input .mbsc-control::-ms-input-placeholder {
    color: #ccc;
  }
  .mbsc-ios .mbsc-input .mbsc-control::-moz-placeholder {
    color: #ccc;
  }
  .mbsc-ios .mbsc-input-wrap input:-webkit-autofill ~ .mbsc-input-fill {
    background: #e8f0fe;
  }
  .mbsc-ios .mbsc-input-wrap input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #e8f0fe inset;
  }
  .mbsc-ios .mbsc-input .mbsc-label,
  .mbsc-ios .mbsc-progress .mbsc-label,
  .mbsc-ios .mbsc-input input,
  .mbsc-ios .mbsc-input textarea {
    color: #000;
  }
  .mbsc-ios .mbsc-err-msg {
    color: #d8332a;
  }
  .mbsc-ios .mbsc-control-w:before,
  .mbsc-ios .mbsc-control-w:after {
    border-top: 1px solid #ccc;
  }
  .mbsc-ios .mbsc-control-w.mbsc-err:after,
  .mbsc-ios .mbsc-control-w.mbsc-err + .mbsc-err:before,
  .mbsc-ios .mbsc-control-w.mbsc-err + .mbsc-divider {
    border-top-color: #d8332a;
  }
  .mbsc-ios .mbsc-input-box.mbsc-err,
  .mbsc-ios .mbsc-input-outline.mbsc-err {
    border-color: #d8332a;
  }
  .mbsc-ios .mbsc-input-box .mbsc-err-msg,
  .mbsc-ios .mbsc-input-outline .mbsc-err-msg {
    color: #d8332a;
  }
  .mbsc-ios .mbsc-input-outline.mbsc-control-w {
    border: 1px solid #ccc;
  }
  .mbsc-ios .mbsc-input-outline.mbsc-err.mbsc-control-w {
    border-color: #d8332a;
  }
  .mbsc-ios.mbsc-progress {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    min-height: 4em;
    margin-top: -1px;
    padding: 0.625em 1em;
    align-items: center;
    box-sizing: border-box;
  }
  .mbsc-ios.mbsc-progress.mbsc-progress-w.mbsc-control-w .mbsc-label {
    padding: 0;
  }
  .mbsc-ios.mbsc-progress .mbsc-input-ic {
    top: 0.0625em;
    margin: 0;
  }
  .mbsc-ios.mbsc-progress.mbsc-ic-left .mbsc-left-ic,
  .mbsc-ios.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-right-ic {
    right: auto;
    left: -0.375em;
  }
  .mbsc-ios.mbsc-progress.mbsc-ic-right .mbsc-right-ic,
  .mbsc-ios.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-left-ic {
    left: auto;
    right: -0.375em;
  }
  .mbsc-ios.mbsc-progress .mbsc-input-wrap {
    position: relative;
    padding: 0 0.75em;
  }
  .mbsc-ios.mbsc-ltr.mbsc-progress.mbsc-progress-w.mbsc-ic-left .mbsc-input-wrap,
  .mbsc-ios.mbsc-rtl.mbsc-progress.mbsc-progress-w.mbsc-ic-right .mbsc-input-wrap {
    padding-left: 2.5em;
  }
  .mbsc-ios.mbsc-ltr.mbsc-progress.mbsc-progress-w.mbsc-ic-right .mbsc-input-wrap,
  .mbsc-ios.mbsc-rtl.mbsc-progress.mbsc-progress-w.mbsc-ic-left .mbsc-input-wrap {
    padding-right: 2.5em;
  }
  .mbsc-ios.mbsc-ltr.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap,
  .mbsc-ios.mbsc-rtl.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap {
    padding-left: 3.5em;
  }
  .mbsc-ios.mbsc-ltr.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap,
  .mbsc-ios.mbsc-rtl.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap {
    padding-right: 3.5em;
  }
  .mbsc-ios.mbsc-ltr.mbsc-progress.mbsc-ic-left.mbsc-progress-value-left .mbsc-input-wrap,
  .mbsc-ios.mbsc-rtl.mbsc-progress.mbsc-ic-right.mbsc-progress-value-right .mbsc-input-wrap {
    padding-left: 5.5em;
  }
  .mbsc-ios.mbsc-ltr.mbsc-progress.mbsc-ic-right.mbsc-progress-value-right .mbsc-input-wrap,
  .mbsc-ios.mbsc-rtl.mbsc-progress.mbsc-ic-left.mbsc-progress-value-left .mbsc-input-wrap {
    padding-right: 5.5em;
  }
  .mbsc-ios.mbsc-ltr.mbsc-progress.mbsc-ic-left.mbsc-progress-value-left .mbsc-progress-value,
  .mbsc-ios.mbsc-rtl.mbsc-progress.mbsc-ic-right.mbsc-progress-value-right .mbsc-progress-value {
    left: 2.2857em;
  }
  .mbsc-ios.mbsc-ltr.mbsc-progress.mbsc-ic-right.mbsc-progress-value-right .mbsc-progress-value,
  .mbsc-ios.mbsc-rtl.mbsc-progress.mbsc-ic-left.mbsc-progress-value-left .mbsc-progress-value {
    right: 2.2857em;
  }
  .mbsc-ios .mbsc-progress-cont {
    padding: 1em 0;
  }
  .mbsc-ios .mbsc-progress-track {
    border-radius: 1em;
    height: 0.0625em;
  }
  .mbsc-ios .mbsc-progress-bar {
    z-index: 1;
  }
  .mbsc-ios .mbsc-progress-value {
    width: 3.715em;
    font-size: 0.875em;
  }
  .mbsc-ios .mbsc-progress-step-label {
    top: 1.333334em;
  }
  .mbsc-ios.mbsc-progress.mbsc-ic-left:before,
  .mbsc-ios.mbsc-progress.mbsc-ic-left:after {
    left: 1em;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w .mbsc-label {
    font-size: 0.75em;
    position: absolute;
    top: 0.666667em;
    line-height: 1em;
    padding: 0;
    overflow: visible;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w:not(.mbsc-slider) .mbsc-input-wrap {
    padding-left: 0;
    padding-right: 0;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w .mbsc-label + .mbsc-input-wrap {
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w .mbsc-label + .mbsc-input-wrap .mbsc-input-ic {
    top: 1em;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-ltr.mbsc-ic-right .mbsc-input-wrap,
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-rtl.mbsc-ic-left .mbsc-input-wrap {
    padding-right: 2.5em;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-ltr.mbsc-ic-left .mbsc-input-wrap,
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-rtl.mbsc-ic-right .mbsc-input-wrap {
    padding-left: 2.5em;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-ltr.mbsc-progress-value-right .mbsc-input-wrap,
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-rtl.mbsc-progress-value-left .mbsc-input-wrap {
    padding-right: 3.5em;
  }
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-ltr.mbsc-progress-value-left .mbsc-input-wrap,
  .mbsc-ios .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-rtl.mbsc-progress-value-right .mbsc-input-wrap {
    padding-left: 3.5em;
  }
  .mbsc-ios
    .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-ltr.mbsc-ic-left.mbsc-progress-value-left
    .mbsc-input-wrap,
  .mbsc-ios
    .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-rtl.mbsc-ic-right.mbsc-progress-value-right
    .mbsc-input-wrap {
    padding-left: 5.5em;
  }
  .mbsc-ios
    .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-ltr.mbsc-ic-right.mbsc-progress-value-right
    .mbsc-input-wrap,
  .mbsc-ios
    .mbsc-label-stacked.mbsc-progress.mbsc-control-w.mbsc-rtl.mbsc-ic-left.mbsc-progress-value-left
    .mbsc-input-wrap {
    padding-right: 5.5em;
  }
  .mbsc-ios .mbsc-progress-primary .mbsc-progress-bar {
    background: #3f97f6;
  }
  .mbsc-ios .mbsc-progress-secondary .mbsc-progress-bar {
    background: #90979e;
  }
  .mbsc-ios .mbsc-progress-success .mbsc-progress-bar {
    background: #43be5f;
  }
  .mbsc-ios .mbsc-progress-danger .mbsc-progress-bar {
    background: #f5504e;
  }
  .mbsc-ios .mbsc-progress-warning .mbsc-progress-bar {
    background: #f8b042;
  }
  .mbsc-ios .mbsc-progress-info .mbsc-progress-bar {
    background: #5bb7c5;
  }
  .mbsc-ios.mbsc-progress {
    background: #fff;
  }
  .mbsc-ios .mbsc-progress-track {
    background: #dedddd;
  }
  .mbsc-ios .mbsc-progress-bar {
    background: #007bff;
  }
  .mbsc-ios .mbsc-progress-value,
  .mbsc-ios .mbsc-progress-step-label {
    color: #b5b5b5;
  }
  .mbsc-ios .mbsc-slider-handle-cont {
    top: 0;
  }
  .mbsc-ios .mbsc-slider-handle {
    top: 50%;
    width: 1.75em;
    height: 1.75em;
    margin: -0.875em -0.875em 0 0;
    border-radius: 1.25em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1), 0 0 0.0625em rgba(0, 0, 0, 0.15), 0 0.125em 0.125em rgba(0, 0, 0, 0.15);
  }
  .mbsc-ios .mbsc-slider-tooltip {
    font-size: 0.875em;
    width: 3em;
    margin-right: -1.5em;
    top: -1em;
    text-align: center;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }
  .mbsc-ios .mbsc-slider-handle:focus ~ .mbsc-slider-tooltip,
  .mbsc-ios .mbsc-active .mbsc-slider-tooltip {
    opacity: 1;
  }
  .mbsc-ios .mbsc-slider-step {
    height: 0.5em;
    width: 0.0625em;
    margin-top: -0.1875em;
    margin-left: -0.0625em;
  }
  .mbsc-ios.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
    opacity: 0.4;
  }
  .mbsc-ios .mbsc-slider-primary .mbsc-progress-bar {
    background: #3f97f6;
  }
  .mbsc-ios .mbsc-slider-secondary .mbsc-progress-bar {
    background: #90979e;
  }
  .mbsc-ios .mbsc-slider-success .mbsc-progress-bar {
    background: #43be5f;
  }
  .mbsc-ios .mbsc-slider-danger .mbsc-progress-bar {
    background: #f5504e;
  }
  .mbsc-ios .mbsc-slider-warning .mbsc-progress-bar {
    background: #f8b042;
  }
  .mbsc-ios .mbsc-slider-info .mbsc-progress-bar {
    background: #5bb7c5;
  }
  .mbsc-ios .mbsc-slider-handle {
    background: #fff;
  }
  .mbsc-ios .mbsc-slider-tooltip {
    color: #b5b5b5;
    background: #fff;
  }
  .mbsc-ios .mbsc-slider-step {
    background: #dedddd;
  }
  .mbsc-ios.mbsc-rating.mbsc-progress .mbsc-input-wrap {
    padding: 0;
  }
  .mbsc-ios.mbsc-rating input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
    opacity: 0.4;
  }
  .mbsc-ios.mbsc-rating.mbsc-rating-primary .mbsc-progress-track {
    color: #3f97f6;
  }
  .mbsc-ios.mbsc-rating.mbsc-rating-secondary .mbsc-progress-track {
    color: #90979e;
  }
  .mbsc-ios.mbsc-rating.mbsc-rating-success .mbsc-progress-track {
    color: #43be5f;
  }
  .mbsc-ios.mbsc-rating.mbsc-rating-danger .mbsc-progress-track {
    color: #f5504e;
  }
  .mbsc-ios.mbsc-rating.mbsc-rating-warning .mbsc-progress-track {
    color: #f8b042;
  }
  .mbsc-ios.mbsc-rating.mbsc-rating-info .mbsc-progress-track {
    color: #5bb7c5;
  }
  .mbsc-ios.mbsc-rating .mbsc-progress-track {
    color: #007bff;
  }
  .mbsc-ios.mbsc-alert .mbsc-fr-c.mbsc-wdg-c {
    padding: 1.142857em;
    line-height: 1.285714;
    text-align: center;
  }
  .mbsc-ios.mbsc-alert h2 {
    margin: 0.277778em 0;
    font-size: 1.285715em;
  }
  .mbsc-ios.mbsc-alert p {
    margin: 0.357143em 0;
  }
  .mbsc-ios.mbsc-alert .mbsc-input {
    margin-top: 1.142857em;
    border-radius: 0;
  }
  .mbsc-ios.mbsc-alert .mbsc-input input {
    height: 1.714286em;
    padding: 0 0.357143em;
  }
  .mbsc-ios.mbsc-toast .mbsc-toast-msg {
    border-radius: 0.5em;
    opacity: 0.9;
  }
  .mbsc-ios.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-primary .mbsc-toast-msg {
    background: #3f97f6;
  }
  .mbsc-ios.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-secondary .mbsc-toast-msg {
    background: #90979e;
  }
  .mbsc-ios.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-success .mbsc-toast-msg {
    background: #43be5f;
  }
  .mbsc-ios.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-danger .mbsc-toast-msg {
    background: #f5504e;
  }
  .mbsc-ios.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-warning .mbsc-toast-msg {
    background: #f8b042;
  }
  .mbsc-ios.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-info .mbsc-toast-msg {
    background: #5bb7c5;
  }
  .mbsc-ios.mbsc-alert .mbsc-input {
    border: 1px solid #ccc;
  }
  .mbsc-ios.mbsc-snackbar .mbsc-fr-w {
    background: #646464;
    color: #fff;
  }
  .mbsc-ios.mbsc-snackbar .mbsc-snackbar-btn {
    color: #99caff;
  }
  .mbsc-ios.mbsc-toast .mbsc-toast-msg {
    background: #646464;
    color: #fff;
  }
  .mbsc-ios.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-ios.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-ios.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-ios.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-ios.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-ios.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-ios.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-ios.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-ios.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-ios.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-ios.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-ios.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
  .mbsc-ios.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #eee;
  }
  .mbsc-ios .mbsc-desc {
    opacity: 0.5;
  }
  .mbsc-ios .mbsc-divider,
  .mbsc-ios .mbsc-form-group-title {
    position: relative;
    z-index: 2;
    margin-top: -1px;
    font-size: 0.75em;
    line-height: 1.5em;
    text-transform: uppercase;
  }
  .mbsc-ios .mbsc-divider {
    padding: 2.333334em 1.333334em 0.5em 1.333334em;
  }
  .mbsc-ios .mbsc-form-group-title {
    padding: 0.5em 1.333334em;
  }
  .mbsc-ios.mbsc-form-outline .mbsc-divider,
  .mbsc-ios.mbsc-form-outline .mbsc-form-group-title,
  .mbsc-ios.mbsc-form-box .mbsc-divider,
  .mbsc-ios.mbsc-form-box .mbsc-form-group-title {
    border: 0;
  }
  .mbsc-ios .mbsc-form-grid {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .mbsc-ios .mbsc-form-grid .mbsc-input-box.mbsc-control-w,
  .mbsc-ios .mbsc-form-grid .mbsc-input-outline.mbsc-control-w {
    margin: 0.75em 1em;
  }
  .mbsc-ios.mbsc-form .mbsc-checkbox,
  .mbsc-ios.mbsc-form .mbsc-radio {
    padding: 0.875em 3.75em 0.875em 1em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-checkbox,
  .mbsc-ios.mbsc-rtl .mbsc-radio {
    padding: 0.875em 1em 0.875em 3.75em;
  }
  .mbsc-ios .mbsc-checkbox input:disabled + .mbsc-checkbox-box,
  .mbsc-ios .mbsc-checkbox input:disabled ~ .mbsc-label,
  .mbsc-ios .mbsc-checkbox input:disabled ~ .mbsc-desc,
  .mbsc-ios .mbsc-radio input:disabled + .mbsc-radio-box,
  .mbsc-ios .mbsc-radio input:disabled ~ .mbsc-label,
  .mbsc-ios .mbsc-radio input:disabled ~ .mbsc-desc,
  .mbsc-ios .mbsc-switch input:disabled + .mbsc-switch-track,
  .mbsc-ios .mbsc-switch input:disabled ~ .mbsc-label,
  .mbsc-ios .mbsc-switch input:disabled ~ .mbsc-desc {
    opacity: 0.3;
  }
  .mbsc-ios .mbsc-checkbox-box:after,
  .mbsc-ios .mbsc-radio-box:after {
    transition: opacity 0.2s ease-in-out;
  }
  .mbsc-ios .mbsc-checkbox-box {
    right: 1em;
    width: 1.75em;
    height: 1.75em;
    margin-top: -0.875em;
    background: transparent;
    border-radius: 2em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-checkbox-box {
    right: auto;
    left: 1em;
  }
  .mbsc-ios .mbsc-checkbox-box:after {
    top: 32%;
    left: 26%;
    width: 0.75em;
    height: 0.375em;
  }
  .mbsc-ios .mbsc-checkbox-primary .mbsc-checkbox-box,
  .mbsc-ios .mbsc-checkbox-primary .mbsc-checkbox-box:after {
    border-color: #3f97f6;
  }
  .mbsc-ios .mbsc-checkbox-secondary .mbsc-checkbox-box,
  .mbsc-ios .mbsc-checkbox-secondary .mbsc-checkbox-box:after {
    border-color: #90979e;
  }
  .mbsc-ios .mbsc-checkbox-success .mbsc-checkbox-box,
  .mbsc-ios .mbsc-checkbox-success .mbsc-checkbox-box:after {
    border-color: #43be5f;
  }
  .mbsc-ios .mbsc-checkbox-danger .mbsc-checkbox-box,
  .mbsc-ios .mbsc-checkbox-danger .mbsc-checkbox-box:after {
    border-color: #f5504e;
  }
  .mbsc-ios .mbsc-checkbox-warning .mbsc-checkbox-box,
  .mbsc-ios .mbsc-checkbox-warning .mbsc-checkbox-box:after {
    border-color: #f8b042;
  }
  .mbsc-ios .mbsc-checkbox-info .mbsc-checkbox-box,
  .mbsc-ios .mbsc-checkbox-info .mbsc-checkbox-box:after {
    border-color: #5bb7c5;
  }
  .mbsc-ios .mbsc-radio-box {
    right: 1.125em;
    margin-top: -0.625em;
    background: transparent;
  }
  .mbsc-ios.mbsc-rtl .mbsc-radio-box {
    right: auto;
    left: 1.125em;
  }
  .mbsc-ios .mbsc-radio-box:after {
    position: absolute;
    top: 44%;
    left: 23%;
    width: 1em;
    height: 0.5em;
    border-radius: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .mbsc-ios .mbsc-radio-primary .mbsc-radio-box:after {
    border-color: #3f97f6;
  }
  .mbsc-ios .mbsc-radio-secondary .mbsc-radio-box:after {
    border-color: #90979e;
  }
  .mbsc-ios .mbsc-radio-success .mbsc-radio-box:after {
    border-color: #43be5f;
  }
  .mbsc-ios .mbsc-radio-danger .mbsc-radio-box:after {
    border-color: #f5504e;
  }
  .mbsc-ios .mbsc-radio-warning .mbsc-radio-box:after {
    border-color: #f8b042;
  }
  .mbsc-ios .mbsc-radio-info .mbsc-radio-box:after {
    border-color: #5bb7c5;
  }
  .mbsc-ios .mbsc-btn {
    margin: 0.5em 0.25em;
    padding: 0.625em 1.375em;
    border: 0;
    border-radius: 0.25em;
  }
  .mbsc-ios .mbsc-btn-ic {
    padding-right: 0.625em;
  }
  .mbsc-ios .mbsc-btn-icon-only .mbsc-btn-ic {
    padding: 0;
  }
  .mbsc-ios.mbsc-no-touch .mbsc-btn:not(:disabled):not(.mbsc-active):hover {
    opacity: 0.8;
  }
  .mbsc-ios .mbsc-btn.mbsc-active {
    opacity: 0.6;
  }
  .mbsc-ios .mbsc-btn-flat.mbsc-active {
    opacity: 0.6;
  }
  .mbsc-ios .mbsc-btn-flat:disabled {
    opacity: 0.8;
  }
  .mbsc-ios .mbsc-btn-group,
  .mbsc-ios .mbsc-btn-group-justified,
  .mbsc-ios .mbsc-btn-group-block {
    margin: 0.5em 0.75em;
  }
  .mbsc-ios .mbsc-btn-group-block {
    margin: 0.5em 1em;
  }
  .mbsc-ios .mbsc-btn-primary.mbsc-btn {
    background: #3f97f6;
  }
  .mbsc-ios .mbsc-btn-primary.mbsc-btn.mbsc-btn-flat {
    color: #3f97f6;
  }
  .mbsc-ios .mbsc-btn-secondary.mbsc-btn {
    background: #90979e;
  }
  .mbsc-ios .mbsc-btn-secondary.mbsc-btn.mbsc-btn-flat {
    color: #90979e;
  }
  .mbsc-ios .mbsc-btn-success.mbsc-btn {
    background: #43be5f;
  }
  .mbsc-ios .mbsc-btn-success.mbsc-btn.mbsc-btn-flat {
    color: #43be5f;
  }
  .mbsc-ios .mbsc-btn-danger.mbsc-btn {
    background: #f5504e;
  }
  .mbsc-ios .mbsc-btn-danger.mbsc-btn.mbsc-btn-flat {
    color: #f5504e;
  }
  .mbsc-ios .mbsc-btn-warning.mbsc-btn {
    background: #f8b042;
  }
  .mbsc-ios .mbsc-btn-warning.mbsc-btn.mbsc-btn-flat {
    color: #f8b042;
  }
  .mbsc-ios .mbsc-btn-info.mbsc-btn {
    background: #5bb7c5;
  }
  .mbsc-ios .mbsc-btn-info.mbsc-btn.mbsc-btn-flat {
    color: #5bb7c5;
  }
  .mbsc-ios .mbsc-btn-light.mbsc-btn {
    background: #fff;
  }
  .mbsc-ios .mbsc-btn-light.mbsc-btn.mbsc-btn-flat {
    color: #ccc;
  }
  .mbsc-ios .mbsc-btn-dark.mbsc-btn {
    background: #47494a;
  }
  .mbsc-ios .mbsc-btn-dark.mbsc-btn.mbsc-btn-flat {
    color: #47494a;
  }
  .mbsc-ios .mbsc-btn-flat.mbsc-btn {
    background: transparent;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-active {
    opacity: 1;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-primary {
    border-color: #3f97f6;
    color: #3f97f6;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-primary.mbsc-active {
    background: #3f97f6;
    color: #fff;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-secondary {
    border-color: #90979e;
    color: #90979e;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-secondary.mbsc-active {
    background: #90979e;
    color: #fff;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-success {
    border-color: #43be5f;
    color: #43be5f;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-success.mbsc-active {
    background: #43be5f;
    color: #fff;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-danger {
    border-color: #f5504e;
    color: #f5504e;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-danger.mbsc-active {
    background: #f5504e;
    color: #fff;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-warning {
    border-color: #f8b042;
    color: #f8b042;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-warning.mbsc-active {
    background: #f8b042;
    color: #fff;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-info {
    border-color: #5bb7c5;
    color: #5bb7c5;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-info.mbsc-active {
    background: #5bb7c5;
    color: #fff;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-light {
    border-color: #bfbfbf;
    color: #bfbfbf;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-light.mbsc-active {
    background: #bfbfbf;
    color: #fff;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-dark {
    border-color: #47494a;
    color: #47494a;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-btn-dark.mbsc-active {
    background: #47494a;
    color: #fff;
  }
  .mbsc-ios.mbsc-form .mbsc-switch {
    padding: 0.875em 5em 0.875em 1em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-switch {
    padding: 0.875em 1em 0.875em 5em;
  }
  .mbsc-ios .mbsc-switch-track {
    right: 1em;
    width: 3em;
    height: 1.75em;
    padding: 0;
    margin-top: -0.96875em;
    border-radius: 1.25em;
    box-sizing: content-box;
  }
  .mbsc-ios.mbsc-rtl .mbsc-switch-track {
    right: auto;
    left: 1em;
  }
  .mbsc-ios .mbsc-switch-track:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 1.25em;
    -webkit-transform: scale(1);
    transform: scale(1);
    transition: transform 0.2s ease-out;
  }
  .mbsc-ios .mbsc-switch-track .mbsc-progress-track {
    margin: 0 0.875em;
  }
  .mbsc-ios .mbsc-switch-handle {
    z-index: 2;
    top: 50%;
    left: 50%;
    width: 1.75em;
    height: 1.75em;
    margin: -0.875em 0 0 -0.875em;
    border-radius: 1.75em;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.1), 0 0 0.0625em rgba(0, 0, 0, 0.15), 0 0.125em 0.125em rgba(0, 0, 0, 0.15);
    transition: transform 0.2s ease-out;
  }
  .mbsc-ios.mbsc-rtl .mbsc-switch-handle {
    margin: -0.875em -0.875em 0 0;
  }
  .mbsc-ios .mbsc-switch input:checked + .mbsc-switch-track:after {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .mbsc-ios .mbsc-switch-primary.mbsc-switch input:checked + .mbsc-switch-track {
    border-color: #3f97f6;
    background: #3f97f6;
  }
  .mbsc-ios .mbsc-switch-secondary.mbsc-switch input:checked + .mbsc-switch-track {
    border-color: #90979e;
    background: #90979e;
  }
  .mbsc-ios .mbsc-switch-success.mbsc-switch input:checked + .mbsc-switch-track {
    border-color: #43be5f;
    background: #43be5f;
  }
  .mbsc-ios .mbsc-switch-danger.mbsc-switch input:checked + .mbsc-switch-track {
    border-color: #f5504e;
    background: #f5504e;
  }
  .mbsc-ios .mbsc-switch-warning.mbsc-switch input:checked + .mbsc-switch-track {
    border-color: #f8b042;
    background: #f8b042;
  }
  .mbsc-ios .mbsc-switch-info.mbsc-switch input:checked + .mbsc-switch-track {
    border-color: #5bb7c5;
    background: #5bb7c5;
  }
  .mbsc-ios .mbsc-segmented {
    padding: 0.75em;
  }
  .mbsc-ios .mbsc-stepper {
    display: block;
  }
  .mbsc-ios .mbsc-segmented-content {
    height: 2.142857142em;
    margin-left: -0.07142857em;
    line-height: 2.142857142857143em;
    padding: 0 0.42857em;
    text-transform: capitalize;
  }
  .mbsc-ios.mbsc-ltr .mbsc-stepper-minus .mbsc-segmented-content,
  .mbsc-ios.mbsc-ltr .mbsc-segmented-item:first-child .mbsc-segmented-content,
  .mbsc-ios.mbsc-rtl .mbsc-stepper-plus .mbsc-segmented-content,
  .mbsc-ios.mbsc-rtl .mbsc-segmented-item:last-child .mbsc-segmented-content {
    border-top-left-radius: 0.25em;
    border-bottom-left-radius: 0.25em;
  }
  .mbsc-ios .mbsc-segmented.mbsc-stepper.mbsc-control-w:before,
  .mbsc-ios .mbsc-segmented.mbsc-stepper.mbsc-control-w:after {
    border: 0;
  }
  .mbsc-ios.mbsc-ltr .mbsc-stepper-plus .mbsc-segmented-content,
  .mbsc-ios.mbsc-ltr .mbsc-segmented-item:last-child .mbsc-segmented-content,
  .mbsc-ios.mbsc-rtl .mbsc-stepper-minus .mbsc-segmented-content,
  .mbsc-ios.mbsc-rtl .mbsc-segmented-item:first-child .mbsc-segmented-content {
    border-top-right-radius: 0.25em;
    border-bottom-right-radius: 0.25em;
  }
  .mbsc-ios .mbsc-stepper-cont {
    padding: 1.5em 11.75em 1.5em 1em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-stepper-cont {
    padding: 1.5em 1em 1.5em 11.75em;
  }
  .mbsc-ios .mbsc-stepper {
    right: 1em;
    margin-top: -1em;
  }
  .mbsc-ios.mbsc-rtl .mbsc-stepper {
    right: auto;
    left: 1em;
  }
  .mbsc-ios .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-ios .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-ios .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    background: none;
  }
  .mbsc-ios .mbsc-stepper .mbsc-segmented-item {
    width: 3.25em;
  }
  .mbsc-ios .mbsc-stepper input {
    width: 3.714286em;
    left: 3.714286em;
    z-index: 3;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-primary .mbsc-segmented-content {
    border-color: #3f97f6;
    color: #3f97f6;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-primary.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    color: #3f97f6;
    background: rgba(63, 151, 246, 0.45);
  }
  .mbsc-ios
    .mbsc-segmented
    .mbsc-segmented-primary.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #3f97f6;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-secondary .mbsc-segmented-content {
    border-color: #90979e;
    color: #90979e;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-secondary.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    color: #90979e;
    background: rgba(144, 151, 158, 0.45);
  }
  .mbsc-ios
    .mbsc-segmented
    .mbsc-segmented-secondary.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #90979e;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-success .mbsc-segmented-content {
    border-color: #43be5f;
    color: #43be5f;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-success.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    color: #43be5f;
    background: rgba(67, 190, 95, 0.45);
  }
  .mbsc-ios
    .mbsc-segmented
    .mbsc-segmented-success.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #43be5f;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-danger .mbsc-segmented-content {
    border-color: #f5504e;
    color: #f5504e;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-danger.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    color: #f5504e;
    background: rgba(245, 80, 78, 0.45);
  }
  .mbsc-ios
    .mbsc-segmented
    .mbsc-segmented-danger.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #f5504e;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-warning .mbsc-segmented-content {
    border-color: #f8b042;
    color: #f8b042;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-warning.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    color: #f8b042;
    background: rgba(248, 176, 66, 0.45);
  }
  .mbsc-ios
    .mbsc-segmented
    .mbsc-segmented-warning.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #f8b042;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-info .mbsc-segmented-content {
    border-color: #5bb7c5;
    color: #5bb7c5;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-info.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    color: #5bb7c5;
    background: rgba(91, 183, 197, 0.45);
  }
  .mbsc-ios
    .mbsc-segmented
    .mbsc-segmented-info.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #5bb7c5;
  }
  .mbsc-ios.mbsc-no-touch .mbsc-segmented-primary.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(63, 151, 246, 0.15);
  }
  .mbsc-ios.mbsc-no-touch .mbsc-segmented-secondary.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(144, 151, 158, 0.15);
  }
  .mbsc-ios.mbsc-no-touch .mbsc-segmented-success.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(67, 190, 95, 0.15);
  }
  .mbsc-ios.mbsc-no-touch .mbsc-segmented-danger.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(245, 80, 78, 0.15);
  }
  .mbsc-ios.mbsc-no-touch .mbsc-segmented-warning.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(248, 176, 66, 0.15);
  }
  .mbsc-ios.mbsc-no-touch .mbsc-segmented-info.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(91, 183, 197, 0.15);
  }
  .mbsc-ios.mbsc-no-touch .mbsc-stepper-primary .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(63, 151, 246, 0.15);
  }
  .mbsc-ios.mbsc-no-touch .mbsc-stepper-secondary .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(144, 151, 158, 0.15);
  }
  .mbsc-ios.mbsc-no-touch .mbsc-stepper-success .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(67, 190, 95, 0.15);
  }
  .mbsc-ios.mbsc-no-touch .mbsc-stepper-danger .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(245, 80, 78, 0.15);
  }
  .mbsc-ios.mbsc-no-touch .mbsc-stepper-warning .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(248, 176, 66, 0.15);
  }
  .mbsc-ios.mbsc-no-touch .mbsc-stepper-info .mbsc-segmented .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(91, 183, 197, 0.15);
  }
  .mbsc-ios .mbsc-stepper-primary .mbsc-segmented-content {
    border-color: #3f97f6;
    color: #3f97f6;
  }
  .mbsc-ios
    .mbsc-stepper-primary
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #3f97f6;
  }
  .mbsc-ios .mbsc-stepper-secondary .mbsc-segmented-content {
    border-color: #90979e;
    color: #90979e;
  }
  .mbsc-ios
    .mbsc-stepper-secondary
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #90979e;
  }
  .mbsc-ios .mbsc-stepper-success .mbsc-segmented-content {
    border-color: #43be5f;
    color: #43be5f;
  }
  .mbsc-ios
    .mbsc-stepper-success
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #43be5f;
  }
  .mbsc-ios .mbsc-stepper-danger .mbsc-segmented-content {
    border-color: #f5504e;
    color: #f5504e;
  }
  .mbsc-ios
    .mbsc-stepper-danger
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #f5504e;
  }
  .mbsc-ios .mbsc-stepper-warning .mbsc-segmented-content {
    border-color: #f8b042;
    color: #f8b042;
  }
  .mbsc-ios
    .mbsc-stepper-warning
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #f8b042;
  }
  .mbsc-ios .mbsc-stepper-info .mbsc-segmented-content {
    border-color: #5bb7c5;
    color: #5bb7c5;
  }
  .mbsc-ios
    .mbsc-stepper-info
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #5bb7c5;
  }
  .mbsc-ios.mbsc-form {
    background: #efeff4;
    color: #000;
  }
  .mbsc-ios.mbsc-form *::-moz-selection,
  .mbsc-ios.mbsc-form *::selection {
    color: #ccc;
    background: #1274de;
  }
  .mbsc-ios .mbsc-input-ic {
    color: #8c8c8c;
  }
  .mbsc-ios .mbsc-divider,
  .mbsc-ios .mbsc-form-group-title {
    border-bottom: 1px solid #ccc;
    color: #707070;
  }
  .mbsc-ios .mbsc-divider {
    border-top: 1px solid #ccc;
  }
  .mbsc-ios.mbsc-form .mbsc-checkbox,
  .mbsc-ios.mbsc-form .mbsc-radio {
    background: #fff;
  }
  .mbsc-ios .mbsc-checkbox-box {
    border: 0.125em solid #1274de;
  }
  .mbsc-ios .mbsc-checkbox-box:after {
    border: 0.125em solid #1274de;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-ios .mbsc-radio-box:after {
    border: 0.125em solid #1274de;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-ios .mbsc-radio input:disabled ~ .mbsc-radio-label {
    color: #c4c4c4;
  }
  .mbsc-ios .mbsc-btn {
    background: #fff;
    color: #007bff;
  }
  .mbsc-ios .mbsc-btn:not(.mbsc-btn-flat):disabled {
    background: #dedddd;
    color: #c4c4c4;
  }
  .mbsc-ios .mbsc-btn.mbsc-btn-flat {
    background: transparent;
    border-color: transparent;
  }
  .mbsc-ios .mbsc-btn-flat:disabled {
    background: transparent;
    color: #c4c4c4;
  }
  .mbsc-ios .mbsc-btn-primary.mbsc-btn,
  .mbsc-ios .mbsc-btn-secondary.mbsc-btn,
  .mbsc-ios .mbsc-btn-success.mbsc-btn,
  .mbsc-ios .mbsc-btn-danger.mbsc-btn,
  .mbsc-ios .mbsc-btn-warning.mbsc-btn,
  .mbsc-ios .mbsc-btn-info.mbsc-btn,
  .mbsc-ios .mbsc-btn-dark.mbsc-btn {
    color: #eee;
  }
  .mbsc-ios .mbsc-btn-light.mbsc-btn {
    color: #000;
  }
  .mbsc-ios .mbsc-btn-flat.mbsc-btn {
    background: transparent;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn {
    background: transparent;
    border: 1px solid #1274de;
    color: #1274de;
  }
  .mbsc-ios .mbsc-btn-outline.mbsc-btn.mbsc-active {
    background: #1274de;
    color: #efeff4;
  }
  .mbsc-ios .mbsc-btn.mbsc-btn-outline:disabled {
    color: #c4c4c4;
    border-color: #c4c4c4;
    background: transparent;
  }
  .mbsc-ios.mbsc-form .mbsc-switch {
    background: #fff;
  }
  .mbsc-ios .mbsc-switch-track {
    background: #e5e5e5;
    border: 0.0625em solid #e5e5e5;
  }
  .mbsc-ios .mbsc-switch-track:after {
    background: #fff;
  }
  .mbsc-ios .mbsc-switch-handle {
    background: #fff;
  }
  .mbsc-ios .mbsc-switch input:checked + .mbsc-switch-track {
    background: #4cd764;
    border-color: #4cd764;
  }
  .mbsc-ios .mbsc-segmented {
    background-color: #fff;
  }
  .mbsc-ios .mbsc-segmented-content {
    color: #1274de;
    border: 0.07142857em solid #1274de;
    background: #fff;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
  .mbsc-ios .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #1274de;
    color: #fff;
  }
  .mbsc-ios .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(0, 123, 255, 0.3);
    color: #1274de;
  }
  .mbsc-ios .mbsc-stepper-cont {
    background: #fff;
  }
  .mbsc-ios .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-ios .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-ios .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    color: #c4c4c4;
    border-color: #c4c4c4;
  }
  .mbsc-ios .mbsc-stepper input:disabled {
    color: #c4c4c4;
    -webkit-text-fill-color: #c4c4c4;
  }
  .mbsc-ios .mbsc-segmented input:disabled:checked + .mbsc-segmented-content {
    background: #dedddd;
  }
  .mbsc-ios .mbsc-stepper input {
    color: #000;
  }
  .mbsc-ios.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(0, 123, 255, 0.15);
  }
  .mbsc-ios.mbsc-card.mbsc-form {
    margin: 1.5em 1em;
    border-radius: 0.5em;
  }
  .mbsc-ios.mbsc-card .mbsc-btn-flat {
    padding: 0.625em;
  }
  .mbsc-ios .mbsc-card-footer .mbsc-btn {
    margin-top: -0.625em;
    margin-bottom: -0.625em;
  }
  .mbsc-ios.mbsc-card .mbsc-btn-group-block {
    background: transparent;
  }
  .mbsc-ios.mbsc-card .mbsc-ios .mbsc-lv-item:first-child:before {
    border: 0;
  }
  .mbsc-ios.mbsc-card .mbsc-ios .mbsc-lv-item:last-child:after {
    border: 0;
  }
  .mbsc-ios.mbsc-card .mbsc-control-w:last-child:after {
    border: 0;
  }
  .mbsc-ios .mbsc-card-list .mbsc-lv-item:before,
  .mbsc-ios .mbsc-card-list .mbsc-lv-item:after {
    border: 0;
  }
  .mbsc-card-list .mbsc-ios .mbsc-lv-item:before,
  .mbsc-card-list .mbsc-ios .mbsc-lv-item:after {
    border: 0;
  }
  .mbsc-ios.mbsc-card.mbsc-form {
    background: #fff;
    box-shadow: 0 0 1px 1px rgba(204, 204, 204, 0.2);
  }
  .mbsc-ios .mbsc-card-footer {
    border-color: #ccc;
  }
  .mbsc-ios.mbsc-card .mbsc-input-box.mbsc-control-w,
  .mbsc-ios.mbsc-card .mbsc-input-box.mbsc-control-w {
    border: 1px solid #ccc;
  }
  .mbsc-material .mbsc-collapsible .mbsc-form-group-title {
    padding: 1em;
  }
  .mbsc-material .mbsc-collapsible .mbsc-collapsible-header {
    padding-right: 3em;
  }
  .mbsc-material .mbsc-note {
    border-radius: 0.1875em;
  }
  .mbsc-material .mbsc-note,
  .mbsc-material .mbsc-note-primary {
    color: #074b95;
    background-color: #88bff9;
  }
  .mbsc-material .mbsc-note-secondary {
    color: #454b50;
    background-color: #b9bdc2;
  }
  .mbsc-material .mbsc-note-success {
    color: #1b4d26;
    background-color: #7cd28f;
  }
  .mbsc-material .mbsc-note-danger {
    color: #a10b09;
    background-color: #f99896;
  }
  .mbsc-material .mbsc-note-warning {
    color: #9b6006;
    background-color: #fbcf8c;
  }
  .mbsc-material .mbsc-note-info {
    color: #235b64;
    background-color: #94d0d9;
  }
  .mbsc-material .mbsc-note-light {
    color: #666;
    background-color: #fff;
  }
  .mbsc-material .mbsc-note-dark {
    color: #000;
    background-color: #797c7e;
  }
  .mbsc-material .mbsc-ripple {
    background: #000;
  }
  .mbsc-material .mbsc-empty {
    color: #5b5b5b;
  }
  .mbsc-material.mbsc-page {
    background-color: #eee;
    color: #6d6d6d;
  }
  .mbsc-material a {
    color: #009688;
  }
  .mbsc-material .mbsc-input input,
  .mbsc-material .mbsc-input textarea {
    height: 2.125em;
    padding: 0 0 1px 0;
    background-color: transparent;
    font-size: 1em;
    transition: border-color 0.2s;
  }
  .mbsc-material .mbsc-input .mbsc-control:disabled ~ input,
  .mbsc-material .mbsc-input textarea:disabled,
  .mbsc-material .mbsc-input input:disabled {
    border-style: dotted;
    opacity: 0.5;
  }
  .mbsc-material .mbsc-input input:focus,
  .mbsc-material .mbsc-input textarea:focus,
  .mbsc-material .mbsc-input select:focus ~ input {
    padding-bottom: 0;
  }
  .mbsc-material .mbsc-input {
    margin: 1.5em 1em;
  }
  .mbsc-material .mbsc-input .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0.875em;
  }
  .mbsc-material .mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 0.9375em;
  }
  .mbsc-material .mbsc-input.mbsc-err input:focus,
  .mbsc-material .mbsc-input.mbsc-err textarea:focus {
    padding-bottom: 0;
    border-width: 2px;
  }
  .mbsc-material .mbsc-input-ic {
    top: 0.0625em;
    margin: -2px 0 0 0;
  }
  .mbsc-material .mbsc-input textarea {
    padding-top: 0.25em;
  }
  .mbsc-material .mbsc-select .mbsc-input-wrap:after {
    content: "";
    position: absolute;
    height: 0;
    width: 0;
    top: 0.875em;
  }
  .mbsc-material .mbsc-select .mbsc-label ~ .mbsc-input-wrap:after {
    top: 1.75em;
  }
  .mbsc-material.mbsc-ltr .mbsc-select input {
    padding-right: 1em;
  }
  .mbsc-material.mbsc-ltr .mbsc-select.mbsc-ic-right input {
    padding-right: 3em;
  }
  .mbsc-material.mbsc-ltr .mbsc-select .mbsc-input-wrap:after {
    left: auto;
    right: 0;
  }
  .mbsc-material.mbsc-ltr .mbsc-select.mbsc-ic-right .mbsc-input-wrap:after {
    right: 2em;
  }
  .mbsc-material.mbsc-rtl .mbsc-select input {
    padding-left: 1em;
  }
  .mbsc-material.mbsc-rtl .mbsc-select.mbsc-ic-right input {
    padding-left: 3em;
  }
  .mbsc-material.mbsc-rtl .mbsc-select .mbsc-input-wrap:after {
    right: auto;
    left: 0;
  }
  .mbsc-material.mbsc-rtl .mbsc-select.mbsc-ic-right .mbsc-input-wrap:after {
    left: 2em;
  }
  .mbsc-material .mbsc-select-inline .mbsc-input-wrap:after {
    display: none;
  }
  .mbsc-material.mbsc-ltr .mbsc-input.mbsc-ic-left input,
  .mbsc-material.mbsc-ltr .mbsc-input.mbsc-ic-left textarea {
    padding-left: 1.625em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input.mbsc-ic-left .mbsc-left-ic {
    right: auto;
    left: -0.375em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input.mbsc-ic-left .mbsc-label,
  .mbsc-material.mbsc-ltr .mbsc-input.mbsc-ic-left .mbsc-err-msg {
    left: 2.166667em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input.mbsc-ic-right input,
  .mbsc-material.mbsc-ltr .mbsc-input.mbsc-ic-right textarea {
    padding-right: 1.625em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input.mbsc-ic-right .mbsc-right-ic {
    left: auto;
    right: -0.375em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input.mbsc-ic-left input,
  .mbsc-material.mbsc-rtl .mbsc-input.mbsc-ic-left textarea {
    padding-right: 1.625em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input.mbsc-ic-left .mbsc-left-ic {
    left: auto;
    right: -0.375em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input.mbsc-ic-left .mbsc-label,
  .mbsc-material.mbsc-rtl .mbsc-input.mbsc-ic-left .mbsc-err-msg {
    right: 2.166667em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input.mbsc-ic-right input,
  .mbsc-material.mbsc-rtl .mbsc-input.mbsc-ic-right textarea {
    padding-left: 1.625em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input.mbsc-ic-right .mbsc-right-ic {
    right: auto;
    left: -0.375em;
  }
  .mbsc-material .mbsc-input .mbsc-label {
    position: absolute;
    top: 0;
    font-size: 0.75em;
  }
  .mbsc-material .mbsc-label-inline.mbsc-input .mbsc-label {
    line-height: 2em;
  }
  .mbsc-material .mbsc-label-inline.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0;
  }
  .mbsc-material .mbsc-label-inline.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 0.0625em;
  }
  .mbsc-material .mbsc-label-inline.mbsc-input.mbsc-select .mbsc-label ~ .mbsc-input-wrap:after {
    top: 1em;
  }
  .mbsc-material .mbsc-label-floating.mbsc-input .mbsc-label {
    font-size: 1em;
    line-height: 2em;
    top: 0.875em;
  }
  .mbsc-material .mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    -webkit-transform: translateY(-1.125em) scale(0.75);
    transform: translateY(-1.125em) scale(0.75);
  }
  .mbsc-material.mbsc-ltr .mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    left: 1.625em;
  }
  .mbsc-material.mbsc-rtl .mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    right: 1.625em;
  }
  .mbsc-material .mbsc-input-box.mbsc-input .mbsc-input-wrap {
    border-radius: 4px 4px 0 0;
  }
  .mbsc-material .mbsc-input-box.mbsc-input input {
    height: 2.25em;
    padding: 0.0625em 1em;
    border-radius: 4px 4px 0 0;
  }
  .mbsc-material .mbsc-input-box.mbsc-input input:focus,
  .mbsc-material .mbsc-input-box.mbsc-input textarea:focus,
  .mbsc-material .mbsc-input-box.mbsc-input select:focus ~ input {
    padding-bottom: 0;
  }
  .mbsc-material .mbsc-input-box.mbsc-input textarea {
    padding: 0.375em 1em 0.0625em 1em;
    height: 2.25em;
  }
  .mbsc-material .mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0;
  }
  .mbsc-material .mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap input {
    padding-top: 1.25em;
    height: 3.5em;
  }
  .mbsc-material .mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap textarea:focus {
    padding-bottom: 0;
  }
  .mbsc-material .mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 0.875em;
  }
  .mbsc-material .mbsc-input-box .mbsc-input-ic {
    top: 0.25em;
  }
  .mbsc-material .mbsc-input-box .mbsc-label {
    top: 0.666667em;
    z-index: 1;
  }
  .mbsc-material .mbsc-input-box.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 1.625em;
  }
  .mbsc-material .mbsc-input-box.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap textarea {
    height: 1.866667em;
    padding-top: 0;
  }
  .mbsc-material .mbsc-input-box.mbsc-select .mbsc-input-wrap:after {
    top: 1em;
  }
  .mbsc-material .mbsc-input-box.mbsc-select .mbsc-label ~ .mbsc-input-wrap:after {
    top: 1.625em;
  }
  .mbsc-material .mbsc-input-box.mbsc-label-inline .mbsc-label {
    padding-top: 0.125em;
    padding-bottom: 0.125em;
  }
  .mbsc-material .mbsc-input-box.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0;
  }
  .mbsc-material .mbsc-input-box.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap input {
    height: 2.25em;
    padding-top: 0.0625em;
  }
  .mbsc-material .mbsc-input-box.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 0.1875em;
  }
  .mbsc-material .mbsc-input-box.mbsc-label-inline.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0.375em;
  }
  .mbsc-material .mbsc-input-box.mbsc-label-floating.mbsc-label-floating-active .mbsc-label {
    -webkit-transform: translateY(-0.625em) scale(0.75);
    transform: translateY(-0.625em) scale(0.75);
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box .mbsc-err-msg {
    left: 1.333334em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box .mbsc-label {
    left: 1.333334em;
    right: auto;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-ic-left .mbsc-err-msg {
    left: 4em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-ic-left .mbsc-label {
    left: 4em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-ic-left .mbsc-left-ic {
    left: 0.5em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-ic-left input,
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-ic-left textarea {
    padding-left: 3em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-ic-right input,
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-ic-right textarea {
    padding-right: 3em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-ic-right .mbsc-right-ic {
    right: 0.5em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-ic-right .mbsc-input-wrap:after {
    right: 2em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-select input {
    padding-right: 2em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-select .mbsc-input-wrap:after {
    right: 0.6875em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-select.mbsc-ic-right input {
    padding-right: 4em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-select.mbsc-ic-right .mbsc-input-wrap:after {
    right: 3em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-label-floating.mbsc-input .mbsc-label {
    left: 1em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-box.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    left: 3em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box .mbsc-err-msg {
    right: 1.333334em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box .mbsc-label {
    right: 1.333334em;
    left: auto;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-ic-left .mbsc-err-msg {
    right: 4em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-ic-left .mbsc-label {
    right: 4em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-ic-left .mbsc-left-ic {
    right: 0.5em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-ic-left input,
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-ic-left textarea {
    padding-right: 3em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-ic-right input,
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-ic-right textarea {
    padding-left: 3em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-ic-right .mbsc-right-ic {
    left: 0.5em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-ic-right .mbsc-input-wrap:after {
    left: 2em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-select input {
    padding-left: 2em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-select .mbsc-input-wrap:after {
    left: 0.6875em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-select.mbsc-ic-right input {
    padding-left: 4em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-select.mbsc-ic-right .mbsc-input-wrap:after {
    left: 3em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-label-floating.mbsc-input .mbsc-label {
    right: 1em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-box.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    right: 3em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input input,
  .mbsc-material .mbsc-input-outline.mbsc-input textarea {
    border-radius: 4px;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input input {
    height: 3.375em;
    padding: 0.0625em 1em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input textarea {
    height: 3.375em;
    padding: 0.875em 1em 0.4375em 1em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input .mbsc-label {
    top: -0.166667em;
    z-index: 1;
    padding: 0 0.333334em;
  }
  .mbsc-material .mbsc-input-outline .mbsc-input-ic {
    top: 0.8125em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0.25em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 1.0625em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input input:focus,
  .mbsc-material .mbsc-input-outline.mbsc-input select:focus ~ input {
    padding: 0 0.9375em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-err input {
    padding-top: 0;
    padding-bottom: 0;
  }
  .mbsc-material .mbsc-input-outline.mbsc-err input:focus {
    padding: 0 0.9375em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input textarea:focus {
    padding: 0.8125em 0.9375em 0.375em 0.9375em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-err textarea:focus {
    padding: 0.875em 0.9375em 0 0.9375em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-select .mbsc-input-wrap:after {
    top: 1.5em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-select .mbsc-label ~ .mbsc-input-wrap:after {
    top: 1.75em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-label-inline .mbsc-label {
    line-height: 3.375em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0;
  }
  .mbsc-material .mbsc-input-outline.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 0.8125em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-label-inline.mbsc-select .mbsc-label ~ .mbsc-input-wrap:after {
    top: 1.5em;
  }
  .mbsc-material .mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label {
    top: 0.9375em;
    padding: 0;
  }
  .mbsc-material .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    -webkit-transform: translateY(-1.375em) scale(0.75);
    transform: translateY(-1.375em) scale(0.75);
    padding: 0 0.25em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline .mbsc-err-msg {
    left: 1.416667em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline .mbsc-label {
    left: 1.083334em;
    right: auto;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-left .mbsc-err-msg {
    left: 4.083334em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-left .mbsc-label {
    left: 3.75em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-left .mbsc-left-ic {
    left: 0.5em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-left input,
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-left textarea {
    padding-left: 3em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-left input:focus,
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-left textarea:focus,
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-left.mbsc-err textarea {
    padding-left: 2.9375em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-right input,
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-right textarea {
    padding-right: 3em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-right input:focus,
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-right textarea:focus {
    padding-right: 2.9375em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-ic-right .mbsc-right-ic {
    right: 0.5em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-select input {
    padding-right: 2em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-select select:focus ~ input {
    padding-right: 1.9375em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-select .mbsc-input-wrap:after {
    right: 0.6875em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-select.mbsc-ic-right input {
    padding-right: 4em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-select.mbsc-ic-right select:focus ~ input {
    padding-right: 3.9375em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-select.mbsc-ic-right .mbsc-input-wrap:after {
    right: 3em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-select.mbsc-ic-left select:focus ~ input {
    padding-left: 2.9375em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label {
    left: 1em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    left: 0.875em;
  }
  .mbsc-material.mbsc-ltr .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    left: 3em;
  }
  .mbsc-material.mbsc-ltr
    .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-ic-left.mbsc-label-floating-active
    .mbsc-label {
    left: 2.875em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline .mbsc-err-msg {
    right: 1.416667em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline .mbsc-label {
    right: 1.083334em;
    left: auto;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-left .mbsc-err-msg {
    right: 4.083334em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-left .mbsc-label {
    right: 3.75em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-left .mbsc-left-ic {
    right: 0.5em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-left input,
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-left textarea {
    padding-right: 3em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-left input:focus,
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-left textarea:focus,
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-left.mbsc-err textarea {
    padding-right: 2.9375em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-right input,
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-right textarea {
    padding-left: 3em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-right input:focus,
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-right textarea:focus {
    padding-left: 2.9375em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-ic-right .mbsc-right-ic {
    left: 0.5em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-select input {
    padding-left: 2em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-select select:focus ~ input {
    padding-left: 1.9375em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-select .mbsc-input-wrap:after {
    left: 0.6875em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-select.mbsc-ic-right input {
    padding-left: 4em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-select.mbsc-ic-right select:focus ~ input {
    padding-left: 3.9375em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-select.mbsc-ic-right .mbsc-input-wrap:after {
    left: 3em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-select.mbsc-ic-left select:focus ~ input {
    padding-right: 2.9375em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label {
    right: 1em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    right: 0.875em;
  }
  .mbsc-material.mbsc-rtl .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    right: 3em;
  }
  .mbsc-material.mbsc-rtl
    .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-ic-left.mbsc-label-floating-active
    .mbsc-label {
    right: 2.875em;
  }
  .mbsc-material .mbsc-input input,
  .mbsc-material .mbsc-input textarea {
    border-bottom: 1px solid #6d6d6d;
    color: #1e1e1e;
  }
  .mbsc-material .mbsc-input input:focus,
  .mbsc-material .mbsc-input textarea:focus,
  .mbsc-material .mbsc-input select:focus ~ input {
    border-bottom: 2px solid #009688;
  }
  .mbsc-material .mbsc-input.mbsc-err input,
  .mbsc-material .mbsc-input.mbsc-err textarea {
    border-bottom: 1px solid #de3226;
  }
  .mbsc-material .mbsc-select .mbsc-input-wrap:after {
    border: 5px solid #6d6d6d;
    border-color: #6d6d6d transparent transparent transparent;
  }
  .mbsc-material .mbsc-input-box.mbsc-input .mbsc-input-wrap {
    background: #dcdcdc;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input input,
  .mbsc-material .mbsc-input-outline.mbsc-input textarea {
    border: 1px solid #6d6d6d;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input .mbsc-label {
    background: #eee;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input input:focus,
  .mbsc-material .mbsc-input-outline.mbsc-input select:focus ~ input {
    border: 2px solid #009688;
  }
  .mbsc-material .mbsc-input-outline.mbsc-err input {
    border: 1px solid #de3226;
  }
  .mbsc-material .mbsc-input-outline.mbsc-err input:focus {
    border: 2px solid #de3226;
  }
  .mbsc-material .mbsc-input-outline.mbsc-input textarea:focus {
    border: 2px solid #009688;
  }
  .mbsc-material .mbsc-input-outline.mbsc-err textarea {
    border: 1px solid #de3226;
  }
  .mbsc-material .mbsc-input-outline.mbsc-err textarea:focus {
    border: 2px solid #de3226;
  }
  .mbsc-material.mbsc-progress {
    padding: 0.75em 1em;
  }
  .mbsc-material.mbsc-progress .mbsc-input-wrap {
    padding: 0;
  }
  .mbsc-material.mbsc-progress .mbsc-input-ic {
    top: 0.3125em;
    margin: 0;
  }
  .mbsc-material.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-input-wrap,
  .mbsc-material.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-input-wrap {
    padding-left: 2.25em;
  }
  .mbsc-material.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-input-wrap,
  .mbsc-material.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-input-wrap {
    padding-right: 2.25em;
  }
  .mbsc-material.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-left-ic,
  .mbsc-material.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-right-ic {
    right: auto;
    left: -0.375em;
  }
  .mbsc-material.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-right-ic,
  .mbsc-material.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-left-ic {
    left: auto;
    right: -0.375em;
  }
  .mbsc-material.mbsc-progress .mbsc-label {
    font-size: 0.75em;
  }
  .mbsc-material .mbsc-progress-cont {
    padding: 1.25em 0;
  }
  .mbsc-material .mbsc-progress-track {
    height: 0.125em;
  }
  .mbsc-material .mbsc-progress-value {
    font-size: 0.75em;
    width: 4em;
  }
  .mbsc-material.mbsc-ltr.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap,
  .mbsc-material.mbsc-rtl.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap {
    padding-right: 3.25em;
  }
  .mbsc-material.mbsc-ltr.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap,
  .mbsc-material.mbsc-rtl.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap {
    padding-left: 3.25em;
  }
  .mbsc-material.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap,
  .mbsc-material.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap {
    padding-left: 5.5em;
  }
  .mbsc-material.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap,
  .mbsc-material.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap {
    padding-right: 5.5em;
  }
  .mbsc-material.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value,
  .mbsc-material.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value {
    left: 2.25em;
    right: auto;
  }
  .mbsc-material.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value,
  .mbsc-material.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value {
    left: auto;
    right: 2.25em;
  }
  .mbsc-material .mbsc-label-inline.mbsc-progress.mbsc-control-w .mbsc-label {
    line-height: 2.625em;
  }
  .mbsc-material .mbsc-progress-primary .mbsc-progress-bar {
    background: #3f97f6;
  }
  .mbsc-material .mbsc-progress-secondary .mbsc-progress-bar {
    background: #90979e;
  }
  .mbsc-material .mbsc-progress-success .mbsc-progress-bar {
    background: #43be5f;
  }
  .mbsc-material .mbsc-progress-danger .mbsc-progress-bar {
    background: #f5504e;
  }
  .mbsc-material .mbsc-progress-warning .mbsc-progress-bar {
    background: #f8b042;
  }
  .mbsc-material .mbsc-progress-info .mbsc-progress-bar {
    background: #5bb7c5;
  }
  .mbsc-material .mbsc-progress-track {
    background: #b3b3b3;
  }
  .mbsc-material .mbsc-progress-bar {
    background: #009688;
  }
  .mbsc-material.mbsc-slider .mbsc-input-wrap {
    padding: 0 0.5em;
  }
  .mbsc-material .mbsc-slider-handle {
    top: 50%;
    width: 0.75em;
    height: 0.75em;
    margin: -0.5em -0.5em 0 0;
    border-radius: 1.125em;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
  }
  .mbsc-material .mbsc-slider-handle:focus,
  .mbsc-material .mbsc-active .mbsc-slider-handle {
    -webkit-transform: scale(1.4);
    transform: scale(1.4);
  }
  .mbsc-material .mbsc-slider-handle:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: -0.625em;
    left: -0.625em;
    width: 2em;
    height: 2em;
    opacity: 0;
    border-radius: 2.875em;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
  }
  .mbsc-material .mbsc-active .mbsc-slider-handle:before {
    opacity: 1;
  }
  .mbsc-material .mbsc-slider-tooltip {
    margin-right: -1.16665em;
    top: -2.5em;
    width: 2.33333333em;
    height: 2.33333333em;
    border-radius: 2.33333333em;
    line-height: 2.33333333em;
    text-align: center;
    font-size: 0.75em;
    -webkit-transform: scale(0.4) translate3d(0, 6em, 0);
    transform: scale(0.4) translate3d(0, 6em, 0);
    -webkit-transition: -webkit-transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }
  .mbsc-material .mbsc-slider-tooltip:before {
    position: absolute;
    content: "";
    width: 0;
    height: 0;
    border-left: 0.8333em solid transparent;
    border-right: 0.8333em solid transparent;
    bottom: -0.66666em;
    left: 0.3333em;
  }
  .mbsc-material.mbsc-slider-has-tooltip .mbsc-slider-handle:focus ~ .mbsc-slider-tooltip,
  .mbsc-material.mbsc-slider-has-tooltip .mbsc-active .mbsc-slider-tooltip {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
  .mbsc-material.mbsc-slider-has-tooltip .mbsc-slider-handle:focus,
  .mbsc-material.mbsc-slider-has-tooltip .mbsc-active .mbsc-slider-handle {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  .mbsc-material.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
    opacity: 0.4;
  }
  .mbsc-material.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-handle {
    -webkit-transform: scale(0.7);
    transform: scale(0.7);
  }
  .mbsc-material .mbsc-slider-primary .mbsc-slider-tooltip,
  .mbsc-material .mbsc-slider-primary .mbsc-slider-handle {
    background: #3f97f6;
    border-color: #3f97f6;
  }
  .mbsc-material .mbsc-slider-primary .mbsc-slider-tooltip:before {
    border-top-color: #3f97f6;
  }
  .mbsc-material .mbsc-slider-primary .mbsc-progress-bar {
    background: #3f97f6;
  }
  .mbsc-material .mbsc-slider-secondary .mbsc-slider-tooltip,
  .mbsc-material .mbsc-slider-secondary .mbsc-slider-handle {
    background: #90979e;
    border-color: #90979e;
  }
  .mbsc-material .mbsc-slider-secondary .mbsc-slider-tooltip:before {
    border-top-color: #90979e;
  }
  .mbsc-material .mbsc-slider-secondary .mbsc-progress-bar {
    background: #90979e;
  }
  .mbsc-material .mbsc-slider-success .mbsc-slider-tooltip,
  .mbsc-material .mbsc-slider-success .mbsc-slider-handle {
    background: #43be5f;
    border-color: #43be5f;
  }
  .mbsc-material .mbsc-slider-success .mbsc-slider-tooltip:before {
    border-top-color: #43be5f;
  }
  .mbsc-material .mbsc-slider-success .mbsc-progress-bar {
    background: #43be5f;
  }
  .mbsc-material .mbsc-slider-danger .mbsc-slider-tooltip,
  .mbsc-material .mbsc-slider-danger .mbsc-slider-handle {
    background: #f5504e;
    border-color: #f5504e;
  }
  .mbsc-material .mbsc-slider-danger .mbsc-slider-tooltip:before {
    border-top-color: #f5504e;
  }
  .mbsc-material .mbsc-slider-danger .mbsc-progress-bar {
    background: #f5504e;
  }
  .mbsc-material .mbsc-slider-warning .mbsc-slider-tooltip,
  .mbsc-material .mbsc-slider-warning .mbsc-slider-handle {
    background: #f8b042;
    border-color: #f8b042;
  }
  .mbsc-material .mbsc-slider-warning .mbsc-slider-tooltip:before {
    border-top-color: #f8b042;
  }
  .mbsc-material .mbsc-slider-warning .mbsc-progress-bar {
    background: #f8b042;
  }
  .mbsc-material .mbsc-slider-info .mbsc-slider-tooltip,
  .mbsc-material .mbsc-slider-info .mbsc-slider-handle {
    background: #5bb7c5;
    border-color: #5bb7c5;
  }
  .mbsc-material .mbsc-slider-info .mbsc-slider-tooltip:before {
    border-top-color: #5bb7c5;
  }
  .mbsc-material .mbsc-slider-info .mbsc-progress-bar {
    background: #5bb7c5;
  }
  .mbsc-material .mbsc-slider-step {
    background: #000;
  }
  .mbsc-material .mbsc-slider-handle {
    border: 2px solid #009688;
    background: #009688;
  }
  .mbsc-material .mbsc-slider-start .mbsc-slider-handle {
    border-color: #b3b3b3;
    background: #eee;
  }
  .mbsc-material .mbsc-slider-tooltip {
    color: #eee;
    background-color: #009688;
  }
  .mbsc-material .mbsc-slider-tooltip:before {
    border-top: 1em solid #009688;
  }
  .mbsc-material.mbsc-slider-has-tooltip .mbsc-slider-start .mbsc-slider-tooltip,
  .mbsc-material.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-bar {
    background: #b3b3b3;
  }
  .mbsc-material.mbsc-slider-has-tooltip .mbsc-slider-start .mbsc-slider-tooltip:before {
    border-top-color: #b3b3b3;
  }
  .mbsc-material.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-handle {
    background: #b3b3b3;
    border-color: #b3b3b3;
    box-shadow: 0 0 0 0.3125em #eee;
  }
  .mbsc-material.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-start .mbsc-slider-handle {
    border-color: #b3b3b3;
    background: #eee;
  }
  .mbsc-material.mbsc-rating .mbsc-slider-handle:before {
    display: none;
  }
  .mbsc-material.mbsc-rating .mbsc-label {
    padding-bottom: 0.5em;
  }
  .mbsc-material.mbsc-rating input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
    opacity: 0.4;
  }
  .mbsc-material.mbsc-rating.mbsc-rating-primary .mbsc-progress-track {
    color: #3f97f6;
  }
  .mbsc-material.mbsc-rating.mbsc-rating-secondary .mbsc-progress-track {
    color: #90979e;
  }
  .mbsc-material.mbsc-rating.mbsc-rating-success .mbsc-progress-track {
    color: #43be5f;
  }
  .mbsc-material.mbsc-rating.mbsc-rating-danger .mbsc-progress-track {
    color: #f5504e;
  }
  .mbsc-material.mbsc-rating.mbsc-rating-warning .mbsc-progress-track {
    color: #f8b042;
  }
  .mbsc-material.mbsc-rating.mbsc-rating-info .mbsc-progress-track {
    color: #5bb7c5;
  }
  .mbsc-material.mbsc-rating .mbsc-progress-track {
    color: #009688;
  }
  .mbsc-material.mbsc-toast .mbsc-toast-msg {
    opacity: 0.9;
    border-radius: 1.571429em;
  }
  .mbsc-material.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-primary .mbsc-toast-msg {
    background: #3f97f6;
  }
  .mbsc-material.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-secondary .mbsc-toast-msg {
    background: #90979e;
  }
  .mbsc-material.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-success .mbsc-toast-msg {
    background: #43be5f;
  }
  .mbsc-material.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-danger .mbsc-toast-msg {
    background: #f5504e;
  }
  .mbsc-material.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-warning .mbsc-toast-msg {
    background: #f8b042;
  }
  .mbsc-material.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-info .mbsc-toast-msg {
    background: #5bb7c5;
  }
  .mbsc-material.mbsc-snackbar .mbsc-fr-w {
    background: #323232;
    color: #fff;
  }
  .mbsc-material.mbsc-snackbar .mbsc-snackbar-btn {
    color: #00ad9d;
  }
  .mbsc-material.mbsc-toast .mbsc-toast-msg {
    background: #505050;
    color: #fff;
  }
  .mbsc-material.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-material.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-material.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-material.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-material.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-material.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-material.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-material.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-material.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-material.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-material.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-material.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
  .mbsc-material.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #eee;
  }
  .mbsc-material .mbsc-desc {
    opacity: 0.7;
  }
  .mbsc-material .mbsc-divider,
  .mbsc-material .mbsc-form-group-title {
    padding: 0.5em 1em;
  }
  .mbsc-material .mbsc-err-msg {
    position: absolute;
    padding-top: 0.333334em;
    font-size: 0.75em;
  }
  .mbsc-material.mbsc-ltr .mbsc-err-msg {
    left: 0;
  }
  .mbsc-material.mbsc-rtl .mbsc-err-msg {
    right: 0;
  }
  .mbsc-material .mbsc-form-grid {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .mbsc-material .mbsc-form-grid .mbsc-input-box,
  .mbsc-material .mbsc-form-grid .mbsc-input-outline {
    margin: 0.75em 1em;
  }
  .mbsc-material.mbsc-form .mbsc-checkbox,
  .mbsc-material.mbsc-form .mbsc-radio {
    padding: 0.9375em 3.5em 0.9375em 1em;
    line-height: 1.25em;
  }
  .mbsc-material.mbsc-rtl .mbsc-checkbox,
  .mbsc-material.mbsc-rtl .mbsc-radio {
    padding: 0.9375em 1em 0.9375em 3.5em;
  }
  .mbsc-material .mbsc-checkbox-box,
  .mbsc-material .mbsc-radio-box {
    right: 1.25em;
    width: 1.125em;
    height: 1.125em;
    margin-top: -0.5625em;
    background: transparent;
    -webkit-transition: background-color 0.1s ease-out;
    transition: background-color 0.1s ease-out;
  }
  .mbsc-material.mbsc-rtl .mbsc-checkbox-box,
  .mbsc-material.mbsc-rtl .mbsc-radio-box {
    right: auto;
    left: 1.25em;
  }
  .mbsc-material .mbsc-checkbox-box:after,
  .mbsc-material .mbsc-radio-box:after {
    opacity: 1;
    -webkit-transform: scale(0) rotate(-45deg);
    transform: scale(0) rotate(-45deg);
    -webkit-transition: -webkit-transform 0.1s ease-out;
    transition: transform 0.1s ease-out;
  }
  .mbsc-material .mbsc-checkbox input:checked + .mbsc-checkbox-box:after,
  .mbsc-material .mbsc-radio input:checked + .mbsc-radio-box:after {
    -webkit-transform: scale(1) rotate(-45deg);
    transform: scale(1) rotate(-45deg);
  }
  .mbsc-material .mbsc-checkbox-box:before,
  .mbsc-material .mbsc-radio-box:before {
    content: "";
    position: absolute;
    top: -1em;
    left: -1em;
    z-index: -1;
    width: 2.875em;
    height: 2.875em;
    opacity: 0;
    border-radius: 2.875em;
    -webkit-transition: opacity 0.2s ease-in-out;
    transition: opacity 0.2s ease-in-out;
  }
  .mbsc-material .mbsc-checkbox input:disabled + .mbsc-checkbox-box,
  .mbsc-material .mbsc-checkbox input:disabled ~ .mbsc-label,
  .mbsc-material .mbsc-checkbox input:disabled ~ .mbsc-desc,
  .mbsc-material .mbsc-radio input:disabled + .mbsc-radio-box,
  .mbsc-material .mbsc-radio input:disabled ~ .mbsc-label,
  .mbsc-material .mbsc-radio input:disabled ~ .mbsc-desc,
  .mbsc-material .mbsc-switch input:disabled ~ .mbsc-label,
  .mbsc-material .mbsc-switch input:disabled ~ .mbsc-desc {
    opacity: 0.3;
  }
  .mbsc-material .mbsc-checkbox input.mbsc-active + .mbsc-checkbox-box:before,
  .mbsc-material .mbsc-radio input.mbsc-active + .mbsc-radio-box:before,
  .mbsc-material .mbsc-switch input.mbsc-active + .mbsc-switch-track .mbsc-switch-handle:before {
    opacity: 1;
  }
  .mbsc-material .mbsc-checkbox-box {
    border-radius: 0.1875em;
  }
  .mbsc-material .mbsc-checkbox-box:after {
    top: 0.125em;
    left: 0.0625em;
    width: 0.8125em;
    height: 0.4375em;
  }
  .mbsc-material .mbsc-checkbox-primary.mbsc-checkbox input:checked + .mbsc-checkbox-box {
    background: #3f97f6;
    border-color: #3f97f6;
  }
  .mbsc-material .mbsc-checkbox-secondary.mbsc-checkbox input:checked .mbsc-checkbox-box {
    background: #90979e;
    border-color: #90979e;
  }
  .mbsc-material .mbsc-checkbox-success.mbsc-checkbox input:checked + .mbsc-checkbox-box {
    background: #43be5f;
    border-color: #43be5f;
  }
  .mbsc-material .mbsc-checkbox-danger.mbsc-checkbox input:checked + .mbsc-checkbox-box {
    background: #f5504e;
    border-color: #f5504e;
  }
  .mbsc-material .mbsc-checkbox-warning.mbsc-checkbox input:checked + .mbsc-checkbox-box {
    background: #f8b042;
    border-color: #f8b042;
  }
  .mbsc-material .mbsc-checkbox-info.mbsc-checkbox input:checked + .mbsc-checkbox-box {
    background: #5bb7c5;
    border-color: #5bb7c5;
  }
  .mbsc-material .mbsc-radio-box:after {
    width: 0.625em;
    height: 0.625em;
    margin-top: -0.3125em;
    margin-left: -0.3125em;
    border-radius: 0.625em;
  }
  .mbsc-material .mbsc-radio-primary.mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #3f97f6;
  }
  .mbsc-material .mbsc-radio-primary.mbsc-radio input:checked + .mbsc-radio-box:after {
    background: #3f97f6;
  }
  .mbsc-material .mbsc-radio-secondary.mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #90979e;
  }
  .mbsc-material .mbsc-radio-secondary.mbsc-radio input:checked + .mbsc-radio-box:after {
    background: #90979e;
  }
  .mbsc-material .mbsc-radio-success.mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #43be5f;
  }
  .mbsc-material .mbsc-radio-success.mbsc-radio input:checked + .mbsc-radio-box:after {
    background: #43be5f;
  }
  .mbsc-material .mbsc-radio-danger.mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #f5504e;
  }
  .mbsc-material .mbsc-radio-danger.mbsc-radio input:checked + .mbsc-radio-box:after {
    background: #f5504e;
  }
  .mbsc-material .mbsc-radio-warning.mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #f8b042;
  }
  .mbsc-material .mbsc-radio-warning.mbsc-radio input:checked + .mbsc-radio-box:after {
    background: #f8b042;
  }
  .mbsc-material .mbsc-radio-info.mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #5bb7c5;
  }
  .mbsc-material .mbsc-radio-info.mbsc-radio input:checked + .mbsc-radio-box:after {
    background: #5bb7c5;
  }
  .mbsc-material .mbsc-btn {
    margin: 0.5em;
    padding: 0.7143em;
    border-radius: 0.2143em;
    font-size: 0.875em;
    font-weight: bold;
    text-transform: uppercase;
    transition: box-shadow 0.2s ease-out, background-color 0.2s ease-out;
  }
  .mbsc-material .mbsc-btn-ic {
    padding: 0 0.5em 0 0;
  }
  .mbsc-material .mbsc-btn-icon-only .mbsc-btn-ic {
    padding: 0 0.5em;
  }
  .mbsc-material .mbsc-btn-group,
  .mbsc-material .mbsc-btn-group-justified {
    margin: 0.5em;
  }
  .mbsc-material .mbsc-btn-group-block {
    margin: 0.5em 1em;
  }
  .mbsc-material .mbsc-btn:disabled {
    box-shadow: none;
  }
  .mbsc-material .mbsc-btn-primary.mbsc-btn {
    background: #3f97f6;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-primary.mbsc-btn.mbsc-active {
    background: #0e7df4;
  }
  .mbsc-material .mbsc-btn-primary.mbsc-btn.mbsc-btn-flat {
    color: #3f97f6;
  }
  .mbsc-material .mbsc-btn-primary.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(63, 151, 246, 0.3);
  }
  .mbsc-material .mbsc-btn-secondary.mbsc-btn {
    background: #90979e;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-secondary.mbsc-btn.mbsc-active {
    background: #757e86;
  }
  .mbsc-material .mbsc-btn-secondary.mbsc-btn.mbsc-btn-flat {
    color: #90979e;
  }
  .mbsc-material .mbsc-btn-secondary.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(144, 151, 158, 0.3);
  }
  .mbsc-material .mbsc-btn-success.mbsc-btn {
    background: #43be5f;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-success.mbsc-btn.mbsc-active {
    background: #35994c;
  }
  .mbsc-material .mbsc-btn-success.mbsc-btn.mbsc-btn-flat {
    color: #43be5f;
  }
  .mbsc-material .mbsc-btn-success.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(67, 190, 95, 0.3);
  }
  .mbsc-material .mbsc-btn-danger.mbsc-btn {
    background: #f5504e;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-danger.mbsc-btn.mbsc-active {
    background: #f2201e;
  }
  .mbsc-material .mbsc-btn-danger.mbsc-btn.mbsc-btn-flat {
    color: #f5504e;
  }
  .mbsc-material .mbsc-btn-danger.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(245, 80, 78, 0.3);
  }
  .mbsc-material .mbsc-btn-warning.mbsc-btn {
    background: #f8b042;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-warning.mbsc-btn.mbsc-active {
    background: #f69b11;
  }
  .mbsc-material .mbsc-btn-warning.mbsc-btn.mbsc-btn-flat {
    color: #f8b042;
  }
  .mbsc-material .mbsc-btn-warning.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(248, 176, 66, 0.3);
  }
  .mbsc-material .mbsc-btn-info.mbsc-btn {
    background: #5bb7c5;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-info.mbsc-btn.mbsc-active {
    background: #3ea0af;
  }
  .mbsc-material .mbsc-btn-info.mbsc-btn.mbsc-btn-flat {
    color: #5bb7c5;
  }
  .mbsc-material .mbsc-btn-info.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(91, 183, 197, 0.3);
  }
  .mbsc-material .mbsc-btn-light.mbsc-btn {
    background: #fff;
  }
  .mbsc-material .mbsc-btn-light.mbsc-btn.mbsc-active {
    background: #e6e5e5;
  }
  .mbsc-material .mbsc-btn-light.mbsc-btn.mbsc-btn-flat {
    color: #bfbfbf;
  }
  .mbsc-material .mbsc-btn-light.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(255, 255, 255, 0.25);
  }
  .mbsc-material .mbsc-btn-dark.mbsc-btn {
    background: #47494a;
  }
  .mbsc-material .mbsc-btn-dark.mbsc-btn.mbsc-active {
    background: #2e2f30;
  }
  .mbsc-material .mbsc-btn-dark.mbsc-btn.mbsc-btn-flat {
    color: #47494a;
  }
  .mbsc-material .mbsc-btn-dark.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(71, 73, 74, 0.3);
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn {
    box-shadow: none;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-primary {
    border-color: #3f97f6;
    color: #3f97f6;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-primary.mbsc-active {
    background: #3f97f6;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-secondary {
    border-color: #90979e;
    color: #90979e;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-secondary.mbsc-active {
    background: #90979e;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-success {
    border-color: #43be5f;
    color: #43be5f;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-success.mbsc-active {
    background: #43be5f;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-danger {
    border-color: #f5504e;
    color: #f5504e;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-danger.mbsc-active {
    background: #f5504e;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-warning {
    border-color: #f8b042;
    color: #f8b042;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-warning.mbsc-active {
    background: #f8b042;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-info {
    border-color: #5bb7c5;
    color: #5bb7c5;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-info.mbsc-active {
    background: #5bb7c5;
    color: #fff;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-light {
    border-color: #bfbfbf;
    color: #bfbfbf;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-light.mbsc-active {
    background: #bfbfbf;
    color: #bfbfbf;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-dark {
    border-color: #47494a;
    color: #47494a;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-btn-dark.mbsc-active {
    background: #47494a;
    color: #fff;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-outline.mbsc-btn:not(:disabled):hover {
    box-shadow: none;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-primary.mbsc-btn:not(:disabled):hover {
    background: #0e7df4;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-primary.mbsc-btn:not(:disabled):hover.mbsc-btn-flat {
    background: #b9d9fc;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-secondary.mbsc-btn:not(:disabled):hover {
    background: #757e86;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-secondary.mbsc-btn:not(:disabled):hover.mbsc-btn-flat {
    background: #d4d7d9;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-success.mbsc-btn:not(:disabled):hover {
    background: #35994c;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-success.mbsc-btn:not(:disabled):hover.mbsc-btn-flat {
    background: #a2dfb0;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-danger.mbsc-btn:not(:disabled):hover {
    background: #f2201e;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-danger.mbsc-btn:not(:disabled):hover.mbsc-btn-flat {
    background: #fcc7c7;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-warning.mbsc-btn:not(:disabled):hover {
    background: #f69b11;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-warning.mbsc-btn:not(:disabled):hover.mbsc-btn-flat {
    background: #fde3bd;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-info.mbsc-btn:not(:disabled):hover {
    background: #3ea0af;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-info.mbsc-btn:not(:disabled):hover.mbsc-btn-flat {
    background: #b9e0e6;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-light.mbsc-btn:not(:disabled):hover {
    background: #e6e5e5;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-light.mbsc-btn:not(:disabled):hover.mbsc-btn-flat {
    background: #fff;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-dark.mbsc-btn.mbsc-btn:not(:disabled):hover {
    background: #2e2f30;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-dark.mbsc-btn.mbsc-btn:not(:disabled):hover.mbsc-btn-flat {
    background: #86898b;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-outline.mbsc-btn:not(:disabled):not(.mbsc-active):hover {
    background: transparent;
  }
  .mbsc-material.mbsc-active.mbsc-control {
    opacity: 1;
  }
  .mbsc-material.mbsc-form .mbsc-switch {
    padding: 0.9375em 4em 0.9375em 1em;
    line-height: 1.25em;
  }
  .mbsc-material.mbsc-rtl .mbsc-switch {
    padding: 0.9375em 1em 0.9375em 4em;
  }
  .mbsc-material .mbsc-switch-track {
    right: 1.25em;
    width: 1.75em;
    height: 0.875em;
    padding: 0 0.25em;
    margin-top: -0.4375em;
    border-radius: 1.25em;
  }
  .mbsc-material.mbsc-rtl .mbsc-switch-track {
    right: auto;
    left: 1.25em;
  }
  .mbsc-material .mbsc-switch .mbsc-switch-handle {
    z-index: auto;
    top: 0.375em;
    left: 0.375em;
    width: 1.25em;
    height: 1.25em;
    margin: 0;
    border: 0;
    border-radius: 1.25em;
  }
  .mbsc-material.mbsc-rtl .mbsc-switch .mbsc-switch-handle {
    left: auto;
    right: 0.375em;
  }
  .mbsc-material .mbsc-switch-handle:before {
    top: -0.625em;
    left: -0.625em;
    width: 2.5em;
    height: 2.5em;
  }
  .mbsc-material .mbsc-switch-handle,
  .mbsc-material .mbsc-active .mbsc-switch-handle {
    -webkit-transform: none;
    transform: none;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-primary input:checked + .mbsc-switch-track {
    background: #a0ccfb;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-primary input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #3f97f6;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-secondary input:checked + .mbsc-switch-track {
    background: #c6cace;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-secondary input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #90979e;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-success input:checked + .mbsc-switch-track {
    background: #8fd8a0;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-success input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #43be5f;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-danger input:checked + .mbsc-switch-track {
    background: #faafaf;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-danger input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #f5504e;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-warning input:checked + .mbsc-switch-track {
    background: #fcd9a4;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-warning input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #f8b042;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-info input:checked + .mbsc-switch-track {
    background: #a6d8e0;
  }
  .mbsc-material .mbsc-switch.mbsc-switch-info input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #5bb7c5;
  }
  .mbsc-material .mbsc-segmented {
    padding: 0.75em;
  }
  .mbsc-material .mbsc-segmented-content {
    height: 2.2857145em;
    margin: 0 -0.07142857em;
    line-height: 2.2857145em;
    padding: 0 0.428571em;
    text-transform: capitalize;
    -webkit-backface-visibility: hidden;
  }
  .mbsc-material.mbsc-ltr .mbsc-segmented-item:first-child .mbsc-segmented-content,
  .mbsc-material.mbsc-ltr .mbsc-stepper-minus .mbsc-segmented-content,
  .mbsc-material.mbsc-rtl .mbsc-stepper-plus .mbsc-segmented-content,
  .mbsc-material.mbsc-rtl .mbsc-segmented-item:last-child .mbsc-segmented-content {
    border-top-left-radius: 0.214286em;
    border-bottom-left-radius: 0.214286em;
  }
  .mbsc-material.mbsc-ltr .mbsc-stepper-plus .mbsc-segmented-content,
  .mbsc-material.mbsc-ltr .mbsc-segmented-item:last-child .mbsc-segmented-content,
  .mbsc-material.mbsc-rtl .mbsc-segmented-item:first-child .mbsc-segmented-content,
  .mbsc-material.mbsc-rtl .mbsc-stepper-minus .mbsc-segmented-content {
    border-top-right-radius: 0.214286em;
    border-bottom-right-radius: 0.214286em;
  }
  .mbsc-material .mbsc-stepper-cont {
    padding: 1.75em 12.875em 1.75em 1em;
  }
  .mbsc-material.mbsc-rtl .mbsc-stepper-cont {
    padding: 1.75em 1em 1.75em 12.875em;
  }
  .mbsc-material .mbsc-stepper {
    margin-top: -1.125em;
  }
  .mbsc-material .mbsc-stepper .mbsc-segmented-content {
    border-left: 0;
    border-right: 0;
  }
  .mbsc-material .mbsc-stepper-control .mbsc-segmented-content {
    border: 0;
    height: 2.5714285em;
    line-height: 2.5714285em;
  }
  .mbsc-material .mbsc-segmented-primary.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #3f97f6;
  }
  .mbsc-material .mbsc-segmented-secondary .mbsc-segmented-content {
    border-color: #90979e;
    color: #90979e;
  }
  .mbsc-material .mbsc-segmented-secondary.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #90979e;
  }
  .mbsc-material .mbsc-segmented-success .mbsc-segmented-content {
    border-color: #43be5f;
    color: #43be5f;
  }
  .mbsc-material .mbsc-segmented-success.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #43be5f;
  }
  .mbsc-material .mbsc-segmented-danger .mbsc-segmented-content {
    border-color: #f5504e;
    color: #f5504e;
  }
  .mbsc-material .mbsc-segmented-danger.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #f5504e;
  }
  .mbsc-material .mbsc-segmented-warning .mbsc-segmented-content {
    border-color: #f8b042;
    color: #f8b042;
  }
  .mbsc-material .mbsc-segmented-warning.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #f8b042;
  }
  .mbsc-material .mbsc-segmented-info .mbsc-segmented-content {
    border-color: #5bb7c5;
    color: #5bb7c5;
  }
  .mbsc-material .mbsc-segmented-info.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #5bb7c5;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-primary .mbsc-segmented-content {
    border-color: #3f97f6;
    color: #3f97f6;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-primary.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #3f97f6;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-secondary .mbsc-segmented-content {
    border-color: #90979e;
    color: #90979e;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-secondary.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #90979e;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-success .mbsc-segmented-content {
    border-color: #43be5f;
    color: #43be5f;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-success.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #43be5f;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-danger .mbsc-segmented-content {
    border-color: #f5504e;
    color: #f5504e;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-danger.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #f5504e;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-warning .mbsc-segmented-content {
    border-color: #f8b042;
    color: #f8b042;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-warning.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #f8b042;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-info .mbsc-segmented-content {
    border-color: #5bb7c5;
    color: #5bb7c5;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-info.mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #5bb7c5;
  }
  .mbsc-material .mbsc-stepper-primary.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    background: #3f97f6;
    border-color: #3f97f6;
  }
  .mbsc-material .mbsc-stepper-secondary.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    background: #90979e;
    border-color: #90979e;
  }
  .mbsc-material .mbsc-stepper-success.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    background: #43be5f;
    border-color: #43be5f;
  }
  .mbsc-material .mbsc-stepper-danger.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    background: #f5504e;
    border-color: #f5504e;
  }
  .mbsc-material .mbsc-stepper-warning.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    background: #f8b042;
    border-color: #f8b042;
  }
  .mbsc-material .mbsc-stepper-info.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    background: #5bb7c5;
    color: #fff;
    border-color: #5bb7c5;
  }
  .mbsc-material.mbsc-no-touch
    .mbsc-stepper-primary.mbsc-stepper-cont
    .mbsc-segmented-item:hover
    .mbsc-segmented-content {
    background: rgba(63, 151, 246, 0.7);
  }
  .mbsc-material.mbsc-no-touch
    .mbsc-stepper-secondary.mbsc-stepper-cont
    .mbsc-segmented-item:hover
    .mbsc-segmented-content {
    background: rgba(144, 151, 158, 0.7);
  }
  .mbsc-material.mbsc-no-touch
    .mbsc-stepper-success.mbsc-stepper-cont
    .mbsc-segmented-item:hover
    .mbsc-segmented-content {
    background: rgba(67, 190, 95, 0.7);
  }
  .mbsc-material.mbsc-no-touch .mbsc-stepper-danger.mbsc-stepper-cont .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(245, 80, 78, 0.7);
  }
  .mbsc-material.mbsc-no-touch
    .mbsc-stepper-warning.mbsc-stepper-cont
    .mbsc-segmented-item:hover
    .mbsc-segmented-content {
    background: rgba(248, 176, 66, 0.7);
  }
  .mbsc-material.mbsc-no-touch .mbsc-stepper-info.mbsc-stepper-cont .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(91, 183, 197, 0.7);
  }
  .mbsc-material.mbsc-form {
    background-color: #eee;
    color: #6d6d6d;
  }
  .mbsc-material.mbsc-form *::-moz-selection,
  .mbsc-material.mbsc-form *::selection {
    color: #fff;
    background: #009688;
  }
  .mbsc-material .mbsc-divider,
  .mbsc-material .mbsc-form-group-title {
    color: #009688;
  }
  .mbsc-material .mbsc-err-msg,
  .mbsc-material .mbsc-err .mbsc-label {
    color: #de3226;
  }
  .mbsc-material .mbsc-checkbox-box,
  .mbsc-material .mbsc-radio-box {
    border: 0.125em solid #6d6d6d;
  }
  .mbsc-material .mbsc-checkbox-box:before,
  .mbsc-material .mbsc-radio-box:before {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material .mbsc-checkbox-box:after {
    border: 0.125em solid #fff;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-material .mbsc-checkbox input:checked + .mbsc-checkbox-box {
    background: #009688;
    border-color: #009688;
  }
  .mbsc-material .mbsc-radio-box:after {
    background: #009688;
  }
  .mbsc-material .mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #009688;
  }
  .mbsc-material .mbsc-btn {
    background: #d7d7d7;
    color: #000;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn:not(:disabled):hover,
  .mbsc-material .mbsc-btn.mbsc-active {
    background: #cdcdcd;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-flat:not(:disabled):hover,
  .mbsc-material .mbsc-btn-flat.mbsc-active {
    background-color: #b3b3b3;
  }
  .mbsc-material .mbsc-btn:disabled {
    color: #a5a5a5;
  }
  .mbsc-material .mbsc-btn.mbsc-btn-outline:disabled {
    color: #a5a5a5;
    border-color: #a5a5a5;
  }
  .mbsc-material .mbsc-btn-light.mbsc-btn {
    color: #000;
  }
  .mbsc-material .mbsc-btn-dark.mbsc-btn {
    color: #eee;
  }
  .mbsc-material .mbsc-btn-flat.mbsc-btn {
    background: transparent;
    box-shadow: none;
  }
  .mbsc-material.mbsc-no-touch .mbsc-btn-flat:not(:disabled):hover,
  .mbsc-material .mbsc-btn-flat.mbsc-active {
    box-shadow: none;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn {
    background: transparent;
    border: 1px solid #6d6d6d;
    color: #6d6d6d;
    box-shadow: none;
  }
  .mbsc-material .mbsc-btn-outline.mbsc-btn.mbsc-active.mbsc-control {
    background: #6d6d6d;
    color: #eee;
    opacity: 1;
  }
  .mbsc-material .mbsc-switch-track {
    background: #b3b3b3;
  }
  .mbsc-material .mbsc-switch .mbsc-switch-handle {
    background: #fbfbfb;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .mbsc-material .mbsc-switch input:checked + .mbsc-switch-track {
    background-color: rgba(0, 150, 136, 0.3);
  }
  .mbsc-material .mbsc-switch input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #009688;
  }
  .mbsc-material .mbsc-switch input:disabled + .mbsc-switch-track {
    background: #d7d7d7;
  }
  .mbsc-material .mbsc-switch input:disabled + .mbsc-switch-track .mbsc-switch-handle {
    background: #bbb;
  }
  .mbsc-material .mbsc-segmented-content {
    border: 0.142858em solid #009688;
    color: #000;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content,
  .mbsc-material .mbsc-segmented .mbsc-segmented-item input:checked ~ .mbsc-segmented-content {
    background: #009688;
    color: #eee;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
  .mbsc-material .mbsc-segmented .mbsc-segmented-item .mbsc-active .mbsc-segmented-content {
    background: #cdcdcd;
    color: #000;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    color: #a5a5a5;
    border-color: #b3b3b3;
    background: transparent;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-item input:disabled:checked + .mbsc-segmented-content {
    background: #d7d7d7;
    color: #a5a5a5;
    border-color: #d7d7d7;
  }
  .mbsc-material .mbsc-stepper .mbsc-segmented-content {
    border-color: #d7d7d7;
  }
  .mbsc-material .mbsc-stepper-control .mbsc-segmented-content {
    background: #d7d7d7;
  }
  .mbsc-material .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-material .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-material .mbsc-segmented .mbsc-segmented-item.mbsc-disabled .mbsc-segmented-content {
    background: #d7d7d7;
    color: #a5a5a5;
  }
  .mbsc-material .mbsc-segmented .mbsc-segmented-item input:disabled ~ .mbsc-segmented-item .mbsc-stepper-val {
    background: #eee;
  }
  .mbsc-material .mbsc-stepper input {
    color: #5b5b5b;
  }
  .mbsc-material .mbsc-stepper input:disabled {
    color: #a5a5a5;
    -webkit-text-fill-color: #a5a5a5;
  }
  .mbsc-material.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: #cdcdcd;
    color: #000;
  }
  .mbsc-material .mbsc-stepper-primary.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #eee;
  }
  .mbsc-material .mbsc-stepper-primary.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material .mbsc-stepper-secondary.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #eee;
  }
  .mbsc-material .mbsc-stepper-secondary.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material .mbsc-stepper-success.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #eee;
  }
  .mbsc-material .mbsc-stepper-success.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material .mbsc-stepper-danger.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #eee;
  }
  .mbsc-material .mbsc-stepper-danger.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material .mbsc-stepper-warning.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #eee;
  }
  .mbsc-material .mbsc-stepper-warning.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material .mbsc-stepper-info.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #eee;
  }
  .mbsc-material .mbsc-stepper-info.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material.mbsc-card.mbsc-form {
    margin: 0.625em;
    border-radius: 2px;
  }
  .mbsc-material .mbsc-card-footer .mbsc-btn {
    margin-top: -0.7143em;
    margin-bottom: -0.7143em;
  }
  .mbsc-material .mbsc-card-list .mbsc-card {
    margin: 0.625em;
  }
  .mbsc-material.mbsc-card.mbsc-form {
    background: #fafafa;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .mbsc-material.mbsc-card .mbsc-lv-item,
  .mbsc-material .mbsc-card-list .mbsc-card {
    background: #fafafa;
  }
  .mbsc-material .mbsc-card-footer {
    border-color: #c1c1c1;
  }
  .mbsc-material.mbsc-card .mbsc-input.mbsc-input-outline .mbsc-label {
    background: #fafafa;
  }
  .mbsc-mobiscroll .mbsc-collapsible .mbsc-form-group-title {
    padding: 1em;
  }
  .mbsc-mobiscroll .mbsc-collapsible .mbsc-collapsible-header {
    padding-right: 3em;
  }
  .mbsc-mobiscroll .mbsc-note {
    border-radius: 0.1875em;
  }
  .mbsc-mobiscroll .mbsc-note,
  .mbsc-mobiscroll .mbsc-note-primary {
    color: #074b95;
    background-color: #a0ccfb;
  }
  .mbsc-mobiscroll .mbsc-note-secondary {
    color: #454b50;
    background-color: #c6cace;
  }
  .mbsc-mobiscroll .mbsc-note-success {
    color: #1b4d26;
    background-color: #8fd8a0;
  }
  .mbsc-mobiscroll .mbsc-note-danger {
    color: #a10b09;
    background-color: #faafaf;
  }
  .mbsc-mobiscroll .mbsc-note-warning {
    color: #b46f07;
    background-color: #fcd9a4;
  }
  .mbsc-mobiscroll .mbsc-note-info {
    color: #235b64;
    background-color: #a6d8e0;
  }
  .mbsc-mobiscroll .mbsc-note-light {
    color: #4d4c4c;
    background-color: #d9d9d9;
  }
  .mbsc-mobiscroll .mbsc-note-dark {
    color: #000;
    background-color: #adafb0;
  }
  .mbsc-mobiscroll .mbsc-empty {
    color: #787878;
  }
  .mbsc-mobiscroll.mbsc-page {
    background: #f7f7f7;
    color: #454545;
  }
  .mbsc-mobiscroll a {
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-input input,
  .mbsc-mobiscroll .mbsc-input textarea {
    height: 2.125em;
    padding: 0 0 1px 0;
    background: transparent;
    font-size: 1em;
  }
  .mbsc-mobiscroll .mbsc-input input:disabled,
  .mbsc-mobiscroll .mbsc-input textarea:disabled,
  .mbsc-mobiscroll .mbsc-input .mbsc-control:disabled ~ input,
  .mbsc-mobiscroll .mbsc-input .mbsc-control:disabled ~ .mbsc-select-ic {
    opacity: 0.4;
  }
  .mbsc-mobiscroll .mbsc-input {
    margin: 1.5em 1em;
  }
  .mbsc-mobiscroll .mbsc-input .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0.875em;
  }
  .mbsc-mobiscroll .mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 0.9375em;
  }
  .mbsc-mobiscroll .mbsc-input-ic {
    top: 0.0625em;
    margin: -2px 0 0 0;
  }
  .mbsc-mobiscroll .mbsc-input textarea {
    padding-top: 0.25em;
  }
  .mbsc-mobiscroll .mbsc-select-ic {
    position: absolute;
    display: block;
    height: 1.25em;
    width: 1.25em;
    top: 0.5625em;
    text-align: center;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-select-ic {
    left: auto;
    right: 0.6875em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-select-ic {
    right: auto;
    left: 0.6875em;
  }
  .mbsc-mobiscroll .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
    top: 1.4375em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-select input {
    padding-right: 1.25em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-select.mbsc-ic-right input {
    padding-right: 3em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-select .mbsc-input-wrap .mbsc-select-ic {
    left: auto;
    right: 0;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
    right: 1.75em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-select input {
    padding-left: 1.25em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-select.mbsc-ic-right input {
    padding-left: 3em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-select .mbsc-input-wrap .mbsc-select-ic {
    right: auto;
    left: 0;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
    left: 1.75em;
  }
  .mbsc-mobiscroll .mbsc-select-inline .mbsc-select-ic {
    display: none;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input.mbsc-ic-left input,
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input.mbsc-ic-left textarea {
    padding-left: 1.625em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input.mbsc-ic-left .mbsc-left-ic {
    right: auto;
    left: -0.375em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input.mbsc-ic-left .mbsc-label,
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input.mbsc-ic-left .mbsc-err-msg {
    left: 2.166667em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input.mbsc-ic-right input,
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input.mbsc-ic-right textarea {
    padding-right: 1.625em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input.mbsc-ic-right .mbsc-right-ic {
    left: auto;
    right: -0.375em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input.mbsc-ic-left input,
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input.mbsc-ic-left textarea {
    padding-right: 1.625em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input.mbsc-ic-left .mbsc-left-ic {
    left: auto;
    right: -0.375em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input.mbsc-ic-left .mbsc-label,
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input.mbsc-ic-left .mbsc-err-msg {
    right: 2.166667em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input.mbsc-ic-right input,
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input.mbsc-ic-right textarea {
    padding-left: 1.625em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input.mbsc-ic-right .mbsc-right-ic {
    right: auto;
    left: -0.375em;
  }
  .mbsc-mobiscroll .mbsc-input .mbsc-label {
    position: absolute;
    top: 0;
    font-size: 0.75em;
  }
  .mbsc-mobiscroll .mbsc-label-inline.mbsc-input .mbsc-label {
    line-height: 2em;
  }
  .mbsc-mobiscroll .mbsc-label-inline.mbsc-input .mbsc-label + .mbsc-input-wrap {
    padding-top: 0;
  }
  .mbsc-mobiscroll .mbsc-label-inline.mbsc-input .mbsc-label + .mbsc-input-wrap .mbsc-input-ic {
    top: 0.0625em;
  }
  .mbsc-mobiscroll .mbsc-label-inline.mbsc-input.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
    top: 0.5625em;
  }
  .mbsc-mobiscroll .mbsc-label-floating.mbsc-input .mbsc-label {
    font-size: 1em;
    line-height: 2em;
    top: 0.875em;
  }
  .mbsc-mobiscroll .mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    -webkit-transform: translateY(-1.125em) scale(0.75);
    transform: translateY(-1.125em) scale(0.75);
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    left: 1.625em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    right: 1.625em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-input .mbsc-input-wrap {
    border-radius: 4px 4px 0 0;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-input input {
    height: 2.25em;
    padding: 0.0625em 1em;
    border-radius: 4px 4px 0 0;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-input textarea {
    padding: 0.375em 1em 0.0625em 1em;
    height: 2.25em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap input {
    padding-top: 1.25em;
    height: 3.5em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap textarea:focus {
    padding-bottom: 0;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 0.875em;
  }
  .mbsc-mobiscroll .mbsc-input-box .mbsc-input-ic {
    top: 0.25em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-input .mbsc-label {
    padding: 0.666667em 0;
    z-index: 1;
    line-height: normal;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 1.625em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap textarea {
    height: 1.866667em;
    padding-top: 0;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
    top: 0.625em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
    top: 1.375em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-label-inline .mbsc-label {
    padding-top: 0.125em;
    padding-bottom: 0.125em;
    line-height: 2em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap input {
    height: 2.25em;
    padding-top: 0.0625em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 0.1875em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-label-inline.mbsc-textarea .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0.375em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-label-inline.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
    top: 0.625em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-label-floating .mbsc-label {
    top: 0.125em;
    line-height: 2em;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-label-floating.mbsc-label-floating-active .mbsc-label {
    line-height: normal;
    -webkit-transform: translateY(-0.125em) scale(0.75);
    transform: translateY(-0.125em) scale(0.75);
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box .mbsc-err-msg {
    left: 1.333334em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box .mbsc-label {
    left: 1.333334em;
    right: auto;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-ic-left .mbsc-err-msg {
    left: 4em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-ic-left .mbsc-label {
    left: 4em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-ic-left .mbsc-left-ic {
    left: 0.5em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-ic-left input,
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-ic-left textarea {
    padding-left: 3em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-ic-right input,
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-ic-right textarea {
    padding-right: 3em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-ic-right .mbsc-right-ic {
    right: 0.5em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
    right: 2em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-select input {
    padding-right: 2em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
    right: 0.6875em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-select.mbsc-ic-right input {
    padding-right: 4em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
    right: 2.75em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-label-floating.mbsc-input .mbsc-label {
    left: 1em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-box.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    left: 3em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box .mbsc-err-msg {
    right: 1.333334em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box .mbsc-label {
    right: 1.333334em;
    left: auto;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-ic-left .mbsc-err-msg {
    right: 4em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-ic-left .mbsc-label {
    right: 4em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-ic-left .mbsc-left-ic {
    right: 0.5em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-ic-left input,
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-ic-left textarea {
    padding-right: 3em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-ic-right input,
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-ic-right textarea {
    padding-left: 3em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-ic-right .mbsc-right-ic {
    left: 0.5em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
    left: 2em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-select input {
    padding-left: 2em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
    left: 0.6875em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-select.mbsc-ic-right input {
    padding-left: 4em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
    left: 2.75em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-label-floating.mbsc-input .mbsc-label {
    right: 1em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-box.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    right: 3em;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input input,
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input textarea {
    border-radius: 4px;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input input {
    height: 3.375em;
    padding: 0.0625em 1em;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input textarea {
    height: 3.375em;
    padding: 0.875em 1em 0.4375em 1em;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input .mbsc-label {
    top: -0.166667em;
    z-index: 1;
    padding: 0 0.333334em;
  }
  .mbsc-mobiscroll .mbsc-input-outline .mbsc-input-ic {
    top: 0.8125em;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0.25em;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 1.0625em;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input input:focus,
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input select:focus ~ input,
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-err input {
    padding-top: 0;
    padding-bottom: 0;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-err input,
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-err textarea {
    border-color: #de3226;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
    top: 1.3125em;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
    top: 1.5625em;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-label-inline .mbsc-label {
    line-height: 3.375em;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap {
    padding-top: 0;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-label-inline .mbsc-label ~ .mbsc-input-wrap .mbsc-input-ic {
    top: 0.8125em;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-label-inline.mbsc-select .mbsc-label ~ .mbsc-input-wrap .mbsc-select-ic {
    top: 1.3125em;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label {
    top: 0.9375em;
    padding: 0;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    -webkit-transform: translateY(-1.375em) scale(0.75);
    transform: translateY(-1.375em) scale(0.75);
    padding: 0 0.25em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline .mbsc-err-msg {
    left: 1.416667em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline .mbsc-label {
    left: 1.083334em;
    right: auto;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-ic-left .mbsc-err-msg {
    left: 4.083334em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-ic-left .mbsc-label {
    left: 3.75em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-ic-left .mbsc-left-ic {
    left: 0.5em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-ic-left input,
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-ic-left textarea {
    padding-left: 3em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-ic-right input,
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-ic-right textarea {
    padding-right: 3em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-ic-right .mbsc-right-ic {
    right: 0.5em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-select input {
    padding-right: 2em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
    right: 0.6875em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-select.mbsc-ic-right input {
    padding-right: 4em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
    right: 3em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label {
    left: 1em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    left: 0.875em;
  }
  .mbsc-mobiscroll.mbsc-ltr .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    left: 3em;
  }
  .mbsc-mobiscroll.mbsc-ltr
    .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-ic-left.mbsc-label-floating-active
    .mbsc-label {
    left: 2.875em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline .mbsc-err-msg {
    right: 1.416667em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline .mbsc-label {
    right: 1.083334em;
    left: auto;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-ic-left .mbsc-err-msg {
    right: 4.083334em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-ic-left .mbsc-label {
    right: 3.75em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-ic-left .mbsc-left-ic {
    right: 0.5em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-ic-left input,
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-ic-left textarea {
    padding-right: 3em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-ic-right input,
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-ic-right textarea {
    padding-left: 3em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-ic-right .mbsc-right-ic {
    left: 0.5em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-select input {
    padding-left: 2em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-select .mbsc-input-wrap .mbsc-select-ic {
    left: 0.6875em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-select.mbsc-ic-right input {
    padding-left: 4em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-select.mbsc-ic-right .mbsc-input-wrap .mbsc-select-ic {
    left: 3em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-label-floating.mbsc-input .mbsc-label {
    right: 1em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    right: 0.875em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    right: 3em;
  }
  .mbsc-mobiscroll.mbsc-rtl
    .mbsc-input-outline.mbsc-label-floating.mbsc-input.mbsc-ic-left.mbsc-label-floating-active
    .mbsc-label {
    right: 2.875em;
  }
  .mbsc-mobiscroll .mbsc-input input,
  .mbsc-mobiscroll .mbsc-input textarea {
    border-bottom: 1px solid #787878;
    color: #1f1f1f;
  }
  .mbsc-mobiscroll .mbsc-input input:focus,
  .mbsc-mobiscroll .mbsc-input select:focus ~ input .mbsc-input textarea:focus,
  .mbsc-mobiscroll .mbsc-input textarea:focus {
    border-color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-input-ic {
    color: #787878;
  }
  .mbsc-mobiscroll .mbsc-input-box.mbsc-input .mbsc-input-wrap {
    background: #dedddd;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input input,
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input textarea {
    border: 1px solid #787878;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input .mbsc-label {
    background: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input input:focus,
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input select:focus ~ input,
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-err input {
    border-color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-input-outline.mbsc-input textarea:focus {
    border-color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-input.mbsc-err input,
  .mbsc-mobiscroll .mbsc-input.mbsc-err textarea {
    border-color: #de3226;
  }
  .mbsc-mobiscroll.mbsc-progress {
    padding: 1em;
  }
  .mbsc-mobiscroll.mbsc-progress .mbsc-label {
    font-size: 0.75em;
  }
  .mbsc-mobiscroll.mbsc-progress .mbsc-input-ic {
    top: 0.0625em;
    margin: 0;
  }
  .mbsc-mobiscroll.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-input-wrap,
  .mbsc-mobiscroll.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-input-wrap {
    padding-left: 2.75em;
  }
  .mbsc-mobiscroll.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-input-wrap,
  .mbsc-mobiscroll.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-input-wrap {
    padding-right: 2.75em;
  }
  .mbsc-mobiscroll.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-left-ic,
  .mbsc-mobiscroll.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-right-ic {
    right: auto;
    left: -0.375em;
  }
  .mbsc-mobiscroll.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-right-ic,
  .mbsc-mobiscroll.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-left-ic {
    left: auto;
    right: -0.375em;
  }
  .mbsc-mobiscroll .mbsc-progress-cont {
    padding: 1em 0;
  }
  .mbsc-mobiscroll .mbsc-progress-track {
    border-radius: 1em;
    height: 0.125em;
  }
  .mbsc-mobiscroll .mbsc-progress-value {
    width: 3.75em;
    font-size: 0.875em;
  }
  .mbsc-mobiscroll.mbsc-ltr.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap,
  .mbsc-mobiscroll.mbsc-rtl.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap {
    padding-left: 3.5em;
  }
  .mbsc-mobiscroll.mbsc-ltr.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap,
  .mbsc-mobiscroll.mbsc-rtl.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap {
    padding-right: 3.5em;
  }
  .mbsc-mobiscroll.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap,
  .mbsc-mobiscroll.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap {
    padding-left: 5.5em;
  }
  .mbsc-mobiscroll.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap,
  .mbsc-mobiscroll.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap {
    padding-right: 5.5em;
  }
  .mbsc-mobiscroll.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value,
  .mbsc-mobiscroll.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value {
    left: 2.25em;
    right: auto;
  }
  .mbsc-mobiscroll.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value,
  .mbsc-mobiscroll.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value {
    left: auto;
    right: 2.25em;
  }
  .mbsc-mobiscroll .mbsc-progress-primary .mbsc-progress-bar {
    background: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-progress-secondary .mbsc-progress-bar {
    background: #90979e;
  }
  .mbsc-mobiscroll .mbsc-progress-success .mbsc-progress-bar {
    background: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-progress-danger .mbsc-progress-bar {
    background: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-progress-warning .mbsc-progress-bar {
    background: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-progress-info .mbsc-progress-bar {
    background: #5bb7c5;
  }
  .mbsc-mobiscroll.mbsc-progress .mbsc-input-ic {
    color: #787878;
  }
  .mbsc-mobiscroll .mbsc-progress-track {
    background: #dedddd;
  }
  .mbsc-mobiscroll .mbsc-progress-bar {
    background: #4eccc4;
  }
  .mbsc-mobiscroll.mbsc-slider .mbsc-input-wrap {
    padding: 0 0.5em;
  }
  .mbsc-mobiscroll .mbsc-slider-handle {
    top: 50%;
    width: 1em;
    height: 1em;
    border-radius: 1.125em;
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: -webkit-transform 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out;
  }
  .mbsc-mobiscroll .mbsc-slider-handle:focus,
  .mbsc-mobiscroll .mbsc-active .mbsc-slider-handle {
    -webkit-transform: scale(1.3);
    transform: scale(1.3);
  }
  .mbsc-mobiscroll.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
    opacity: 0.4;
  }
  .mbsc-mobiscroll.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-handle {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .mbsc-mobiscroll .mbsc-slider-tooltip {
    top: -1.91666em;
    width: 2.33333333em;
    height: 2.33333333em;
    border-radius: 2em;
    margin-right: -1.1666em;
    line-height: 2.33333333em;
    text-align: center;
    font-size: 0.75em;
    z-index: 5;
    -webkit-transform: scale(0.4) translate3d(0, 5em, 0);
    transform: scale(0.4) translate3d(0, 5em, 0);
    -webkit-transition: -webkit-transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
    transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  }
  .mbsc-mobiscroll .mbsc-slider-handle:focus ~ .mbsc-slider-tooltip,
  .mbsc-mobiscroll .mbsc-active .mbsc-slider-tooltip {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0) scale(1);
    transform: translate3d(0, 0, 0) scale(1);
  }
  .mbsc-mobiscroll.mbsc-slider-has-tooltip .mbsc-slider-handle:focus,
  .mbsc-mobiscroll.mbsc-slider-has-tooltip .mbsc-active .mbsc-slider-handle {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
  }
  .mbsc-mobiscroll .mbsc-slider-primary .mbsc-slider-tooltip,
  .mbsc-mobiscroll .mbsc-slider-primary .mbsc-slider-handle,
  .mbsc-mobiscroll .mbsc-slider-primary .mbsc-progress-bar {
    background: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-slider-secondary .mbsc-slider-tooltip,
  .mbsc-mobiscroll .mbsc-slider-secondary .mbsc-slider-handle,
  .mbsc-mobiscroll .mbsc-slider-secondary .mbsc-progress-bar {
    background: #90979e;
  }
  .mbsc-mobiscroll .mbsc-slider-success .mbsc-slider-tooltip,
  .mbsc-mobiscroll .mbsc-slider-success .mbsc-slider-handlem .mbsc-progress-bar {
    background: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-slider-danger .mbsc-slider-tooltip,
  .mbsc-mobiscroll .mbsc-slider-danger .mbsc-slider-handle,
  .mbsc-mobiscroll .mbsc-slider-danger .mbsc-progress-bar {
    background: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-slider-warning .mbsc-slider-tooltip,
  .mbsc-mobiscroll .mbsc-slider-warning .mbsc-slider-handle,
  .mbsc-mobiscroll .mbsc-slider-warning .mbsc-progress-bar {
    background: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-slider-info .mbsc-slider-tooltip,
  .mbsc-mobiscroll .mbsc-slider-info .mbsc-slider-handle,
  .mbsc-mobiscroll .mbsc-slider-info .mbsc-progress-bar {
    background: #5bb7c5;
  }
  .mbsc-mobiscroll .mbsc-slider-step {
    background: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-slider-handle {
    background: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-slider-tooltip {
    color: #ededed;
    background-color: #4eccc4;
  }
  .mbsc-mobiscroll.mbsc-rating .mbsc-label {
    padding-bottom: 0.5em;
  }
  .mbsc-mobiscroll.mbsc-rating input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
    opacity: 0.4;
  }
  .mbsc-mobiscroll.mbsc-rating.mbsc-rating-primary .mbsc-progress-track {
    color: #3f97f6;
  }
  .mbsc-mobiscroll.mbsc-rating.mbsc-rating-secondary .mbsc-progress-track {
    color: #90979e;
  }
  .mbsc-mobiscroll.mbsc-rating.mbsc-rating-success .mbsc-progress-track {
    color: #43be5f;
  }
  .mbsc-mobiscroll.mbsc-rating.mbsc-rating-danger .mbsc-progress-track {
    color: #f5504e;
  }
  .mbsc-mobiscroll.mbsc-rating.mbsc-rating-warning .mbsc-progress-track {
    color: #f8b042;
  }
  .mbsc-mobiscroll.mbsc-rating.mbsc-rating-info .mbsc-progress-track {
    color: #5bb7c5;
  }
  .mbsc-mobiscroll.mbsc-rating .mbsc-progress-track {
    color: #4eccc4;
  }
  .mbsc-mobiscroll.mbsc-toast .mbsc-toast-msg {
    opacity: 0.9;
    border-radius: 0.5em;
  }
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-primary .mbsc-toast-msg {
    background: #3f97f6;
  }
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-secondary .mbsc-toast-msg {
    background: #90979e;
  }
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-success .mbsc-toast-msg {
    background: #43be5f;
  }
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-danger .mbsc-toast-msg {
    background: #f5504e;
  }
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-warning .mbsc-toast-msg {
    background: #f8b042;
  }
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-info .mbsc-toast-msg {
    background: #5bb7c5;
  }
  .mbsc-mobiscroll.mbsc-snackbar .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast .mbsc-toast-msg {
    background: #787878;
    color: #fff;
  }
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-mobiscroll.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
  .mbsc-mobiscroll.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-divider,
  .mbsc-mobiscroll .mbsc-form-group-title {
    padding: 0.5em 1em;
  }
  .mbsc-mobiscroll .mbsc-err-msg {
    position: absolute;
    padding-top: 0.333334em;
    font-size: 0.75em;
  }
  .mbsc-mobiscroll .mbsc-form-grid {
    margin-top: 0.75em;
    margin-bottom: 0.75em;
  }
  .mbsc-mobiscroll .mbsc-form-grid .mbsc-input-box,
  .mbsc-mobiscroll .mbsc-form-grid .mbsc-input-outline {
    margin: 0.75em 1em;
  }
  .mbsc-mobiscroll.mbsc-form .mbsc-checkbox {
    padding: 1em 3.125em 1em 1em;
  }
  .mbsc-mobiscroll.mbsc-form.mbsc-rtl .mbsc-checkbox {
    padding: 1em 1em 1em 3.125em;
  }
  .mbsc-mobiscroll .mbsc-checkbox-box {
    margin-top: -0.5625em;
    width: 1.125em;
    height: 1.125em;
    right: 1em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-checkbox-box {
    right: auto;
    left: 1em;
  }
  .mbsc-mobiscroll .mbsc-checkbox-box:after {
    top: 0.25em;
    left: 0.185em;
    width: 0.8125em;
    height: 0.4375em;
  }
  .mbsc-mobiscroll .mbsc-checkbox-primary .mbsc-checkbox-box {
    background: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-checkbox-secondary .mbsc-checkbox-box {
    background: #90979e;
  }
  .mbsc-mobiscroll .mbsc-checkbox-success .mbsc-checkbox-box {
    background: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-checkbox-danger .mbsc-checkbox-box {
    background: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-checkbox-warning .mbsc-checkbox-box {
    background: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-checkbox-info .mbsc-checkbox-box {
    background: #5bb7c5;
  }
  .mbsc-mobiscroll .mbsc-radio {
    padding: 1em 3.125em 1em 1em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-radio {
    padding: 1em 1em 1em 3.125em;
  }
  .mbsc-mobiscroll .mbsc-radio-box {
    right: 1em;
    width: 1.125em;
    height: 1.125em;
    margin-top: -0.5625em;
    background: transparent;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-radio-box {
    right: auto;
    left: 1em;
  }
  .mbsc-mobiscroll .mbsc-radio-box:after {
    width: 0.5em;
    height: 0.5em;
    margin-top: -0.25em;
    margin-left: -0.25em;
    border-radius: 0.625em;
  }
  .mbsc-mobiscroll .mbsc-radio input:checked + .mbsc-radio-box {
    background: transparent;
  }
  .mbsc-mobiscroll .mbsc-radio-primary .mbsc-radio-box {
    border-color: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-radio-primary .mbsc-radio-box:after {
    background: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-radio-secondary .mbsc-radio-box {
    border-color: #90979e;
  }
  .mbsc-mobiscroll .mbsc-radio-secondary .mbsc-radio-box:after {
    background: #90979e;
  }
  .mbsc-mobiscroll .mbsc-radio-success .mbsc-radio-box {
    border-color: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-radio-success .mbsc-radio-box:after {
    background: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-radio-danger .mbsc-radio-box {
    border-color: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-radio-danger .mbsc-radio-box:after {
    background: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-radio-warning .mbsc-radio-box {
    border-color: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-radio-warning .mbsc-radio-box:after {
    background: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-radio-info .mbsc-radio-box {
    border-color: #5bb7c5;
  }
  .mbsc-mobiscroll .mbsc-radio-info .mbsc-radio-box:after {
    background: #5bb7c5;
  }
  .mbsc-mobiscroll .mbsc-checkbox input:disabled ~ .mbsc-label,
  .mbsc-mobiscroll .mbsc-checkbox input:disabled ~ .mbsc-desc,
  .mbsc-mobiscroll .mbsc-radio input:disabled ~ .mbsc-label,
  .mbsc-mobiscroll .mbsc-radio input:disabled ~ .mbsc-desc,
  .mbsc-mobiscroll .mbsc-switch input:disabled ~ .mbsc-label,
  .mbsc-mobiscroll .mbsc-switch input:disabled ~ .mbsc-desc {
    opacity: 0.4;
  }
  .mbsc-mobiscroll .mbsc-checkbox-box:after,
  .mbsc-mobiscroll .mbsc-radio-box:after {
    opacity: 1;
    -webkit-transform: scale(0) rotate(-45deg);
    transform: scale(0) rotate(-45deg);
    transition: transform 0.1s ease-out;
  }
  .mbsc-mobiscroll .mbsc-checkbox input:checked + .mbsc-checkbox-box:after,
  .mbsc-mobiscroll .mbsc-radio input:checked + .mbsc-radio-box:after {
    opacity: 1;
    -webkit-transform: scale(1) rotate(-45deg);
    transform: scale(1) rotate(-45deg);
  }
  .mbsc-mobiscroll .mbsc-btn {
    margin: 0.5em;
    padding: 0.6875em;
    font-size: 1em;
    text-transform: uppercase;
  }
  .mbsc-mobiscroll .mbsc-btn-ic {
    padding-right: 0.6875em;
  }
  .mbsc-mobiscroll .mbsc-btn-icon-only .mbsc-btn-ic {
    padding: 0 0.5em;
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-btn:not(:disabled):not(.mbsc-active):hover {
    opacity: 0.8;
  }
  .mbsc-mobiscroll .mbsc-btn.mbsc-active {
    opacity: 0.6;
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    opacity: 1;
  }
  .mbsc-mobiscroll .mbsc-btn-flat {
    background: transparent;
    border-color: transparent;
  }
  .mbsc-mobiscroll .mbsc-btn-flat.mbsc-btn.mbsc-active {
    opacity: 1;
  }
  .mbsc-mobiscroll .mbsc-btn-flat:disabled {
    background: transparent;
  }
  .mbsc-mobiscroll .mbsc-btn-primary.mbsc-btn {
    background: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-btn-primary.mbsc-btn.mbsc-btn-flat {
    color: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-btn-primary.mbsc-btn.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    background: rgba(63, 151, 246, 0.2);
  }
  .mbsc-mobiscroll .mbsc-btn-primary.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(63, 151, 246, 0.3);
  }
  .mbsc-mobiscroll .mbsc-btn-secondary.mbsc-btn {
    background: #90979e;
  }
  .mbsc-mobiscroll .mbsc-btn-secondary.mbsc-btn.mbsc-btn-flat {
    color: #90979e;
  }
  .mbsc-mobiscroll .mbsc-btn-secondary.mbsc-btn.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    background: rgba(144, 151, 158, 0.2);
  }
  .mbsc-mobiscroll .mbsc-btn-secondary.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(144, 151, 158, 0.3);
  }
  .mbsc-mobiscroll .mbsc-btn-success.mbsc-btn {
    background: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-btn-success.mbsc-btn.mbsc-btn-flat {
    color: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-btn-success.mbsc-btn.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    background: rgba(67, 190, 95, 0.2);
  }
  .mbsc-mobiscroll .mbsc-btn-success.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(67, 190, 95, 0.3);
  }
  .mbsc-mobiscroll .mbsc-btn-danger.mbsc-btn {
    background: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-btn-danger.mbsc-btn.mbsc-btn-flat {
    color: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-btn-danger.mbsc-btn.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    background: rgba(245, 80, 78, 0.2);
  }
  .mbsc-mobiscroll .mbsc-btn-danger.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(245, 80, 78, 0.3);
  }
  .mbsc-mobiscroll .mbsc-btn-warning.mbsc-btn {
    background: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-btn-warning.mbsc-btn.mbsc-btn-flat {
    color: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-btn-warning.mbsc-btn.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    background: rgba(248, 176, 66, 0.2);
  }
  .mbsc-mobiscroll .mbsc-btn-warning.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(248, 176, 66, 0.3);
  }
  .mbsc-mobiscroll .mbsc-btn-info.mbsc-btn {
    background: #5bb7c5;
  }
  .mbsc-mobiscroll .mbsc-btn-info.mbsc-btn.mbsc-btn-flat {
    color: #5bb7c5;
  }
  .mbsc-mobiscroll .mbsc-btn-info.mbsc-btn.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    background: rgba(91, 183, 197, 0.2);
  }
  .mbsc-mobiscroll .mbsc-btn-info.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(91, 183, 197, 0.3);
  }
  .mbsc-mobiscroll .mbsc-btn-light.mbsc-btn {
    background: #e6e5e5;
  }
  .mbsc-mobiscroll .mbsc-btn-light.mbsc-btn.mbsc-btn-flat {
    color: #a6a6a6;
  }
  .mbsc-mobiscroll .mbsc-btn-light.mbsc-btn.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    color: #a6a6a6;
    background: rgba(166, 166, 166, 0.2);
  }
  .mbsc-mobiscroll .mbsc-btn-light.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(166, 166, 166, 0.3);
  }
  .mbsc-mobiscroll .mbsc-btn-dark.mbsc-btn {
    background: #47494a;
  }
  .mbsc-mobiscroll .mbsc-btn-dark.mbsc-btn.mbsc-btn-flat {
    color: #47494a;
  }
  .mbsc-mobiscroll .mbsc-btn-dark.mbsc-btn.mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    background: rgba(71, 73, 74, 0.2);
  }
  .mbsc-mobiscroll .mbsc-btn-dark.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(71, 73, 74, 0.3);
  }
  .mbsc-mobiscroll .mbsc-btn-flat.mbsc-btn,
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn {
    background: transparent;
  }
  .mbsc-mobiscroll .mbsc-btn-group,
  .mbsc-mobiscroll .mbsc-btn-group-justified {
    margin: 0.5em;
  }
  .mbsc-mobiscroll .mbsc-btn-group-block {
    margin: 0.5em 1em;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-active {
    opacity: 1;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-primary {
    border-color: #3f97f6;
    color: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-primary.mbsc-active {
    background: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-secondary {
    border-color: #90979e;
    color: #90979e;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-secondary.mbsc-active {
    background: #90979e;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-success {
    border-color: #43be5f;
    color: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-success.mbsc-active {
    background: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-danger {
    border-color: #f5504e;
    color: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-danger.mbsc-active {
    background: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-warning {
    border-color: #f8b042;
    color: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-warning.mbsc-active {
    background: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-info {
    border-color: #5bb7c5;
    color: #5bb7c5;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-info.mbsc-active {
    background: #5bb7c5;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-light {
    border-color: #a6a6a6;
    color: #a6a6a6;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-light.mbsc-active {
    background: #a6a6a6;
    color: #e6e5e5;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-dark {
    border-color: #47494a;
    color: #47494a;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-dark.mbsc-active {
    background: #47494a;
  }
  .mbsc-mobiscroll .mbsc-switch {
    padding: 1em 4.375em 1em 1em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-switch {
    padding: 1em 1em 1em 4.375em;
  }
  .mbsc-mobiscroll .mbsc-switch-track {
    right: 1em;
    width: 2.375em;
    height: 0.875em;
    padding: 0 0.75em;
    margin-top: -0.4375em;
    border-radius: 1.25em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-switch-track {
    right: auto;
    left: 1em;
  }
  .mbsc-mobiscroll .mbsc-switch-handle {
    top: 0.25em;
    left: 0.25em;
    margin: 0;
    width: 1.5em;
    height: 1.5em;
    border-radius: 1.25em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-switch-handle {
    right: 0.25em;
  }
  .mbsc-mobiscroll .mbsc-active .mbsc-switch-handle {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  .mbsc-mobiscroll .mbsc-switch input:disabled + .mbsc-switch-track {
    opacity: 0.7;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-primary input:checked + .mbsc-switch-track {
    background: #a0ccfb;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-primary input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-secondary input:checked + .mbsc-switch-track {
    background: #c6cace;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-secondary input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #90979e;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-success input:checked + .mbsc-switch-track {
    background: #8fd8a0;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-success input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-danger input:checked + .mbsc-switch-track {
    background: #faafaf;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-danger input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-warning input:checked + .mbsc-switch-track {
    background: #fcd9a4;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-warning input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-info input:checked + .mbsc-switch-track {
    background: #a6d8e0;
  }
  .mbsc-mobiscroll .mbsc-switch.mbsc-switch-info input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #5bb7c5;
  }
  .mbsc-mobiscroll .mbsc-segmented {
    padding: 0.5em 1em;
  }
  .mbsc-mobiscroll .mbsc-segmented-content {
    height: 2.28571428em;
    margin: 0 -0.071428em;
    line-height: 2.28575em;
    padding: 0 0.285714em;
    text-transform: uppercase;
  }
  .mbsc-mobiscroll .mbsc-stepper-cont {
    padding: 1.75em 12.875em 1.75em 1em;
  }
  .mbsc-mobiscroll.mbsc-rtl .mbsc-stepper-cont {
    padding: 1.75em 1em 1.75em 12.875em;
  }
  .mbsc-mobiscroll .mbsc-stepper {
    margin-top: -1.125em;
  }
  .mbsc-mobiscroll .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-mobiscroll .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-mobiscroll .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    background: transparent;
  }
  .mbsc-mobiscroll .mbsc-segmented-primary .mbsc-segmented-content {
    border-color: #3f97f6;
    color: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-segmented-primary.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
    background: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-segmented-primary.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(63, 151, 246, 0.35);
  }
  .mbsc-mobiscroll .mbsc-segmented-secondary .mbsc-segmented-content {
    border-color: #90979e;
    color: #90979e;
  }
  .mbsc-mobiscroll .mbsc-segmented-secondary.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
    background: #90979e;
  }
  .mbsc-mobiscroll .mbsc-segmented-secondary.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(144, 151, 158, 0.35);
  }
  .mbsc-mobiscroll .mbsc-segmented-success .mbsc-segmented-content {
    border-color: #43be5f;
    color: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-segmented-success.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
    background: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-segmented-success.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(67, 190, 95, 0.35);
  }
  .mbsc-mobiscroll .mbsc-segmented-danger .mbsc-segmented-content {
    border-color: #f5504e;
    color: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-segmented-danger.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
    background: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-segmented-danger.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(245, 80, 78, 0.35);
  }
  .mbsc-mobiscroll .mbsc-segmented-warning .mbsc-segmented-content {
    border-color: #f8b042;
    color: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-segmented-warning.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
    background: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-segmented-warning.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(248, 176, 66, 0.35);
  }
  .mbsc-mobiscroll .mbsc-segmented-info .mbsc-segmented-content {
    border-color: #5bb7c5;
    color: #5bb7c5;
  }
  .mbsc-mobiscroll .mbsc-segmented-info.mbsc-segmented-item input.mbsc-control:checked + .mbsc-segmented-content {
    background: #5bb7c5;
  }
  .mbsc-mobiscroll .mbsc-segmented-info.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(91, 183, 197, 0.35);
  }
  .mbsc-mobiscroll .mbsc-stepper-primary .mbsc-segmented-content {
    border-color: #3f97f6;
    color: #3f97f6;
  }
  .mbsc-mobiscroll
    .mbsc-stepper-primary
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #3f97f6;
  }
  .mbsc-mobiscroll .mbsc-stepper-secondary .mbsc-segmented-content {
    border-color: #90979e;
    color: #90979e;
  }
  .mbsc-mobiscroll
    .mbsc-stepper-secondary
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #90979e;
  }
  .mbsc-mobiscroll .mbsc-stepper-success .mbsc-segmented-content {
    border-color: #43be5f;
    color: #43be5f;
  }
  .mbsc-mobiscroll
    .mbsc-stepper-success
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #43be5f;
  }
  .mbsc-mobiscroll .mbsc-stepper-danger .mbsc-segmented-content {
    border-color: #f5504e;
    color: #f5504e;
  }
  .mbsc-mobiscroll
    .mbsc-stepper-danger
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #f5504e;
  }
  .mbsc-mobiscroll .mbsc-stepper-warning .mbsc-segmented-content {
    border-color: #f8b042;
    color: #f8b042;
  }
  .mbsc-mobiscroll
    .mbsc-stepper-warning
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #f8b042;
  }
  .mbsc-mobiscroll .mbsc-stepper-info .mbsc-segmented-content {
    border-color: #5bb7c5;
    color: #5bb7c5;
  }
  .mbsc-mobiscroll
    .mbsc-stepper-info
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active
    .mbsc-segmented-content {
    background: #5bb7c5;
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-segmented-primary.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(63, 151, 246, 0.35);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-segmented-secondary.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(144, 151, 158, 0.35);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-segmented-success.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(67, 190, 95, 0.35);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-segmented-danger.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(245, 80, 78, 0.35);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-segmented-warning.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(248, 176, 66, 0.35);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-segmented-info.mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(91, 183, 197, 0.35);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-stepper-primary .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(63, 151, 246, 0.35);
  }
  .mbsc-mobiscroll.mbsc-no-touch
    .mbsc-stepper-secondary
    .mbsc-stepper
    .mbsc-segmented-item:hover
    .mbsc-segmented-content {
    background: rgba(144, 151, 158, 0.35);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-stepper-success .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(67, 190, 95, 0.35);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-stepper-danger .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(245, 80, 78, 0.35);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-stepper-warning .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(248, 176, 66, 0.35);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-stepper-info .mbsc-stepper .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(91, 183, 197, 0.35);
  }
  .mbsc-mobiscroll.mbsc-form {
    background: #f7f7f7;
    color: #454545;
  }
  .mbsc-mobiscroll.mbsc-form *::-moz-selection {
    background: #4eccc4;
  }
  .mbsc-mobiscroll.mbsc-form *::selection {
    background: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-divider,
  .mbsc-mobiscroll .mbsc-form-group-title {
    background: #f7f7f7;
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-err-msg,
  .mbsc-mobiscroll .mbsc-err .mbsc-label {
    color: #de3226;
  }
  .mbsc-mobiscroll .mbsc-checkbox-box {
    background: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-checkbox-box:after {
    border-bottom: 0.125em solid #fff;
    border-left: 0.125em solid #fff;
  }
  .mbsc-mobiscroll .mbsc-checkbox input:disabled + .mbsc-checkbox-box {
    background: #d6d6d6;
  }
  .mbsc-mobiscroll .mbsc-radio-box {
    border: 0.125em solid #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-radio-box:after {
    background: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-radio input:checked + .mbsc-radio-box {
    background: transparent;
  }
  .mbsc-mobiscroll .mbsc-radio input:disabled + .mbsc-radio-box {
    border-color: #d6d6d6;
  }
  .mbsc-mobiscroll .mbsc-radio input:disabled + .mbsc-radio-box:after {
    background: #d6d6d6;
  }
  .mbsc-mobiscroll .mbsc-btn {
    background: #4eccc4;
    color: #efefef;
  }
  .mbsc-mobiscroll .mbsc-btn:disabled {
    background: #dedddd;
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    background: rgba(247, 247, 247, 0.1);
  }
  .mbsc-mobiscroll .mbsc-btn-flat {
    background: transparent;
    color: #4eccc4;
    border-color: transparent;
  }
  .mbsc-mobiscroll .mbsc-btn-flat.mbsc-btn.mbsc-active {
    background: rgba(78, 204, 196, 0.3);
  }
  .mbsc-mobiscroll .mbsc-btn-flat:disabled {
    color: #c7c7c7;
    background: transparent;
  }
  .mbsc-mobiscroll .mbsc-btn-light.mbsc-btn {
    color: #000;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn {
    border: 1px solid #4eccc4;
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-active {
    background: #4eccc4;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-primary.mbsc-active,
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-secondary.mbsc-active,
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-success.mbsc-active,
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-danger.mbsc-active,
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-warning.mbsc-active,
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-info.mbsc-active,
  .mbsc-mobiscroll .mbsc-btn-outline.mbsc-btn.mbsc-btn-dark.mbsc-active {
    color: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-btn.mbsc-btn-outline:disabled {
    color: #dedddd;
    border-color: #dedddd;
    background: transparent;
  }
  .mbsc-mobiscroll .mbsc-switch-track {
    background: #dedddd;
  }
  .mbsc-mobiscroll .mbsc-switch-handle {
    background: #c7c7c7;
  }
  .mbsc-mobiscroll .mbsc-switch input:checked + .mbsc-switch-track {
    background: #b4e6e2;
  }
  .mbsc-mobiscroll .mbsc-switch input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-switch input:disabled + .mbsc-switch-track {
    background: #d6d6d6;
  }
  .mbsc-mobiscroll .mbsc-switch input:disabled + .mbsc-switch-track .mbsc-switch-handle {
    background: #e3e3e3;
  }
  .mbsc-mobiscroll .mbsc-segmented-content {
    border: 0.142857em solid #4eccc4;
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-stepper input {
    color: #454545;
  }
  .mbsc-mobiscroll .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
  .mbsc-mobiscroll .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #4eccc4;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(78, 204, 196, 0.3);
  }
  .mbsc-mobiscroll .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-mobiscroll .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-mobiscroll .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    color: #d6d6d6;
    border-color: #d6d6d6;
  }
  .mbsc-mobiscroll .mbsc-stepper input:disabled {
    color: #d6d6d6;
    -webkit-text-fill-color: #d6d6d6;
  }
  .mbsc-mobiscroll .mbsc-segmented input:disabled:checked + .mbsc-segmented-content {
    border-color: #dedddd;
    background: #dedddd;
    color: #efefef;
  }
  .mbsc-mobiscroll .mbsc-stepper .mbsc-active.mbsc-disabled .mbsc-segmented-content {
    background: transparent;
    color: #d6d6d6;
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(78, 204, 196, 0.1);
  }
  .mbsc-mobiscroll.mbsc-card.mbsc-form {
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.3);
  }
  .mbsc-mobiscroll.mbsc-card.mbsc-form {
    background: #fff;
  }
  .mbsc-mobiscroll.mbsc-card .mbsc-lv-item {
    background: #fff;
  }
  .mbsc-mobiscroll .mbsc-card-footer {
    border-color: #ccc;
  }
  .mbsc-mobiscroll.mbsc-card .mbsc-input.mbsc-input-outline .mbsc-label {
    background: #fff;
  }
  .mbsc-windows .mbsc-collapsible .mbsc-form-group-title.mbsc-collapsible-header {
    padding-left: 0.666667em;
    padding-right: 2em;
  }
  .mbsc-windows .mbsc-collapsible .mbsc-form-group-title .mbsc-collapsible-icon {
    font-size: 0.666667em;
  }
  .mbsc-windows .mbsc-note,
  .mbsc-windows .mbsc-note-primary {
    color: #074b95;
    background-color: #70b1f8;
  }
  .mbsc-windows .mbsc-note-secondary {
    color: #454b50;
    background-color: #c6cace;
  }
  .mbsc-windows .mbsc-note-success {
    color: #1b4d26;
    background-color: #8fd8a0;
  }
  .mbsc-windows .mbsc-note-danger {
    color: #710806;
    background-color: #f8807e;
  }
  .mbsc-windows .mbsc-note-warning {
    color: #835105;
    background-color: #f9ba5b;
  }
  .mbsc-windows .mbsc-note-info {
    color: #235b64;
    background-color: #a6d8e0;
  }
  .mbsc-windows .mbsc-note-light {
    color: #4d4c4c;
    background-color: #fff;
  }
  .mbsc-windows .mbsc-note-dark {
    color: #000;
    background-color: #606364;
  }
  .mbsc-windows .mbsc-empty {
    color: #737272;
  }
  .mbsc-windows.mbsc-page {
    background-color: #fff;
    color: #262626;
  }
  .mbsc-windows a {
    color: #0078d7;
  }
  .mbsc-windows .mbsc-input {
    padding: 0.5em 1em 1em;
  }
  .mbsc-windows .mbsc-input input,
  .mbsc-windows .mbsc-input textarea,
  .mbsc-windows .mbsc-select input {
    height: 2em;
    padding: 0 0.3125em;
    font-size: 1em;
    background: transparent;
  }
  .mbsc-windows.mbsc-ltr .mbsc-select input {
    padding-right: 1.75em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-select input {
    padding-left: 1.75em;
  }
  .mbsc-windows .mbsc-input textarea {
    padding-top: 0.125em;
    padding-bottom: 0.125em;
  }
  .mbsc-windows .mbsc-input-ic {
    top: 0;
    margin-top: 0;
  }
  .mbsc-windows .mbsc-err-msg {
    position: absolute;
    padding-top: 0.333334em;
  }
  .mbsc-windows .mbsc-input.mbsc-ic-left input,
  .mbsc-windows .mbsc-input.mbsc-ic-left textarea {
    padding-left: 2.25em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-input.mbsc-ic-left input,
  .mbsc-windows.mbsc-rtl .mbsc-input.mbsc-ic-left textarea {
    padding-right: 2.25em;
    padding-left: 0.3125em;
  }
  .mbsc-windows.mbsc-ltr .mbsc-input.mbsc-ic-right input,
  .mbsc-windows.mbsc-ltr .mbsc-input.mbsc-ic-right textarea {
    padding-right: 2.25em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-input.mbsc-ic-right input,
  .mbsc-windows.mbsc-rtl .mbsc-input.mbsc-ic-right textarea {
    padding-left: 2.25em;
  }
  .mbsc-windows .mbsc-ic-left .mbsc-left-ic,
  .mbsc-windows.mbsc-rtl .mbsc-ic-right .mbsc-right-ic {
    right: auto;
    left: 0.25em;
  }
  .mbsc-windows .mbsc-ic-right .mbsc-right-ic,
  .mbsc-windows.mbsc-rtl .mbsc-ic-left .mbsc-left-ic {
    left: auto;
    right: 0.25em;
  }
  .mbsc-windows .mbsc-input .mbsc-label {
    padding-bottom: 0.3125em;
    font-size: 0.8125em;
  }
  .mbsc-windows .mbsc-select .mbsc-select-ic {
    display: block;
    position: absolute;
    top: 0.5em;
  }
  .mbsc-windows.mbsc-ltr .mbsc-select .mbsc-select-ic {
    right: 0.5em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-select .mbsc-select-ic {
    left: 0.5em;
  }
  .mbsc-windows .mbsc-ic-right .mbsc-select-ic {
    display: none;
  }
  .mbsc-windows .mbsc-label-inline.mbsc-input .mbsc-label {
    line-height: 2em;
    padding-bottom: 0;
  }
  .mbsc-windows .mbsc-label-floating.mbsc-input {
    padding-top: 1.625em;
  }
  .mbsc-windows .mbsc-label-floating.mbsc-input .mbsc-label {
    font-size: 1em;
    position: absolute;
    top: 2.0625em;
  }
  .mbsc-windows .mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    -webkit-transform: translateY(-1.5em) scale(0.75);
    transform: translateY(-1.5em) scale(0.75);
  }
  .mbsc-windows.mbsc-ltr .mbsc-label-floating.mbsc-input .mbsc-label {
    left: 1.375em;
  }
  .mbsc-windows.mbsc-ltr .mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    left: 1em;
  }
  .mbsc-windows.mbsc-ltr .mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    left: 3.375em;
  }
  .mbsc-windows.mbsc-ltr .mbsc-label-floating.mbsc-input.mbsc-label-floating-active.mbsc-ic-left .mbsc-label {
    left: 1em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-label-floating.mbsc-input .mbsc-label {
    right: 1.375em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-label-floating.mbsc-input.mbsc-label-floating-active .mbsc-label {
    right: 1em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-label-floating.mbsc-input.mbsc-ic-left .mbsc-label {
    right: 3.375em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-label-floating.mbsc-input.mbsc-label-floating-active.mbsc-ic-left .mbsc-label {
    right: 1em;
  }
  .mbsc-windows .mbsc-input input,
  .mbsc-windows .mbsc-input textarea,
  .mbsc-windows .mbsc-select input {
    border: 0.125em solid #999;
    color: #262626;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-input input:hover,
  .mbsc-windows.mbsc-no-touch .mbsc-input textarea:hover,
  .mbsc-windows.mbsc-no-touch .mbsc-select:hover input {
    border-color: #666;
  }
  .mbsc-windows .mbsc-input input::-webkit-input-placeholder {
    color: #5c5c5c;
  }
  .mbsc-windows .mbsc-input input::-ms-input-placeholder {
    color: #5c5c5c;
  }
  .mbsc-windows .mbsc-input input::-moz-placeholder {
    color: #5c5c5c;
  }
  .mbsc-windows .mbsc-input .mbsc-input-wrap input:focus,
  .mbsc-windows .mbsc-input .mbsc-input-wrap select:focus + input,
  .mbsc-windows .mbsc-input .mbsc-input-wrap textarea:focus,
  .mbsc-windows.mbsc-alert .mbsc-input input:focus {
    background: #fff;
    border-color: #0078d7;
    color: #262626;
  }
  .mbsc-windows .mbsc-input .mbsc-input-wrap input:focus ~ .mbsc-input-ic,
  .mbsc-windows .mbsc-input .mbsc-input-wrap select:focus + input ~ .mbsc-input-ic,
  .mbsc-windows .mbsc-input .mbsc-input-wrap textarea:focus ~ .mbsc-input-ic,
  .mbsc-windows.mbsc-alert .mbsc-input input:focus ~ .mbsc-input-ic {
    color: #262626;
  }
  .mbsc-windows .mbsc-input .mbsc-input-wrap .mbsc-control:disabled + input,
  .mbsc-windows .mbsc-input .mbsc-input-wrap textarea:disabled,
  .mbsc-windows .mbsc-input .mbsc-input-wrap input:disabled {
    border-color: #ccc;
  }
  .mbsc-windows .mbsc-input.mbsc-err input,
  .mbsc-windows .mbsc-input.mbsc-err textarea {
    border-color: #d30101;
  }
  .mbsc-windows .mbsc-input-ic,
  .mbsc-windows .mbsc-input .mbsc-label,
  .mbsc-windows .mbsc-select .mbsc-input-wrap .mbsc-ic {
    color: #262626;
  }
  .mbsc-windows .mbsc-err-msg {
    color: #d30101;
  }
  .mbsc-windows.mbsc-progress {
    padding: 0.75em 1em;
  }
  .mbsc-windows.mbsc-progress .mbsc-label {
    padding-bottom: 0.3125em;
    font-size: 1em;
  }
  .mbsc-windows.mbsc-progress .mbsc-input-wrap {
    padding: 0;
  }
  .mbsc-windows.mbsc-progress .mbsc-input-ic {
    top: -0.125em;
  }
  .mbsc-windows.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-input-wrap,
  .mbsc-windows.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-input-wrap {
    padding-left: 2.25em;
  }
  .mbsc-windows.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-input-wrap,
  .mbsc-windows.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-input-wrap {
    padding-right: 2.25em;
  }
  .mbsc-windows.mbsc-ltr.mbsc-progress.mbsc-ic-left .mbsc-left-ic,
  .mbsc-windows.mbsc-rtl.mbsc-progress.mbsc-ic-right .mbsc-right-ic {
    right: auto;
    left: -0.375em;
  }
  .mbsc-windows.mbsc-ltr.mbsc-progress.mbsc-ic-right .mbsc-right-ic,
  .mbsc-windows.mbsc-rtl.mbsc-progress.mbsc-ic-left .mbsc-left-ic {
    left: auto;
    right: -0.375em;
  }
  .mbsc-windows.mbsc-ltr.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap,
  .mbsc-windows.mbsc-rtl.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap {
    padding-right: 3.75em;
  }
  .mbsc-windows.mbsc-ltr.mbsc-progress.mbsc-progress-value-left .mbsc-input-wrap,
  .mbsc-windows.mbsc-rtl.mbsc-progress.mbsc-progress-value-right .mbsc-input-wrap {
    padding-left: 3.75em;
  }
  .mbsc-windows.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap,
  .mbsc-windows.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap {
    padding-left: 5.75em;
  }
  .mbsc-windows.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-input-wrap,
  .mbsc-windows.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-input-wrap {
    padding-right: 5.75em;
  }
  .mbsc-windows.mbsc-ltr.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value,
  .mbsc-windows.mbsc-rtl.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value {
    left: 1.875em;
    right: auto;
  }
  .mbsc-windows.mbsc-ltr.mbsc-progress-value-right.mbsc-ic-right .mbsc-progress-value,
  .mbsc-windows.mbsc-rtl.mbsc-progress-value-left.mbsc-ic-left .mbsc-progress-value {
    left: auto;
    right: 1.875em;
  }
  .mbsc-windows .mbsc-progress-cont {
    padding: 0.75em 0;
  }
  .mbsc-windows .mbsc-progress-track {
    height: 0.25em;
  }
  .mbsc-windows .mbsc-progress-value {
    margin-top: -0.4375em;
    width: 3.5em;
  }
  .mbsc-windows .mbsc-progress-step-label {
    top: auto;
    bottom: -2em;
    line-height: normal;
  }
  .mbsc-windows .mbsc-label-inline.mbsc-progress.mbsc-control-w .mbsc-label {
    line-height: 1.875em;
    padding-bottom: 0;
  }
  .mbsc-windows .mbsc-progress-primary .mbsc-progress-bar {
    background: #3f97f6;
  }
  .mbsc-windows .mbsc-progress-secondary .mbsc-progress-bar {
    background: #90979e;
  }
  .mbsc-windows .mbsc-progress-success .mbsc-progress-bar {
    background: #43be5f;
  }
  .mbsc-windows .mbsc-progress-danger .mbsc-progress-bar {
    background: #f5504e;
  }
  .mbsc-windows .mbsc-progress-warning .mbsc-progress-bar {
    background: #f8b042;
  }
  .mbsc-windows .mbsc-progress-info .mbsc-progress-bar {
    background: #5bb7c5;
  }
  .mbsc-windows.mbsc-progress .mbsc-label,
  .mbsc-windows.mbsc-progress .mbsc-input-ic {
    color: #262626;
  }
  .mbsc-windows .mbsc-progress-track {
    background: #ccc;
  }
  .mbsc-windows .mbsc-progress-bar {
    background: #0078d7;
  }
  .mbsc-windows.mbsc-slider .mbsc-input-wrap {
    padding: 0 0.3125em;
  }
  .mbsc-windows.mbsc-slider .mbsc-input-ic {
    top: -0.0625em;
  }
  .mbsc-windows.mbsc-slider .mbsc-progress-track {
    height: 6px;
  }
  .mbsc-windows .mbsc-slider-handle {
    top: 50%;
    width: 0.5em;
    border-radius: 6px;
    height: 1.5em;
    margin: -0.75em -0.25em 0 0;
  }
  .mbsc-windows .mbsc-slider-tooltip {
    height: 1.875em;
    padding: 0 0.75em;
    top: -2.75em;
    line-height: 1.875em;
    text-align: center;
    transition: opacity 0.2s ease-in-out;
    -webkit-transform: translateX(50%);
    transform: translateX(50%);
  }
  .mbsc-windows .mbsc-slider-handle:focus ~ .mbsc-slider-tooltip,
  .mbsc-windows .mbsc-active .mbsc-slider-tooltip {
    opacity: 1;
  }
  .mbsc-windows .mbsc-slider-step {
    height: 16px;
    width: 1px;
    top: -7px;
    z-index: -1;
  }
  .mbsc-windows .mbsc-progress-bar + .mbsc-slider-step {
    margin: 0;
  }
  .mbsc-windows .mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
    opacity: 0.4;
  }
  .mbsc-windows .mbsc-slider-primary .mbsc-progress-bar {
    background: #3f97f6;
  }
  .mbsc-windows .mbsc-slider-secondary .mbsc-progress-bar {
    background: #90979e;
  }
  .mbsc-windows .mbsc-slider-success .mbsc-progress-bar {
    background: #43be5f;
  }
  .mbsc-windows .mbsc-slider-danger .mbsc-progress-bar {
    background: #f5504e;
  }
  .mbsc-windows .mbsc-slider-warning .mbsc-progress-bar {
    background: #f8b042;
  }
  .mbsc-windows .mbsc-slider-info .mbsc-progress-bar {
    background: #5bb7c5;
  }
  .mbsc-windows.mbsc-slider .mbsc-progress-track {
    background: #999;
    border-top: 2px solid #f2f2f2;
    border-bottom: 2px solid #f2f2f2;
  }
  .mbsc-windows.mbsc-form .mbsc-slider .mbsc-progress-track {
    border-top-color: #fff;
    border-bottom-color: #fff;
  }
  .mbsc-windows.mbsc-slider:hover .mbsc-progress-track {
    background: #666;
  }
  .mbsc-windows.mbsc-slider:hover .mbsc-progress-track .mbsc-slider-handle {
    background: #262626;
  }
  .mbsc-windows.mbsc-slider .mbsc-active ~ .mbsc-progress-cont .mbsc-progress-track {
    background: #999;
  }
  .mbsc-windows.mbsc-slider .mbsc-active ~ .mbsc-progress-cont .mbsc-progress-track .mbsc-slider-handle {
    background: #ccc;
  }
  .mbsc-windows .mbsc-slider-handle {
    background: #0078d7;
  }
  .mbsc-windows .mbsc-slider-tooltip {
    border: 1px solid #ccc;
    background: #f2f2f2;
    color: #262626;
  }
  .mbsc-windows .mbsc-slider-step {
    background: #999;
  }
  .mbsc-windows.mbsc-rating input:disabled ~ .mbsc-progress-cont .mbsc-progress-track {
    opacity: 0.4;
  }
  .mbsc-windows.mbsc-rating.mbsc-rating-primary .mbsc-progress-track {
    color: #3f97f6;
  }
  .mbsc-windows.mbsc-rating.mbsc-rating-secondary .mbsc-progress-track {
    color: #90979e;
  }
  .mbsc-windows.mbsc-rating.mbsc-rating-success .mbsc-progress-track {
    color: #43be5f;
  }
  .mbsc-windows.mbsc-rating.mbsc-rating-danger .mbsc-progress-track {
    color: #f5504e;
  }
  .mbsc-windows.mbsc-rating.mbsc-rating-warning .mbsc-progress-track {
    color: #f8b042;
  }
  .mbsc-windows.mbsc-rating.mbsc-rating-info .mbsc-progress-track {
    color: #5bb7c5;
  }
  .mbsc-windows.mbsc-rating .mbsc-progress-track {
    color: #0078d7;
  }
  .mbsc-windows.mbsc-wdg .mbsc-fr-btn-cont {
    padding: 1em 0.875em;
    border-top: 0;
  }
  .mbsc-windows.mbsc-wdg .mbsc-fr-btn {
    margin: 0 0.125em;
    height: 2em;
    line-height: 2em;
  }
  .mbsc-windows.mbsc-wdg.mbsc-fr-btn {
    background: #ccc;
  }
  .mbsc-windows.mbsc-toast .mbsc-fr-w,
  .mbsc-windows.mbsc-snackbar .mbsc-fr-w {
    border: 0;
  }
  .mbsc-windows.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-primary .mbsc-toast-msg {
    background: #3f97f6;
  }
  .mbsc-windows.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-secondary .mbsc-toast-msg {
    background: #90979e;
  }
  .mbsc-windows.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-success .mbsc-toast-msg {
    background: #43be5f;
  }
  .mbsc-windows.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-danger .mbsc-toast-msg {
    background: #f5504e;
  }
  .mbsc-windows.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-warning .mbsc-toast-msg {
    background: #f8b042;
  }
  .mbsc-windows.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-info .mbsc-toast-msg {
    background: #5bb7c5;
  }
  .mbsc-windows.mbsc-snackbar .mbsc-snackbar-btn {
    color: #0078d7;
  }
  .mbsc-windows.mbsc-toast .mbsc-toast-msg {
    background: #666;
    color: #fff;
  }
  .mbsc-windows.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-windows.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-windows.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-windows.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-windows.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-windows.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-windows.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-windows.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-windows.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-windows.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-windows.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-windows.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #efeff4;
  }
  .mbsc-windows.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn {
    color: #000;
  }
  .mbsc-windows .mbsc-divider,
  .mbsc-windows .mbsc-form-group-title {
    font-size: 1.5em;
    padding: 0 0.666667em;
    line-height: 2em;
  }
  .mbsc-windows .mbsc-checkbox-box,
  .mbsc-windows .mbsc-radio-box {
    margin-top: -0.6875em;
  }
  .mbsc-windows .mbsc-checkbox input:disabled + .mbsc-checkbox-box,
  .mbsc-windows .mbsc-checkbox input:disabled ~ .mbsc-label,
  .mbsc-windows .mbsc-checkbox input:disabled ~ .mbsc-desc,
  .mbsc-windows .mbsc-radio input:disabled + .mbsc-radio-box,
  .mbsc-windows .mbsc-radio input:disabled ~ .mbsc-label,
  .mbsc-windows .mbsc-radio input:disabled ~ .mbsc-desc,
  .mbsc-windows .mbsc-switch input:disabled + .mbsc-switch-track,
  .mbsc-windows .mbsc-switch input:disabled ~ .mbsc-label,
  .mbsc-windows .mbsc-switch input:disabled ~ .mbsc-desc {
    opacity: 0.2;
  }
  .mbsc-windows.mbsc-form .mbsc-checkbox {
    padding: 1.125em 1em 1.125em 2.875em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-checkbox {
    padding: 1.125em 2.875em 1.125em 1em;
  }
  .mbsc-windows .mbsc-checkbox-box {
    width: 1.3125em;
    height: 1.3125em;
    left: 1em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-checkbox-box {
    left: auto;
    right: 1em;
  }
  .mbsc-windows .mbsc-checkbox-box:after {
    top: 16%;
    left: 10%;
    width: 0.875em;
    height: 0.475em;
  }
  .mbsc-windows .mbsc-checkbox-primary input:checked + .mbsc-checkbox-box {
    background: #3f97f6;
    border-color: #3f97f6;
  }
  .mbsc-windows .mbsc-checkbox-secondary input:checked + .mbsc-checkbox-box {
    background: #90979e;
    border-color: #90979e;
  }
  .mbsc-windows .mbsc-checkbox-success input:checked + .mbsc-checkbox-box {
    background: #43be5f;
    border-color: #43be5f;
  }
  .mbsc-windows .mbsc-checkbox-danger input:checked + .mbsc-checkbox-box {
    background: #f5504e;
    border-color: #f5504e;
  }
  .mbsc-windows .mbsc-checkbox-warning input:checked + .mbsc-checkbox-box {
    background: #f8b042;
    border-color: #f8b042;
  }
  .mbsc-windows .mbsc-checkbox-info input:checked + .mbsc-checkbox-box {
    background: #5bb7c5;
    border-color: #5bb7c5;
  }
  .mbsc-windows.mbsc-form .mbsc-radio {
    padding: 1.125em 3.25em 1.125em 1em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-radio {
    padding: 1.125em 1em 1.125em 3.25em;
  }
  .mbsc-windows .mbsc-radio-box {
    right: 1.125em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-radio-box {
    right: auto;
    left: 1.125em;
  }
  .mbsc-windows .mbsc-radio-primary .mbsc-radio-box:after {
    background: #3f97f6;
  }
  .mbsc-windows .mbsc-radio-secondary .mbsc-radio-box:after {
    background: #90979e;
  }
  .mbsc-windows .mbsc-radio-success .mbsc-radio-box:after {
    background: #43be5f;
  }
  .mbsc-windows .mbsc-radio-danger .mbsc-radio-box:after {
    background: #f5504e;
  }
  .mbsc-windows .mbsc-radio-warning .mbsc-radio-box:after {
    background: #f8b042;
  }
  .mbsc-windows .mbsc-radio-info .mbsc-radio-box:after {
    background: #5bb7c5;
  }
  .mbsc-windows .mbsc-btn {
    margin: 0.5em 0.25em;
    padding: 0.3125em 0.5em;
    border-radius: 0;
  }
  .mbsc-windows .mbsc-btn-ic {
    padding-right: 0.5em;
  }
  .mbsc-windows .mbsc-btn-icon-only .mbsc-btn-ic {
    padding: 0;
  }
  .mbsc-windows .mbsc-btn:disabled {
    opacity: 0.3;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn-flat:not(:disabled):hover {
    opacity: 0.7;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-flat.mbsc-active:not(:disabled) {
    opacity: 1;
  }
  .mbsc-windows .mbsc-btn-group,
  .mbsc-windows .mbsc-btn-group-justified {
    margin: 0.5em 0.75em;
  }
  .mbsc-windows .mbsc-btn-group-block {
    margin: 0.5em 1em;
  }
  .mbsc-windows .mbsc-btn-primary.mbsc-btn {
    color: #fff;
    background: #3f97f6;
    border-color: #3f97f6;
  }
  .mbsc-windows .mbsc-btn-primary.mbsc-btn.mbsc-btn-flat {
    color: #3f97f6;
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows .mbsc-btn-primary.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(63, 151, 246, 0.25);
  }
  .mbsc-windows .mbsc-btn-secondary.mbsc-btn {
    color: #fff;
    background: #90979e;
    border-color: #90979e;
  }
  .mbsc-windows .mbsc-btn-secondary.mbsc-btn.mbsc-btn-flat {
    color: #90979e;
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows .mbsc-btn-secondary.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(144, 151, 158, 0.25);
  }
  .mbsc-windows .mbsc-btn-success.mbsc-btn {
    color: #fff;
    background: #43be5f;
    border-color: #43be5f;
  }
  .mbsc-windows .mbsc-btn-success.mbsc-btn.mbsc-btn-flat {
    color: #43be5f;
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows .mbsc-btn-success.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(67, 190, 95, 0.25);
  }
  .mbsc-windows .mbsc-btn-danger.mbsc-btn {
    color: #fff;
    background: #f5504e;
    border-color: #f5504e;
  }
  .mbsc-windows .mbsc-btn-danger.mbsc-btn.mbsc-btn-flat {
    color: #f5504e;
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows .mbsc-btn-danger.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(245, 80, 78, 0.25);
  }
  .mbsc-windows .mbsc-btn-warning.mbsc-btn {
    color: #fff;
    background: #f8b042;
    border-color: #f8b042;
  }
  .mbsc-windows .mbsc-btn-warning.mbsc-btn.mbsc-btn-flat {
    color: #f8b042;
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows .mbsc-btn-warning.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(248, 176, 66, 0.25);
  }
  .mbsc-windows .mbsc-btn-info.mbsc-btn {
    color: #fff;
    background: #5bb7c5;
    border-color: #5bb7c5;
  }
  .mbsc-windows .mbsc-btn-info.mbsc-btn.mbsc-btn-flat {
    color: #5bb7c5;
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows .mbsc-btn-info.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(91, 183, 197, 0.25);
  }
  .mbsc-windows .mbsc-btn-light.mbsc-btn {
    color: #000;
    background: #bfbfbf;
    border-color: #bfbfbf;
  }
  .mbsc-windows .mbsc-btn-light.mbsc-btn.mbsc-btn-flat {
    color: #bfbfbf;
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows .mbsc-btn-light.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(191, 191, 191, 0.25);
  }
  .mbsc-windows .mbsc-btn-dark.mbsc-btn {
    color: #fff;
    background: #47494a;
    border-color: #47494a;
  }
  .mbsc-windows .mbsc-btn-dark.mbsc-btn.mbsc-btn-flat {
    color: #47494a;
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows .mbsc-btn-dark.mbsc-btn.mbsc-btn-flat.mbsc-active {
    background: rgba(71, 73, 74, 0.25);
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-active {
    opacity: 1;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-primary {
    border-color: #3f97f6;
    color: #3f97f6;
    background: transparent;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-primary.mbsc-active {
    background: #3f97f6;
    color: #fff;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-secondary {
    border-color: #90979e;
    color: #90979e;
    background: transparent;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-secondary.mbsc-active {
    background: #90979e;
    color: #fff;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-success {
    border-color: #43be5f;
    color: #43be5f;
    background: transparent;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-success.mbsc-active {
    background: #43be5f;
    color: #fff;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-danger {
    border-color: #f5504e;
    color: #f5504e;
    background: transparent;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-danger.mbsc-active {
    background: #f5504e;
    color: #fff;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-warning {
    border-color: #f8b042;
    color: #f8b042;
    background: transparent;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-warning.mbsc-active {
    background: #f8b042;
    color: #fff;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-info {
    border-color: #5bb7c5;
    color: #5bb7c5;
    background: transparent;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-info.mbsc-active {
    background: #5bb7c5;
    color: #fff;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-light {
    border-color: #bfbfbf;
    color: #bfbfbf;
    background: transparent;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-light.mbsc-active {
    background: #bfbfbf;
    color: #47494a;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-dark {
    border-color: #47494a;
    color: #47494a;
    background: transparent;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-btn-dark.mbsc-active {
    background: #47494a;
    color: #fff;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn-primary.mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
    border-color: #0964c6;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn-secondary.mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
    border-color: #5d646b;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn-success.mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
    border-color: #287339;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn-danger.mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
    border-color: #d10e0c;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn-warning.mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
    border-color: #cc7f08;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn-info.mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
    border-color: #317e89;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn-light.mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
    border-color: #999;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn-dark.mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
    border-color: #000;
  }
  .mbsc-windows .mbsc-btn-primary.mbsc-btn.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
    background: #0964c6;
    border-color: #0964c6;
  }
  .mbsc-windows .mbsc-btn-secondary.mbsc-btn.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
    background: #5d646b;
    border-color: #5d646b;
  }
  .mbsc-windows .mbsc-btn-success.mbsc-btn.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
    background: #287339;
    border-color: #287339;
  }
  .mbsc-windows .mbsc-btn-danger.mbsc-btn.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
    background: #d10e0c;
    border-color: #d10e0c;
  }
  .mbsc-windows .mbsc-btn-warning.mbsc-btn.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
    background: #cc7f08;
    border-color: #cc7f08;
  }
  .mbsc-windows .mbsc-btn-info.mbsc-btn.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
    background: #317e89;
    border-color: #317e89;
  }
  .mbsc-windows .mbsc-btn-light.mbsc-btn.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
    background: #999;
    border-color: #999;
  }
  .mbsc-windows .mbsc-btn-dark.mbsc-btn.mbsc-active:not(.mbsc-btn-flat):not(:disabled) {
    background: #000;
    border-color: #000;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-flat {
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows.mbsc-form .mbsc-switch {
    padding: 1.125em 5.875em 1.125em 1em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-switch {
    padding: 1.125em 1em 1.125em 5.875em;
  }
  .mbsc-windows .mbsc-switch-track {
    right: 1.125em;
    width: 2.75em;
    height: 1.25em;
    margin-top: -0.645em;
    padding: 0 0.5em;
    border-radius: 10px;
  }
  .mbsc-windows.mbsc-rtl .mbsc-switch-track {
    right: auto;
    left: 1.125em;
  }
  .mbsc-windows .mbsc-switch-track .mbsc-switch-handle {
    z-index: 1;
    top: 50%;
    left: 50%;
    right: auto;
    height: 0.625em;
    width: 0.625em;
    border-radius: 10px;
    margin: -0.3125em 0 0 -0.3125em;
  }
  .mbsc-windows .mbsc-switch-primary.mbsc-switch input:checked + .mbsc-switch-track {
    background: #3f97f6;
    border-color: #3f97f6;
  }
  .mbsc-windows .mbsc-switch-secondary.mbsc-switch input:checked + .mbsc-switch-track {
    background: #90979e;
    border-color: #90979e;
  }
  .mbsc-windows .mbsc-switch-success.mbsc-switch input:checked + .mbsc-switch-track {
    background: #43be5f;
    border-color: #43be5f;
  }
  .mbsc-windows .mbsc-switch-danger.mbsc-switch input:checked + .mbsc-switch-track {
    background: #f5504e;
    border-color: #f5504e;
  }
  .mbsc-windows .mbsc-switch-warning.mbsc-switch input:checked + .mbsc-switch-track {
    background: #f8b042;
    border-color: #f8b042;
  }
  .mbsc-windows .mbsc-switch-info.mbsc-switch input:checked + .mbsc-switch-track {
    background: #5bb7c5;
    border-color: #5bb7c5;
  }
  .mbsc-windows .mbsc-segmented {
    padding: 0.75em 1em;
  }
  .mbsc-windows .mbsc-segmented-content {
    height: 2.285715em;
    line-height: 2.285715em;
    padding: 0 0.4285715em;
  }
  .mbsc-windows .mbsc-segmented-content.mbsc-stepper-val {
    box-sizing: border-box;
  }
  .mbsc-windows .mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item:last-child,
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-plus,
  .mbsc-windows .mbsc-stepper-val-left .mbsc-segmented input + .mbsc-segmented-item,
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control {
    border: 0;
  }
  .mbsc-windows .mbsc-stepper {
    margin-top: -1em;
  }
  .mbsc-windows .mbsc-stepper-cont {
    padding: 1.75em 13em 1.75em 1em;
  }
  .mbsc-windows.mbsc-rtl .mbsc-stepper-cont {
    padding: 1.75em 1em 1.75em 13em;
  }
  .mbsc-windows .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-windows .mbsc-stepper .mbsc-segmented-item.mbsc-disabled .mbsc-segmented-content,
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    opacity: 0.3;
  }
  .mbsc-windows .mbsc-segmented .mbsc-segmented-primary.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
  .mbsc-windows
    .mbsc-segmented
    .mbsc-segmented-primary.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #3f97f6;
  }
  .mbsc-windows .mbsc-segmented .mbsc-segmented-secondary.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
  .mbsc-windows
    .mbsc-segmented
    .mbsc-segmented-secondary.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #90979e;
  }
  .mbsc-windows .mbsc-segmented .mbsc-segmented-success.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
  .mbsc-windows
    .mbsc-segmented
    .mbsc-segmented-success.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #43be5f;
  }
  .mbsc-windows .mbsc-segmented .mbsc-segmented-danger.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
  .mbsc-windows
    .mbsc-segmented
    .mbsc-segmented-danger.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #f5504e;
  }
  .mbsc-windows .mbsc-segmented .mbsc-segmented-warning.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
  .mbsc-windows
    .mbsc-segmented
    .mbsc-segmented-warning.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #f8b042;
  }
  .mbsc-windows .mbsc-segmented .mbsc-segmented-info.mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
  .mbsc-windows
    .mbsc-segmented
    .mbsc-segmented-info.mbsc-segmented-item
    input.mbsc-control:checked
    + .mbsc-segmented-content {
    background: #5bb7c5;
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-segmented-primary.mbsc-segmented-item:hover
    input:not(:disabled):not(.mbsc-active)
    + .mbsc-segmented-content {
    background: rgba(63, 151, 246, 0.25);
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-segmented-secondary.mbsc-segmented-item:hover
    input:not(:disabled):not(.mbsc-active)
    + .mbsc-segmented-content {
    background: rgba(144, 151, 158, 0.25);
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-segmented-success.mbsc-segmented-item:hover
    input:not(:disabled):not(.mbsc-active)
    + .mbsc-segmented-content {
    background: rgba(67, 190, 95, 0.25);
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-segmented-danger.mbsc-segmented-item:hover
    input:not(:disabled):not(.mbsc-active)
    + .mbsc-segmented-content {
    background: rgba(245, 80, 78, 0.25);
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-segmented-warning.mbsc-segmented-item:hover
    input:not(:disabled):not(.mbsc-active)
    + .mbsc-segmented-content {
    background: rgba(248, 176, 66, 0.25);
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-segmented-info.mbsc-segmented-item:hover
    input:not(:disabled):not(.mbsc-active)
    + .mbsc-segmented-content {
    background: rgba(91, 183, 197, 0.25);
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-stepper-primary
    .mbsc-segmented
    .mbsc-segmented-item:not(.mbsc-disabled):hover
    .mbsc-segmented-content {
    background: #0e7df4;
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-stepper-secondary
    .mbsc-segmented
    .mbsc-segmented-item:not(.mbsc-disabled):hover
    .mbsc-segmented-content {
    background: #757e86;
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-stepper-success
    .mbsc-segmented
    .mbsc-segmented-item:not(.mbsc-disabled):hover
    .mbsc-segmented-content {
    background: #35994c;
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-stepper-danger
    .mbsc-segmented
    .mbsc-segmented-item:not(.mbsc-disabled):hover
    .mbsc-segmented-content {
    background: #f2201e;
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-stepper-warning
    .mbsc-segmented
    .mbsc-segmented-item:not(.mbsc-disabled):hover
    .mbsc-segmented-content {
    background: #f69b11;
  }
  .mbsc-windows.mbsc-no-touch
    .mbsc-stepper-info
    .mbsc-segmented
    .mbsc-segmented-item:not(.mbsc-disabled):hover
    .mbsc-segmented-content {
    background: #3ea0af;
  }
  .mbsc-windows .mbsc-stepper-primary .mbsc-segmented-content.mbsc-stepper-val {
    border-color: #3f97f6;
  }
  .mbsc-windows .mbsc-stepper-primary .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control .mbsc-segmented-content {
    background: #3f97f6;
  }
  .mbsc-windows
    .mbsc-stepper-primary
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled)
    .mbsc-segmented-content {
    background: #0858ad;
  }
  .mbsc-windows .mbsc-stepper-secondary .mbsc-segmented-content.mbsc-stepper-val {
    border-color: #90979e;
  }
  .mbsc-windows
    .mbsc-stepper-secondary
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control
    .mbsc-segmented-content {
    background: #90979e;
  }
  .mbsc-windows
    .mbsc-stepper-secondary
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled)
    .mbsc-segmented-content {
    background: #51575d;
  }
  .mbsc-windows .mbsc-stepper-success .mbsc-segmented-content.mbsc-stepper-val {
    border-color: #43be5f;
  }
  .mbsc-windows .mbsc-stepper-success .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control .mbsc-segmented-content {
    background: #43be5f;
  }
  .mbsc-windows
    .mbsc-stepper-success
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled)
    .mbsc-segmented-content {
    background: #216030;
  }
  .mbsc-windows .mbsc-stepper-danger .mbsc-segmented-content.mbsc-stepper-val {
    border-color: #f5504e;
  }
  .mbsc-windows .mbsc-stepper-danger .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control .mbsc-segmented-content {
    background: #f5504e;
  }
  .mbsc-windows
    .mbsc-stepper-danger
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled)
    .mbsc-segmented-content {
    background: #b90d0a;
  }
  .mbsc-windows .mbsc-stepper-warning .mbsc-segmented-content.mbsc-stepper-val {
    border-color: #f8b042;
  }
  .mbsc-windows .mbsc-stepper-warning .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control .mbsc-segmented-content {
    background: #f8b042;
  }
  .mbsc-windows
    .mbsc-stepper-warning
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled)
    .mbsc-segmented-content {
    background: #b46f07;
  }
  .mbsc-windows .mbsc-stepper-info .mbsc-segmented-content.mbsc-stepper-val {
    border-color: #5bb7c5;
  }
  .mbsc-windows .mbsc-stepper-info .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control .mbsc-segmented-content {
    background: #5bb7c5;
  }
  .mbsc-windows
    .mbsc-stepper-info
    .mbsc-segmented
    .mbsc-segmented-item.mbsc-stepper-control.mbsc-active:not(.mbsc-disabled)
    .mbsc-segmented-content {
    background: #2a6c77;
  }
  .mbsc-windows.mbsc-form {
    background-color: #fff;
    color: #262626;
  }
  .mbsc-windows.mbsc-form *::-moz-selection,
  .mbsc-windows.mbsc-form *::selection {
    color: #fff;
    background: rgba(0, 120, 215, 0.9);
  }
  .mbsc-windows .mbsc-checkbox-box,
  .mbsc-windows .mbsc-radio-box {
    border: 0.125em solid #262626;
  }
  .mbsc-windows .mbsc-checkbox-box:after {
    border: 0.125em solid #e5f1fb;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-windows .mbsc-checkbox input:checked + .mbsc-checkbox-box {
    background: #0078d7;
    border-color: #0078d7;
  }
  .mbsc-windows .mbsc-checkbox input.mbsc-active + .mbsc-checkbox-box {
    border-color: #666;
    background: #666;
  }
  .mbsc-windows .mbsc-radio-box:after {
    background: #262626;
  }
  .mbsc-windows .mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #0078d7;
  }
  .mbsc-windows .mbsc-radio input.mbsc-active + .mbsc-radio-box {
    border-color: rgba(38, 38, 38, 0.6);
  }
  .mbsc-windows .mbsc-radio input.mbsc-active + .mbsc-radio-box:after {
    background: rgba(38, 38, 38, 0.6);
  }
  .mbsc-windows .mbsc-btn {
    background: #ccc;
    border: 0.125em solid #ccc;
    color: #262626;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
    border-color: #7a7a7a;
  }
  .mbsc-windows .mbsc-btn.mbsc-active {
    border-color: #7a7a7a;
    background: #7a7a7a;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn-flat:not(:disabled):hover {
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-flat.mbsc-active:not(:disabled) {
    background: rgba(204, 204, 204, 0.25);
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline {
    background: transparent;
    border: 0.125em solid #999;
    color: #999;
  }
  .mbsc-windows .mbsc-btn.mbsc-btn-outline.mbsc-active {
    background: #7f7f7f;
    border-color: #7f7f7f;
    color: #fff;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-btn.mbsc-btn-outline:not(:disabled):hover {
    border-color: #7f7f7f;
  }
  .mbsc-windows .mbsc-switch-track {
    border: 2px solid #262626;
  }
  .mbsc-windows .mbsc-switch-track .mbsc-switch-handle {
    background: #262626;
  }
  .mbsc-windows .mbsc-switch input:checked + .mbsc-switch-track {
    background: #0078d7;
    border-color: #0078d7;
  }
  .mbsc-windows .mbsc-switch input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #f2f2f2;
  }
  .mbsc-windows .mbsc-switch input:checked:disabled + .mbsc-switch-track {
    background: #262626;
    border-color: #262626;
  }
  .mbsc-windows.mbsc-form .mbsc-switch input.mbsc-active + .mbsc-switch-track {
    background: #666;
    border-color: #666;
  }
  .mbsc-windows.mbsc-form .mbsc-switch input.mbsc-active + .mbsc-switch-track .mbsc-switch-handle {
    background: #f2f2f2;
  }
  .mbsc-windows .mbsc-segmented-content {
    color: #262626;
    background: #ccc;
  }
  .mbsc-windows .mbsc-segmented-content.mbsc-stepper-val {
    background: transparent;
    border-top: 2px solid #ccc;
    border-bottom: 2px solid #ccc;
  }
  .mbsc-windows.mbsc-ltr .mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item {
    border-right: 1px solid #c2c2c2;
  }
  .mbsc-windows.mbsc-rtl .mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item {
    border-left: 1px solid #c2c2c2;
  }
  .mbsc-windows .mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item:last-child,
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-plus,
  .mbsc-windows .mbsc-stepper-val-left .mbsc-segmented input + .mbsc-segmented-item,
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control {
    border: 0;
  }
  .mbsc-windows.mbsc-ltr .mbsc-segmented .mbsc-stepper-control + .mbsc-stepper-control {
    border-left: 1px solid #c2c2c2;
  }
  .mbsc-windows.mbsc-rtl .mbsc-segmented .mbsc-stepper-control + .mbsc-stepper-control {
    border-right: 1px solid #c2c2c2;
  }
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #262626;
    color: #fff;
  }
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content,
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: #7a7a7a;
  }
  .mbsc-windows .mbsc-stepper .mbsc-segmented-item.mbsc-active .mbsc-segmented-content {
    background: #7a7a7a;
  }
  .mbsc-windows .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-windows .mbsc-stepper .mbsc-segmented-item.mbsc-disabled .mbsc-segmented-content,
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    background: #ccc;
  }
  .mbsc-windows .mbsc-stepper input:disabled {
    color: #c7c7c7;
    -webkit-text-fill-color: #c7c7c7;
  }
  .mbsc-windows .mbsc-segmented .mbsc-segmented-item input:disabled:checked + .mbsc-segmented-content {
    color: #fff;
    background: #c7c7c7;
  }
  .mbsc-windows .mbsc-stepper .mbsc-active.mbsc-disabled .mbsc-segmented-content {
    background: transparent;
  }
  .mbsc-windows .mbsc-stepper input {
    color: #262626;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: #d9d8d8;
  }
  .mbsc-windows.mbsc-card.mbsc-form {
    border-radius: 0;
  }
  .mbsc-windows .mbsc-card-footer .mbsc-btn {
    margin-top: -0.625em;
    margin-bottom: -0.625em;
  }
  .mbsc-windows.mbsc-card.mbsc-form {
    background: #f8f8f8;
    box-shadow: 0 0 1px 1px #e5e5e5;
  }
  .mbsc-windows.mbsc-card .mbsc-lv-item {
    background: #f8f8f8;
  }
  .mbsc-windows .mbsc-card-footer {
    border-color: #e5e5e5;
  }
  .mbsc-color {
    font-size: 16px;
  }
  .mbsc-color-scroll-cont {
    padding: 0.125em 0 0.1875em 0;
    overflow: hidden;
    box-sizing: content-box;
  }
  .mbsc-color-cont {
    display: table;
    table-layout: fixed;
    margin: 0 auto;
  }
  .mbsc-color-item-c {
    position: relative;
    display: table-cell;
    padding: 0.18755em;
    max-width: 4.25em;
    max-height: 4.25em;
  }
  .mbsc-color-clear-item,
  .mbsc-color-item {
    position: relative;
    min-width: 3.5em;
    min-height: 3.5em;
    margin: 0.1875em;
    cursor: pointer;
  }
  .mbsc-color-clear-item {
    background: rgba(223, 222, 222, 0.6);
  }
  .mbsc-color-clear-cross {
    position: absolute;
    height: 100%;
    width: 2px;
    top: 0;
    left: 50%;
    margin-left: -1px;
    background: red;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .mbsc-color-row {
    padding: 0 0.5em;
  }
  .mbsc-color.mbsc-fr-bubble .mbsc-fr-w,
  .mbsc-color.mbsc-fr-center .mbsc-fr-w {
    max-width: 286px;
    min-width: 280px;
  }
  .mbsc-color .mbsc-fr-c {
    padding: 0.25em 0;
  }
  .mbsc-color-vertical .mbsc-color-cont {
    width: 100%;
    height: 100%;
    white-space: normal;
    text-align: start;
  }
  .mbsc-color-vertical .mbsc-color-item-c {
    display: inline-block;
    vertical-align: top;
    text-align: center;
  }
  .mbsc-color .mbsc-color-slider-cont.mbsc-progress {
    padding: 0;
  }
  .mbsc-color .mbsc-color-slider-cont .mbsc-progress-cont {
    padding: 1.5em 0;
  }
  .mbsc-color .mbsc-color-slider-cont .mbsc-progress-track {
    height: 0.25em;
    border: 0;
  }
  .mbsc-color .mbsc-color-slider-cont .mbsc-slider-handle-cont {
    top: 0.125em;
  }
  .mbsc-color .mbsc-color-slider-cont .mbsc-slider-step {
    display: none;
  }
  .mbsc-color-clear-item:before,
  .mbsc-color-item:before {
    position: absolute;
    width: 1em;
    height: 1em;
    top: 50%;
    left: 50%;
    margin-top: -0.5em;
    margin-left: -0.5em;
    color: #f7f7f7;
    display: none;
    font-size: 1.5em;
  }
  .mbsc-color-clear-item:before {
    display: block;
  }
  .mbsc-color-clear-item,
  .mbsc-color-input-item-close:before,
  .mbsc-color-selected::before {
    display: block;
    text-shadow: 0 0 0.125em #000;
  }
  .mbsc-color-refine-preview {
    display: table;
    padding: 0 0.25em;
  }
  .mbsc-color-preview-cont {
    border-top: 1px solid #ccc;
    overflow: hidden;
    padding-top: 0.25em;
  }
  .mbsc-color-preview-item-c {
    display: table-cell;
  }
  .mbsc-color-preview-item {
    min-width: 1.875em;
    width: 1.875em;
    height: 1.875em;
    margin: 0.25em;
    cursor: pointer;
    background: transparent;
    box-sizing: border-box;
  }
  .mbsc-color-preview-item-empty {
    border: 1px dashed;
    border-color: #bbb;
  }
  .mbsc-color-input {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    -ms-flex-flow: row wrap;
    flex-flow: row wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .mbsc-color-input:empty:not(:focus):before {
    content: attr(data-placeholder);
    position: absolute;
    top: 50%;
    overflow: hidden;
    margin-top: -1em;
    color: #ccc;
    font-size: 1em;
    line-height: 2em;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .mbsc-color-input:not(.mbsc-comp) {
    border: 1px solid #aaa;
    min-height: 2.125em;
    width: 100%;
    padding: 0.125em;
    box-sizing: border-box;
  }
  .mbsc-color-input-item {
    display: inline-block;
    -webkit-box-flex: 1;
    -ms-flex: auto;
    flex: auto;
    position: relative;
    margin: 0.125em;
    height: 1.5em;
    width: 4.0625em;
    vertical-align: middle;
  }
  .mbsc-color-input-hdn {
    position: absolute;
    height: 1px !important;
    width: 1px !important;
    margin: 0 !important;
    padding: 0 !important;
    left: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  .mbsc-color-input-item-close {
    position: absolute;
    top: 10%;
    right: 0;
    height: 80%;
    width: 1.75em;
    cursor: pointer;
    color: #fff;
    border-left: 1px solid #fff;
    text-align: center;
  }
  .mbsc-color-input-item-close:before {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 1.5em;
    height: 1.5em;
    line-height: 1.5em;
    margin: -0.75em 0 0 -0.75em;
  }
  .mbsc-form .mbsc-color-input {
    margin: 0;
    padding: 0;
    border: 0;
    border-radius: 0;
    outline: 0;
    font-family: inherit;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    box-sizing: border-box;
  }
  .ui-input-text .mbsc-color-input {
    border-color: transparent;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap.mbsc-color.mbsc-fr-bubble .mbsc-fr-w,
  .mbsc-bootstrap.mbsc-color.mbsc-fr-center .mbsc-fr-w {
    max-width: 286px;
    min-width: 280px;
  }
  .mbsc-bootstrap.mbsc-color .mbsc-fr-w {
    min-width: 230px;
  }
  .mbsc-bootstrap.mbsc-color .mbsc-ic-material-check:before {
    margin-top: -0.65em;
  }
  .mbsc-bootstrap.mbsc-color .mbsc-fr-c {
    font-size: 16px;
  }
  .mbsc-bootstrap.mbsc-color .mbsc-color-item-c {
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-color-active {
    border-radius: 0;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-color-active .mbsc-color-item,
  .mbsc-bootstrap .mbsc-color-active .mbsc-color-preview-item {
    box-shadow: inset 0 0 0 0.1875em #38c;
  }
  .mbsc-bootstrap.mbsc-color .mbsc-slider-handle {
    top: 50%;
    width: 1em;
    height: 1em;
    background: #337ab7;
    transition: transform 0.2s ease-in-out;
  }
  .mbsc-bootstrap.mbsc-color .mbsc-slider .mbsc-input-wrap {
    padding: 0 0.75em;
  }
  .mbsc-bootstrap.mbsc-color .mbsc-color-slider-cont .mbsc-progress-track {
    background: #ccc;
  }
  .mbsc-ios.mbsc-color.mbsc-fr-bubble .mbsc-fr-w,
  .mbsc-ios.mbsc-color.mbsc-fr-center .mbsc-fr-w {
    max-width: 286px;
    min-width: 280px;
  }
  .mbsc-ios.mbsc-color .mbsc-fr-c {
    padding: 0.25em 0;
  }
  .mbsc-ios .mbsc-color-active,
  .mbsc-ios.mbsc-no-touch .mbsc-color-item-c:hover {
    border-radius: 3em;
  }
  .mbsc-ios .mbsc-color-clear-item,
  .mbsc-ios .mbsc-color-item {
    border-radius: 1.75em;
  }
  .mbsc-ios.mbsc-color .mbsc-fr-c {
    font-size: 16px;
  }
  .mbsc-ios .mbsc-fr-center .mbsc-color-preview-cont {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
  .mbsc-ios .mbsc-color-preview-cont {
    bottom: 2.8125em;
  }
  .mbsc-ios.mbsc-color .mbsc-slider-step {
    height: 0.25em;
    margin: 0;
  }
  .mbsc-ios .mbsc-color-slider-cont.mbsc-progress {
    border: 0;
    padding: 0 0.5em;
  }
  .mbsc-ios .mbsc-color-slider-cont.mbsc-slider {
    background: transparent;
  }
  .mbsc-ios .mbsc-color-slider-cont.mbsc-slider:before,
  .mbsc-ios .mbsc-color-slider-cont.mbsc-slider:after {
    display: none;
  }
  .mbsc-ios .mbsc-color-slider-cont .mbsc-progress-cont {
    padding: 1.5em 0;
  }
  .mbsc-ios .mbsc-color-preview-item {
    border-radius: 1em;
    margin: 0.25em;
  }
  .mbsc-ios .mbsc-color-refine-preview {
    padding: 0.25em;
  }
  .mbsc-ios.mbsc-form .mbsc-color-input {
    height: auto;
    min-height: 2.75em;
    padding: 0.1875em 0.8125em;
    background: transparent;
    text-indent: 0.1875em;
  }
  .mbsc-ios.mbsc-form .mbsc-color-input-item {
    height: 2em;
    margin: 0.1875em;
    border-radius: 0.125em;
  }
  .mbsc-ios .mbsc-label-stacked .mbsc-color-input,
  .mbsc-ios .mbsc-label-floating .mbsc-color-input {
    min-height: 3.75em;
    padding-top: 1.5em;
  }
  .mbsc-ios .mbsc-label-stacked .mbsc-color-input-item,
  .mbsc-ios .mbsc-label-floating .mbsc-color-input-item {
    height: 1.6875em;
  }
  .mbsc-ios .mbsc-color-active,
  .mbsc-ios.mbsc-no-touch .mbsc-color-item-c:hover {
    box-shadow: inset 0 0 0 0.1875em #007bff;
  }
  .mbsc-material.mbsc-color.mbsc-fr-bubble .mbsc-fr-w,
  .mbsc-material.mbsc-color.mbsc-fr-center .mbsc-fr-w {
    max-width: 248px;
    min-width: 246px;
  }
  .mbsc-material.mbsc-color .mbsc-fr-c {
    padding: 0.5em;
  }
  .mbsc-material .mbsc-color-clear-item,
  .mbsc-material .mbsc-color-item {
    margin: 0;
    border: 0;
    box-shadow: none;
    overflow: hidden;
  }
  .mbsc-material .mbsc-color-clear-cross {
    height: 140%;
    top: -11px;
    left: 50%;
  }
  .mbsc-material .mbsc-color-active {
    border-radius: 0;
    box-shadow: none;
  }
  .mbsc-material .mbsc-color-row {
    padding: 0;
  }
  .mbsc-material .mbsc-color-item-c {
    padding: 0;
    border: 1px solid transparent;
  }
  .mbsc-material .mbsc-color-preview-item {
    margin: 0.25em 0.125em;
  }
  .mbsc-material.mbsc-color .mbsc-color-slider-cont .mbsc-slider-handle-cont {
    top: 0.15625em;
  }
  .mbsc-material.mbsc-form .mbsc-color-input {
    height: auto;
    min-height: 2.125em;
    padding: 0 0 1px 0;
    font-size: 1em;
  }
  .mbsc-material.mbsc-form .mbsc-color-input-item {
    margin: 0.25em 0.125em 0 0.125em;
  }
  .mbsc-material .mbsc-color-item:before {
    color: #eee;
  }
  .mbsc-material.mbsc-form .mbsc-color-input {
    background-color: transparent;
    border-bottom: 1px solid #6d6d6d;
    color: #1e1e1e;
  }
  .mbsc-mobiscroll .mbsc-color-preview-item {
    border-radius: 1em;
  }
  .mbsc-mobiscroll .mbsc-color-clear-item,
  .mbsc-mobiscroll .mbsc-color-item {
    border-radius: 1.75em;
  }
  .mbsc-mobiscroll .mbsc-color-active,
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-color-item-c:hover {
    border-radius: 3em;
  }
  .mbsc-mobiscroll .mbsc-color-preview-cont {
    padding: 0.25em 0 0 0.25em;
  }
  .mbsc-mobiscroll.mbsc-form .mbsc-color-input {
    height: auto;
    min-height: 2.125em;
    padding: 0 0 1px 0;
    background: transparent;
    font-size: 1em;
  }
  .mbsc-mobiscroll.mbsc-form .mbsc-color-input-item {
    margin: 0.25em 0.125em 0 0.125em;
    border-radius: 0.125em;
  }
  .mbsc-mobiscroll .mbsc-color-active,
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-color-item-c:hover {
    box-shadow: inset 0 0 0 0.1875em #4eccc4;
  }
  .mbsc-mobiscroll.mbsc-form .mbsc-color-input {
    border-bottom: 1px solid #787878;
    color: #1f1f1f;
  }
  .mbsc-windows.mbsc-color .mbsc-fr-w {
    min-width: 230px;
  }
  .mbsc-windows .mbsc-color-cont {
    padding: 0.25em 0;
  }
  .mbsc-windows .mbsc-color-active,
  .mbsc-windows.mbsc-no-touch .mbsc-color-item-c:hover {
    border-radius: 3em;
  }
  .mbsc-windows .mbsc-color-clear-item,
  .mbsc-windows .mbsc-color-item {
    border-radius: 1.75em;
  }
  .mbsc-windows .mbsc-color-preview-item {
    border-radius: 1em;
    margin: 0.25em;
  }
  .mbsc-windows .mbsc-color-input:empty:not(:focus):before {
    padding: 0 0.3125em;
  }
  .mbsc-windows.mbsc-form .mbsc-color-input {
    height: auto;
    min-height: 2em;
    padding: 0;
    font-size: 1em;
    background: transparent;
  }
  .mbsc-windows .mbsc-color-active,
  .mbsc-windows.mbsc-no-touch .mbsc-color-item-c:hover {
    box-shadow: inset 0 0 0 0.1875em #0078d7;
  }
  .mbsc-windows .mbsc-color-clear-item {
    background: #fff;
  }
  .mbsc-windows.mbsc-color .mbsc-color-slider-cont .mbsc-progress-track {
    background: #c2c2c2;
  }
  .mbsc-windows .mbsc-color-preview-item-empty,
  .mbsc-windows .mbsc-color-preview-cont {
    border-color: #b2b2b2;
  }
  .mbsc-windows.mbsc-form .mbsc-color-input {
    border: 0.125em solid #999;
    color: #262626;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  .mbsc-ios .mbsc-dt-whl-y .mbsc-sc-itm {
    min-width: 3.8em;
  }
  .mbsc-ios .mbsc-dt-whl-m .mbsc-sc-itm {
    text-align: left;
  }
  .mbsc-ios .mbsc-dt-whl-d .mbsc-sc-itm,
  .mbsc-ios .mbsc-dt-whl-h .mbsc-sc-itm,
  .mbsc-ios .mbsc-dt-whl-date .mbsc-sc-itm {
    text-align: right;
  }
  .mbsc-windows .mbsc-ltr .mbsc-dt-whl-m .mbsc-sc-itm,
  .mbsc-windows .mbsc-ltr .mbsc-dt-whl-date .mbsc-sc-itm {
    text-align: left;
  }
  .mbsc-windows .mbsc-rtl .mbsc-dt-whl-m .mbsc-sc-itm,
  .mbsc-windows .mbsc-rtl .mbsc-dt-whl-date .mbsc-sc-itm {
    text-align: right;
  }
  .mbsc-windows .mbsc-dt-whl-date .mbsc-sc-whl {
    min-width: 120px;
  }
  .mbsc-lv-cont {
    overflow: hidden;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -ms-touch-action: pan-y;
    user-select: none;
    touch-action: pan-y;
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    padding-top: 1px;
  }
  .mbsc-lv-cont .mbsc-lv {
    list-style: none;
    line-height: normal;
    margin: 0;
    padding: 0;
  }
  .mbsc-lv {
    display: none;
    position: relative;
  }
  .mbsc-lv-v {
    display: block;
  }
  .mbsc-lv-gr-title {
    position: relative;
    z-index: 3;
    display: block;
  }
  .mbsc-lv .mbsc-lv-item {
    position: relative;
    z-index: 2;
    list-style: none;
    display: block;
    box-sizing: border-box;
  }
  .mbsc-lv-item-actionable {
    cursor: pointer;
  }
  .mbsc-lv .mbsc-lv-item.mbsc-input {
    margin: -1px 0 0 0;
  }
  .mbsc-lv-item-actionable:before,
  .mbsc-lv-item.mbsc-selected:before {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    pointer-events: none;
    transition: background-color 0.2s ease-out;
  }
  .mbsc-cal-events-popup .mbsc-fr-persp {
    pointer-events: none;
  }
  .mbsc-cal-events-popup .mbsc-fr-popup {
    pointer-events: auto;
  }
  .mbsc-cal-events {
    box-sizing: content-box;
    font-size: 0.857143em;
    max-width: 100%;
  }
  .mbsc-cal-events-i {
    max-height: 24em;
  }
  .mbsc-cal-events-sc {
    position: relative;
    -webkit-backface-visibility: hidden;
  }
  .mbsc-cal-event {
    line-height: 1.666667em;
    list-style: none;
    position: relative;
    white-space: nowrap;
    cursor: pointer;
  }
  .mbsc-ltr .mbsc-cal-events .mbsc-cal-event {
    padding-right: 5em;
    text-align: left;
  }
  .mbsc-rtl .mbsc-cal-events .mbsc-cal-event {
    padding-left: 5em;
    text-align: right;
  }
  .mbsc-cal-event-color {
    position: absolute;
    top: 0;
    left: 0;
    width: 0.5em;
    height: 100%;
  }
  .mbsc-cal-event-text {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mbsc-cal-event-time {
    display: inline-block;
    width: 5em;
  }
  .mbsc-cal-event-dur {
    position: absolute;
    top: 50%;
    height: 2em;
    margin-top: -1em;
    line-height: 2em;
  }
  .mbsc-ltr .mbsc-cal-event-dur {
    right: 1em;
  }
  .mbsc-rtl .mbsc-cal-event-dur {
    left: 1em;
  }
  .mbsc-event-list {
    padding: 0;
    box-sizing: border-box;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }
  .mbsc-event-list-h {
    display: none;
  }
  .mbsc-event-list .mbsc-lv .mbsc-lv-gr-title {
    padding: 0.5em 1em;
    line-height: 1.5em;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    margin: 0;
  }
  .mbsc-event-list .mbsc-lv .mbsc-lv-item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    font-size: 0.875em;
    padding: 0.714286em 1em;
    cursor: pointer;
  }
  .mbsc-event-list .mbsc-lv .mbsc-lv-gr-title.mbsc-event-empty {
    text-transform: none;
    font-size: 1em;
  }
  .mbsc-ltr .mbsc-event-list {
    text-align: left;
  }
  .mbsc-rtl .mbsc-event-list {
    text-align: right;
  }
  .mbsc-event-color,
  .mbsc-event-time {
    -webkit-box-flex: 0;
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .mbsc-event-time {
    width: 6em;
    font-size: 0.714286em;
    line-height: 1.6;
    min-height: 3.2em;
    opacity: 0.7;
  }
  .mbsc-ltr .mbsc-event-time {
    text-align: right;
  }
  .mbsc-rtl .mbsc-event-time {
    text-align: left;
  }
  .mbsc-event-color {
    width: 1.5px;
    margin: -0.285714em 0.714286em;
  }
  .mbsc-event-txt {
    line-height: 1.142857;
  }
  .mbsc-ev-cal.mbsc-fr-md .mbsc-cal-hdr {
    padding: 0.5em;
  }
  .mbsc-ev-cal.mbsc-fr-md .mbsc-cal-scroll-c {
    margin-left: -1px;
  }
  .mbsc-ev-cal.mbsc-fr-md .mbsc-cal-day-i {
    min-height: 4em;
  }
  .mbsc-ev-cal.mbsc-fr-md .mbsc-cal-marks {
    top: 100%;
  }
  .mbsc-ev-cal.mbsc-fr-md .mbsc-cal-txt,
  .mbsc-ev-cal.mbsc-fr-md .mbsc-cal-txt-ph,
  .mbsc-ev-cal.mbsc-fr-md .mbsc-cal-txt-more {
    font-size: 0.75em;
    height: 1.5em;
    line-height: 1.5em;
    padding: 0 0.333334em;
    margin: 0 0.333334em 0.166667em 0.333334em;
  }
  .mbsc-cal-events-i:hover .mbsc-sc-bar-c {
    opacity: 1;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  .mbsc-bootstrap .mbsc-cal-c {
    padding: 4px 2px 2px 2px;
  }
  .mbsc-bootstrap .mbsc-cal-hdr {
    font-size: 18px;
    line-height: 40px;
  }
  .mbsc-bootstrap .mbsc-cal .mbsc-cal-btn {
    width: 40px;
    height: 40px;
    background: none;
    color: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 40px;
  }
  .mbsc-bootstrap .mbsc-cal-picker {
    max-width: none;
    display: block;
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    text-align: center;
  }
  .mbsc-bootstrap .mbsc-cal-table {
    border-spacing: 2px;
  }
  .mbsc-bootstrap .mbsc-cal-cell:focus {
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-cal-cell:focus .mbsc-cal-cell-i {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-cal-day-i {
    padding: 2px;
    line-height: 34px;
  }
  .mbsc-bootstrap .mbsc-cal-hide-diff .mbsc-cal-day-diff {
    background: none;
  }
  .mbsc-bootstrap .mbsc-cal-today.mbsc-selected {
    color: #fff !important;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-cal-weeks .mbsc-cal-days-c {
    padding-left: 26px;
  }
  .mbsc-bootstrap .mbsc-rtl .mbsc-cal-weeks .mbsc-cal-days-c {
    padding-right: 26px;
  }
  .mbsc-bootstrap .mbsc-cal-week-nr {
    width: 26px;
    font-weight: bold;
  }
  .mbsc-bootstrap .mbsc-cal-tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 4px 4px 0 4px;
    box-sizing: border-box;
  }
  .mbsc-bootstrap .mbsc-cal-tab {
    -webkit-box-flex: 1 0;
    -webkit-flex: 1 0;
    -ms-flex: 1 0;
    flex: 1 0;
    margin-bottom: -1px;
  }
  .mbsc-bootstrap .mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: 0;
  }
  .mbsc-bootstrap .mbsc-cal-has-txt .mbsc-cal-day-i {
    line-height: 30px;
    padding-top: 5px;
    padding-bottom: 15px;
  }
  .mbsc-bootstrap .mbsc-cal-marks {
    height: 6px;
    margin-top: -9px;
  }
  .mbsc-bootstrap .mbsc-cal-mark {
    width: 4px;
    height: 4px;
    margin: 1px;
    border-radius: 4px;
    background: #aaa;
  }
  .mbsc-bootstrap .mbsc-cal-txt {
    left: 1px;
    right: 1px;
    height: 15px;
    margin-top: -1px;
    font-size: 10px;
    line-height: 15px;
  }
  .mbsc-bootstrap.mbsc-fr-liq.mbsc-fr-center .mbsc-fr-popup {
    border: 0;
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-cal-events {
    padding: 5px;
    position: relative;
    max-width: none;
    width: 400px;
  }
  .mbsc-bootstrap .mbsc-cal-event-color {
    background: #fff;
  }
  .mbsc-ios.mbsc-lv-cont {
    margin-top: -1px;
  }
  .mbsc-ios .mbsc-lv-item {
    margin-top: -1px;
    padding: 1em;
    font-weight: normal;
    text-shadow: none;
  }
  .mbsc-ios .mbsc-lv-item:before,
  .mbsc-ios .mbsc-lv-item:after {
    content: "";
    position: absolute;
    right: 0;
    left: 1em;
  }
  .mbsc-ios .mbsc-lv-item:before {
    top: 0;
  }
  .mbsc-ios .mbsc-lv-item:after {
    bottom: 0;
  }
  .mbsc-ios .mbsc-lv-item:first-child:before,
  .mbsc-ios .mbsc-lv-item:last-child:after,
  .mbsc-ios .mbsc-lv-back:after {
    left: 0;
  }
  .mbsc-ios .mbsc-lv-gr-title {
    z-index: 4;
    margin-top: -1px;
    padding: 2.333334em 1.333334em 0.5em 1.333334em;
    font-size: 0.75em;
    line-height: 1.5em;
    text-transform: uppercase;
  }
  .mbsc-ios .mbsc-lv-gr-title:first-child {
    margin-top: 0;
    border-top: 0;
  }
  .mbsc-ios .mbsc-lv-item-actionable:before {
    transition: none;
  }
  .mbsc-ios.mbsc-no-touch .mbsc-lv-item-actionable:hover,
  .mbsc-ios .mbsc-lv-item.mbsc-selected {
    z-index: 3;
  }
  .mbsc-ios.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
  .mbsc-ios.mbsc-no-touch .mbsc-lv-item-actionable:hover:after,
  .mbsc-ios .mbsc-lv-item-actionable.mbsc-lv-item-active:before,
  .mbsc-ios .mbsc-lv-item-actionable.mbsc-lv-item-active:after,
  .mbsc-ios .mbsc-lv-item.mbsc-selected:before,
  .mbsc-ios .mbsc-lv-item.mbsc-selected:after {
    left: 0;
    right: 0;
  }
  .mbsc-ios .mbsc-lv {
    background: #ccc;
  }
  .mbsc-ios .mbsc-lv-item {
    background: #fff;
    border-color: #ccc;
    color: #000;
  }
  .mbsc-ios .mbsc-lv-item:before,
  .mbsc-ios .mbsc-lv-item:after {
    border-top: 1px solid #ccc;
  }
  .mbsc-ios .mbsc-lv-gr-title {
    background: #efeff4;
    color: #707070;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  .mbsc-ios.mbsc-no-touch .mbsc-lv-item-actionable:hover:before {
    background: rgba(0, 0, 0, 0.05);
  }
  .mbsc-ios .mbsc-lv-item-actionable.mbsc-lv-item-active:before {
    background: rgba(0, 0, 0, 0.15);
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-hdr {
    padding: 0.5em 0;
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-picker {
    padding-top: 1.875em;
    padding-bottom: 0;
  }
  .mbsc-ios.mbsc-cal-events-popup .mbsc-fr-arr-w {
    margin: -1px 0;
  }
  .mbsc-ios .mbsc-cal-events {
    width: 36.833334em;
  }
  .mbsc-ios .mbsc-cal-event {
    font-weight: bold;
    line-height: 1.833334em;
    margin-top: -1px;
    padding: 1.166667em;
  }
  .mbsc-ios .mbsc-cal-event:last-child:after {
    border: 0;
  }
  .mbsc-ios .mbsc-cal-event-color {
    top: 50%;
    margin-top: -0.25em;
    width: 0.5em;
    height: 0.5em;
    border-radius: 0.5em;
  }
  .mbsc-ios .mbsc-ltr .mbsc-cal-event-color {
    left: 6em;
  }
  .mbsc-ios .mbsc-rtl .mbsc-cal-event-color {
    right: 6em;
  }
  .mbsc-ios .mbsc-cal-event-dur,
  .mbsc-ios .mbsc-cal-event-time {
    font-weight: normal;
  }
  .mbsc-ios .mbsc-cal-event-time {
    width: 4.166667em;
  }
  .mbsc-ios .mbsc-ltr .mbsc-cal-event-time {
    margin-right: 1.916667em;
  }
  .mbsc-ios .mbsc-rtl .mbsc-cal-event-time {
    margin-left: 1.916667em;
  }
  .mbsc-ios .mbsc-event-list {
    margin: 0;
    padding: 0 0 2em 0;
    background: #efeff4;
  }
  .mbsc-ios .mbsc-event-list .mbsc-event-day {
    font-weight: bold;
  }
  .mbsc-ios .mbsc-event-empty {
    border-bottom: 0;
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-days > div {
    padding: 0 0.571428em;
    font-size: 0.875em;
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-slide {
    padding: 0;
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-day-date {
    margin: 0.1875em;
    margin-bottom: 0.375em;
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-has-labels .mbsc-cal-day-i {
    min-height: 5em;
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-marks {
    margin: -0.5em 0.875em 0 0.875em;
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-md .mbsc-ltr .mbsc-cal-days,
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-md .mbsc-ltr .mbsc-cal-day {
    text-align: right;
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-md .mbsc-rtl .mbsc-cal-days,
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-md .mbsc-rtl .mbsc-cal-day {
    text-align: left;
  }
  .mbsc-ios .mbsc-empty {
    color: #6e6e6e;
  }
  .mbsc-ios.mbsc-ev-cal .mbsc-fr-w {
    background: #f7f7f7;
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-picker {
    background: #f7f7f7;
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-scroll-c {
    background: #fff;
  }
  .mbsc-ios.mbsc-cal-events-popup .mbsc-fr-w {
    border: 1px solid #ccc;
  }
  .mbsc-ios.mbsc-cal-events-popup .mbsc-fr-arr {
    background: #fff;
    border: 1px solid #ccc;
  }
  .mbsc-ios .mbsc-cal-events-i {
    background: #fff;
  }
  .mbsc-ios .mbsc-cal-event-color {
    background: #94b8dd;
  }
  .mbsc-ios .mbsc-cal-event-dur,
  .mbsc-ios .mbsc-cal-event-time {
    color: #8e8e8e;
  }
  .mbsc-ios .mbsc-event-list {
    background: #efeff4;
  }
  .mbsc-ios.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    border-left: 1px solid #ccc;
  }
  .mbsc-material .mbsc-lv-item {
    overflow: hidden;
    padding: 1.1875em 1em;
    font-weight: normal;
  }
  .mbsc-material .mbsc-lv-gr-title {
    margin-top: -1px;
    font-size: 0.875em;
    font-weight: bold;
    padding: 0.5714em 1.14285em;
  }
  .mbsc-material .mbsc-lv {
    background: #b3b3b3;
  }
  .mbsc-material .mbsc-lv-item {
    background: #eee;
    color: #5b5b5b;
  }
  .mbsc-material .mbsc-lv-gr-title {
    background: #eee;
    color: #009688;
  }
  .mbsc-material.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
  .mbsc-material .mbsc-lv-item-actionable.mbsc-lv-item-active:before {
    background: rgba(0, 0, 0, 0.05);
  }
  .mbsc-material .mbsc-cal-events {
    width: 30em;
  }
  .mbsc-material .mbsc-cal-event {
    margin: 0.5em;
    padding: 0.5em;
  }
  .mbsc-material .mbsc-cal-event-color {
    z-index: -2;
    width: 100%;
    border-radius: 0.214286em;
  }
  .mbsc-material .mbsc-cal-event-time,
  .mbsc-material .mbsc-cal-event-text {
    position: relative;
    z-index: 1;
  }
  .mbsc-material .mbsc-event-list .mbsc-lv {
    background: none;
  }
  .mbsc-material .mbsc-event-list .mbsc-lv-item {
    margin: 0.5em;
    padding: 0.5em;
  }
  .mbsc-material .mbsc-ltr .mbsc-event-time {
    text-align: left;
  }
  .mbsc-material .mbsc-rtl .mbsc-event-time {
    text-align: right;
  }
  .mbsc-material .mbsc-event-color {
    position: absolute;
    width: auto;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: -2;
    margin: 0;
    border-radius: 0.214286em;
  }
  .mbsc-material .mbsc-event-txt {
    padding: 0 1em;
  }
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-c {
    padding: 0;
  }
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-days > div {
    padding: 0 0.714286em;
    font-size: 0.875em;
  }
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-picker {
    padding-top: 1.875em;
    padding-bottom: 0;
  }
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-day-date {
    margin: 0.230769em;
    margin-bottom: 0.461538em;
  }
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-has-labels .mbsc-cal-day-i {
    min-height: 5.375em;
  }
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-cal .mbsc-cal-day .mbsc-cal-marks {
    margin: -0.5em 1.125em 0 1.125em;
    -webkit-transform: none;
    transform: none;
  }
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-ltr .mbsc-cal-days,
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-ltr .mbsc-cal-day {
    text-align: left;
  }
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-rtl .mbsc-cal-days,
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-rtl .mbsc-cal-day {
    text-align: right;
  }
  .mbsc-material .mbsc-cal-events .mbsc-cal-event {
    color: #fff;
  }
  .mbsc-material .mbsc-cal-event-color {
    background: #009688;
  }
  .mbsc-material .mbsc-event-list .mbsc-lv-item {
    color: #fafafa;
  }
  .mbsc-material .mbsc-event-color {
    background: #009688;
  }
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-table {
    border-top: 1px solid #b3b3b3;
  }
  .mbsc-material.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    border-left: 1px solid #b3b3b3;
    border-bottom: 1px solid #b3b3b3;
  }
  .mbsc-mobiscroll.mbsc-lv-cont {
    font-family: arial, verdana, sans-serif;
    padding-top: 1px;
  }
  .mbsc-mobiscroll .mbsc-lv-item {
    padding: 1.25em;
    font-weight: normal;
  }
  .mbsc-mobiscroll .mbsc-lv-gr-title {
    padding: 0 1.25em;
    font-size: 0.75em;
    text-transform: uppercase;
    line-height: 2em;
    margin-top: -1px;
  }
  .mbsc-mobiscroll .mbsc-lv {
    background: #c7c7c7;
  }
  .mbsc-mobiscroll .mbsc-lv-item {
    background: #f7f7f7;
    color: #454545;
  }
  .mbsc-mobiscroll .mbsc-lv-gr-title {
    background: #4eccc4;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
  .mbsc-mobiscroll .mbsc-lv-item-active.mbsc-lv-item-actionable:before {
    background: rgba(78, 204, 196, 0.15);
  }
  .mbsc-mobiscroll.mbsc-cal-events-popup .mbsc-fr-w {
    border-radius: 0;
  }
  .mbsc-mobiscroll.mbsc-cal-events-popup .mbsc-fr-bubble-top .mbsc-fr-arr {
    border-bottom-color: transparent;
  }
  .mbsc-mobiscroll .mbsc-cal-events {
    width: 32em;
  }
  .mbsc-mobiscroll .mbsc-cal-event {
    padding: 1em 1em 1em 1.25em;
  }
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-c {
    padding: 0;
  }
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-days > div {
    border: 0;
    padding: 0 0.785714em;
    font-size: 0.875em;
  }
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-picker {
    padding-top: 1.875em;
    padding-bottom: 0;
  }
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-day-date {
    margin: 0.230769em;
    margin-bottom: 0.461538em;
  }
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-has-labels .mbsc-cal-day-i {
    min-height: 5.125em;
  }
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-marks {
    margin: -0.5em 1em 0 1em;
  }
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-ltr .mbsc-cal-days,
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-ltr .mbsc-cal-day {
    text-align: left;
  }
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-rtl .mbsc-cal-days,
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-rtl .mbsc-cal-day {
    text-align: right;
  }
  .mbsc-mobiscroll.mbsc-cal-events-popup .mbsc-fr-w {
    border: 0.375em solid #858585;
  }
  .mbsc-mobiscroll.mbsc-cal-events-popup .mbsc-fr-arr {
    background: #858585;
  }
  .mbsc-mobiscroll .mbsc-cal-event-color {
    background: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-cal-event-time,
  .mbsc-mobiscroll .mbsc-cal-event-dur {
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-event-list .mbsc-lv-gr-title {
    background: #f7f7f7;
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-event-color {
    background: rgba(69, 69, 69, 0.3);
  }
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-table {
    border-top: 1px solid #dedddd;
  }
  .mbsc-mobiscroll.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    border-left: 1px solid #dedddd;
    border-bottom: 1px solid #dedddd;
  }
  .mbsc-windows .mbsc-lv-item {
    padding: 1.25em;
    font-family: Segoe UI, arial, verdana, sans-serif;
    font-weight: normal;
    text-shadow: none;
  }
  .mbsc-windows .mbsc-lv-gr-title {
    font-size: 1.3125em;
    padding: 1em 0.625em 0 0.625em;
    line-height: 2em;
  }
  .mbsc-windows .mbsc-lv {
    background: #9b9b9b;
  }
  .mbsc-windows .mbsc-lv-item,
  .mbsc-windows .mbsc-lv-gr-title {
    background: #f2f2f2;
    color: #262626;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
  .mbsc-windows .mbsc-lv-item-actionable.mbsc-lv-item-active:before {
    background: rgba(0, 0, 0, 0.05);
  }
  .mbsc-windows .mbsc-cal-events {
    width: 31.5em;
  }
  .mbsc-windows .mbsc-cal-event {
    padding: 1em;
  }
  .mbsc-windows .mbsc-event-list .mbsc-lv-gr-title {
    font-size: 0.9375em;
    font-weight: bold;
  }
  .mbsc-windows .mbsc-event-color {
    width: 0.5em;
    -webkit-box-ordinal-group: -1;
    -moz-box-ordinal-group: -1;
    -ms-flex-order: -1;
    -webkit-order: -1;
    order: -1;
  }
  .mbsc-windows .mbsc-ltr .mbsc-event-color {
    margin-left: -1em;
    margin-right: 0;
  }
  .mbsc-windows .mbsc-rtl .mbsc-event-color {
    margin-left: 0;
    margin-right: -1em;
  }
  .mbsc-windows .mbsc-event-time {
    margin: 0 1em;
  }
  .mbsc-windows .mbsc-ltr .mbsc-event-time {
    text-align: left;
  }
  .mbsc-windows .mbsc-rtl .mbsc-event-time {
    text-align: right;
  }
  .mbsc-windows .mbsc-fr-arr-w {
    margin: 0;
  }
  .mbsc-windows .mbsc-fr-arr {
    border: 0;
  }
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-c {
    padding: 0;
  }
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-days > div {
    padding: 0 0.6em;
  }
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-picker {
    padding: 0;
    padding-top: 1.875em;
  }
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-day-date {
    padding: 0 0.5em;
  }
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-has-labels .mbsc-cal-day-i {
    min-height: 4.75em;
  }
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-marks {
    margin: -0.875em 0.625em 0 0.625em;
  }
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-ltr .mbsc-cal-days,
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-ltr .mbsc-cal-day,
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-ltr .mbsc-cal-day-date {
    text-align: left;
  }
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-rtl .mbsc-cal-days,
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-rtl .mbsc-cal-day,
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-rtl .mbsc-cal-day-date {
    text-align: right;
  }
  .mbsc-windows.mbsc-cal-events-popup .mbsc-fr-w {
    border: 0.375em solid rgba(38, 38, 38, 0.8);
  }
  .mbsc-windows.mbsc-cal-events-popup .mbsc-fr-arr {
    background: rgba(38, 38, 38, 0.8);
  }
  .mbsc-windows .mbsc-cal-events {
    background: rgba(38, 38, 38, 0.8);
  }
  .mbsc-windows .mbsc-cal-event-color {
    background: #262626;
  }
  .mbsc-windows .mbsc-event-list .mbsc-lv-item {
    background: #fff;
  }
  .mbsc-windows .mbsc-event-list .mbsc-lv-item:last-child {
    border-bottom: 1px solid #e5e5e5;
  }
  .mbsc-windows .mbsc-event-list .mbsc-lv-gr-title {
    background: #fff;
  }
  .mbsc-windows .mbsc-event-color {
    background: #0078d7;
  }
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-table {
    border-top: 1px solid #ccc;
  }
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    background: #fff;
    border-left: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  .mbsc-windows.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-day-diff .mbsc-cal-day-i {
    background: #f2f2f2;
  }
  .mbsc-grid,
  .mbsc-grid-unresp,
  .mbsc-grid-fixed {
    width: 100%;
    padding-right: 1em;
    padding-left: 1em;
    margin-right: auto;
    margin-left: auto;
    box-sizing: border-box;
  }
  .mbsc-grid *,
  .mbsc-grid-unresp *,
  .mbsc-grid-fixed * {
    box-sizing: border-box;
  }
  .mbsc-no-padding .mbsc-col,
  .mbsc-no-padding [class*="mbsc-col-"],
  .mbsc-form-grid .mbsc-col,
  .mbsc-form-grid [class*="mbsc-col-"] {
    padding-right: 0;
    padding-left: 0;
  }
  .mbsc-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-right: -1em;
    margin-left: -1em;
  }
  .mbsc-col {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .mbsc-col-1,
  .mbsc-col-2,
  .mbsc-col-3,
  .mbsc-col-4,
  .mbsc-col-5,
  .mbsc-col-6,
  .mbsc-col-7,
  .mbsc-col-8,
  .mbsc-col-9,
  .mbsc-col-10,
  .mbsc-col-11,
  .mbsc-col-12,
  .mbsc-col,
  .mbsc-col-auto,
  .mbsc-col-sm-1,
  .mbsc-col-sm-2,
  .mbsc-col-sm-3,
  .mbsc-col-sm-4,
  .mbsc-col-sm-5,
  .mbsc-col-sm-6,
  .mbsc-col-sm-7,
  .mbsc-col-sm-8,
  .mbsc-col-sm-9,
  .mbsc-col-sm-10,
  .mbsc-col-sm-11,
  .mbsc-col-sm-12,
  .mbsc-col-sm,
  .mbsc-col-sm-auto,
  .mbsc-col-md-1,
  .mbsc-col-md-2,
  .mbsc-col-md-3,
  .mbsc-col-md-4,
  .mbsc-col-md-5,
  .mbsc-col-md-6,
  .mbsc-col-md-7,
  .mbsc-col-md-8,
  .mbsc-col-md-9,
  .mbsc-col-md-10,
  .mbsc-col-md-11,
  .mbsc-col-md-12,
  .mbsc-col-md,
  .mbsc-col-md-auto,
  .mbsc-col-lg-1,
  .mbsc-col-lg-2,
  .mbsc-col-lg-3,
  .mbsc-col-lg-4,
  .mbsc-col-lg-5,
  .mbsc-col-lg-6,
  .mbsc-col-lg-7,
  .mbsc-col-lg-8,
  .mbsc-col-lg-9,
  .mbsc-col-lg-10,
  .mbsc-col-lg-11,
  .mbsc-col-lg-12,
  .mbsc-col-lg,
  .mbsc-col-lg-auto,
  .mbsc-col-xl-1,
  .mbsc-col-xl-2,
  .mbsc-col-xl-3,
  .mbsc-col-xl-4,
  .mbsc-col-xl-5,
  .mbsc-col-xl-6,
  .mbsc-col-xl-7,
  .mbsc-col-xl-8,
  .mbsc-col-xl-9,
  .mbsc-col-xl-10,
  .mbsc-col-xl-11,
  .mbsc-col-xl-12,
  .mbsc-col-xl,
  .mbsc-col-xl-auto {
    position: relative;
    width: 100%;
    min-height: 1px;
    padding-right: 1em;
    padding-left: 1em;
  }
  .mbsc-col-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mbsc-col-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mbsc-col-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mbsc-col-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mbsc-col-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mbsc-col-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mbsc-col-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mbsc-col-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mbsc-col-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mbsc-col-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mbsc-col-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mbsc-col-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mbsc-col-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .mbsc-offset-1 {
    margin-left: 8.333333%;
  }
  .mbsc-offset-2 {
    margin-left: 16.666667%;
  }
  .mbsc-offset-3 {
    margin-left: 25%;
  }
  .mbsc-offset-4 {
    margin-left: 33.333333%;
  }
  .mbsc-offset-5 {
    margin-left: 41.666667%;
  }
  .mbsc-offset-6 {
    margin-left: 50%;
  }
  .mbsc-offset-7 {
    margin-left: 58.333333%;
  }
  .mbsc-offset-8 {
    margin-left: 66.666667%;
  }
  .mbsc-offset-9 {
    margin-left: 75%;
  }
  .mbsc-offset-10 {
    margin-left: 83.333333%;
  }
  .mbsc-offset-11 {
    margin-left: 91.666667%;
  }
  .mbsc-grid-sm.mbsc-grid-fixed {
    max-width: 540px;
  }
  .mbsc-grid-sm .mbsc-col-sm {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .mbsc-grid-sm .mbsc-col-sm-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .mbsc-grid-sm .mbsc-col-sm-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mbsc-grid-sm .mbsc-col-sm-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mbsc-grid-sm .mbsc-col-sm-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mbsc-grid-sm .mbsc-col-sm-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mbsc-grid-sm .mbsc-col-sm-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mbsc-grid-sm .mbsc-col-sm-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mbsc-grid-sm .mbsc-col-sm-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mbsc-grid-sm .mbsc-col-sm-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mbsc-grid-sm .mbsc-col-sm-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mbsc-grid-sm .mbsc-col-sm-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mbsc-grid-sm .mbsc-col-sm-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mbsc-grid-sm .mbsc-col-sm-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mbsc-grid-sm .mbsc-offset-sm-0 {
    margin-left: 0;
  }
  .mbsc-grid-sm .mbsc-offset-sm-1 {
    margin-left: 8.333333%;
  }
  .mbsc-grid-sm .mbsc-offset-sm-2 {
    margin-left: 16.666667%;
  }
  .mbsc-grid-sm .mbsc-offset-sm-3 {
    margin-left: 25%;
  }
  .mbsc-grid-sm .mbsc-offset-sm-4 {
    margin-left: 33.333333%;
  }
  .mbsc-grid-sm .mbsc-offset-sm-5 {
    margin-left: 41.666667%;
  }
  .mbsc-grid-sm .mbsc-offset-sm-6 {
    margin-left: 50%;
  }
  .mbsc-grid-sm .mbsc-offset-sm-7 {
    margin-left: 58.333333%;
  }
  .mbsc-grid-sm .mbsc-offset-sm-8 {
    margin-left: 66.666667%;
  }
  .mbsc-grid-sm .mbsc-offset-sm-9 {
    margin-left: 75%;
  }
  .mbsc-grid-sm .mbsc-offset-sm-10 {
    margin-left: 83.333333%;
  }
  .mbsc-grid-sm .mbsc-offset-sm-11 {
    margin-left: 91.666667%;
  }
  .mbsc-grid-sm .mbsc-push-sm-0 {
    left: auto;
  }
  .mbsc-grid-sm .mbsc-push-sm-1 {
    left: 8.33333333%;
  }
  .mbsc-grid-sm .mbsc-push-sm-2 {
    left: 16.66666667%;
  }
  .mbsc-grid-sm .mbsc-push-sm-3 {
    left: 25%;
  }
  .mbsc-grid-sm .mbsc-push-sm-4 {
    left: 33.33333333%;
  }
  .mbsc-grid-sm .mbsc-push-sm-5 {
    left: 41.66666667%;
  }
  .mbsc-grid-sm .mbsc-push-sm-6 {
    left: 50%;
  }
  .mbsc-grid-sm .mbsc-push-sm-7 {
    left: 58.33333333%;
  }
  .mbsc-grid-sm .mbsc-push-sm-8 {
    left: 66.66666667%;
  }
  .mbsc-grid-sm .mbsc-push-sm-9 {
    left: 75%;
  }
  .mbsc-grid-sm .mbsc-push-sm-10 {
    left: 83.33333333%;
  }
  .mbsc-grid-sm .mbsc-push-sm-11 {
    left: 91.66666667%;
  }
  .mbsc-grid-sm .mbsc-push-sm-12 {
    left: 100%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-0 {
    right: auto;
  }
  .mbsc-grid-sm .mbsc-pull-sm-1 {
    right: 8.33333333%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-2 {
    right: 16.66666667%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-3 {
    right: 25%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-4 {
    right: 33.33333333%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-5 {
    right: 41.66666667%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-6 {
    right: 50%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-7 {
    right: 58.33333333%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-8 {
    right: 66.66666667%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-9 {
    right: 75%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-10 {
    right: 83.33333333%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-11 {
    right: 91.66666667%;
  }
  .mbsc-grid-sm .mbsc-pull-sm-12 {
    right: 100%;
  }
  @media (min-width: 576px) {
    .mbsc-grid.mbsc-grid-fixed {
      max-width: 540px;
    }
    .mbsc-grid .mbsc-col-sm {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .mbsc-grid .mbsc-col-sm-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .mbsc-grid .mbsc-col-sm-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .mbsc-grid .mbsc-col-sm-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .mbsc-grid .mbsc-col-sm-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .mbsc-grid .mbsc-col-sm-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .mbsc-grid .mbsc-col-sm-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .mbsc-grid .mbsc-col-sm-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .mbsc-grid .mbsc-col-sm-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .mbsc-grid .mbsc-col-sm-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .mbsc-grid .mbsc-col-sm-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .mbsc-grid .mbsc-col-sm-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .mbsc-grid .mbsc-col-sm-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .mbsc-grid .mbsc-col-sm-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .mbsc-grid .mbsc-offset-sm-0 {
      margin-left: 0;
    }
    .mbsc-grid .mbsc-offset-sm-1 {
      margin-left: 8.333333%;
    }
    .mbsc-grid .mbsc-offset-sm-2 {
      margin-left: 16.666667%;
    }
    .mbsc-grid .mbsc-offset-sm-3 {
      margin-left: 25%;
    }
    .mbsc-grid .mbsc-offset-sm-4 {
      margin-left: 33.333333%;
    }
    .mbsc-grid .mbsc-offset-sm-5 {
      margin-left: 41.666667%;
    }
    .mbsc-grid .mbsc-offset-sm-6 {
      margin-left: 50%;
    }
    .mbsc-grid .mbsc-offset-sm-7 {
      margin-left: 58.333333%;
    }
    .mbsc-grid .mbsc-offset-sm-8 {
      margin-left: 66.666667%;
    }
    .mbsc-grid .mbsc-offset-sm-9 {
      margin-left: 75%;
    }
    .mbsc-grid .mbsc-offset-sm-10 {
      margin-left: 83.333333%;
    }
    .mbsc-grid .mbsc-offset-sm-11 {
      margin-left: 91.666667%;
    }
    .mbsc-grid .mbsc-push-sm-0 {
      left: auto;
    }
    .mbsc-grid .mbsc-push-sm-1 {
      left: 8.33333333%;
    }
    .mbsc-grid .mbsc-push-sm-2 {
      left: 16.66666667%;
    }
    .mbsc-grid .mbsc-push-sm-3 {
      left: 25%;
    }
    .mbsc-grid .mbsc-push-sm-4 {
      left: 33.33333333%;
    }
    .mbsc-grid .mbsc-push-sm-5 {
      left: 41.66666667%;
    }
    .mbsc-grid .mbsc-push-sm-6 {
      left: 50%;
    }
    .mbsc-grid .mbsc-push-sm-7 {
      left: 58.33333333%;
    }
    .mbsc-grid .mbsc-push-sm-8 {
      left: 66.66666667%;
    }
    .mbsc-grid .mbsc-push-sm-9 {
      left: 75%;
    }
    .mbsc-grid .mbsc-push-sm-10 {
      left: 83.33333333%;
    }
    .mbsc-grid .mbsc-push-sm-11 {
      left: 91.66666667%;
    }
    .mbsc-grid .mbsc-push-sm-12 {
      left: 100%;
    }
    .mbsc-grid .mbsc-pull-sm-0 {
      right: auto;
    }
    .mbsc-grid .mbsc-pull-sm-1 {
      right: 8.33333333%;
    }
    .mbsc-grid .mbsc-pull-sm-2 {
      right: 16.66666667%;
    }
    .mbsc-grid .mbsc-pull-sm-3 {
      right: 25%;
    }
    .mbsc-grid .mbsc-pull-sm-4 {
      right: 33.33333333%;
    }
    .mbsc-grid .mbsc-pull-sm-5 {
      right: 41.66666667%;
    }
    .mbsc-grid .mbsc-pull-sm-6 {
      right: 50%;
    }
    .mbsc-grid .mbsc-pull-sm-7 {
      right: 58.33333333%;
    }
    .mbsc-grid .mbsc-pull-sm-8 {
      right: 66.66666667%;
    }
    .mbsc-grid .mbsc-pull-sm-9 {
      right: 75%;
    }
    .mbsc-grid .mbsc-pull-sm-10 {
      right: 83.33333333%;
    }
    .mbsc-grid .mbsc-pull-sm-11 {
      right: 91.66666667%;
    }
    .mbsc-grid .mbsc-pull-sm-12 {
      right: 100%;
    }
  }
  .mbsc-grid-md.mbsc-grid-fixed {
    max-width: 720px;
  }
  .mbsc-grid-md .mbsc-col-md {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .mbsc-grid-md .mbsc-col-md-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .mbsc-grid-md .mbsc-col-md-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mbsc-grid-md .mbsc-col-md-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mbsc-grid-md .mbsc-col-md-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mbsc-grid-md .mbsc-col-md-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mbsc-grid-md .mbsc-col-md-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mbsc-grid-md .mbsc-col-md-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mbsc-grid-md .mbsc-col-md-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mbsc-grid-md .mbsc-col-md-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mbsc-grid-md .mbsc-col-md-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mbsc-grid-md .mbsc-col-md-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mbsc-grid-md .mbsc-col-md-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mbsc-grid-md .mbsc-col-md-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mbsc-grid-md .mbsc-offset-md-0 {
    margin-left: 0;
  }
  .mbsc-grid-md .mbsc-offset-md-1 {
    margin-left: 8.333333%;
  }
  .mbsc-grid-md .mbsc-offset-md-2 {
    margin-left: 16.666667%;
  }
  .mbsc-grid-md .mbsc-offset-md-3 {
    margin-left: 25%;
  }
  .mbsc-grid-md .mbsc-offset-md-4 {
    margin-left: 33.333333%;
  }
  .mbsc-grid-md .mbsc-offset-md-5 {
    margin-left: 41.666667%;
  }
  .mbsc-grid-md .mbsc-offset-md-6 {
    margin-left: 50%;
  }
  .mbsc-grid-md .mbsc-offset-md-7 {
    margin-left: 58.333333%;
  }
  .mbsc-grid-md .mbsc-offset-md-8 {
    margin-left: 66.666667%;
  }
  .mbsc-grid-md .mbsc-offset-md-9 {
    margin-left: 75%;
  }
  .mbsc-grid-md .mbsc-offset-md-10 {
    margin-left: 83.333333%;
  }
  .mbsc-grid-md .mbsc-offset-md-11 {
    margin-left: 91.666667%;
  }
  .mbsc-grid-md .mbsc-push-md-0 {
    left: auto;
  }
  .mbsc-grid-md .mbsc-push-md-1 {
    left: 8.33333333%;
  }
  .mbsc-grid-md .mbsc-push-md-2 {
    left: 16.66666667%;
  }
  .mbsc-grid-md .mbsc-push-md-3 {
    left: 25%;
  }
  .mbsc-grid-md .mbsc-push-md-4 {
    left: 33.33333333%;
  }
  .mbsc-grid-md .mbsc-push-md-5 {
    left: 41.66666667%;
  }
  .mbsc-grid-md .mbsc-push-md-6 {
    left: 50%;
  }
  .mbsc-grid-md .mbsc-push-md-7 {
    left: 58.33333333%;
  }
  .mbsc-grid-md .mbsc-push-md-8 {
    left: 66.66666667%;
  }
  .mbsc-grid-md .mbsc-push-md-9 {
    left: 75%;
  }
  .mbsc-grid-md .mbsc-push-md-10 {
    left: 83.33333333%;
  }
  .mbsc-grid-md .mbsc-push-md-11 {
    left: 91.66666667%;
  }
  .mbsc-grid-md .mbsc-push-md-12 {
    left: 100%;
  }
  .mbsc-grid-md .mbsc-pull-md-0 {
    right: auto;
  }
  .mbsc-grid-md .mbsc-pull-md-1 {
    right: 8.33333333%;
  }
  .mbsc-grid-md .mbsc-pull-md-2 {
    right: 16.66666667%;
  }
  .mbsc-grid-md .mbsc-pull-md-3 {
    right: 25%;
  }
  .mbsc-grid-md .mbsc-pull-md-4 {
    right: 33.33333333%;
  }
  .mbsc-grid-md .mbsc-pull-md-5 {
    right: 41.66666667%;
  }
  .mbsc-grid-md .mbsc-pull-md-6 {
    right: 50%;
  }
  .mbsc-grid-md .mbsc-pull-md-7 {
    right: 58.33333333%;
  }
  .mbsc-grid-md .mbsc-pull-md-8 {
    right: 66.66666667%;
  }
  .mbsc-grid-md .mbsc-pull-md-9 {
    right: 75%;
  }
  .mbsc-grid-md .mbsc-pull-md-10 {
    right: 83.33333333%;
  }
  .mbsc-grid-md .mbsc-pull-md-11 {
    right: 91.66666667%;
  }
  .mbsc-grid-md .mbsc-pull-md-12 {
    right: 100%;
  }
  @media (min-width: 768px) {
    .mbsc-grid.mbsc-grid-fixed {
      max-width: 720px;
    }
    .mbsc-grid .mbsc-col-md {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .mbsc-grid .mbsc-col-md-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .mbsc-grid .mbsc-col-md-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .mbsc-grid .mbsc-col-md-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .mbsc-grid .mbsc-col-md-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .mbsc-grid .mbsc-col-md-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .mbsc-grid .mbsc-col-md-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .mbsc-grid .mbsc-col-md-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .mbsc-grid .mbsc-col-md-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .mbsc-grid .mbsc-col-md-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .mbsc-grid .mbsc-col-md-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .mbsc-grid .mbsc-col-md-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .mbsc-grid .mbsc-col-md-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .mbsc-grid .mbsc-col-md-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .mbsc-grid .mbsc-offset-md-0 {
      margin-left: 0;
    }
    .mbsc-grid .mbsc-offset-md-1 {
      margin-left: 8.333333%;
    }
    .mbsc-grid .mbsc-offset-md-2 {
      margin-left: 16.666667%;
    }
    .mbsc-grid .mbsc-offset-md-3 {
      margin-left: 25%;
    }
    .mbsc-grid .mbsc-offset-md-4 {
      margin-left: 33.333333%;
    }
    .mbsc-grid .mbsc-offset-md-5 {
      margin-left: 41.666667%;
    }
    .mbsc-grid .mbsc-offset-md-6 {
      margin-left: 50%;
    }
    .mbsc-grid .mbsc-offset-md-7 {
      margin-left: 58.333333%;
    }
    .mbsc-grid .mbsc-offset-md-8 {
      margin-left: 66.666667%;
    }
    .mbsc-grid .mbsc-offset-md-9 {
      margin-left: 75%;
    }
    .mbsc-grid .mbsc-offset-md-10 {
      margin-left: 83.333333%;
    }
    .mbsc-grid .mbsc-offset-md-11 {
      margin-left: 91.666667%;
    }
    .mbsc-grid .mbsc-push-md-0 {
      left: auto;
    }
    .mbsc-grid .mbsc-push-md-1 {
      left: 8.33333333%;
    }
    .mbsc-grid .mbsc-push-md-2 {
      left: 16.66666667%;
    }
    .mbsc-grid .mbsc-push-md-3 {
      left: 25%;
    }
    .mbsc-grid .mbsc-push-md-4 {
      left: 33.33333333%;
    }
    .mbsc-grid .mbsc-push-md-5 {
      left: 41.66666667%;
    }
    .mbsc-grid .mbsc-push-md-6 {
      left: 50%;
    }
    .mbsc-grid .mbsc-push-md-7 {
      left: 58.33333333%;
    }
    .mbsc-grid .mbsc-push-md-8 {
      left: 66.66666667%;
    }
    .mbsc-grid .mbsc-push-md-9 {
      left: 75%;
    }
    .mbsc-grid .mbsc-push-md-10 {
      left: 83.33333333%;
    }
    .mbsc-grid .mbsc-push-md-11 {
      left: 91.66666667%;
    }
    .mbsc-grid .mbsc-push-md-12 {
      left: 100%;
    }
    .mbsc-grid .mbsc-pull-md-0 {
      right: auto;
    }
    .mbsc-grid .mbsc-pull-md-1 {
      right: 8.33333333%;
    }
    .mbsc-grid .mbsc-pull-md-2 {
      right: 16.66666667%;
    }
    .mbsc-grid .mbsc-pull-md-3 {
      right: 25%;
    }
    .mbsc-grid .mbsc-pull-md-4 {
      right: 33.33333333%;
    }
    .mbsc-grid .mbsc-pull-md-5 {
      right: 41.66666667%;
    }
    .mbsc-grid .mbsc-pull-md-6 {
      right: 50%;
    }
    .mbsc-grid .mbsc-pull-md-7 {
      right: 58.33333333%;
    }
    .mbsc-grid .mbsc-pull-md-8 {
      right: 66.66666667%;
    }
    .mbsc-grid .mbsc-pull-md-9 {
      right: 75%;
    }
    .mbsc-grid .mbsc-pull-md-10 {
      right: 83.33333333%;
    }
    .mbsc-grid .mbsc-pull-md-11 {
      right: 91.66666667%;
    }
    .mbsc-grid .mbsc-pull-md-12 {
      right: 100%;
    }
  }
  .mbsc-grid-lg.mbsc-grid-fixed {
    max-width: 960px;
  }
  .mbsc-grid-lg .mbsc-col-lg {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .mbsc-grid-lg .mbsc-col-lg-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .mbsc-grid-lg .mbsc-col-lg-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mbsc-grid-lg .mbsc-col-lg-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mbsc-grid-lg .mbsc-col-lg-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mbsc-grid-lg .mbsc-col-lg-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mbsc-grid-lg .mbsc-col-lg-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mbsc-grid-lg .mbsc-col-lg-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mbsc-grid-lg .mbsc-col-lg-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mbsc-grid-lg .mbsc-col-lg-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mbsc-grid-lg .mbsc-col-lg-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mbsc-grid-lg .mbsc-col-lg-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mbsc-grid-lg .mbsc-col-lg-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mbsc-grid-lg .mbsc-col-lg-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mbsc-grid-lg .mbsc-offset-lg-0 {
    margin-left: 0;
  }
  .mbsc-grid-lg .mbsc-offset-lg-1 {
    margin-left: 8.333333%;
  }
  .mbsc-grid-lg .mbsc-offset-lg-2 {
    margin-left: 16.666667%;
  }
  .mbsc-grid-lg .mbsc-offset-lg-3 {
    margin-left: 25%;
  }
  .mbsc-grid-lg .mbsc-offset-lg-4 {
    margin-left: 33.333333%;
  }
  .mbsc-grid-lg .mbsc-offset-lg-5 {
    margin-left: 41.666667%;
  }
  .mbsc-grid-lg .mbsc-offset-lg-6 {
    margin-left: 50%;
  }
  .mbsc-grid-lg .mbsc-offset-lg-7 {
    margin-left: 58.333333%;
  }
  .mbsc-grid-lg .mbsc-offset-lg-8 {
    margin-left: 66.666667%;
  }
  .mbsc-grid-lg .mbsc-offset-lg-9 {
    margin-left: 75%;
  }
  .mbsc-grid-lg .mbsc-offset-lg-10 {
    margin-left: 83.333333%;
  }
  .mbsc-grid-lg .mbsc-offset-lg-11 {
    margin-left: 91.666667%;
  }
  .mbsc-grid-lg .mbsc-push-lg-0 {
    left: auto;
  }
  .mbsc-grid-lg .mbsc-push-lg-1 {
    left: 8.33333333%;
  }
  .mbsc-grid-lg .mbsc-push-lg-2 {
    left: 16.66666667%;
  }
  .mbsc-grid-lg .mbsc-push-lg-3 {
    left: 25%;
  }
  .mbsc-grid-lg .mbsc-push-lg-4 {
    left: 33.33333333%;
  }
  .mbsc-grid-lg .mbsc-push-lg-5 {
    left: 41.66666667%;
  }
  .mbsc-grid-lg .mbsc-push-lg-6 {
    left: 50%;
  }
  .mbsc-grid-lg .mbsc-push-lg-7 {
    left: 58.33333333%;
  }
  .mbsc-grid-lg .mbsc-push-lg-8 {
    left: 66.66666667%;
  }
  .mbsc-grid-lg .mbsc-push-lg-9 {
    left: 75%;
  }
  .mbsc-grid-lg .mbsc-push-lg-10 {
    left: 83.33333333%;
  }
  .mbsc-grid-lg .mbsc-push-lg-11 {
    left: 91.66666667%;
  }
  .mbsc-grid-lg .mbsc-push-lg-12 {
    left: 100%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-0 {
    right: auto;
  }
  .mbsc-grid-lg .mbsc-pull-lg-1 {
    right: 8.33333333%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-2 {
    right: 16.66666667%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-3 {
    right: 25%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-4 {
    right: 33.33333333%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-5 {
    right: 41.66666667%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-6 {
    right: 50%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-7 {
    right: 58.33333333%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-8 {
    right: 66.66666667%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-9 {
    right: 75%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-10 {
    right: 83.33333333%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-11 {
    right: 91.66666667%;
  }
  .mbsc-grid-lg .mbsc-pull-lg-12 {
    right: 100%;
  }
  @media (min-width: 992px) {
    .mbsc-grid.mbsc-grid-fixed {
      max-width: 960px;
    }
    .mbsc-grid .mbsc-col-lg {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .mbsc-grid .mbsc-col-lg-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .mbsc-grid .mbsc-col-lg-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .mbsc-grid .mbsc-col-lg-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .mbsc-grid .mbsc-col-lg-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .mbsc-grid .mbsc-col-lg-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .mbsc-grid .mbsc-col-lg-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .mbsc-grid .mbsc-col-lg-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .mbsc-grid .mbsc-col-lg-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .mbsc-grid .mbsc-col-lg-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .mbsc-grid .mbsc-col-lg-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .mbsc-grid .mbsc-col-lg-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .mbsc-grid .mbsc-col-lg-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .mbsc-grid .mbsc-col-lg-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .mbsc-grid .mbsc-offset-lg-0 {
      margin-left: 0;
    }
    .mbsc-grid .mbsc-offset-lg-1 {
      margin-left: 8.333333%;
    }
    .mbsc-grid .mbsc-offset-lg-2 {
      margin-left: 16.666667%;
    }
    .mbsc-grid .mbsc-offset-lg-3 {
      margin-left: 25%;
    }
    .mbsc-grid .mbsc-offset-lg-4 {
      margin-left: 33.333333%;
    }
    .mbsc-grid .mbsc-offset-lg-5 {
      margin-left: 41.666667%;
    }
    .mbsc-grid .mbsc-offset-lg-6 {
      margin-left: 50%;
    }
    .mbsc-grid .mbsc-offset-lg-7 {
      margin-left: 58.333333%;
    }
    .mbsc-grid .mbsc-offset-lg-8 {
      margin-left: 66.666667%;
    }
    .mbsc-grid .mbsc-offset-lg-9 {
      margin-left: 75%;
    }
    .mbsc-grid .mbsc-offset-lg-10 {
      margin-left: 83.333333%;
    }
    .mbsc-grid .mbsc-offset-lg-11 {
      margin-left: 91.666667%;
    }
    .mbsc-grid .mbsc-push-lg-0 {
      left: auto;
    }
    .mbsc-grid .mbsc-push-lg-1 {
      left: 8.33333333%;
    }
    .mbsc-grid .mbsc-push-lg-2 {
      left: 16.66666667%;
    }
    .mbsc-grid .mbsc-push-lg-3 {
      left: 25%;
    }
    .mbsc-grid .mbsc-push-lg-4 {
      left: 33.33333333%;
    }
    .mbsc-grid .mbsc-push-lg-5 {
      left: 41.66666667%;
    }
    .mbsc-grid .mbsc-push-lg-6 {
      left: 50%;
    }
    .mbsc-grid .mbsc-push-lg-7 {
      left: 58.33333333%;
    }
    .mbsc-grid .mbsc-push-lg-8 {
      left: 66.66666667%;
    }
    .mbsc-grid .mbsc-push-lg-9 {
      left: 75%;
    }
    .mbsc-grid .mbsc-push-lg-10 {
      left: 83.33333333%;
    }
    .mbsc-grid .mbsc-push-lg-11 {
      left: 91.66666667%;
    }
    .mbsc-grid .mbsc-push-lg-12 {
      left: 100%;
    }
    .mbsc-grid .mbsc-pull-lg-0 {
      right: auto;
    }
    .mbsc-grid .mbsc-pull-lg-1 {
      right: 8.33333333%;
    }
    .mbsc-grid .mbsc-pull-lg-2 {
      right: 16.66666667%;
    }
    .mbsc-grid .mbsc-pull-lg-3 {
      right: 25%;
    }
    .mbsc-grid .mbsc-pull-lg-4 {
      right: 33.33333333%;
    }
    .mbsc-grid .mbsc-pull-lg-5 {
      right: 41.66666667%;
    }
    .mbsc-grid .mbsc-pull-lg-6 {
      right: 50%;
    }
    .mbsc-grid .mbsc-pull-lg-7 {
      right: 58.33333333%;
    }
    .mbsc-grid .mbsc-pull-lg-8 {
      right: 66.66666667%;
    }
    .mbsc-grid .mbsc-pull-lg-9 {
      right: 75%;
    }
    .mbsc-grid .mbsc-pull-lg-10 {
      right: 83.33333333%;
    }
    .mbsc-grid .mbsc-pull-lg-11 {
      right: 91.66666667%;
    }
    .mbsc-grid .mbsc-pull-lg-12 {
      right: 100%;
    }
  }
  .mbsc-grid-xl.mbsc-grid-fixed {
    max-width: 1140px;
  }
  .mbsc-grid-xl .mbsc-col-xl {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }
  .mbsc-grid-xl .mbsc-col-xl-auto {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    width: auto;
    max-width: none;
  }
  .mbsc-grid-xl .mbsc-col-xl-1 {
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }
  .mbsc-grid-xl .mbsc-col-xl-2 {
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }
  .mbsc-grid-xl .mbsc-col-xl-3 {
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }
  .mbsc-grid-xl .mbsc-col-xl-4 {
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }
  .mbsc-grid-xl .mbsc-col-xl-5 {
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }
  .mbsc-grid-xl .mbsc-col-xl-6 {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }
  .mbsc-grid-xl .mbsc-col-xl-7 {
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }
  .mbsc-grid-xl .mbsc-col-xl-8 {
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }
  .mbsc-grid-xl .mbsc-col-xl-9 {
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }
  .mbsc-grid-xl .mbsc-col-xl-10 {
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }
  .mbsc-grid-xl .mbsc-col-xl-11 {
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }
  .mbsc-grid-xl .mbsc-col-xl-12 {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .mbsc-grid-xl .mbsc-offset-xl-0 {
    margin-left: 0;
  }
  .mbsc-grid-xl .mbsc-offset-xl-1 {
    margin-left: 8.333333%;
  }
  .mbsc-grid-xl .mbsc-offset-xl-2 {
    margin-left: 16.666667%;
  }
  .mbsc-grid-xl .mbsc-offset-xl-3 {
    margin-left: 25%;
  }
  .mbsc-grid-xl .mbsc-offset-xl-4 {
    margin-left: 33.333333%;
  }
  .mbsc-grid-xl .mbsc-offset-xl-5 {
    margin-left: 41.666667%;
  }
  .mbsc-grid-xl .mbsc-offset-xl-6 {
    margin-left: 50%;
  }
  .mbsc-grid-xl .mbsc-offset-xl-7 {
    margin-left: 58.333333%;
  }
  .mbsc-grid-xl .mbsc-offset-xl-8 {
    margin-left: 66.666667%;
  }
  .mbsc-grid-xl .mbsc-offset-xl-9 {
    margin-left: 75%;
  }
  .mbsc-grid-xl .mbsc-offset-xl-10 {
    margin-left: 83.333333%;
  }
  .mbsc-grid-xl .mbsc-offset-xl-11 {
    margin-left: 91.666667%;
  }
  .mbsc-grid-xl .mbsc-push-xl-0 {
    left: auto;
  }
  .mbsc-grid-xl .mbsc-push-xl-1 {
    left: 8.33333333%;
  }
  .mbsc-grid-xl .mbsc-push-xl-2 {
    left: 16.66666667%;
  }
  .mbsc-grid-xl .mbsc-push-xl-3 {
    left: 25%;
  }
  .mbsc-grid-xl .mbsc-push-xl-4 {
    left: 33.33333333%;
  }
  .mbsc-grid-xl .mbsc-push-xl-5 {
    left: 41.66666667%;
  }
  .mbsc-grid-xl .mbsc-push-xl-6 {
    left: 50%;
  }
  .mbsc-grid-xl .mbsc-push-xl-7 {
    left: 58.33333333%;
  }
  .mbsc-grid-xl .mbsc-push-xl-8 {
    left: 66.66666667%;
  }
  .mbsc-grid-xl .mbsc-push-xl-9 {
    left: 75%;
  }
  .mbsc-grid-xl .mbsc-push-xl-10 {
    left: 83.33333333%;
  }
  .mbsc-grid-xl .mbsc-push-xl-11 {
    left: 91.66666667%;
  }
  .mbsc-grid-xl .mbsc-push-xl-12 {
    left: 100%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-0 {
    right: auto;
  }
  .mbsc-grid-xl .mbsc-pull-xl-1 {
    right: 8.33333333%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-2 {
    right: 16.66666667%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-3 {
    right: 25%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-4 {
    right: 33.33333333%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-5 {
    right: 41.66666667%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-6 {
    right: 50%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-7 {
    right: 58.33333333%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-8 {
    right: 66.66666667%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-9 {
    right: 75%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-10 {
    right: 83.33333333%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-11 {
    right: 91.66666667%;
  }
  .mbsc-grid-xl .mbsc-pull-xl-12 {
    right: 100%;
  }
  @media (min-width: 1200px) {
    .mbsc-grid.mbsc-grid-fixed {
      max-width: 1140px;
    }
    .mbsc-grid .mbsc-col-xl {
      -ms-flex-preferred-size: 0;
      flex-basis: 0;
      -ms-flex-positive: 1;
      flex-grow: 1;
      max-width: 100%;
    }
    .mbsc-grid .mbsc-col-xl-auto {
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      width: auto;
      max-width: none;
    }
    .mbsc-grid .mbsc-col-xl-1 {
      -ms-flex: 0 0 8.333333%;
      flex: 0 0 8.333333%;
      max-width: 8.333333%;
    }
    .mbsc-grid .mbsc-col-xl-2 {
      -ms-flex: 0 0 16.666667%;
      flex: 0 0 16.666667%;
      max-width: 16.666667%;
    }
    .mbsc-grid .mbsc-col-xl-3 {
      -ms-flex: 0 0 25%;
      flex: 0 0 25%;
      max-width: 25%;
    }
    .mbsc-grid .mbsc-col-xl-4 {
      -ms-flex: 0 0 33.333333%;
      flex: 0 0 33.333333%;
      max-width: 33.333333%;
    }
    .mbsc-grid .mbsc-col-xl-5 {
      -ms-flex: 0 0 41.666667%;
      flex: 0 0 41.666667%;
      max-width: 41.666667%;
    }
    .mbsc-grid .mbsc-col-xl-6 {
      -ms-flex: 0 0 50%;
      flex: 0 0 50%;
      max-width: 50%;
    }
    .mbsc-grid .mbsc-col-xl-7 {
      -ms-flex: 0 0 58.333333%;
      flex: 0 0 58.333333%;
      max-width: 58.333333%;
    }
    .mbsc-grid .mbsc-col-xl-8 {
      -ms-flex: 0 0 66.666667%;
      flex: 0 0 66.666667%;
      max-width: 66.666667%;
    }
    .mbsc-grid .mbsc-col-xl-9 {
      -ms-flex: 0 0 75%;
      flex: 0 0 75%;
      max-width: 75%;
    }
    .mbsc-grid .mbsc-col-xl-10 {
      -ms-flex: 0 0 83.333333%;
      flex: 0 0 83.333333%;
      max-width: 83.333333%;
    }
    .mbsc-grid .mbsc-col-xl-11 {
      -ms-flex: 0 0 91.666667%;
      flex: 0 0 91.666667%;
      max-width: 91.666667%;
    }
    .mbsc-grid .mbsc-col-xl-12 {
      -ms-flex: 0 0 100%;
      flex: 0 0 100%;
      max-width: 100%;
    }
    .mbsc-grid .mbsc-offset-xl-0 {
      margin-left: 0;
    }
    .mbsc-grid .mbsc-offset-xl-1 {
      margin-left: 8.333333%;
    }
    .mbsc-grid .mbsc-offset-xl-2 {
      margin-left: 16.666667%;
    }
    .mbsc-grid .mbsc-offset-xl-3 {
      margin-left: 25%;
    }
    .mbsc-grid .mbsc-offset-xl-4 {
      margin-left: 33.333333%;
    }
    .mbsc-grid .mbsc-offset-xl-5 {
      margin-left: 41.666667%;
    }
    .mbsc-grid .mbsc-offset-xl-6 {
      margin-left: 50%;
    }
    .mbsc-grid .mbsc-offset-xl-7 {
      margin-left: 58.333333%;
    }
    .mbsc-grid .mbsc-offset-xl-8 {
      margin-left: 66.666667%;
    }
    .mbsc-grid .mbsc-offset-xl-9 {
      margin-left: 75%;
    }
    .mbsc-grid .mbsc-offset-xl-10 {
      margin-left: 83.333333%;
    }
    .mbsc-grid .mbsc-offset-xl-11 {
      margin-left: 91.666667%;
    }
    .mbsc-grid .mbsc-push-xl-0 {
      left: auto;
    }
    .mbsc-grid .mbsc-push-xl-1 {
      left: 8.33333333%;
    }
    .mbsc-grid .mbsc-push-xl-2 {
      left: 16.66666667%;
    }
    .mbsc-grid .mbsc-push-xl-3 {
      left: 25%;
    }
    .mbsc-grid .mbsc-push-xl-4 {
      left: 33.33333333%;
    }
    .mbsc-grid .mbsc-push-xl-5 {
      left: 41.66666667%;
    }
    .mbsc-grid .mbsc-push-xl-6 {
      left: 50%;
    }
    .mbsc-grid .mbsc-push-xl-7 {
      left: 58.33333333%;
    }
    .mbsc-grid .mbsc-push-xl-8 {
      left: 66.66666667%;
    }
    .mbsc-grid .mbsc-push-xl-9 {
      left: 75%;
    }
    .mbsc-grid .mbsc-push-xl-10 {
      left: 83.33333333%;
    }
    .mbsc-grid .mbsc-push-xl-11 {
      left: 91.66666667%;
    }
    .mbsc-grid .mbsc-push-xl-12 {
      left: 100%;
    }
    .mbsc-grid .mbsc-pull-xl-0 {
      right: auto;
    }
    .mbsc-grid .mbsc-pull-xl-1 {
      right: 8.33333333%;
    }
    .mbsc-grid .mbsc-pull-xl-2 {
      right: 16.66666667%;
    }
    .mbsc-grid .mbsc-pull-xl-3 {
      right: 25%;
    }
    .mbsc-grid .mbsc-pull-xl-4 {
      right: 33.33333333%;
    }
    .mbsc-grid .mbsc-pull-xl-5 {
      right: 41.66666667%;
    }
    .mbsc-grid .mbsc-pull-xl-6 {
      right: 50%;
    }
    .mbsc-grid .mbsc-pull-xl-7 {
      right: 58.33333333%;
    }
    .mbsc-grid .mbsc-pull-xl-8 {
      right: 66.66666667%;
    }
    .mbsc-grid .mbsc-pull-xl-9 {
      right: 75%;
    }
    .mbsc-grid .mbsc-pull-xl-10 {
      right: 83.33333333%;
    }
    .mbsc-grid .mbsc-pull-xl-11 {
      right: 91.66666667%;
    }
    .mbsc-grid .mbsc-pull-xl-12 {
      right: 100%;
    }
  }
  .mbsc-align-items-start {
    -ms-flex-align: start !important;
    align-items: flex-start !important;
  }
  .mbsc-align-items-center {
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .mbsc-align-items-end {
    -ms-flex-align: end !important;
    align-items: flex-end !important;
  }
  .mbsc-justify-content-start {
    -ms-flex-pack: start !important;
    justify-content: flex-start !important;
  }
  .mbsc-justify-content-center {
    -ms-flex-pack: center !important;
    justify-content: center !important;
  }
  .mbsc-justify-content-end {
    -ms-flex-pack: end !important;
    justify-content: flex-end !important;
  }
  .mbsc-justify-content-around {
    -ms-flex-pack: distribute !important;
    justify-content: space-around !important;
  }
  .mbsc-justify-content-between {
    -ms-flex-pack: justify !important;
    justify-content: space-between !important;
  }
  .mbsc-ltr .mbsc-img-w {
    text-align: left;
  }
  .mbsc-ltr .mbsc-img-txt {
    margin: 0 0 0 0.5em;
  }
  .mbsc-rtl .mbsc-img-w {
    text-align: right;
  }
  .mbsc-rtl .mbsc-img-txt {
    margin: 0 0.5em 0 0;
  }
  .mbsc-img-w .mbsc-ic {
    display: inline-block;
    margin: 0 0.5em;
  }
  .mbsc-img-txt {
    display: inline-block;
  }
  .mbsc-img-c {
    min-width: 50px;
    height: 28px;
    margin-top: -2px;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    line-height: normal;
  }
  .mbsc-img {
    max-height: 28px;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  @-webkit-keyframes mbsc-lv-spin {
    from {
      -webkit-transform: rotate(180deg);
      opacity: 0;
    }
    to {
      -webkit-transform: rotate(0);
      opacity: 1;
    }
  }
  @-webkit-keyframes mbsc-lv-remove-right {
    from {
    }
    to {
      -webkit-transform: translateX(100%);
    }
  }
  @-webkit-keyframes mbsc-lv-remove-left {
    from {
    }
    to {
      -webkit-transform: translateX(-100%);
    }
  }
  @-webkit-keyframes mbsc-lv-add-right {
    from {
      -webkit-transform: translateX(100%);
    }
    to {
      -webkit-transform: translateX(0);
    }
  }
  @-webkit-keyframes mbsc-lv-add-left {
    from {
      -webkit-transform: translateX(-100%);
    }
    to {
      -webkit-transform: translateX(0);
    }
  }
  @-webkit-keyframes mbsc-lv-pop-in {
    from {
      -webkit-transform: scale(0);
      opacity: 0;
    }
    to {
      -webkit-transform: scale(1);
      opacity: 1;
    }
  }
  @-webkit-keyframes mbsc-lv-pop-out {
    from {
      -webkit-transform: scale(1);
      opacity: 1;
    }
    to {
      -webkit-transform: scale(0);
      opacity: 0;
    }
  }
  @-webkit-keyframes mbsc-lv-collapse {
    from {
      padding: 0;
      border: 0;
    }
    to {
      padding: 0;
      border: 0;
      height: 0;
    }
  }
  @-webkit-keyframes mbsc-lv-expand {
    from {
      padding: 0;
      border: 0;
      height: 0;
    }
    to {
      padding: 0;
      border: 0;
    }
  }
  @-webkit-keyframes mbsc-lv-fill {
    from {
      -webkit-transform: scale(1, 0);
    }
    to {
      -webkit-transform: scale(1, 1);
    }
  }
  @keyframes mbsc-lv-spin {
    from {
      transform: rotate(180deg);
      opacity: 0;
    }
    to {
      transform: rotate(0);
      opacity: 1;
    }
  }
  @keyframes mbsc-lv-remove-right {
    to {
      transform: translateX(100%);
    }
  }
  @keyframes mbsc-lv-remove-left {
    to {
      transform: translateX(-100%);
    }
  }
  @keyframes mbsc-lv-add-right {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes mbsc-lv-add-left {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }
  @keyframes mbsc-lv-pop-in {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
    }
  }
  @keyframes mbsc-lv-pop-out {
    from {
      transform: scale(1);
    }
    to {
      transform: scale(0);
      opacity: 0;
    }
  }
  @keyframes mbsc-lv-collapse {
    from {
      padding: 0;
      border: 0;
    }
    to {
      padding: 0;
      border: 0;
      height: 0;
    }
  }
  @keyframes mbsc-lv-expand {
    from {
      padding: 0;
      border: 0;
      height: 0;
    }
    to {
      padding: 0;
      border: 0;
    }
  }
  @keyframes mbsc-lv-fill {
    from {
      transform: scale(1, 0);
    }
    to {
      transform: scale(1, 1);
    }
  }
  @keyframes mbsc-lv-rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  @-webkit-keyframes mbsc-lv-rotating {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }
  .mbsc-lv-stage-c-v,
  .mbsc-lv-item,
  .mbsc-lv-gr-title,
  .mbsc-lv-ic-text {
    -webkit-transform: translate3d(0, 0, 0);
  }
  .mbsc-lv,
  .mbsc-lv-fill-item {
    -webkit-backface-visibility: hidden;
  }
  .mbsc-lv-rtl {
    direction: rtl;
  }
  .mbsc-lv.mbsc-lv-dummy {
    display: block;
    margin-top: -1px;
    -webkit-backface-visibility: visible;
  }
  .mbsc-lv-fixed-header {
    position: fixed;
    display: none;
    top: 0;
    left: 0;
    list-style: none;
    width: 100%;
    z-index: 10;
  }
  .mbsc-lv-fixed-header-ctx {
    position: absolute;
  }
  .mbsc-lv .mbsc-lv-item.mbsc-lv-item-swiping {
    z-index: 1;
    overflow: visible;
  }
  .mbsc-lv-stage-c {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
  }
  .mbsc-lv-stage-c-v {
    display: block;
  }
  .mbsc-lv-item-new-left {
    -webkit-transform: translateX(-100%);
  }
  .mbsc-lv-item-new-right {
    -webkit-transform: translateX(100%);
  }
  .mbsc-lv-item-remove-right {
    -webkit-animation: mbsc-lv-remove-right 125ms;
    -webkit-animation-delay: 1ms;
    animation: mbsc-lv-remove-right 125ms;
  }
  .mbsc-lv-item-remove-left {
    -webkit-animation: mbsc-lv-remove-left 125ms;
    -webkit-animation-delay: 1ms;
    animation: mbsc-lv-remove-left 125ms;
  }
  .mbsc-lv-item-add-right {
    -webkit-animation: mbsc-lv-add-right 125ms;
    animation: mbsc-lv-add-right 125ms;
  }
  .mbsc-lv-item-add-left {
    -webkit-animation: mbsc-lv-add-left 125ms;
    animation: mbsc-lv-add-left 125ms;
  }
  .mbsc-lv-item-pop-in {
    -webkit-animation: mbsc-lv-pop-in 200ms;
    animation: mbsc-lv-pop-in 200ms;
  }
  .mbsc-lv-item-pop-out {
    -webkit-animation: mbsc-lv-pop-out 200ms;
    animation: mbsc-lv-pop-out 200ms;
  }
  .mbsc-lv-item-collapse {
    visibility: hidden;
    -webkit-animation: mbsc-lv-collapse 125ms;
    animation: mbsc-lv-collapse 125ms;
  }
  .mbsc-lv-item-expand {
    visibility: hidden;
    -webkit-animation: mbsc-lv-expand 125ms;
    animation: mbsc-lv-expand 125ms;
  }
  .mbsc-lv .mbsc-lv-item.mbsc-lv-item-undo {
    position: absolute;
    left: 0;
    right: 0;
  }
  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-item-dragging {
    z-index: 100;
    position: absolute;
    left: 0;
    right: 0;
    opacity: 0.9;
    box-shadow: 0 0 0.625em rgba(0, 0, 0, 0.5);
    cursor: pointer;
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .mbsc-lv-item-dragging .mbsc-lv-handle-c {
    cursor: -webkit-grabbing;
    cursor: grabbing;
  }
  .mbsc-lv .mbsc-lv-item.mbsc-lv-ph {
    padding: 0;
    border: 0;
    visibility: hidden;
  }
  .mbsc-lv-fill-item {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: 0.1;
  }
  .mbsc-lv-handle-c {
    position: absolute;
    z-index: 4;
    top: 0;
    height: 100%;
    width: 2.5em;
    -ms-touch-action: none;
    touch-action: none;
    cursor: pointer;
    cursor: -webkit-grab;
    cursor: grab;
  }
  .mbsc-lv-item-h-right {
    right: 0;
  }
  .mbsc-lv-item-h-left {
    left: 0;
  }
  .mbsc-lv-cont.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item {
    padding-left: 2.5em;
  }
  .mbsc-lv-cont.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item {
    padding-right: 2.5em;
  }
  .mbsc-lv-handle-bar-c {
    position: absolute;
    width: 100%;
    top: 50%;
    margin-top: -7px;
  }
  .mbsc-lv-handle-bar {
    position: relative;
    height: 2px;
    margin: 2px 0.625em;
    background: #888;
  }
  .mbsc-lv-ic,
  .mbsc-lv-ic-m {
    top: 0;
    text-align: center;
    text-shadow: none;
    font-size: 0.75em;
  }
  .mbsc-lv-ic-s {
    position: absolute;
    top: 50%;
    margin-top: -0.9375em;
    width: 2.8em;
    height: 1.875em;
    line-height: 1.875em;
    font-size: 1.25em;
  }
  .mbsc-lv-ic-c {
    position: absolute;
    top: 0;
    height: 100%;
    line-height: 1.875em;
    color: #fff;
    cursor: pointer;
  }
  .mbsc-lv-ic-text {
    text-shadow: none;
    font-size: 1em;
  }
  .mbsc-ic-none {
    border: 0;
  }
  .mbsc-ic-none:before {
    content: "0";
    font-size: 0;
    color: rgba(0, 0, 0, 0);
  }
  .mbsc-lv-ic-anim .mbsc-lv-ic-s {
    opacity: 0;
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  .mbsc-lv-ic-anim .mbsc-lv-ic-a {
    opacity: 1;
    -webkit-animation: mbsc-lv-spin 200ms;
    -webkit-transform: rotate(0);
    animation: mbsc-lv-spin 200ms;
    transform: rotate(0);
  }
  .mbsc-lv-ic-anim .mbsc-lv-ic-v {
    opacity: 1;
    -webkit-animation: none;
    -webkit-transform: rotate(0);
    animation: none;
    transform: rotate(0);
  }
  .mbsc-lv-ic-left {
    left: 0;
  }
  .mbsc-lv-ic-move-left {
    left: 0;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .mbsc-lv-ic-right .mbsc-lv-ic,
  .mbsc-lv-ic-move-left .mbsc-lv-ic {
    right: 0;
  }
  .mbsc-lv-ic-right .mbsc-lv-ic-text,
  .mbsc-lv-ic-move-left .mbsc-lv-ic-text {
    padding: 0 3.5em 0 0.625em;
  }
  .mbsc-lv-ic-right {
    right: 0;
  }
  .mbsc-lv-ic-move-right {
    right: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  .mbsc-lv-ic-left .mbsc-lv-ic,
  .mbsc-lv-ic-move-right .mbsc-lv-ic {
    left: 0;
  }
  .mbsc-lv-ic-left .mbsc-lv-ic-text,
  .mbsc-lv-ic-move-right .mbsc-lv-ic-text {
    padding: 0 0.625em 0 3.5em;
  }
  .mbsc-lv-ic-c .mbsc-lv-ic-only {
    width: 0;
    padding: 0 0 0 3.5em;
  }
  .mbsc-lv-ic-c .mbsc-lv-ic-text-only {
    padding: 0 0.625em;
  }
  .mbsc-lv-multi {
    position: absolute;
    top: 0;
    display: none;
    width: 90%;
    height: 100%;
    table-layout: fixed;
  }
  .mbsc-lv-multi-ic-right {
    right: 0;
  }
  .mbsc-lv-multi-ic-left {
    left: 0;
  }
  .mbsc-lv-right .mbsc-lv-multi-ic-right {
    display: table;
  }
  .mbsc-lv-left .mbsc-lv-multi-ic-left {
    display: table;
  }
  .mbsc-lv-ic-m {
    display: table-cell;
    vertical-align: middle;
    padding: 0 0.25em;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .mbsc-lv-ic-m:before {
    display: block;
    padding: 0.25em 0;
    font-size: 1.333334em;
  }
  .mbsc-lv-ic-disabled {
    opacity: 0.5;
  }
  .mbsc-lv-sl-c {
    position: relative;
    -webkit-animation-fill-mode: forwards;
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  .mbsc-lv-sl-r {
    -webkit-animation: mbsc-lv-remove-left 300ms;
    animation: mbsc-lv-remove-left 300ms;
  }
  .mbsc-lv-sl-l {
    -webkit-animation: mbsc-lv-remove-right 300ms;
    animation: mbsc-lv-remove-right 300ms;
  }
  .mbsc-lv-sl-l .mbsc-lv-sl-curr,
  .mbsc-lv-sl-r .mbsc-lv-sl-curr {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .mbsc-lv-sl-r .mbsc-lv-sl-new {
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  .mbsc-lv-sl-l .mbsc-lv-sl-new {
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
  .mbsc-lv-arr {
    position: absolute;
    top: 50%;
    width: 2em;
    height: 2em;
    font-size: 1em;
    line-height: 2em;
    margin-top: -1em;
  }
  .mbsc-lv .mbsc-lv-item.mbsc-lv-parent {
    padding-right: 2.25em;
  }
  .mbsc-lv .mbsc-lv-item.mbsc-lv-back {
    padding-left: 2.25em;
    z-index: 3;
  }
  .mbsc-lv-parent .mbsc-lv-arr {
    right: 0;
  }
  .mbsc-lv-handle-right .mbsc-lv-parent .mbsc-lv-arr {
    right: 40px;
  }
  .mbsc-rtl .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-item-ic-right.mbsc-lv-parent {
    padding-right: 5.25em;
  }
  .mbsc-rtl .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-item-ic-right.mbsc-lv-parent .mbsc-lv-item-ic {
    right: 2.5em;
  }
  .mbsc-lv-back .mbsc-lv-arr {
    left: 0;
  }
  .mbsc-lv .mbsc-lv-txt {
    margin: 0;
    font-weight: normal;
  }
  .mbsc-lv h1.mbsc-lv-txt {
    font-size: 2em;
  }
  .mbsc-lv h2.mbsc-lv-txt {
    font-size: 1.5em;
  }
  .mbsc-lv h3.mbsc-lv-txt {
    font-size: 1.125em;
  }
  .mbsc-lv h4.mbsc-lv-txt {
    font-size: 1em;
  }
  .mbsc-lv h5.mbsc-lv-txt {
    font-size: 0.875em;
  }
  .mbsc-lv h6.mbsc-lv-txt {
    font-size: 0.625em;
  }
  .mbsc-lv p.mbsc-lv-txt {
    font-size: 0.75em;
  }
  .mbsc-lv-img {
    position: absolute;
    max-height: 2.5em;
    max-width: 2.5em;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 5em;
  }
  .mbsc-lv-cont .mbsc-lv-item.mbsc-lv-img-right {
    padding-right: 5em;
  }
  .mbsc-lv-img-left .mbsc-lv-img {
    left: 2.5em;
  }
  .mbsc-lv-img-right .mbsc-lv-img {
    right: 0;
  }
  .mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 6.25em;
  }
  .mbsc-lv-handle-left .mbsc-lv-img-left .mbsc-lv-img {
    left: 3.75em;
  }
  .mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item.mbsc-lv-img-right {
    padding-right: 6.25em;
  }
  .mbsc-lv-handle-right .mbsc-lv-img-right .mbsc-lv-img {
    right: 1.25em;
  }
  .mbsc-lv-item-ic {
    position: absolute;
    top: 50%;
    margin-top: -1em;
    width: 2em;
    height: 2em;
    line-height: 2em;
    text-align: center;
  }
  .mbsc-lv-cont .mbsc-lv .mbsc-lv-item.mbsc-lv-item-ic-left {
    padding-left: 4em;
  }
  .mbsc-lv-cont .mbsc-lv .mbsc-lv-item.mbsc-lv-item-ic-right {
    padding-right: 4em;
  }
  .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
    left: 1em;
  }
  .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
    right: 1em;
  }
  .mbsc-lv-handle-left.mbsc-lv-cont .mbsc-lv .mbsc-lv-item-ic-left {
    padding-left: 5.5em;
  }
  .mbsc-lv-handle-left .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
    left: 2.5em;
  }
  .mbsc-lv-handle-right.mbsc-lv-cont .mbsc-lv .mbsc-lv-item-ic-right {
    padding-right: 5.5em;
  }
  .mbsc-lv-handle-right .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
    right: 2.5em;
  }
  .mbsc-lv-loading {
    text-align: center;
    opacity: 0;
    height: 0;
  }
  .mbsc-lv-loading .mbsc-ic {
    font-size: 1.375em;
  }
  .mbsc-lv-loading .mbsc-ic:before {
    display: inline-block;
    -webkit-animation: mbsc-lv-rotating 0.6s linear infinite;
    animation: mbsc-lv-rotating 0.6s linear infinite;
  }
  .mbsc-show-lv-loading.mbsc-lv-loading {
    opacity: 1;
    height: auto;
    padding: 1em;
  }
  .mbsc-ios .mbsc-lv-item.mbsc-lv-item-dragging {
    margin: 0;
  }
  .mbsc-ios .mbsc-lv-handle-c {
    width: 4em;
  }
  .mbsc-ios .mbsc-lv-handle-bar {
    margin: 0.125em 1em;
  }
  .mbsc-ios.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item {
    padding-left: 4em;
  }
  .mbsc-ios.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item {
    padding-right: 4em;
  }
  .mbsc-ios.mbsc-lv-handle-right .mbsc-lv-parent .mbsc-lv-arr {
    right: 3.125em;
  }
  .mbsc-ios.mbsc-lv-has-fixed-header .mbsc-lv-gr-title,
  .mbsc-ios.mbsc-lv-fixed-header .mbsc-lv-gr-title {
    line-height: 1.125em;
    font-size: 1em;
    padding: 0.4em 1em;
    border: 0;
    font-weight: bold;
  }
  .mbsc-ios .mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 4.5em;
  }
  .mbsc-ios .mbsc-lv-item.mbsc-lv-img-right {
    padding-right: 4.5em;
  }
  .mbsc-ios .mbsc-lv-img-left .mbsc-lv-img {
    left: 2.25em;
  }
  .mbsc-ios .mbsc-lv-img-right .mbsc-lv-img {
    right: -0.25em;
  }
  .mbsc-ios.mbsc-lv-handle-right .mbsc-lv-img-right .mbsc-lv-img {
    right: 2.75em;
  }
  .mbsc-ios.mbsc-lv-handle-left .mbsc-lv-img-left .mbsc-lv-img {
    left: 5.25em;
  }
  .mbsc-ios.mbsc-lv-handle-right .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
    right: 4em;
  }
  .mbsc-ios.mbsc-lv-handle-left .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
    left: 4em;
  }
  .mbsc-ios.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 7.5em;
  }
  .mbsc-ios.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item.mbsc-lv-img-right {
    padding-right: 7.5em;
  }
  .mbsc-ios.mbsc-lv-handle-left .mbsc-lv-item-ic-left {
    padding-left: 7em;
  }
  .mbsc-ios.mbsc-lv-handle-right .mbsc-lv-item-ic-right {
    padding-right: 7em;
  }
  .mbsc-ios .mbsc-lv-arr {
    font-size: 1.3125em;
  }
  .mbsc-ios .mbsc-lv-item-ic-left:before,
  .mbsc-ios .mbsc-lv-item-ic-left:after {
    left: 4em;
  }
  .mbsc-ios .mbsc-lv-img-left:before,
  .mbsc-ios .mbsc-lv-img-left:after {
    left: 4.5em;
  }
  .mbsc-ios.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #f4f4f4;
  }
  .mbsc-ios .mbsc-lv-item-hl,
  .mbsc-ios .mbsc-lv .mbsc-lv-item.mbsc-lv-item-active {
    background: #d8d8d8;
  }
  .mbsc-ios .mbsc-lv-handle-bar {
    background: #ccc;
  }
  .mbsc-ios .mbsc-lv-arr {
    color: #ccc;
  }
  .mbsc-ios.mbsc-lv-has-fixed-header .mbsc-lv-gr-title,
  .mbsc-ios.mbsc-lv-fixed-header .mbsc-lv-gr-title {
    color: #000;
  }
  .mbsc-ios .mbsc-lv-ic-m {
    color: #787878;
  }
  .mbsc-ios .mbsc-lv-loading .mbsc-ic {
    color: #000;
  }
  .mbsc-ios.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(0, 123, 255, 0.1);
  }
  .mbsc-material .mbsc-lv-item-dragging {
    margin: 0;
  }
  .mbsc-material .mbsc-lv-item-swiping {
    overflow: visible;
  }
  .mbsc-material.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item {
    padding-left: 3.125em;
  }
  .mbsc-material.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item {
    padding-right: 3.125em;
  }
  .mbsc-material .mbsc-lv-arr:before {
    font-size: 1.5em;
  }
  .mbsc-material .mbsc-lv-handle-c {
    width: 3.125em;
  }
  .mbsc-material .mbsc-lv-handle-bar {
    margin: 0.125em 1em;
  }
  .mbsc-material .mbsc-lv-img-left .mbsc-lv-img {
    left: 2.25em;
  }
  .mbsc-material .mbsc-lv-img-right .mbsc-lv-img {
    right: -0.25em;
  }
  .mbsc-material.mbsc-lv-handle-left .mbsc-lv-img-left .mbsc-lv-img {
    left: 4.375em;
  }
  .mbsc-material.mbsc-lv-handle-right .mbsc-lv-img-right .mbsc-lv-img {
    right: 1.875em;
  }
  .mbsc-material.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item.mbsc-lv-img-left {
    padding-left: 6.625em;
  }
  .mbsc-material.mbsc-lv-handle-right .mbsc-lv .mbsc-lv-item.mbsc-lv-img-right {
    padding-right: 6.625em;
  }
  .mbsc-material.mbsc-lv-handle-left .mbsc-lv-item-ic-left .mbsc-lv-item-ic {
    left: 3.125em;
  }
  .mbsc-material.mbsc-lv-handle-right .mbsc-lv-item-ic-right .mbsc-lv-item-ic {
    right: 3.125em;
  }
  .mbsc-material.mbsc-lv-handle-left .mbsc-lv-item-ic-left {
    padding-left: 6.125em;
  }
  .mbsc-material.mbsc-lv-handle-right .mbsc-lv-item-ic-right {
    padding-right: 6.125em;
  }
  .mbsc-material .mbsc-lv-item-hl:before,
  .mbsc-material .mbsc-lv-item-dragging:before {
    background: rgba(0, 0, 0, 0.05);
  }
  .mbsc-material.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #f8f8f8;
  }
  .mbsc-material .mbsc-lv-ic-m {
    color: #fff;
  }
  .mbsc-material .mbsc-lv-arr {
    color: #009688;
  }
  .mbsc-material .mbsc-lv-handle-bar {
    background: #009688;
  }
  .mbsc-material p.mbsc-lv-txt {
    color: #757474;
  }
  .mbsc-material .mbsc-lv-loading .mbsc-ic {
    color: #5b5b5b;
  }
  .mbsc-material.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-mobiscroll .mbsc-lv-item.mbsc-lv-item-dragging {
    margin: 0;
    box-shadow: none;
  }
  .mbsc-mobiscroll.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #ededed;
  }
  .mbsc-mobiscroll .mbsc-lv-item-hl:before {
    background: rgba(78, 204, 196, 0.15);
  }
  .mbsc-mobiscroll .mbsc-lv-ic-m {
    color: #fff;
  }
  .mbsc-mobiscroll .mbsc-lv-arr {
    color: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-lv-handle-bar {
    background: #4eccc4;
  }
  .mbsc-mobiscroll .mbsc-lv-item.mbsc-lv-item-dragging {
    background: #4eccc4;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-lv-item-dragging .mbsc-lv-handle-bar {
    background: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-lv-item-dragging .mbsc-lv-arr {
    color: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-lv-loading .mbsc-ic {
    color: #454545;
  }
  .mbsc-mobiscroll.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(78, 204, 196, 0.3);
  }
  .mbsc-windows .mbsc-lv-fixed-header {
    font-size: 16px;
  }
  .mbsc-windows .mbsc-lv .mbsc-lv-item.mbsc-lv-item-dragging {
    margin: 0;
    opacity: 0.7;
    box-shadow: none;
  }
  .mbsc-windows.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item.mbsc-checkbox {
    padding-left: 5em;
  }
  .mbsc-windows.mbsc-lv-handle-left .mbsc-lv .mbsc-lv-item.mbsc-checkbox .mbsc-checkbox-box {
    left: 3em;
  }
  .mbsc-windows .mbsc-lv .mbsc-lv-item.mbsc-lv-item-ic-left.mbsc-checkbox {
    padding-left: 5.625em;
  }
  .mbsc-windows .mbsc-lv .mbsc-lv-item.mbsc-lv-item-ic-left.mbsc-checkbox .mbsc-checkbox-box {
    left: 3.25em;
  }
  .mbsc-windows .mbsc-lv-handle-bar {
    background: #888;
  }
  .mbsc-windows .mbsc-lv-ic-m {
    color: #f2f2f2;
  }
  .mbsc-windows.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #e5e5e5;
  }
  .mbsc-windows .mbsc-lv-item.mbsc-lv-item-hl {
    background: #ccc;
  }
  .mbsc-windows .mbsc-lv-loading .mbsc-ic {
    color: #262626;
  }
  .mbsc-windows.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(0, 120, 215, 0.2);
  }
  .mbsc-msr .mbsc-sc-whl-gr {
    direction: ltr;
  }
  .mbsc-msr .mbsc-rtl .mbsc-sc-whl-w {
    -webkit-box-ordinal-group: 2;
    -moz-box-ordinal-group: 2;
    -ms-flex-order: 2;
    order: 2;
  }
  .mbsc-msr .mbsc-rtl .mbsc-msr-whl-unit {
    -webkit-box-ordinal-group: 1;
    -moz-box-ordinal-group: 1;
    -ms-flex-order: 1;
    order: 1;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  .mbsc-scv-c {
    overflow: hidden;
    position: relative;
    font-size: 16px;
    font-family: arial, verdana, sans-serif;
    -webkit-font-smoothing: antialiased;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-text-size-adjust: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -ms-touch-action: pan-y;
    user-select: none;
    touch-action: pan-y;
  }
  .mbsc-scv,
  .mbsc-scv-sc {
    position: relative;
  }
  .mbsc-rtl {
    direction: rtl;
  }
  .mbsc-scv,
  .mbsc-scv-sc,
  .mbsc-scv-item {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    height: 100%;
  }
  .mbsc-scv,
  .mbsc-scv-item {
    -webkit-flex: 0 0 auto;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }
  .mbsc-scv.mbsc-comp {
    padding: 0;
    margin: 0 auto;
    list-style: none;
    line-height: normal;
    -webkit-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
  }
  .mbsc-scv-item {
    height: auto;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -webkit-box-pack: center;
    -moz-box-pack: center;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    -ms-justify-content: center;
    justify-content: center;
    box-sizing: border-box;
  }
  .mbsc-scv-liq,
  .mbsc-scv-liq .mbsc-scv-item {
    -webkit-box-flex: 1;
    -webkit-flex: 1 0 auto;
    -ms-flex: 1 0 auto;
    flex: 1 0 auto;
  }
  .mbsc-ms-c {
    text-align: center;
  }
  .mbsc-ms-top.mbsc-ms-c,
  .mbsc-ms-bottom.mbsc-ms-c {
    z-index: 9999;
    position: fixed;
    left: 0;
    right: 0;
    height: auto;
  }
  .mbsc-ms-top.mbsc-ms-c .mbsc-ms,
  .mbsc-ms-bottom.mbsc-ms-c .mbsc-ms {
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
  }
  .mbsc-ms-top.mbsc-ms-c {
    padding-top: constant(safe-area-inset-top);
    padding-top: env(safe-area-inset-top);
  }
  .mbsc-ms-bottom.mbsc-ms-c {
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }
  .mbsc-ms-top.mbsc-scv-ctx,
  .mbsc-ms-bottom.mbsc-scv-ctx {
    position: absolute;
  }
  .mbsc-ms-top {
    top: 0;
  }
  .mbsc-ms-bottom {
    bottom: 0;
  }
  .mbsc-ms {
    text-align: center;
    white-space: nowrap;
  }
  .mbsc-ms-item {
    position: relative;
    margin: 0;
    font-size: 1em;
    line-height: 1.25;
    min-width: 4em;
    padding: 0.75em 0.5em;
    text-decoration: none;
    cursor: pointer;
    overflow: hidden;
    transition-property: color, background, border-color, text-shadow;
    transition-duration: 200ms;
  }
  .mbsc-scv-hasw .mbsc-ms-item {
    white-space: normal;
    min-width: 0;
  }
  .mbsc-ms.mbsc-comp a.mbsc-ms-item {
    font-weight: normal;
    text-decoration: none;
  }
  .mbsc-ms .mbsc-ms-item.mbsc-disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  .mbsc-ms-icons .mbsc-ms-item {
    min-width: 6em;
    padding: 1.666667em 0.5em;
    font-size: 0.75em;
  }
  .mbsc-ms-icons.mbsc-ms-txt .mbsc-ms-item {
    padding-top: 3em;
    padding-bottom: 0.5em;
  }
  .mbsc-ms-ic:before {
    position: absolute;
    top: 0.25em;
    left: 0;
    right: 0;
    display: block;
    text-align: center;
    line-height: 1em;
  }
  .mbsc-ms-base .mbsc-ms-ic:before {
    font-size: 2em;
  }
  .mbsc-ms-badge {
    position: absolute;
    top: 0.25em;
    min-width: 1.666667em;
    height: 1.666667em;
    padding: 0 0.25em;
    border-radius: 2em;
    line-height: 1.666667em;
    background: red;
    color: #fff;
    text-align: center;
    box-sizing: border-box;
  }
  .mbsc-ltr .mbsc-ms-badge {
    left: 50%;
    margin-left: 0.5em;
  }
  .mbsc-rtl .mbsc-ms-badge {
    right: 50%;
    margin-right: 0.5em;
  }
  .mbsc-ms-txt:not(.mbsc-ms-icons) .mbsc-ms-badge {
    font-size: 0.75em;
  }
  .mbsc-ms-txt:not(.mbsc-ms-icons).mbsc-ltr .mbsc-ms-badge {
    left: auto;
    right: 0;
  }
  .mbsc-ms-txt:not(.mbsc-ms-icons).mbsc-rtl .mbsc-ms-badge {
    left: 0;
    right: auto;
  }
  .mbsc-ms-item.mbsc-menu-item-ic {
    padding: 1.666667em 0.5em;
    font-size: 0.75em;
  }
  .mbsc-ms-item.mbsc-menu-item-ic:before {
    top: 50%;
    margin-top: -0.5em;
  }
  .mbsc-ms-icons .mbsc-ms-item.mbsc-menu-item-ic:before {
    top: 0.25em;
    margin-top: 0;
  }
  .mbsc-ms-hamburger.mbsc-ms-c.mbsc-ms-inline {
    display: inline-block;
    vertical-align: middle;
    background: none;
    border: 0;
  }
  .mbsc-ms-more .mbsc-ms {
    display: block;
  }
  .mbsc-ms-more .mbsc-ms-item {
    min-height: 2.857143em;
  }
  .mbsc-ms-more .mbsc-ms-item.mbsc-ms-ic:before {
    top: 50%;
    width: 2.333334em;
    margin-top: -0.5em;
    line-height: 1em;
    font-size: 1.714286em;
  }
  .mbsc-ms-more .mbsc-ms-badge {
    top: 50%;
    font-size: 0.857143em;
    margin-top: -0.833333em;
  }
  .mbsc-ms-more .mbsc-ltr .mbsc-ms {
    text-align: left;
  }
  .mbsc-ms-more .mbsc-ltr .mbsc-ms .mbsc-ms-ic {
    padding-left: 4em;
  }
  .mbsc-ms-more .mbsc-ltr .mbsc-ms-ic:before {
    right: auto;
  }
  .mbsc-ms-more .mbsc-ltr .mbsc-ms-badge {
    left: auto;
    right: 0.5em;
    margin-left: 0;
  }
  .mbsc-ms-more .mbsc-rtl .mbsc-ms {
    text-align: right;
  }
  .mbsc-ms-more .mbsc-rtl .mbsc-ms .mbsc-ms-ic {
    padding-right: 4em;
  }
  .mbsc-ms-more .mbsc-rtl .mbsc-ms-ic:before {
    left: auto;
  }
  .mbsc-ms-more .mbsc-rtl .mbsc-ms-badge {
    right: auto;
    left: 0.5em;
    margin-right: 0;
  }
  .mbsc-ms-more.mbsc-ms-more-icons .mbsc-fr-popup .mbsc-fr-w {
    min-width: 0;
  }
  .mbsc-ms-more.mbsc-ms-more-icons .mbsc-wdg-c.mbsc-w-p {
    padding: 0;
  }
  .mbsc-ms-more.mbsc-ms-more-icons .mbsc-ms .mbsc-ms-ic {
    padding-left: 0.714286em;
    padding-right: 0.714286em;
  }
  .mbsc-ms-more.mbsc-ms-more-icons .mbsc-ms-ic:before {
    position: static;
    width: 1.5em;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-ios .mbsc-form-group-title + .mbsc-ms-c {
    margin-top: -1px;
  }
  .mbsc-ios.mbsc-ms-top {
    border-top: 0;
  }
  .mbsc-ios.mbsc-ms-bottom {
    border-bottom: 0;
  }
  .mbsc-ios.mbsc-ms-nosel .mbsc-ms-item.mbsc-active {
    opacity: 0.5;
  }
  .mbsc-ios.mbsc-ms-c {
    background: #f7f7f7;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
  }
  .mbsc-ios .mbsc-ms .mbsc-ms-item {
    color: #878787;
  }
  .mbsc-ios.mbsc-ms-nosel .mbsc-ms-item,
  .mbsc-ios .mbsc-ms .mbsc-ms-item-sel {
    color: #007bff;
  }
  .mbsc-ios.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: rgba(0, 123, 255, 0.15);
  }
  .mbsc-ios.mbsc-ms-more .mbsc-wdg-c.mbsc-w-p {
    padding: 0;
  }
  .mbsc-ios.mbsc-ms-more .mbsc-ms-item {
    padding: 1em;
  }
  .mbsc-ios.mbsc-ms-more .mbsc-ms-item:after {
    content: "";
    position: absolute;
    right: 0;
    left: 1em;
    bottom: 0;
  }
  .mbsc-ios.mbsc-ms-more .mbsc-ms-item:last-child:after {
    display: none;
  }
  .mbsc-ios.mbsc-ms-more .mbsc-ms-ic:after {
    left: 4em;
  }
  .mbsc-ios.mbsc-ms-more-icons .mbsc-ms-ic:after {
    left: 0;
  }
  .mbsc-ios.mbsc-ms-more .mbsc-ms-item:after {
    border-top: 1px solid #ccc;
  }
  .mbsc-material .mbsc-ms .mbsc-ms-item {
    overflow: hidden;
  }
  .mbsc-material.mbsc-ms-b .mbsc-ms-item {
    border: 2px solid transparent;
    border-width: 2px 0;
  }
  .mbsc-material.mbsc-ms-c {
    background: #eee;
  }
  .mbsc-material .mbsc-ms .mbsc-ms-item {
    color: #5b5b5b;
  }
  .mbsc-material .mbsc-ms .mbsc-ms-item.mbsc-ms-item-sel {
    color: #009688;
  }
  .mbsc-material .mbsc-ms .mbsc-ms-item.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material.mbsc-ms-top {
    border-bottom: 1px solid #c1c1c1;
  }
  .mbsc-material.mbsc-ms-bottom {
    border-top: 1px solid #c1c1c1;
  }
  .mbsc-material.mbsc-ms-a .mbsc-ms-item.mbsc-ms-item-sel {
    color: #009688;
  }
  .mbsc-material.mbsc-ms-b .mbsc-ms-item.mbsc-ms-item-sel {
    border-bottom-color: #009688;
  }
  .mbsc-material.mbsc-ms-b.mbsc-ms-bottom .mbsc-ms-item.mbsc-ms-item-sel {
    border-top-color: #009688;
    border-bottom-color: transparent;
  }
  .mbsc-material.mbsc-ms-more .mbsc-wdg-c.mbsc-w-p {
    padding: 0.5em 0;
  }
  .mbsc-material.mbsc-ms-more .mbsc-ms-item {
    padding: 0.875em 1em;
  }
  .mbsc-mobiscroll.mbsc-ms-b .mbsc-ms-item {
    border: 2px solid transparent;
    border-width: 2px 0;
  }
  .mbsc-mobiscroll.mbsc-ms-c {
    background: #f7f7f7;
  }
  .mbsc-mobiscroll .mbsc-ms .mbsc-ms-item {
    color: #454545;
  }
  .mbsc-mobiscroll.mbsc-ms-c .mbsc-scv-sc .mbsc-ms .mbsc-ms-item.mbsc-active {
    background: rgba(78, 204, 196, 0.3);
  }
  .mbsc-mobiscroll.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: rgba(78, 204, 196, 0.1);
  }
  .mbsc-mobiscroll.mbsc-ms-b .mbsc-ms-item-sel {
    border-bottom-color: #4eccc4;
  }
  .mbsc-mobiscroll.mbsc-ms-b.mbsc-ms-top {
    border-bottom: 1px solid #4eccc4;
  }
  .mbsc-mobiscroll.mbsc-ms-b.mbsc-ms-bottom {
    border-top: 1px solid #4eccc4;
  }
  .mbsc-mobiscroll.mbsc-ms-b.mbsc-ms-bottom .mbsc-ms-item-sel {
    border-top-color: #4eccc4;
    border-bottom-color: transparent;
  }
  .mbsc-mobiscroll.mbsc-ms-a.mbsc-ms-top {
    border-bottom: 1px solid #ccc;
  }
  .mbsc-mobiscroll.mbsc-ms-a.mbsc-ms-bottom {
    border-top: 1px solid #ccc;
  }
  .mbsc-mobiscroll.mbsc-ms-a .mbsc-ms-item-sel {
    color: #4eccc4;
  }
  .mbsc-windows.mbsc-ms-c {
    font-family: Segoe UI, arial, verdana, sans-serif;
  }
  .mbsc-windows .mbsc-ms-item {
    padding: 0.4em 0.3em;
    font-size: 1.25em;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
  }
  .mbsc-windows .mbsc-ms-item.mbsc-ms-ic:before {
    font-size: 22px;
    top: 0.4090909em;
  }
  .mbsc-windows.mbsc-ms-icons .mbsc-ms-item {
    font-size: 0.875em;
    line-height: 0.875em;
    padding: 1.428571em 0.428571em;
  }
  .mbsc-windows.mbsc-ms-icons.mbsc-ms-txt .mbsc-ms-item {
    padding: 2.571428em 0.428571em 0.428571em 0.428571em;
    line-height: 1.428571;
  }
  .mbsc-windows.mbsc-ms-icons.mbsc-ms-txt .mbsc-ms-item:before {
    top: 0.4090909em;
  }
  .mbsc-windows.mbsc-ms-bottom .mbsc-ms-item {
    border: 0;
  }
  .mbsc-windows.mbsc-ms-c {
    background: #f2f2f2;
    color: #262626;
  }
  .mbsc-windows .mbsc-ms-item.mbsc-active,
  .mbsc-windows.mbsc-no-touch .mbsc-ms-item.mbsc-active:not(.mbsc-disabled):hover {
    background: #ccc;
  }
  .mbsc-windows .mbsc-ms-item-sel,
  .mbsc-windows .mbsc-ms-item-sel.mbsc-ms-ic:before {
    color: #0078d7;
  }
  .mbsc-windows.mbsc-ms-b .mbsc-ms-item.mbsc-ms-item-sel {
    border-bottom-color: #0078d7;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: #d9d8d8;
  }
  .mbsc-windows .mbsc-ms-item.mbsc-menu-item-ic {
    padding: 1em 0.3em;
    min-width: 3em;
  }
  .mbsc-windows .mbsc-ms-item.mbsc-menu-item-ic:before {
    top: 0.9545454em;
  }
  .mbsc-windows .mbsc-ms-icons .mbsc-ms-item.mbsc-menu-item-ic:before {
    top: 0.4090909em;
  }
  .mbsc-windows.mbsc-ms-more .mbsc-fr-w,
  .mbsc-windows.mbsc-ms-more .mbsc-wdg-c.mbsc-w-p {
    padding: 0;
  }
  .mbsc-windows.mbsc-ms-more .mbsc-ms-item {
    padding: 1em;
    font-size: 1em;
  }
  .mbsc-windows.mbsc-ms-more .mbsc-ms-item.mbsc-ms-ic {
    background: none;
  }
  .mbsc-windows.mbsc-ms-more .mbsc-ms-item.mbsc-ms-ic:before {
    top: 0.9545454em;
  }
  .mbsc-windows.mbsc-ms-more-icons .mbsc-ms-item {
    padding: 0.5em;
  }
  .mbsc-windows.mbsc-ms-more-icons .mbsc-ms-item.mbsc-ms-ic:before {
    margin: 0;
  }
  .mbsc-windows .mbsc-ms-badge {
    font-size: 0.857142em;
  }
  .mbsc-windows .mbsc-ms-item.mbsc-menu-item-ic {
    color: #262626;
  }
  .mbsc-windows.mbsc-ms-more .mbsc-ms-item {
    color: #262626;
  }
  .mbsc-windows.mbsc-ms-more .mbsc-ms-item.mbsc-ms-item-sel {
    color: #0078d7;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  .mbsc-np-hdr {
    position: relative;
  }
  .mbsc-np-dsp {
    direction: ltr;
    padding: 0.3125em 1.75em 0.3125em 0.5em;
    text-align: left;
    font-size: 2em;
    line-height: normal;
  }
  .mbsc-np-sup {
    display: inline-block;
    font-size: 0.375em;
    vertical-align: top;
  }
  .mbsc-np-del {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: 3.5em;
    text-align: center;
  }
  .mbsc-np-del:before {
    display: block;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    margin-top: -0.5em;
    font-size: 2em;
    line-height: 1;
  }
  .mbsc-np-ph {
    display: inline-block;
    min-width: 0.5625em;
    text-align: center;
    vertical-align: top;
  }
  .mbsc-np-tbl-c {
    min-width: 15em;
  }
  .mbsc-fr-top .mbsc-np-tbl-c,
  .mbsc-fr-bottom .mbsc-np-tbl-c {
    display: block;
  }
  .mbsc-np-tbl {
    direction: ltr;
    display: table;
    width: 100%;
    font-size: 1.375em;
  }
  .mbsc-np-row {
    display: table-row;
  }
  .mbsc-np-btn {
    position: relative;
    z-index: 0;
    width: 33.3333333333%;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
    height: 3em;
    -webkit-user-select: none;
  }
  .mbsc-np-btn.mbsc-active {
    opacity: 1;
  }
  .mbsc-np-time {
    text-transform: uppercase;
  }
  .mbsc-np-ts-h,
  .mbsc-np-ts-m {
    font-weight: bold;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap.mbsc-np .mbsc-fr-w {
    font-size: 16px;
  }
  .mbsc-bootstrap.mbsc-np .mbsc-fr-btn-cont {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-np-del.mbsc-active {
    opacity: 0.5;
  }
  .mbsc-bootstrap .mbsc-np-btn {
    border-top-width: 0;
    border-right-width: 0;
    font-size: inherit;
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-np-row:first-child .mbsc-np-btn {
    border-top-width: 1px;
  }
  .mbsc-bootstrap .mbsc-np-btn:first-child {
    border-left-width: 0;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-np-row:last-child .mbsc-np-btn {
    border-bottom-width: 0;
  }
  .mbsc-bootstrap .mbsc-np-row .mbsc-np-btn.mbsc-np-btn-empty {
    background: none;
  }
  .mbsc-ios.mbsc-np .mbsc-fr-c {
    font-size: 1.333334em;
  }
  .mbsc-ios .mbsc-np-btn {
    font-size: 1.363637em;
    height: 2.2em;
  }
  .mbsc-ios .mbsc-np-btn:first-child {
    border-left: 0;
  }
  .mbsc-ios .mbsc-np-ts-h,
  .mbsc-ios .mbsc-np-ts-m {
    font-weight: normal;
  }
  .mbsc-ios .mbsc-np-del:before {
    font-size: 1.125em;
  }
  .mbsc-ios .mbsc-np-btn {
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
  }
  .mbsc-ios .mbsc-np-btn.mbsc-active,
  .mbsc-ios .mbsc-np-btn-empty {
    background: #d1d5db;
  }
  .mbsc-ios .mbsc-np-btn.mbsc-disabled {
    color: #d6d6d6;
  }
  .mbsc-material .mbsc-np-del,
  .mbsc-material .mbsc-np-btn {
    overflow: hidden;
  }
  .mbsc-material .mbsc-np-btn {
    font-size: 1.4545em;
    height: 2.0625em;
  }
  .mbsc-material .mbsc-np-del {
    font-size: 0.875em;
  }
  .mbsc-material .mbsc-np-tbl-c {
    padding: 0.5em;
  }
  .mbsc-material .mbsc-np-btn.mbsc-disabled {
    opacity: 0.5;
  }
  .mbsc-material .mbsc-np-del {
    color: #009688;
  }
  .mbsc-mobiscroll .mbsc-np-tbl-c {
    padding: 0.5em;
  }
  .mbsc-mobiscroll .mbsc-np-btn.mbsc-disabled {
    opacity: 0.5;
  }
  .mbsc-mobiscroll .mbsc-np-del {
    color: #4eccc4;
  }
  .mbsc-windows.mbsc-fr .mbsc-fr-w .mbsc-np-del.mbsc-active {
    top: 0;
  }
  .mbsc-windows .mbsc-np-del.mbsc-active:before {
    background: none;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-fr-c .mbsc-np-del:hover:before {
    background: transparent;
  }
  .mbsc-windows .mbsc-np-btn {
    top: 0;
  }
  .mbsc-windows .mbsc-np-btn-empty {
    background: none;
  }
  .mbsc-windows.mbsc-fr .mbsc-fr-w .mbsc-np-del.mbsc-active {
    background: #ccc;
  }
  .mbsc-windows .mbsc-np-del.mbsc-active:before {
    color: #262626;
  }
  .mbsc-windows.mbsc-fr .mbsc-fr-w .mbsc-np-btn.mbsc-active {
    background: #ccc;
    color: #000;
  }
  .mbsc-windows .mbsc-np-btn.mbsc-disabled {
    color: #999;
  }
  .mbsc-bootstrap.mbsc-ms-c {
    display: block;
    margin: 0;
    padding: 0;
    border-radius: 0;
    border-width: 1px 0;
    max-width: none;
    box-shadow: none;
  }
  .mbsc-bootstrap.mbsc-ms-inline {
    z-index: 0;
  }
  .mbsc-bootstrap.mbsc-ms-top {
    bottom: auto;
    border-top-width: 0;
  }
  .mbsc-bootstrap.mbsc-ms-bottom {
    top: auto;
    border-bottom-width: 0;
  }
  .mbsc-bootstrap.mbsc-ms-c .mbsc-ms-item {
    float: none;
    margin: 0;
    font-size: 0.75em;
    border-width: 0 0 0 1px;
    border-radius: 0;
  }
  .mbsc-bootstrap.mbsc-ms-ltr .mbsc-ms-item:first-child,
  .mbsc-bootstrap.mbsc-ms-rtl .mbsc-ms-item:last-child {
    border: 0;
  }
  .mbsc-range-btn,
  .mbsc-range-btn-t,
  .mbsc-range-btn-c {
    box-sizing: border-box;
  }
  .mbsc-range-btn-t {
    display: table;
    width: 100%;
  }
  .mbsc-range-btn-c {
    display: table-cell;
    width: 50%;
  }
  .mbsc-range-btn {
    text-decoration: none;
    text-align: center;
    display: block;
    cursor: pointer;
  }
  .mbsc-cal-hide-diff .mbsc-cal-day-diff:after {
    display: none;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  .mbsc-bootstrap .mbsc-cal-c {
    padding: 4px 2px 2px 2px;
  }
  .mbsc-bootstrap .mbsc-cal-hdr {
    font-size: 18px;
    line-height: 40px;
  }
  .mbsc-bootstrap .mbsc-cal .mbsc-cal-btn {
    width: 40px;
    height: 40px;
    background: none;
    color: inherit;
    margin: 0;
    padding: 0;
    border: 0;
    line-height: 40px;
  }
  .mbsc-bootstrap .mbsc-cal-picker {
    max-width: none;
    display: block;
    padding: 0;
    border: 0;
    border-radius: 0;
    box-shadow: none;
    text-align: center;
  }
  .mbsc-bootstrap .mbsc-cal-table {
    border-spacing: 2px;
  }
  .mbsc-bootstrap .mbsc-cal-cell:focus {
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-cal-cell:focus .mbsc-cal-cell-i {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-cal-day-i {
    padding: 2px;
    line-height: 34px;
  }
  .mbsc-bootstrap .mbsc-cal-hide-diff .mbsc-cal-day-diff {
    background: none;
  }
  .mbsc-bootstrap .mbsc-cal-today.mbsc-selected {
    color: #fff !important;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-cal-weeks .mbsc-cal-days-c {
    padding-left: 26px;
  }
  .mbsc-bootstrap .mbsc-rtl .mbsc-cal-weeks .mbsc-cal-days-c {
    padding-right: 26px;
  }
  .mbsc-bootstrap .mbsc-cal-week-nr {
    width: 26px;
    font-weight: bold;
  }
  .mbsc-bootstrap .mbsc-cal-tabs {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 4px 4px 0 4px;
    box-sizing: border-box;
  }
  .mbsc-bootstrap .mbsc-cal-tab {
    -webkit-box-flex: 1 0;
    -webkit-flex: 1 0;
    -ms-flex: 1 0;
    flex: 1 0;
    margin-bottom: -1px;
  }
  .mbsc-bootstrap .mbsc-cal-has-marks .mbsc-cal-day {
    padding-bottom: 0;
  }
  .mbsc-bootstrap .mbsc-cal-has-txt .mbsc-cal-day-i {
    line-height: 30px;
    padding-top: 5px;
    padding-bottom: 15px;
  }
  .mbsc-bootstrap .mbsc-cal-marks {
    height: 6px;
    margin-top: -9px;
  }
  .mbsc-bootstrap .mbsc-cal-mark {
    width: 4px;
    height: 4px;
    margin: 1px;
    border-radius: 4px;
    background: #aaa;
  }
  .mbsc-bootstrap .mbsc-cal-txt {
    left: 1px;
    right: 1px;
    height: 15px;
    margin-top: -1px;
    font-size: 10px;
    line-height: 15px;
  }
  .mbsc-bootstrap.mbsc-fr-liq.mbsc-fr-center .mbsc-fr-popup {
    border: 0;
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-range-btn-t {
    padding: 4px 2px 0 2px;
  }
  .mbsc-bootstrap .mbsc-range-btn {
    margin: 0 2px;
    line-height: 20px;
  }
  .mbsc-bootstrap.mbsc-range .mbsc-cal-day-hl .mbsc-cal-day-i {
    background: rgba(0, 0, 0, 0.3);
  }
  .mbsc-ios .mbsc-range-btn-t,
  .mbsc-ios .mbsc-range-btn-c {
    width: auto;
    display: block;
  }
  .mbsc-ios .mbsc-range-btn {
    position: relative;
    min-height: 2.75em;
    padding: 0.75em 1em;
    font-size: 1.333333em;
  }
  .mbsc-ios .mbsc-range-btn-v {
    position: absolute;
    top: 0.75em;
    font-weight: normal;
  }
  .mbsc-ios .mbsc-ltr .mbsc-range-btn {
    text-align: left;
  }
  .mbsc-ios .mbsc-ltr .mbsc-range-btn-v {
    right: 1em;
  }
  .mbsc-ios .mbsc-rtl .mbsc-range-btn {
    text-align: right;
  }
  .mbsc-ios .mbsc-rtl .mbsc-range-btn-v {
    left: 1em;
    right: auto;
  }
  .mbsc-ios .mbsc-range-btn-end .mbsc-range-btn:before {
    content: "";
    position: absolute;
    right: 0;
    left: 1em;
    top: 0;
  }
  .mbsc-ios.mbsc-range .mbsc-cal .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
    background: none;
  }
  .mbsc-ios.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0.3125em;
    left: 0;
    right: 0;
    height: 1.875em;
  }
  .mbsc-ios.mbsc-range .mbsc-cal-has-marks .mbsc-cal-day.mbsc-selected:after {
    top: 0.1875em;
  }
  .mbsc-ios.mbsc-range .mbsc-ltr .mbsc-cal-day:first-child:after,
  .mbsc-ios.mbsc-range .mbsc-rtl .mbsc-cal-day:last-child:after {
    left: 0.25em;
  }
  .mbsc-ios.mbsc-range .mbsc-ltr .mbsc-cal-day:last-child:after,
  .mbsc-ios.mbsc-range .mbsc-rtl .mbsc-cal-day:first-child:after {
    right: 0.25em;
  }
  .mbsc-ios.mbsc-range .mbsc-ltr .mbsc-cal-day.mbsc-cal-sel-start:after,
  .mbsc-ios.mbsc-range .mbsc-rtl .mbsc-cal-day.mbsc-cal-sel-end:after {
    left: 50%;
    margin-left: -0.9375em;
  }
  .mbsc-ios.mbsc-range .mbsc-ltr .mbsc-cal-day.mbsc-cal-sel-end:after,
  .mbsc-ios.mbsc-range .mbsc-rtl .mbsc-cal-day.mbsc-cal-sel-start:after {
    right: 50%;
    margin-right: -0.9375em;
  }
  .mbsc-ios.mbsc-range .mbsc-ltr .mbsc-cal-sel-start:after,
  .mbsc-ios.mbsc-range .mbsc-ltr .mbsc-cal-day:first-child:after,
  .mbsc-ios.mbsc-range .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-first:after,
  .mbsc-ios.mbsc-range .mbsc-rtl .mbsc-cal-sel-end:after,
  .mbsc-ios.mbsc-range .mbsc-rtl .mbsc-cal-day:last-child:after,
  .mbsc-ios.mbsc-range .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-last:after {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
  }
  .mbsc-ios.mbsc-range .mbsc-ltr .mbsc-cal-sel-end:after,
  .mbsc-ios.mbsc-range .mbsc-ltr .mbsc-cal-day:last-child:after,
  .mbsc-ios.mbsc-range .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-last:after,
  .mbsc-ios.mbsc-range .mbsc-rtl .mbsc-cal-sel-start:after,
  .mbsc-ios.mbsc-range .mbsc-rtl .mbsc-cal-day:first-child:after,
  .mbsc-ios.mbsc-range .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-first:after {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
  }
  .mbsc-ios .mbsc-range-btn-end {
    border-bottom: 1px solid #ccc;
  }
  .mbsc-ios .mbsc-range-btn-end .mbsc-range-btn:before {
    border-top: 1px solid #ccc;
  }
  .mbsc-ios .mbsc-range-btn.mbsc-selected .mbsc-range-btn-v {
    color: #007bff;
  }
  .mbsc-ios.mbsc-range .mbsc-cal .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date,
  .mbsc-ios.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    background: #007bff;
  }
  .mbsc-material .mbsc-range-btn {
    padding: 0.571428em 0.285714em;
    border-bottom: 2px solid transparent;
    margin-bottom: -1px;
    font-size: 0.875em;
    line-height: 1.428571em;
  }
  .mbsc-material.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0.125em;
    left: 0;
    right: 0;
    height: 2.25em;
  }
  .mbsc-material.mbsc-range .mbsc-ltr .mbsc-cal-sel-start:after,
  .mbsc-material.mbsc-range .mbsc-rtl .mbsc-cal-sel-end:after {
    left: 50%;
    margin-left: -1.125em;
  }
  .mbsc-material.mbsc-range .mbsc-ltr .mbsc-cal-sel-end:after,
  .mbsc-material.mbsc-range .mbsc-rtl .mbsc-cal-sel-start:after {
    right: 50%;
    margin-right: -1.125em;
  }
  .mbsc-material.mbsc-range .mbsc-ltr .mbsc-cal-sel-start:after,
  .mbsc-material.mbsc-range .mbsc-ltr .mbsc-cal-day:first-child:after,
  .mbsc-material.mbsc-range .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-first:after,
  .mbsc-material.mbsc-range .mbsc-rtl .mbsc-cal-sel-end:after,
  .mbsc-material.mbsc-range .mbsc-rtl .mbsc-cal-day:last-child:after,
  .mbsc-material.mbsc-range .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-last:after {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
  }
  .mbsc-material.mbsc-range .mbsc-ltr .mbsc-cal-sel-end:after,
  .mbsc-material.mbsc-range .mbsc-ltr .mbsc-cal-day:last-child:after,
  .mbsc-material.mbsc-range .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-last:after,
  .mbsc-material.mbsc-range .mbsc-rtl .mbsc-cal-sel-start:after,
  .mbsc-material.mbsc-range .mbsc-rtl .mbsc-cal-day:first-child:after,
  .mbsc-material.mbsc-range .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-first:after {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
  }
  .mbsc-material .mbsc-range-btn-t {
    border-bottom: 1px solid #c1c1c1;
  }
  .mbsc-material .mbsc-range-btn.mbsc-selected {
    border-color: #009688;
    color: #009688;
  }
  .mbsc-material.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
    background: none;
    border-color: transparent;
  }
  .mbsc-material.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
    background: #009688;
    border-color: #009688;
    color: #eee;
  }
  .mbsc-material.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    background: rgba(0, 150, 136, 0.1);
  }
  .mbsc-mobiscroll .mbsc-range-btn-t {
    padding: 0.5em 0.25em 0 0.25em;
  }
  .mbsc-mobiscroll .mbsc-range-btn {
    margin: 0 0.3333em;
    padding: 0.3333em;
    font-size: 0.75em;
    line-height: 1.666667em;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-fr-w {
    padding: 0;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-fr-hdr {
    padding-top: 0.6666em;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
    background: none;
    border-color: transparent;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    content: "";
    position: absolute;
    z-index: -1;
    top: 0.25em;
    left: 0;
    right: 0;
    height: 2em;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-ltr .mbsc-cal-sel-start:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-rtl .mbsc-cal-sel-end:after {
    left: 50%;
    margin-left: -1em;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-ltr .mbsc-cal-sel-end:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-rtl .mbsc-cal-sel-start:after {
    right: 50%;
    margin-right: -1em;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-ltr .mbsc-cal-sel-start:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-ltr .mbsc-cal-day:first-child:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-first:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-rtl .mbsc-cal-sel-end:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-rtl .mbsc-cal-day:last-child:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-last:after {
    border-top-left-radius: 2em;
    border-bottom-left-radius: 2em;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-ltr .mbsc-cal-sel-end:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-ltr .mbsc-cal-day:last-child:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-ltr .mbsc-cal-hide-diff .mbsc-cal-day-last:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-rtl .mbsc-cal-sel-start:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-rtl .mbsc-cal-day:first-child:after,
  .mbsc-mobiscroll.mbsc-range .mbsc-rtl .mbsc-cal-hide-diff .mbsc-cal-day-first:after {
    border-top-right-radius: 2em;
    border-bottom-right-radius: 2em;
  }
  .mbsc-mobiscroll .mbsc-range-btn {
    border: 1px solid #4eccc4;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-range-btn-t .mbsc-range-btn.mbsc-selected {
    background: #4eccc4;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
    background: none;
    border-color: transparent;
    color: #454545;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
    background: #4eccc4;
    border-color: #4eccc4;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    background: rgba(78, 204, 196, 0.3);
  }
  .mbsc-windows .mbsc-range-btn-t {
    padding: 0.5em 0.25em;
  }
  .mbsc-windows .mbsc-range-btn {
    margin: 0 0.3333em;
    font-size: 0.75em;
    line-height: 1.666667em;
    border-width: 1px;
  }
  .mbsc-windows .mbsc-range-btn {
    color: #262626;
    background: #ccc;
    border: 2px solid #ccc;
  }
  .mbsc-windows.mbsc-no-touch .mbsc-range-btn.mbsc-fr-btn-e:not(.mbsc-disabled):hover {
    border-color: #7f7f7f;
    background: #ccc;
  }
  .mbsc-windows .mbsc-range-btn.mbsc-selected,
  .mbsc-windows.mbsc-no-touch .mbsc-range-btn.mbsc-selected:not(.mbsc-disabled):hover {
    background: #0078d7;
    border-color: #0078d7;
    color: #fff;
  }
  .mbsc-windows.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-i,
  .mbsc-windows.mbsc-range .mbsc-cal .mbsc-cal-today.mbsc-cal-day-hl .mbsc-cal-day-date {
    background: #0078d7;
    color: #f2f2f2;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  .mbsc-sel-hdn {
    position: absolute;
    height: 1px !important;
    width: 1px !important;
    margin: 0 !important;
    padding: 0 !important;
    left: 0;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  .mbsc-sel .mbsc-sel-gr {
    padding: 0 0.25em;
    opacity: 1;
    font-weight: bold;
    text-align: left;
  }
  .mbsc-sel .mbsc-fr-c {
    position: relative;
  }
  .mbsc-sel-multi .mbsc-sc-whl-w .mbsc-sc-whl-l {
    display: none;
  }
  .mbsc-sel-empty {
    display: none;
    opacity: 0.6;
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 0;
    width: 100%;
    height: 2em;
    margin-top: -1em;
    font-size: 1.5em;
    line-height: 2em;
  }
  .mbsc-sel-empty-v {
    display: block;
  }
  .mbsc-sel-filter-clear {
    position: absolute;
    top: 0;
    height: 100%;
    width: 1.5em;
    display: none;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    cursor: pointer;
    font-size: 1.125em;
  }
  .mbsc-sel-filter-show-clear {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .mbsc-sel-input-wrap {
    position: relative;
  }
  .mbsc-sel .mbsc-ltr .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    padding-right: 1.5em;
  }
  .mbsc-sel .mbsc-rtl .mbsc-sel-filter-cont .mbsc-sel-filter-input {
    padding-left: 1.5em;
  }
  .mbsc-ltr .mbsc-sel-filter-clear {
    right: 0;
  }
  .mbsc-rtl .mbsc-sel-filter-clear {
    left: 0;
  }
  .mbsc-sel.mbsc-fr-pointer.mbsc-no-touch .mbsc-sc-whl .mbsc-sc-itm.mbsc-sel-gr:hover {
    background: none;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  .mbsc-bootstrap .mbsc-sc-whl .mbsc-sel-gr {
    padding: 0 5px;
    font-size: 20px;
  }
  .mbsc-bootstrap .mbsc-sel-filter-cont {
    padding: 4px;
  }
  .mbsc-ios .mbsc-sc-whl .mbsc-sel-gr {
    padding-left: 0.277777em;
    font-weight: normal;
    font-size: 1.222222em;
  }
  .mbsc-ios .mbsc-sel-filter-cont.mbsc-input {
    font-size: 1.166667em;
    padding: 0.5em;
  }
  .mbsc-ios .mbsc-sel-filter-cont.mbsc-input input {
    height: 2em;
    border-radius: 0.325em;
  }
  .mbsc-ios .mbsc-sel-filter-clear {
    width: 1.75em;
    right: 0.5em;
    font-size: 1em;
  }
  .mbsc-ios.mbsc-sel .mbsc-rtl .mbsc-sel-filter-clear {
    left: 0.5em;
  }
  .mbsc-ios .mbsc-sel-filter-cont.mbsc-control-w:before,
  .mbsc-ios .mbsc-sel-filter-cont.mbsc-control-w:after {
    border: none;
  }
  .mbsc-ios .mbsc-sel-gr-whl .mbsc-sc-itm {
    padding: 0 0.5em;
  }
  .mbsc-ios .mbsc-sel-gr-whl .mbsc-sc-itm::before {
    display: none;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sc-whl .mbsc-sel-gr {
    font-weight: 700;
    font-size: 1em;
    padding: 0 1.666667em;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sel-gr-whl .mbsc-sc-itm {
    padding: 0 1.25em;
  }
  .mbsc-ios .mbsc-sc-whl .mbsc-sel-gr {
    color: #9d9d9d;
  }
  .mbsc-ios .mbsc-sel-filter-cont.mbsc-input {
    background: #c4c4c4;
  }
  .mbsc-ios.mbsc-fr-center .mbsc-sel-filter-cont {
    background: #eaeaea;
  }
  .mbsc-ios .mbsc-sel-filter-cont.mbsc-input input {
    background: #fff;
  }
  .mbsc-ios .mbsc-sel-filter-clear {
    color: #8c8c8c;
  }
  .mbsc-ios.mbsc-fr-pointer .mbsc-sc-whl .mbsc-sel-gr {
    color: #000;
  }
  .mbsc-ios.mbsc-fr-pointer.mbsc-sel .mbsc-ltr .mbsc-sc-whl-w + .mbsc-sc-whl-w {
    border-left: 1px solid #ccc;
  }
  .mbsc-ios.mbsc-fr-pointer.mbsc-sel .mbsc-rtl .mbsc-sc-whl-w + .mbsc-sc-whl-w {
    border-right: 1px solid #ccc;
  }
  .mbsc-material .mbsc-sc-whl .mbsc-sel-gr {
    padding: 0 0.333333em;
    font-size: 1.125em;
  }
  .mbsc-material .mbsc-sel-filter-cont {
    margin: 0.25em 1em;
  }
  .mbsc-material .mbsc-input .mbsc-sel-filter-input:focus,
  .mbsc-material .mbsc-input .mbsc-sel-filter-input {
    border-bottom: 0;
    padding: inherit;
  }
  .mbsc-material .mbsc-rtl .mbsc-sel-filter-clear {
    left: -0.5em;
  }
  .mbsc-material .mbsc-ltr .mbsc-sel-filter-clear {
    right: -0.5em;
  }
  .mbsc-material .mbsc-sel-gr-whl .mbsc-sc-itm {
    padding: 0 0.272727em;
  }
  .mbsc-material .mbsc-sel-gr-whl .mbsc-sc-itm::before {
    display: none;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-itm {
    padding: 0 1.25em;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-itm-sel:before {
    display: none;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sc-whl .mbsc-sel-gr {
    padding: 0 1.25em;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sc-itm.mbsc-sel-gr-itm {
    padding: 0 2.5em;
  }
  .mbsc-material .mbsc-sel-filter-cont {
    border-bottom: 1px solid #b3b3b3;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-itm-sel {
    color: #009688;
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sel-one.mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material.mbsc-fr-pointer .mbsc-sel-one.mbsc-no-touch.mbsc-sc-whl-multi .mbsc-sc-itm-sel:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-mobiscroll .mbsc-sc-whl .mbsc-sel-gr {
    padding: 0 0.25em;
    font-size: 1.125em;
  }
  .mbsc-mobiscroll .mbsc-sel-gr-whl .mbsc-sc-itm {
    padding: 0 0.25em;
  }
  .mbsc-mobiscroll .mbsc-sel-gr-whl .mbsc-sc-itm::before {
    display: none;
  }
  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-itm {
    padding: 0 1.25em;
  }
  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-itm-sel:before {
    display: none;
  }
  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sc-itm.mbsc-sel-gr-itm {
    padding: 0 2.5em;
  }
  .mbsc-mobiscroll .mbsc-sel-gr-whl.mbsc-sel-one .mbsc-sc-itm-sel {
    color: #4eccc4;
  }
  .mbsc-mobiscroll.mbsc-fr-pointer .mbsc-sel-one.mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #454545;
    background: rgba(78, 204, 196, 0.3);
  }
  .mbsc-windows .mbsc-sc-whl .mbsc-sel-gr {
    opacity: 1;
    font-size: 1.375em;
    font-weight: normal;
  }
  .mbsc-windows .mbsc-sc-whl .mbsc-sel-gr:after {
    display: none;
  }
  .mbsc-windows .mbsc-sel-gr-whl .mbsc-sc-itm {
    padding: 0 0.5em;
  }
  .mbsc-windows .mbsc-sel-gr-whl .mbsc-sc-itm::before {
    display: none;
  }
  .mbsc-windows .mbsc-input.mbsc-sel-filter-cont {
    padding: 0.5em;
  }
  .mbsc-windows.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-itm {
    padding: 0 1.25em;
  }
  .mbsc-windows.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-itm-sel:before {
    display: none;
  }
  .mbsc-windows.mbsc-fr-pointer .mbsc-sc-whl .mbsc-sel-gr {
    padding: 0 0.90909em;
  }
  .mbsc-windows.mbsc-fr-pointer .mbsc-sc-itm.mbsc-sel-gr-itm {
    padding: 0 2.5em;
  }
  .mbsc-windows .mbsc-sel-gr-whl.mbsc-sel-one .mbsc-sc-itm-sel {
    color: #0078d7;
  }
  .mbsc-windows .mbsc-sel-filter-clear {
    color: #262626;
  }
  .mbsc-windows.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #262626;
    background: rgba(0, 120, 215, 0.2);
  }
  .mbsc-timer .mbsc-timer-lbl {
    display: none;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  .mbsc-ios.mbsc-timer .mbsc-sc-whl-gr {
    padding: 0.833333em;
  }
  .mbsc-ios.mbsc-timer .mbsc-sc-lbl {
    z-index: 3;
    top: 50%;
    bottom: auto;
    right: 0;
    left: auto;
    width: auto;
    margin-top: -0.75em;
    padding: 0 0.55em;
    font-size: 1.666667em;
    line-height: 1.5em;
    text-transform: lowercase;
  }
  .mbsc-ios.mbsc-timer .mbsc-rtl .mbsc-sc-lbl {
    left: 0;
    right: auto;
  }
  .mbsc-ios.mbsc-timer .mbsc-sc-whl-gr-3d .mbsc-sc-lbl {
    -webklit-transform: translateZ(88px);
    transform: translateZ(88px);
  }
  .mbsc-ios.mbsc-timer .mbsc-sc-itm {
    text-overflow: clip;
  }
  .mbsc-ios.mbsc-timer .mbsc-sc-lbl-v {
    margin: 0;
  }
  .mbsc-ios .mbsc-sc-lbl-v .mbsc-timer-lbl {
    display: inline;
    visibility: hidden;
    padding-left: 0.5em;
    font-size: 0.90909em;
    text-transform: lowercase;
  }
  .mbsc-ios.mbsc-timer.mbsc-fr-pointer .mbsc-sc-whl-gr {
    padding: 0;
  }
  .mbsc-ios.mbsc-timer.mbsc-fr-pointer .mbsc-sc-lbl {
    font-size: 1.333334em;
    padding: 0 0.6875em;
  }
  .mbsc-ios.mbsc-timer .mbsc-sc-lbl {
    color: #007bff;
  }
  .mbsc-material.mbsc-timer .mbsc-fr-btn-cont {
    display: table;
  }
  .mbsc-material.mbsc-timer .mbsc-fr-btn-w {
    width: 1px;
    text-align: left;
    display: table-cell;
    float: none;
  }
  .mbsc-material.mbsc-timer .mbsc-fr-btn-w:first-child {
    width: auto;
    text-align: left;
  }
  .mbsc-material.mbsc-timer .mbsc-fr-btn-w .mbsc-fr-btn {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
  }
  .mbsc-mobiscroll.mbsc-timer .mbsc-fr-btn-cont {
    display: table;
  }
  .mbsc-mobiscroll.mbsc-timer .mbsc-fr-btn-cont .mbsc-fr-btn-w {
    width: 1px;
    text-align: left;
    display: table-cell;
    float: none;
  }
  .mbsc-mobiscroll.mbsc-timer .mbsc-fr-btn-cont .mbsc-fr-btn-w:first-child {
    width: auto;
    text-align: left;
  }
  .mbsc-mobiscroll.mbsc-timer .mbsc-fr-btn-cont .mbsc-fr-btn-w .mbsc-fr-btn {
    display: inline-block;
    vertical-align: middle;
    text-align: center;
    text-decoration: none;
  }
  .mbsc-windows.mbsc-timer .mbsc-sc-whl-gr {
    padding: 0;
  }
  .mbsc-windows.mbsc-timer .mbsc-sc-lbl {
    line-height: 1.5em;
    z-index: 3;
    top: 50%;
    bottom: auto;
    right: 0;
    left: auto;
    width: auto;
    margin-top: -0.75em;
    padding: 0 0.5em;
    font-size: 1em;
    text-transform: lowercase;
  }
  .mbsc-windows.mbsc-timer .mbsc-timer-lbl {
    display: inline;
    visibility: hidden;
    padding-left: 0.5em;
    font-size: 1em;
    text-transform: lowercase;
  }
  .mbsc-windows.mbsc-timer .mbsc-sc-lbl-v {
    margin-bottom: 0;
  }
  .mbsc-windows.mbsc-timer .mbsc-sc-lbl {
    color: #262626;
  }
  .mbsc-ts .mbsc-ts-lbl {
    display: none;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  .mbsc-ios.mbsc-ts .mbsc-sc-lbl-v {
    margin: 0;
  }
  .mbsc-ios.mbsc-ts .mbsc-sc-lbl-v .mbsc-ts-lbl {
    display: inline;
    visibility: hidden;
    padding-left: 0.5em;
    font-size: 0.90909em;
    text-transform: lowercase;
  }
  .mbsc-ios.mbsc-ts .mbsc-sc-whl-gr {
    padding: 0.833333em;
  }
  .mbsc-ios.mbsc-ts .mbsc-sc-lbl {
    padding: 0 0.55em;
    margin-top: -0.75em;
    left: auto;
    top: 50%;
    right: 0;
    width: auto;
    z-index: 3;
    font-size: 1.666667em;
    line-height: 1.5em;
    text-transform: lowercase;
  }
  .mbsc-ios.mbsc-ts .mbsc-rtl .mbsc-sc-lbl {
    left: 0;
    right: auto;
  }
  .mbsc-ios.mbsc-ts .mbsc-sc-whl-gr-3d .mbsc-sc-lbl {
    -webklit-transform: translateZ(88px);
    transform: translateZ(88px);
  }
  .mbsc-ios.mbsc-ts.mbsc-fr-pointer .mbsc-sc-whl-gr {
    padding: 0;
  }
  .mbsc-ios.mbsc-ts.mbsc-fr-pointer .mbsc-sc-lbl {
    font-size: 1.333334em;
    padding: 0 0.6875em;
  }
  .mbsc-ios.mbsc-ts .mbsc-sc-lbl {
    color: #007bff;
  }
  .mbsc-windows.mbsc-ts .mbsc-sc-whl-gr {
    padding: 0;
  }
  .mbsc-windows.mbsc-ts .mbsc-sc-lbl {
    line-height: 1.5em;
    z-index: 3;
    top: 50%;
    bottom: auto;
    right: 0;
    left: auto;
    width: auto;
    margin-top: -0.75em;
    padding: 0 0.5em;
    font-size: 1em;
    text-transform: lowercase;
  }
  .mbsc-windows.mbsc-ts .mbsc-ts-lbl {
    display: inline;
    visibility: hidden;
    padding-left: 0.5em;
    font-size: 1em;
    text-transform: lowercase;
  }
  .mbsc-windows.mbsc-ts .mbsc-sc-lbl-v {
    margin-bottom: 0;
  }
  .mbsc-windows.mbsc-ts .mbsc-sc-lbl {
    color: #262626;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr-c {
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-sc-whl-gr {
    padding: 4px 2px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl-v .mbsc-sc-whl-gr {
    padding-top: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-lbl {
    line-height: 30px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-w {
    margin: 0 2px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-l {
    margin: 0 -2px;
    background: rgba(0, 0, 0, 0.2);
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l {
    margin-left: -4px;
  }
  .mbsc-bootstrap .mbsc-ltr .mbsc-sc-whl-w:last-child .mbsc-sc-whl-l,
  .mbsc-bootstrap .mbsc-rtl .mbsc-sc-whl-w:first-child .mbsc-sc-whl-l {
    margin-right: -4px;
  }
  .mbsc-bootstrap .mbsc-sc-itm {
    padding: 0 5px;
    font-size: 18px;
  }
  .mbsc-bootstrap .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-cp .mbsc-sc-whl-w {
    padding: 30px 0;
  }
  .mbsc-bootstrap .mbsc-sc-btn {
    height: 30px !important;
    line-height: 30px !important;
  }
  .mbsc-bootstrap .mbsc-sc-btn-plus {
    top: auto;
  }
  .mbsc-bootstrap .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm {
    padding: 0 40px;
  }
  .mbsc-bootstrap .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    width: 40px;
    font-size: 16px;
  }
  .mbsc-bootstrap .mbsc-fr-popup {
    display: block;
    padding: 0;
    margin: 0;
  }
  .mbsc-bootstrap .mbsc-fr-hdr {
    padding: 0 14px;
    min-height: 37px;
    line-height: 37px;
  }
  .mbsc-bootstrap .mbsc-fr-w {
    font-family: inherit;
    padding: 0;
  }
  .mbsc-bootstrap .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  .mbsc-bootstrap .mbsc-fr-btn-cont {
    padding: 0 2px 4px 2px;
  }
  .mbsc-bootstrap .mbsc-fr-btn {
    display: block;
    margin: 0 2px;
  }
  .mbsc-bootstrap.mbsc-inline .mbsc-fr-popup {
    display: inline-block;
  }
  .mbsc-bootstrap.mbsc-fr-inline .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-top .mbsc-fr-popup,
  .mbsc-bootstrap.mbsc-fr-bottom .mbsc-fr-popup {
    border-radius: 0;
  }
  .mbsc-bootstrap .mbsc-fr-arr-i {
    max-width: none;
    height: 20px;
    margin: 0 10px;
    padding: 0;
    border: 0;
    display: block;
    border-color: transparent;
    background: none;
    box-shadow: none;
  }
  .mbsc-bootstrap .mbsc-fr-arr-w {
    margin: 0;
    overflow: visible;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-top .mbsc-fr-arr {
    top: 0;
    bottom: auto;
  }
  .mbsc-bootstrap .mbsc-fr-bubble-bottom .mbsc-fr-arr {
    top: auto;
    bottom: 0;
  }
  .mbsc-bootstrap.mbsc-fr .mbsc-fr-arr {
    margin: 0;
    -webklit-transform: translate(-50%);
    transform: translate(-50%);
  }
  .mbsc-ios-dark.mbsc-fr-top .mbsc-fr-btn-cont,
  .mbsc-ios-dark.mbsc-fr-bottom .mbsc-fr-btn-cont {
    background: #1c1c1c;
  }
  .mbsc-ios-dark .mbsc-fr-overlay {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-ios-dark .mbsc-fr-w {
    background: #080808;
    color: #fff;
  }
  .mbsc-ios-dark .mbsc-fr-hdr {
    color: #fff;
  }
  .mbsc-ios-dark.mbsc-fr-nobtn .mbsc-fr-hdr,
  .mbsc-ios-dark.mbsc-fr-center:not(.mbsc-cal-liq) .mbsc-fr-hdr,
  .mbsc-ios-dark .mbsc-fr-btn-cont {
    border-bottom: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-fr-btn {
    color: #ff8400;
  }
  .mbsc-ios-dark .mbsc-fr-arr {
    background: #080808;
  }
  .mbsc-ios-dark.mbsc-fr-bottom .mbsc-fr-popup {
    border-top: 1px solid #363636;
  }
  .mbsc-ios-dark.mbsc-fr-top .mbsc-fr-popup {
    border-bottom: 1px solid #363636;
  }
  .mbsc-ios-dark.mbsc-fr-center .mbsc-fr-btn-w {
    border-top: 1px solid #333;
    border-left: 1px solid #333;
  }
  .mbsc-ios-dark.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w {
    border-right: 1px solid #333;
  }
  .mbsc-ios-dark.mbsc-fr-center.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
  .mbsc-ios-dark.mbsc-fr-center .mbsc-fr-btn.mbsc-active {
    background: #1f1f1f;
  }
  .mbsc-ios-dark.mbsc-fr-inline .mbsc-fr-w {
    background: #0f0f0f;
    border-top: 1px solid #333;
  }
  .mbsc-ios-dark.mbsc-fr-inline .mbsc-fr-c {
    border-bottom: 1px solid #333;
  }
  .mbsc-ios-dark.mbsc-fr-no-overlay .mbsc-fr-arr {
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.8);
  }
  .mbsc-ios-dark.mbsc-fr-no-overlay .mbsc-fr-w {
    box-shadow: 0 0.125em 1em rgba(0, 0, 0, 0.8);
  }
  .mbsc-ios-dark.mbsc-fr-pointer .mbsc-fr-persp .mbsc-fr-popup .mbsc-fr-w,
  .mbsc-ios-dark.mbsc-fr-pointer .mbsc-fr-persp .mbsc-cal-picker {
    background: #333;
  }
  .mbsc-ios-dark.mbsc-fr-pointer .mbsc-fr-hdr {
    border-color: #545454;
  }
  .mbsc-ios-dark.mbsc-sc.mbsc-fr-top .mbsc-fr-w,
  .mbsc-ios-dark.mbsc-sc.mbsc-fr-bottom .mbsc-fr-w {
    background: #080808;
  }
  .mbsc-ios-dark.mbsc-calendar .mbsc-fr-persp .mbsc-fr-w {
    background: #080808;
  }
  .mbsc-ios-dark.mbsc-calendar.mbsc-fr-top .mbsc-fr-btn-cont,
  .mbsc-ios-dark.mbsc-calendar.mbsc-fr-bottom .mbsc-fr-btn-cont {
    border-bottom: 1px solid #333;
  }
  .mbsc-ios-dark.mbsc-fr-top .mbsc-sc-whl-l,
  .mbsc-ios-dark.mbsc-fr-bottom .mbsc-sc-whl-l {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-sc-whl-l,
  .mbsc-ios-dark.mbsc-calendar .mbsc-sc-whl-l {
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
  }
  .mbsc-ios-dark.mbsc-fr-top .mbsc-sc-whl-o,
  .mbsc-ios-dark.mbsc-fr-bottom .mbsc-sc-whl-o {
    background: -webkit-linear-gradient(#080808, rgba(8, 8, 8, 0) 52%, rgba(8, 8, 8, 0) 48%, #080808);
    background: linear-gradient(#080808, rgba(8, 8, 8, 0) 52%, rgba(8, 8, 8, 0) 48%, #080808);
  }
  .mbsc-ios-dark .mbsc-sc-whl-o,
  .mbsc-ios-dark.mbsc-calendar .mbsc-sc-whl-o {
    background: -webkit-linear-gradient(#080808, rgba(8, 8, 8, 0) 52%, rgba(8, 8, 8, 0) 48%, #080808);
    background: linear-gradient(#080808, rgba(8, 8, 8, 0) 52%, rgba(8, 8, 8, 0) 48%, #080808);
  }
  .mbsc-ios-dark.mbsc-fr-top .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c,
  .mbsc-ios-dark.mbsc-fr-bottom .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    background: #080808;
  }
  .mbsc-ios-dark.mbsc-fr.mbsc-fr-inline .mbsc-sc-whl-o {
    background: -webkit-linear-gradient(#0f0f0f, rgba(15, 15, 15, 0) 52%, rgba(15, 15, 15, 0) 48%, #0f0f0f);
    background: linear-gradient(#0f0f0f, rgba(15, 15, 15, 0) 52%, rgba(15, 15, 15, 0) 48%, #0f0f0f);
  }
  .mbsc-ios-dark.mbsc-fr.mbsc-fr-inline .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    background: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-sc-lbl {
    color: #fff;
  }
  .mbsc-ios-dark .mbsc-sc-itm {
    color: #9e9e9e;
  }
  .mbsc-ios-dark.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-ios-dark .mbsc-sc-itm:focus {
    background: rgba(255, 132, 0, 0.15);
  }
  .mbsc-ios-dark.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
    background: rgba(255, 132, 0, 0.2);
  }
  .mbsc-ios-dark .mbsc-sc-itm-sel,
  .mbsc-ios-dark .mbsc-sc-whl-gr-3d .mbsc-sc-itm {
    color: #fff;
  }
  .mbsc-ios-dark .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c,
  .mbsc-ios-dark.mbsc-calendar .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    background: #080808;
  }
  .mbsc-ios-dark .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
    color: #6e6e6e;
  }
  .mbsc-ios-dark .mbsc-sc-btn {
    color: #ff8400;
  }
  .mbsc-ios-dark.mbsc-sel-multi .mbsc-sc-itm {
    color: #fff;
  }
  .mbsc-ios-dark .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #ff8400;
  }
  .mbsc-ios-dark.mbsc-fr-pointer .mbsc-sc-whl-l {
    border-color: #545454;
  }
  .mbsc-ios-dark.mbsc-fr-pointer .mbsc-sc-itm {
    color: #fff;
  }
  .mbsc-ios-dark.mbsc-fr-pointer .mbsc-sc-itm-sel {
    color: #ff8400;
  }
  .mbsc-ios-dark .mbsc-cal-picker {
    background: #080808;
  }
  .mbsc-ios-dark .mbsc-cal-cell {
    border-top: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-cal-today {
    color: #ff8400;
  }
  .mbsc-ios-dark .mbsc-cal-cell:focus .mbsc-cal-cell-txt,
  .mbsc-ios-dark.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
  .mbsc-ios-dark.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover {
    background: rgba(255, 132, 0, 0.3);
  }
  .mbsc-ios-dark .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
    border-color: #ff8400;
    background: #ff8400;
    color: #fff;
  }
  .mbsc-ios-dark .mbsc-cal-tab {
    border: 1px solid #ff8400;
    color: #ff8400;
  }
  .mbsc-ios-dark .mbsc-fr-popup .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
    background: #ff8400;
    color: #fff;
  }
  .mbsc-ios-dark .mbsc-cal-mark {
    background: #333;
  }
  .mbsc-ios-dark .mbsc-cal-txt {
    background: #efdcc3;
    color: #000;
  }
  .mbsc-ios-dark.mbsc-fr-inline .mbsc-cal-picker {
    background: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-fr-btn-cont {
    border-bottom: 1px solid #333;
  }
  .mbsc-ios-dark.mbsc-page {
    background: #19191a;
    color: #fff;
  }
  .mbsc-ios-dark a {
    color: #ff8400;
  }
  .mbsc-ios-dark .mbsc-input {
    background: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-input .mbsc-control::-webkit-input-placeholder {
    color: #ccc;
  }
  .mbsc-ios-dark .mbsc-input .mbsc-control::-ms-input-placeholder {
    color: #ccc;
  }
  .mbsc-ios-dark .mbsc-input .mbsc-control::-moz-placeholder {
    color: #ccc;
  }
  .mbsc-ios-dark .mbsc-input-wrap input:-webkit-autofill ~ .mbsc-input-fill {
    background: #e8f0fe;
  }
  .mbsc-ios-dark .mbsc-input-wrap input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #e8f0fe inset;
  }
  .mbsc-ios-dark .mbsc-input .mbsc-label,
  .mbsc-ios-dark .mbsc-progress .mbsc-label,
  .mbsc-ios-dark .mbsc-input input,
  .mbsc-ios-dark .mbsc-input textarea {
    color: #fff;
  }
  .mbsc-ios-dark .mbsc-err-msg {
    color: #d8332a;
  }
  .mbsc-ios-dark .mbsc-control-w:before,
  .mbsc-ios-dark .mbsc-control-w:after {
    border-top: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-control-w.mbsc-err:after,
  .mbsc-ios-dark .mbsc-control-w.mbsc-err + .mbsc-err:before,
  .mbsc-ios-dark .mbsc-control-w.mbsc-err + .mbsc-divider {
    border-top-color: #d8332a;
  }
  .mbsc-ios-dark .mbsc-input-box.mbsc-err,
  .mbsc-ios-dark .mbsc-input-outline.mbsc-err {
    border-color: #d8332a;
  }
  .mbsc-ios-dark .mbsc-input-box .mbsc-err-msg,
  .mbsc-ios-dark .mbsc-input-outline .mbsc-err-msg {
    color: #d8332a;
  }
  .mbsc-ios-dark .mbsc-input-outline.mbsc-control-w {
    border: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-input-outline.mbsc-err.mbsc-control-w {
    border-color: #d8332a;
  }
  .mbsc-ios-dark.mbsc-progress {
    background: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-progress-track {
    background: #454545;
  }
  .mbsc-ios-dark .mbsc-progress-bar {
    background: #ff8400;
  }
  .mbsc-ios-dark .mbsc-progress-value,
  .mbsc-ios-dark .mbsc-progress-step-label {
    color: #b3b2b2;
  }
  .mbsc-ios-dark .mbsc-slider-handle {
    background: #fff;
  }
  .mbsc-ios-dark .mbsc-slider-tooltip {
    color: #b3b2b2;
    background: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-slider-step {
    background: #454545;
  }
  .mbsc-ios-dark.mbsc-rating .mbsc-progress-track {
    color: #ff8400;
  }
  .mbsc-ios-dark.mbsc-alert .mbsc-input {
    border: 1px solid #ccc;
  }
  .mbsc-ios-dark.mbsc-snackbar .mbsc-fr-w {
    background: #646464;
    color: #fff;
  }
  .mbsc-ios-dark.mbsc-snackbar .mbsc-snackbar-btn {
    color: #ff8400;
  }
  .mbsc-ios-dark.mbsc-toast .mbsc-toast-msg {
    background: #646464;
    color: #fff;
  }
  .mbsc-ios-dark.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-ios-dark.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-ios-dark.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-ios-dark.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-ios-dark.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-ios-dark.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-ios-dark.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-ios-dark.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-ios-dark.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-ios-dark.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-ios-dark.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-ios-dark.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-ios-dark.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-ios-dark.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-ios-dark.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-ios-dark.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-ios-dark.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
  .mbsc-ios-dark.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #eee;
  }
  .mbsc-ios-dark.mbsc-form {
    background: #19191a;
    color: #fff;
  }
  .mbsc-ios-dark.mbsc-form *::-moz-selection,
  .mbsc-ios-dark.mbsc-form *::selection {
    color: #ccc;
    background: #de7b12;
  }
  .mbsc-ios-dark .mbsc-input-ic {
    color: #fff;
  }
  .mbsc-ios-dark .mbsc-divider,
  .mbsc-ios-dark .mbsc-form-group-title {
    border-bottom: 1px solid #333;
    color: #8f8f8f;
  }
  .mbsc-ios-dark .mbsc-divider {
    border-top: 1px solid #333;
  }
  .mbsc-ios-dark.mbsc-form .mbsc-checkbox,
  .mbsc-ios-dark.mbsc-form .mbsc-radio {
    background: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-checkbox-box {
    border: 0.125em solid #de7b12;
  }
  .mbsc-ios-dark .mbsc-checkbox-box:after {
    border: 0.125em solid #de7b12;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-ios-dark .mbsc-radio-box:after {
    border: 0.125em solid #de7b12;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-ios-dark .mbsc-radio input:disabled ~ .mbsc-radio-label {
    color: #3b3b3b;
  }
  .mbsc-ios-dark .mbsc-btn {
    background: #3b3b3b;
    color: #ff8400;
  }
  .mbsc-ios-dark .mbsc-btn:not(.mbsc-btn-flat):disabled {
    background: #212121;
    color: #3b3b3b;
  }
  .mbsc-ios-dark .mbsc-btn.mbsc-btn-flat {
    background: transparent;
    border-color: transparent;
  }
  .mbsc-ios-dark .mbsc-btn-flat:disabled {
    background: transparent;
    color: #3b3b3b;
  }
  .mbsc-ios-dark .mbsc-btn-primary.mbsc-btn,
  .mbsc-ios-dark .mbsc-btn-secondary.mbsc-btn,
  .mbsc-ios-dark .mbsc-btn-success.mbsc-btn,
  .mbsc-ios-dark .mbsc-btn-danger.mbsc-btn,
  .mbsc-ios-dark .mbsc-btn-warning.mbsc-btn,
  .mbsc-ios-dark .mbsc-btn-info.mbsc-btn,
  .mbsc-ios-dark .mbsc-btn-dark.mbsc-btn {
    color: #eee;
  }
  .mbsc-ios-dark .mbsc-btn-light.mbsc-btn {
    color: #000;
  }
  .mbsc-ios-dark .mbsc-btn-flat.mbsc-btn {
    background: transparent;
  }
  .mbsc-ios-dark .mbsc-btn-outline.mbsc-btn {
    background: transparent;
    border: 1px solid #de7b12;
    color: #de7b12;
  }
  .mbsc-ios-dark .mbsc-btn-outline.mbsc-btn.mbsc-active {
    background: #de7b12;
    color: #19191a;
  }
  .mbsc-ios-dark .mbsc-btn.mbsc-btn-outline:disabled {
    color: #3b3b3b;
    border-color: #3b3b3b;
    background: transparent;
  }
  .mbsc-ios-dark.mbsc-form .mbsc-switch {
    background: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-switch-track {
    background: #fff;
    border: 0.0625em solid #2b2b2b;
  }
  .mbsc-ios-dark .mbsc-switch-track:after {
    background: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-switch-handle {
    background: #fff;
  }
  .mbsc-ios-dark .mbsc-switch input:checked + .mbsc-switch-track {
    background: #ff8400;
    border-color: #ff8400;
  }
  .mbsc-ios-dark .mbsc-segmented {
    background-color: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-segmented-content {
    color: #de7b12;
    border: 0.07142857em solid #de7b12;
    background: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
  .mbsc-ios-dark .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #de7b12;
    color: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(255, 132, 0, 0.3);
    color: #de7b12;
  }
  .mbsc-ios-dark .mbsc-stepper-cont {
    background: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-ios-dark .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-ios-dark .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    color: #3b3b3b;
    border-color: #3b3b3b;
  }
  .mbsc-ios-dark .mbsc-stepper input:disabled {
    color: #3b3b3b;
    -webkit-text-fill-color: #3b3b3b;
  }
  .mbsc-ios-dark .mbsc-segmented input:disabled:checked + .mbsc-segmented-content {
    background: #212121;
  }
  .mbsc-ios-dark .mbsc-stepper input {
    color: #fff;
  }
  .mbsc-ios-dark.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(255, 132, 0, 0.15);
  }
  .mbsc-ios-dark.mbsc-card.mbsc-form {
    background: #0f0f0f;
    box-shadow: 0 0 1px 1px rgba(0, 0, 0, 0.2);
  }
  .mbsc-ios-dark .mbsc-card-footer {
    border-color: #333;
  }
  .mbsc-ios-dark.mbsc-card .mbsc-input-box.mbsc-control-w,
  .mbsc-ios-dark.mbsc-card .mbsc-input-box.mbsc-control-w {
    border: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-color-active,
  .mbsc-ios-dark.mbsc-no-touch .mbsc-color-item-c:hover {
    box-shadow: inset 0 0 0 0.1875em #ff8400;
  }
  .mbsc-ios-dark .mbsc-lv {
    background: #333;
  }
  .mbsc-ios-dark .mbsc-lv-item {
    background: #0f0f0f;
    border-color: #333;
    color: #fff;
  }
  .mbsc-ios-dark .mbsc-lv-item:before,
  .mbsc-ios-dark .mbsc-lv-item:after {
    border-top: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-lv-gr-title {
    background: #19191a;
    color: #8f8f8f;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
  }
  .mbsc-ios-dark.mbsc-no-touch .mbsc-lv-item-actionable:hover:before {
    background: rgba(255, 255, 255, 0.05);
  }
  .mbsc-ios-dark .mbsc-lv-item-actionable.mbsc-lv-item-active:before {
    background: rgba(0, 0, 0, 0.15);
  }
  .mbsc-ios-dark .mbsc-empty {
    color: #fff;
  }
  .mbsc-ios-dark.mbsc-ev-cal .mbsc-fr-w {
    background: #000;
  }
  .mbsc-ios-dark.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-picker {
    background: #000;
  }
  .mbsc-ios-dark.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-scroll-c {
    background: #0f0f0f;
  }
  .mbsc-ios-dark.mbsc-cal-events-popup .mbsc-fr-w {
    border: 1px solid #545454;
  }
  .mbsc-ios-dark.mbsc-cal-events-popup .mbsc-fr-arr {
    background: #0f0f0f;
    border: 1px solid #545454;
  }
  .mbsc-ios-dark .mbsc-cal-events-i {
    background: #333;
  }
  .mbsc-ios-dark .mbsc-cal-event-color {
    background: #94b8dd;
  }
  .mbsc-ios-dark .mbsc-cal-event-dur,
  .mbsc-ios-dark .mbsc-cal-event-time {
    color: #8e8e8e;
  }
  .mbsc-ios-dark .mbsc-event-list {
    background: #19191a;
  }
  .mbsc-ios-dark.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    border-left: 1px solid #333;
  }
  .mbsc-ios-dark.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #212121;
  }
  .mbsc-ios-dark .mbsc-lv-item-hl,
  .mbsc-ios-dark .mbsc-lv .mbsc-lv-item.mbsc-lv-item-active {
    background: #1f1f1f;
  }
  .mbsc-ios-dark .mbsc-lv-handle-bar {
    background: #6e6e6e;
  }
  .mbsc-ios-dark .mbsc-lv-arr {
    color: #6e6e6e;
  }
  .mbsc-ios-dark.mbsc-lv-has-fixed-header .mbsc-lv-gr-title,
  .mbsc-ios-dark.mbsc-lv-fixed-header .mbsc-lv-gr-title {
    color: #fff;
  }
  .mbsc-ios-dark .mbsc-lv-ic-m {
    color: #fff;
  }
  .mbsc-ios-dark .mbsc-lv-loading .mbsc-ic {
    color: #fff;
  }
  .mbsc-ios-dark.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(255, 132, 0, 0.3);
  }
  .mbsc-ios-dark.mbsc-ms-c {
    background: #000;
    border-top: 1px solid #333;
    border-bottom: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-ms .mbsc-ms-item {
    color: #ababab;
  }
  .mbsc-ios-dark.mbsc-ms-nosel .mbsc-ms-item,
  .mbsc-ios-dark .mbsc-ms .mbsc-ms-item-sel {
    color: #ff8400;
  }
  .mbsc-ios-dark.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: rgba(255, 132, 0, 0.15);
  }
  .mbsc-ios-dark.mbsc-ms-more .mbsc-ms-item:after {
    border-top: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-np-btn {
    border-top: 1px solid #333;
    border-left: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-np-btn.mbsc-active,
  .mbsc-ios-dark .mbsc-np-btn-empty {
    background: #080808;
  }
  .mbsc-ios-dark .mbsc-np-btn.mbsc-disabled {
    color: #212121;
  }
  .mbsc-ios-dark .mbsc-range-btn-end {
    border-bottom: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-range-btn-end .mbsc-range-btn:before {
    border-top: 1px solid #333;
  }
  .mbsc-ios-dark .mbsc-range-btn.mbsc-selected .mbsc-range-btn-v {
    color: #ff8400;
  }
  .mbsc-ios-dark.mbsc-range .mbsc-cal .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date,
  .mbsc-ios-dark.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    background: #ff8400;
  }
  .mbsc-ios-dark .mbsc-sc-whl .mbsc-sel-gr {
    color: #9d9d9d;
  }
  .mbsc-ios-dark .mbsc-sel-filter-cont.mbsc-input {
    background: #3b3b3b;
  }
  .mbsc-ios-dark.mbsc-fr-center .mbsc-sel-filter-cont {
    background: #1f1f1f;
  }
  .mbsc-ios-dark .mbsc-sel-filter-cont.mbsc-input input {
    background: #0f0f0f;
  }
  .mbsc-ios-dark .mbsc-sel-filter-clear {
    color: #8c8c8c;
  }
  .mbsc-ios-dark.mbsc-fr-pointer .mbsc-sc-whl .mbsc-sel-gr {
    color: #fff;
  }
  .mbsc-ios-dark.mbsc-fr-pointer.mbsc-sel .mbsc-ltr .mbsc-sc-whl-w + .mbsc-sc-whl-w {
    border-left: 1px solid #545454;
  }
  .mbsc-ios-dark.mbsc-fr-pointer.mbsc-sel .mbsc-rtl .mbsc-sc-whl-w + .mbsc-sc-whl-w {
    border-right: 1px solid #545454;
  }
  .mbsc-ios-dark.mbsc-timer .mbsc-sc-lbl {
    color: #ff8400;
  }
  .mbsc-ios-dark.mbsc-ts .mbsc-sc-lbl {
    color: #ff8400;
  }
  .mbsc-material-dark .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.6);
  }
  .mbsc-material-dark .mbsc-fr-w {
    background: #303030;
    color: #c2c2c2;
    box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.3);
  }
  .mbsc-material-dark .mbsc-fr-hdr {
    color: #81ccc4;
  }
  .mbsc-material-dark .mbsc-fr-btn {
    color: #81ccc4;
  }
  .mbsc-material-dark.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
  .mbsc-material-dark .mbsc-fr-btn.mbsc-active {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-material-dark .mbsc-fr-arr {
    box-shadow: 0 0 1.5em rgba(0, 0, 0, 0.2);
    background: #303030;
  }
  .mbsc-material-dark .mbsc-sc-lbl {
    color: #81ccc4;
  }
  .mbsc-material-dark.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-material-dark .mbsc-sc-itm:focus {
    background: rgba(0, 0, 0, 0.05);
  }
  .mbsc-material-dark.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material-dark .mbsc-sc-whl-l {
    border-top: 2px solid #81ccc4;
    border-bottom: 2px solid #81ccc4;
  }
  .mbsc-material-dark .mbsc-sc-btn {
    color: #81ccc4;
    background: #303030;
  }
  .mbsc-material-dark.mbsc-no-touch .mbsc-sc-btn:hover,
  .mbsc-material-dark .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material-dark .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    color: #81ccc4;
  }
  .mbsc-material-dark .mbsc-cal-picker {
    background: #303030;
  }
  .mbsc-material-dark .mbsc-cal-today,
  .mbsc-material-dark .mbsc-cal-tab.mbsc-selected {
    color: #81ccc4;
  }
  .mbsc-material-dark.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
  .mbsc-material-dark.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
  .mbsc-material-dark .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-material-dark .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
    background: rgba(129, 204, 196, 0.3);
  }
  .mbsc-material-dark .mbsc-cal-c .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
    border-color: #81ccc4;
  }
  .mbsc-material-dark .mbsc-cal-mark {
    background: #81ccc4;
  }
  .mbsc-material-dark .mbsc-cal-txt {
    color: #303030;
    background: #e3e3e3;
  }
  .mbsc-material-dark .mbsc-cal-icons {
    color: #c2c2c2;
  }
  .mbsc-material-dark .mbsc-ripple {
    background: #fff;
  }
  .mbsc-material-dark .mbsc-empty {
    color: #c2c2c2;
  }
  .mbsc-material-dark.mbsc-page {
    background-color: #303030;
    color: #d4d4d4;
  }
  .mbsc-material-dark a {
    color: #81ccc4;
  }
  .mbsc-material-dark .mbsc-input input,
  .mbsc-material-dark .mbsc-input textarea {
    border-bottom: 1px solid #d4d4d4;
    color: #858585;
  }
  .mbsc-material-dark .mbsc-input input:focus,
  .mbsc-material-dark .mbsc-input textarea:focus,
  .mbsc-material-dark .mbsc-input select:focus ~ input {
    border-bottom: 2px solid #81ccc4;
  }
  .mbsc-material-dark .mbsc-input.mbsc-err input,
  .mbsc-material-dark .mbsc-input.mbsc-err textarea {
    border-bottom: 1px solid #de3226;
  }
  .mbsc-material-dark .mbsc-select .mbsc-input-wrap:after {
    border: 5px solid #d4d4d4;
    border-color: #d4d4d4 transparent transparent transparent;
  }
  .mbsc-material-dark .mbsc-input-box.mbsc-input .mbsc-input-wrap {
    background: #424242;
  }
  .mbsc-material-dark .mbsc-input-outline.mbsc-input input,
  .mbsc-material-dark .mbsc-input-outline.mbsc-input textarea {
    border: 1px solid #d4d4d4;
  }
  .mbsc-material-dark .mbsc-input-outline.mbsc-input .mbsc-label {
    background: #303030;
  }
  .mbsc-material-dark .mbsc-input-outline.mbsc-input input:focus,
  .mbsc-material-dark .mbsc-input-outline.mbsc-input select:focus ~ input {
    border: 2px solid #81ccc4;
  }
  .mbsc-material-dark .mbsc-input-outline.mbsc-err input {
    border: 1px solid #de3226;
  }
  .mbsc-material-dark .mbsc-input-outline.mbsc-err input:focus {
    border: 2px solid #de3226;
  }
  .mbsc-material-dark .mbsc-input-outline.mbsc-input textarea:focus {
    border: 2px solid #81ccc4;
  }
  .mbsc-material-dark .mbsc-input-outline.mbsc-err textarea {
    border: 1px solid #de3226;
  }
  .mbsc-material-dark .mbsc-input-outline.mbsc-err textarea:focus {
    border: 2px solid #de3226;
  }
  .mbsc-material-dark .mbsc-progress-track {
    background: #5b5b5b;
  }
  .mbsc-material-dark .mbsc-progress-bar {
    background: #81ccc4;
  }
  .mbsc-material-dark .mbsc-slider-step {
    background: #fff;
  }
  .mbsc-material-dark .mbsc-slider-handle {
    border: 2px solid #81ccc4;
    background: #81ccc4;
  }
  .mbsc-material-dark .mbsc-slider-start .mbsc-slider-handle {
    border-color: #5b5b5b;
    background: #303030;
  }
  .mbsc-material-dark .mbsc-slider-tooltip {
    color: #303030;
    background-color: #81ccc4;
  }
  .mbsc-material-dark .mbsc-slider-tooltip:before {
    border-top: 1em solid #81ccc4;
  }
  .mbsc-material-dark.mbsc-slider-has-tooltip .mbsc-slider-start .mbsc-slider-tooltip,
  .mbsc-material-dark.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-bar {
    background: #5b5b5b;
  }
  .mbsc-material-dark.mbsc-slider-has-tooltip .mbsc-slider-start .mbsc-slider-tooltip:before {
    border-top-color: #5b5b5b;
  }
  .mbsc-material-dark.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-handle {
    background: #5b5b5b;
    border-color: #5b5b5b;
    box-shadow: 0 0 0 0.3125em #303030;
  }
  .mbsc-material-dark.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-start .mbsc-slider-handle {
    border-color: #5b5b5b;
    background: #303030;
  }
  .mbsc-material-dark.mbsc-rating .mbsc-progress-track {
    color: #81ccc4;
  }
  .mbsc-material-dark.mbsc-snackbar .mbsc-fr-w {
    background: #5b5b5b;
    color: #fff;
  }
  .mbsc-material-dark.mbsc-snackbar .mbsc-snackbar-btn {
    color: #81ccc4;
  }
  .mbsc-material-dark.mbsc-toast .mbsc-toast-msg {
    background: #505050;
    color: #fff;
  }
  .mbsc-material-dark.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-material-dark.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-material-dark.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-material-dark.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-material-dark.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-material-dark.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-material-dark.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-material-dark.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-material-dark.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-material-dark.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-material-dark.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-material-dark.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-material-dark.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-material-dark.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-material-dark.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-material-dark.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-material-dark.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
  .mbsc-material-dark.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #eee;
  }
  .mbsc-material-dark.mbsc-form {
    background-color: #303030;
    color: #d4d4d4;
  }
  .mbsc-material-dark.mbsc-form *::-moz-selection,
  .mbsc-material-dark.mbsc-form *::selection {
    color: #fff;
    background: #81ccc4;
  }
  .mbsc-material-dark .mbsc-divider,
  .mbsc-material-dark .mbsc-form-group-title {
    color: #81ccc4;
  }
  .mbsc-material-dark .mbsc-err-msg,
  .mbsc-material-dark .mbsc-err .mbsc-label {
    color: #de3226;
  }
  .mbsc-material-dark .mbsc-checkbox-box,
  .mbsc-material-dark .mbsc-radio-box {
    border: 0.125em solid #d4d4d4;
  }
  .mbsc-material-dark .mbsc-checkbox-box:before,
  .mbsc-material-dark .mbsc-radio-box:before {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material-dark .mbsc-checkbox-box:after {
    border: 0.125em solid #303030;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-material-dark .mbsc-checkbox input:checked + .mbsc-checkbox-box {
    background: #81ccc4;
    border-color: #81ccc4;
  }
  .mbsc-material-dark .mbsc-radio-box:after {
    background: #81ccc4;
  }
  .mbsc-material-dark .mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #81ccc4;
  }
  .mbsc-material-dark .mbsc-btn {
    background: #5b5b5b;
    color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  }
  .mbsc-material-dark.mbsc-no-touch .mbsc-btn:not(:disabled):hover,
  .mbsc-material-dark .mbsc-btn.mbsc-active {
    background: #0f0f0f;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  }
  .mbsc-material-dark.mbsc-no-touch .mbsc-btn-flat:not(:disabled):hover,
  .mbsc-material-dark .mbsc-btn-flat.mbsc-active {
    background-color: #5b5b5b;
  }
  .mbsc-material-dark .mbsc-btn:disabled {
    color: #929292;
  }
  .mbsc-material-dark .mbsc-btn.mbsc-btn-outline:disabled {
    color: #929292;
    border-color: #929292;
  }
  .mbsc-material-dark .mbsc-btn-light.mbsc-btn {
    color: #000;
  }
  .mbsc-material-dark .mbsc-btn-dark.mbsc-btn {
    color: #eee;
  }
  .mbsc-material-dark .mbsc-btn-flat.mbsc-btn {
    background: transparent;
    box-shadow: none;
  }
  .mbsc-material-dark.mbsc-no-touch .mbsc-btn-flat:not(:disabled):hover,
  .mbsc-material-dark .mbsc-btn-flat.mbsc-active {
    box-shadow: none;
  }
  .mbsc-material-dark .mbsc-btn-outline.mbsc-btn {
    background: transparent;
    border: 1px solid #d4d4d4;
    color: #d4d4d4;
    box-shadow: none;
  }
  .mbsc-material-dark .mbsc-btn-outline.mbsc-btn.mbsc-active.mbsc-control {
    background: #d4d4d4;
    color: #303030;
    opacity: 1;
  }
  .mbsc-material-dark .mbsc-switch-track {
    background: #5b5b5b;
  }
  .mbsc-material-dark .mbsc-switch .mbsc-switch-handle {
    background: #b7b7b7;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .mbsc-material-dark .mbsc-switch input:checked + .mbsc-switch-track {
    background-color: rgba(129, 204, 196, 0.3);
  }
  .mbsc-material-dark .mbsc-switch input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #81ccc4;
  }
  .mbsc-material-dark .mbsc-switch input:disabled + .mbsc-switch-track {
    background: #5b5b5b;
  }
  .mbsc-material-dark .mbsc-switch input:disabled + .mbsc-switch-track .mbsc-switch-handle {
    background: #595959;
  }
  .mbsc-material-dark .mbsc-segmented-content {
    border: 0.142858em solid #81ccc4;
    color: #fff;
  }
  .mbsc-material-dark .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content,
  .mbsc-material-dark .mbsc-segmented .mbsc-segmented-item input:checked ~ .mbsc-segmented-content {
    background: #81ccc4;
    color: #303030;
  }
  .mbsc-material-dark .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
  .mbsc-material-dark .mbsc-segmented .mbsc-segmented-item .mbsc-active .mbsc-segmented-content {
    background: #0f0f0f;
    color: #fff;
  }
  .mbsc-material-dark .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    color: #929292;
    border-color: #5b5b5b;
    background: transparent;
  }
  .mbsc-material-dark .mbsc-segmented .mbsc-segmented-item input:disabled:checked + .mbsc-segmented-content {
    background: #5b5b5b;
    color: #929292;
    border-color: #5b5b5b;
  }
  .mbsc-material-dark .mbsc-stepper .mbsc-segmented-content {
    border-color: #5b5b5b;
  }
  .mbsc-material-dark .mbsc-stepper-control .mbsc-segmented-content {
    background: #5b5b5b;
  }
  .mbsc-material-dark .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-material-dark .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-material-dark .mbsc-segmented .mbsc-segmented-item.mbsc-disabled .mbsc-segmented-content {
    background: #5b5b5b;
    color: #929292;
  }
  .mbsc-material-dark .mbsc-segmented .mbsc-segmented-item input:disabled ~ .mbsc-segmented-item .mbsc-stepper-val {
    background: #303030;
  }
  .mbsc-material-dark .mbsc-stepper input {
    color: #c2c2c2;
  }
  .mbsc-material-dark .mbsc-stepper input:disabled {
    color: #929292;
    -webkit-text-fill-color: #929292;
  }
  .mbsc-material-dark.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: #0f0f0f;
    color: #fff;
  }
  .mbsc-material-dark .mbsc-stepper-primary.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303030;
  }
  .mbsc-material-dark .mbsc-stepper-primary.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-dark .mbsc-stepper-secondary.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303030;
  }
  .mbsc-material-dark .mbsc-stepper-secondary.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-dark .mbsc-stepper-success.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303030;
  }
  .mbsc-material-dark .mbsc-stepper-success.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-dark .mbsc-stepper-danger.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303030;
  }
  .mbsc-material-dark .mbsc-stepper-danger.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-dark .mbsc-stepper-warning.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303030;
  }
  .mbsc-material-dark .mbsc-stepper-warning.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-dark .mbsc-stepper-info.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303030;
  }
  .mbsc-material-dark .mbsc-stepper-info.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-dark.mbsc-card.mbsc-form {
    background: #3c3c3c;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .mbsc-material-dark.mbsc-card .mbsc-lv-item,
  .mbsc-material-dark .mbsc-card-list .mbsc-card {
    background: #3c3c3c;
  }
  .mbsc-material-dark .mbsc-card-footer {
    border-color: #5c5c5c;
  }
  .mbsc-material-dark.mbsc-card .mbsc-input.mbsc-input-outline .mbsc-label {
    background: #3c3c3c;
  }
  .mbsc-material-dark .mbsc-color-item:before {
    color: #303030;
  }
  .mbsc-material-dark.mbsc-form .mbsc-color-input {
    background-color: transparent;
    border-bottom: 1px solid #d4d4d4;
    color: #858585;
  }
  .mbsc-material-dark .mbsc-lv {
    background: #5b5b5b;
  }
  .mbsc-material-dark .mbsc-lv-item {
    background: #303030;
    color: #c2c2c2;
  }
  .mbsc-material-dark .mbsc-lv-gr-title {
    background: #303030;
    color: #81ccc4;
  }
  .mbsc-material-dark.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
  .mbsc-material-dark .mbsc-lv-item-actionable.mbsc-lv-item-active:before {
    background: rgba(255, 255, 255, 0.05);
  }
  .mbsc-material-dark .mbsc-cal-events .mbsc-cal-event {
    color: #fff;
  }
  .mbsc-material-dark .mbsc-cal-event-color {
    background: #81ccc4;
  }
  .mbsc-material-dark .mbsc-event-list .mbsc-lv-item {
    color: #3c3c3c;
  }
  .mbsc-material-dark .mbsc-event-color {
    background: #81ccc4;
  }
  .mbsc-material-dark.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-table {
    border-top: 1px solid #5b5b5b;
  }
  .mbsc-material-dark.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    border-left: 1px solid #5b5b5b;
    border-bottom: 1px solid #5b5b5b;
  }
  .mbsc-material-dark .mbsc-lv-item-hl:before,
  .mbsc-material-dark .mbsc-lv-item-dragging:before {
    background: rgba(255, 255, 255, 0.05);
  }
  .mbsc-material-dark.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #383838;
  }
  .mbsc-material-dark .mbsc-lv-ic-m {
    color: #fff;
  }
  .mbsc-material-dark .mbsc-lv-arr {
    color: #81ccc4;
  }
  .mbsc-material-dark .mbsc-lv-handle-bar {
    background: #81ccc4;
  }
  .mbsc-material-dark p.mbsc-lv-txt {
    color: #dcdbdb;
  }
  .mbsc-material-dark .mbsc-lv-loading .mbsc-ic {
    color: #c2c2c2;
  }
  .mbsc-material-dark.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-material-dark.mbsc-ms-c {
    background: #303030;
  }
  .mbsc-material-dark .mbsc-ms .mbsc-ms-item {
    color: #c2c2c2;
  }
  .mbsc-material-dark .mbsc-ms .mbsc-ms-item.mbsc-ms-item-sel {
    color: #81ccc4;
  }
  .mbsc-material-dark .mbsc-ms .mbsc-ms-item.mbsc-active {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-material-dark.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-material-dark.mbsc-ms-top {
    border-bottom: 1px solid #5c5c5c;
  }
  .mbsc-material-dark.mbsc-ms-bottom {
    border-top: 1px solid #5c5c5c;
  }
  .mbsc-material-dark.mbsc-ms-a .mbsc-ms-item.mbsc-ms-item-sel {
    color: #81ccc4;
  }
  .mbsc-material-dark.mbsc-ms-b .mbsc-ms-item.mbsc-ms-item-sel {
    border-bottom-color: #81ccc4;
  }
  .mbsc-material-dark.mbsc-ms-b.mbsc-ms-bottom .mbsc-ms-item.mbsc-ms-item-sel {
    border-top-color: #81ccc4;
    border-bottom-color: transparent;
  }
  .mbsc-material-dark .mbsc-np-del {
    color: #81ccc4;
  }
  .mbsc-material-dark .mbsc-range-btn-t {
    border-bottom: 1px solid #5c5c5c;
  }
  .mbsc-material-dark .mbsc-range-btn.mbsc-selected {
    border-color: #81ccc4;
    color: #81ccc4;
  }
  .mbsc-material-dark.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
    background: none;
    border-color: transparent;
  }
  .mbsc-material-dark.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
    background: #81ccc4;
    border-color: #81ccc4;
    color: #303030;
  }
  .mbsc-material-dark.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    background: rgba(129, 204, 196, 0.1);
  }
  .mbsc-material-dark .mbsc-sel-filter-cont {
    border-bottom: 1px solid #5b5b5b;
  }
  .mbsc-material-dark.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-itm-sel {
    color: #81ccc4;
  }
  .mbsc-material-dark.mbsc-fr-pointer .mbsc-sel-one.mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material-dark.mbsc-fr-pointer .mbsc-sel-one.mbsc-no-touch.mbsc-sc-whl-multi .mbsc-sc-itm-sel:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-mobiscroll-dark .mbsc-fr-w {
    background: #263238;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll-dark .mbsc-fr-hdr,
  .mbsc-mobiscroll-dark .mbsc-fr-btn {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-fr-btn.mbsc-active,
  .mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
    background: rgba(79, 204, 196, 0.3);
  }
  .mbsc-mobiscroll-dark .mbsc-fr-arr {
    background: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-sc-lbl {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-sc-whl-l {
    border-top: 1px solid #4fccc4;
    border-bottom: 1px solid #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-sc-btn {
    color: #4fccc4;
    background: #263238;
  }
  .mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-mobiscroll-dark .mbsc-sc-itm:focus {
    background: rgba(247, 247, 247, 0.1);
  }
  .mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-sc-btn:hover:before,
  .mbsc-mobiscroll-dark.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active,
  .mbsc-mobiscroll-dark .mbsc-sc-btn.mbsc-active:before {
    background: rgba(79, 204, 196, 0.3);
  }
  .mbsc-mobiscroll-dark .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-cal-picker {
    background: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-cal-days > div {
    border-bottom: 1px solid #4fccc4;
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-cal-today {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
  .mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
  .mbsc-mobiscroll-dark .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
    background: rgba(79, 204, 196, 0.3);
  }
  .mbsc-mobiscroll-dark .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
    background: #4fccc4;
    color: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
    border-color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-cal-week-nr {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-cal-tab {
    border: 1px solid #4fccc4;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll-dark .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
    background: #4fccc4;
    color: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-cal-mark {
    background: #f7f7f7;
  }
  .mbsc-mobiscroll-dark .mbsc-cal-txt {
    color: #263238;
    background: #f7f7f7;
  }
  .mbsc-mobiscroll-dark .mbsc-cal-icons {
    color: #f7f7f7;
  }
  .mbsc-mobiscroll-dark .mbsc-empty {
    color: #f7f7f7;
  }
  .mbsc-mobiscroll-dark.mbsc-page {
    background: #263238;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll-dark a {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-input input,
  .mbsc-mobiscroll-dark .mbsc-input textarea {
    border-bottom: 1px solid #fff;
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-dark .mbsc-input input:focus,
  .mbsc-mobiscroll-dark .mbsc-input select:focus ~ input .mbsc-input textarea:focus,
  .mbsc-mobiscroll-dark .mbsc-input textarea:focus {
    border-color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-input-ic {
    color: #fff;
  }
  .mbsc-mobiscroll-dark .mbsc-input-box.mbsc-input .mbsc-input-wrap {
    background: #3b4d56;
  }
  .mbsc-mobiscroll-dark .mbsc-input-outline.mbsc-input input,
  .mbsc-mobiscroll-dark .mbsc-input-outline.mbsc-input textarea {
    border: 1px solid #fff;
  }
  .mbsc-mobiscroll-dark .mbsc-input-outline.mbsc-input .mbsc-label {
    background: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-input-outline.mbsc-input input:focus,
  .mbsc-mobiscroll-dark .mbsc-input-outline.mbsc-input select:focus ~ input,
  .mbsc-mobiscroll-dark .mbsc-input-outline.mbsc-err input {
    border-color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-input-outline.mbsc-input textarea:focus {
    border-color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-input.mbsc-err input,
  .mbsc-mobiscroll-dark .mbsc-input.mbsc-err textarea {
    border-color: #de3226;
  }
  .mbsc-mobiscroll-dark.mbsc-progress .mbsc-input-ic {
    color: #fff;
  }
  .mbsc-mobiscroll-dark .mbsc-progress-track {
    background: #364850;
  }
  .mbsc-mobiscroll-dark .mbsc-progress-bar {
    background: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-slider-step {
    background: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-slider-handle {
    background: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-slider-tooltip {
    color: #263238;
    background-color: #4fccc4;
  }
  .mbsc-mobiscroll-dark.mbsc-rating .mbsc-progress-track {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark.mbsc-snackbar .mbsc-fr-w,
  .mbsc-mobiscroll-dark.mbsc-toast .mbsc-toast-msg {
    background: #787878;
    color: #fff;
  }
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-mobiscroll-dark.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-mobiscroll-dark.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-mobiscroll-dark.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-mobiscroll-dark.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-mobiscroll-dark.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-mobiscroll-dark.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
  .mbsc-mobiscroll-dark.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #263238;
  }
  .mbsc-mobiscroll-dark.mbsc-form {
    background: #263238;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll-dark.mbsc-form *::-moz-selection {
    background: #4fccc4;
  }
  .mbsc-mobiscroll-dark.mbsc-form *::selection {
    background: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-divider,
  .mbsc-mobiscroll-dark .mbsc-form-group-title {
    background: #263238;
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-err-msg,
  .mbsc-mobiscroll-dark .mbsc-err .mbsc-label {
    color: #de3226;
  }
  .mbsc-mobiscroll-dark .mbsc-checkbox-box {
    background: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-checkbox-box:after {
    border-bottom: 0.125em solid #263238;
    border-left: 0.125em solid #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-checkbox input:disabled + .mbsc-checkbox-box {
    background: #49606c;
  }
  .mbsc-mobiscroll-dark .mbsc-radio-box {
    border: 0.125em solid #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-radio-box:after {
    background: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-radio input:checked + .mbsc-radio-box {
    background: transparent;
  }
  .mbsc-mobiscroll-dark .mbsc-radio input:disabled + .mbsc-radio-box {
    border-color: #49606c;
  }
  .mbsc-mobiscroll-dark .mbsc-radio input:disabled + .mbsc-radio-box:after {
    background: #49606c;
  }
  .mbsc-mobiscroll-dark .mbsc-btn {
    background: #4fccc4;
    color: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-btn:disabled {
    background: #364850;
  }
  .mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    background: rgba(38, 50, 56, 0.1);
  }
  .mbsc-mobiscroll-dark .mbsc-btn-flat {
    background: transparent;
    color: #4fccc4;
    border-color: transparent;
  }
  .mbsc-mobiscroll-dark .mbsc-btn-flat.mbsc-btn.mbsc-active {
    background: rgba(79, 204, 196, 0.3);
  }
  .mbsc-mobiscroll-dark .mbsc-btn-flat:disabled {
    color: #435863;
    background: transparent;
  }
  .mbsc-mobiscroll-dark .mbsc-btn-light.mbsc-btn {
    color: #000;
  }
  .mbsc-mobiscroll-dark .mbsc-btn-outline.mbsc-btn {
    border: 1px solid #4fccc4;
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-btn-outline.mbsc-btn.mbsc-active {
    background: #4fccc4;
    color: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-btn-outline.mbsc-btn.mbsc-btn-primary.mbsc-active,
  .mbsc-mobiscroll-dark .mbsc-btn-outline.mbsc-btn.mbsc-btn-secondary.mbsc-active,
  .mbsc-mobiscroll-dark .mbsc-btn-outline.mbsc-btn.mbsc-btn-success.mbsc-active,
  .mbsc-mobiscroll-dark .mbsc-btn-outline.mbsc-btn.mbsc-btn-danger.mbsc-active,
  .mbsc-mobiscroll-dark .mbsc-btn-outline.mbsc-btn.mbsc-btn-warning.mbsc-active,
  .mbsc-mobiscroll-dark .mbsc-btn-outline.mbsc-btn.mbsc-btn-info.mbsc-active,
  .mbsc-mobiscroll-dark .mbsc-btn-outline.mbsc-btn.mbsc-btn-dark.mbsc-active {
    color: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-btn.mbsc-btn-outline:disabled {
    color: #364850;
    border-color: #364850;
    background: transparent;
  }
  .mbsc-mobiscroll-dark .mbsc-switch-track {
    background: #364850;
  }
  .mbsc-mobiscroll-dark .mbsc-switch-handle {
    background: #435863;
  }
  .mbsc-mobiscroll-dark .mbsc-switch input:checked + .mbsc-switch-track {
    background: #b5e6e3;
  }
  .mbsc-mobiscroll-dark .mbsc-switch input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-switch input:disabled + .mbsc-switch-track {
    background: #49606c;
  }
  .mbsc-mobiscroll-dark .mbsc-switch input:disabled + .mbsc-switch-track .mbsc-switch-handle {
    background: #34454d;
  }
  .mbsc-mobiscroll-dark .mbsc-segmented-content {
    border: 0.142857em solid #4fccc4;
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-stepper input {
    color: #f7f7f7;
  }
  .mbsc-mobiscroll-dark .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
  .mbsc-mobiscroll-dark .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #4fccc4;
    color: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(79, 204, 196, 0.3);
  }
  .mbsc-mobiscroll-dark .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-mobiscroll-dark .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-mobiscroll-dark .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    color: #49606c;
    border-color: #49606c;
  }
  .mbsc-mobiscroll-dark .mbsc-stepper input:disabled {
    color: #49606c;
    -webkit-text-fill-color: #49606c;
  }
  .mbsc-mobiscroll-dark .mbsc-segmented input:disabled:checked + .mbsc-segmented-content {
    border-color: #364850;
    background: #364850;
    color: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-stepper .mbsc-active.mbsc-disabled .mbsc-segmented-content {
    background: transparent;
    color: #49606c;
  }
  .mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(79, 204, 196, 0.1);
  }
  .mbsc-mobiscroll-dark.mbsc-card.mbsc-form {
    background: #2e3d44;
  }
  .mbsc-mobiscroll-dark.mbsc-card .mbsc-lv-item {
    background: #2e3d44;
  }
  .mbsc-mobiscroll-dark .mbsc-card-footer {
    border-color: #49606c;
  }
  .mbsc-mobiscroll-dark.mbsc-card .mbsc-input.mbsc-input-outline .mbsc-label {
    background: #2e3d44;
  }
  .mbsc-mobiscroll-dark .mbsc-color-active,
  .mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-color-item-c:hover {
    box-shadow: inset 0 0 0 0.1875em #4fccc4;
  }
  .mbsc-mobiscroll-dark.mbsc-form .mbsc-color-input {
    border-bottom: 1px solid #fff;
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-dark .mbsc-lv {
    background: #435863;
  }
  .mbsc-mobiscroll-dark .mbsc-lv-item {
    background: #263238;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll-dark .mbsc-lv-gr-title {
    background: #4fccc4;
    color: #263238;
  }
  .mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
  .mbsc-mobiscroll-dark .mbsc-lv-item-active.mbsc-lv-item-actionable:before {
    background: rgba(79, 204, 196, 0.15);
  }
  .mbsc-mobiscroll-dark.mbsc-cal-events-popup .mbsc-fr-w {
    border: 0.375em solid #fff;
  }
  .mbsc-mobiscroll-dark.mbsc-cal-events-popup .mbsc-fr-arr {
    background: #fff;
  }
  .mbsc-mobiscroll-dark .mbsc-cal-event-color {
    background: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-cal-event-time,
  .mbsc-mobiscroll-dark .mbsc-cal-event-dur {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-event-list .mbsc-lv-gr-title {
    background: #263238;
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-event-color {
    background: rgba(247, 247, 247, 0.3);
  }
  .mbsc-mobiscroll-dark.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-table {
    border-top: 1px solid #364850;
  }
  .mbsc-mobiscroll-dark.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    border-left: 1px solid #364850;
    border-bottom: 1px solid #364850;
  }
  .mbsc-mobiscroll-dark.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #1e272c;
  }
  .mbsc-mobiscroll-dark .mbsc-lv-item-hl:before {
    background: rgba(79, 204, 196, 0.15);
  }
  .mbsc-mobiscroll-dark .mbsc-lv-ic-m {
    color: #fff;
  }
  .mbsc-mobiscroll-dark .mbsc-lv-arr {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-lv-handle-bar {
    background: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-lv-item.mbsc-lv-item-dragging {
    background: #4fccc4;
    color: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-lv-item-dragging .mbsc-lv-handle-bar {
    background: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-lv-item-dragging .mbsc-lv-arr {
    color: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-lv-loading .mbsc-ic {
    color: #f7f7f7;
  }
  .mbsc-mobiscroll-dark.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(79, 204, 196, 0.3);
  }
  .mbsc-mobiscroll-dark.mbsc-ms-c {
    background: #263238;
  }
  .mbsc-mobiscroll-dark .mbsc-ms .mbsc-ms-item {
    color: #f7f7f7;
  }
  .mbsc-mobiscroll-dark.mbsc-ms-c .mbsc-scv-sc .mbsc-ms .mbsc-ms-item.mbsc-active {
    background: rgba(79, 204, 196, 0.3);
  }
  .mbsc-mobiscroll-dark.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: rgba(79, 204, 196, 0.1);
  }
  .mbsc-mobiscroll-dark.mbsc-ms-b .mbsc-ms-item-sel {
    border-bottom-color: #4fccc4;
  }
  .mbsc-mobiscroll-dark.mbsc-ms-b.mbsc-ms-top {
    border-bottom: 1px solid #4fccc4;
  }
  .mbsc-mobiscroll-dark.mbsc-ms-b.mbsc-ms-bottom {
    border-top: 1px solid #4fccc4;
  }
  .mbsc-mobiscroll-dark.mbsc-ms-b.mbsc-ms-bottom .mbsc-ms-item-sel {
    border-top-color: #4fccc4;
    border-bottom-color: transparent;
  }
  .mbsc-mobiscroll-dark.mbsc-ms-a.mbsc-ms-top {
    border-bottom: 1px solid #49606c;
  }
  .mbsc-mobiscroll-dark.mbsc-ms-a.mbsc-ms-bottom {
    border-top: 1px solid #49606c;
  }
  .mbsc-mobiscroll-dark.mbsc-ms-a .mbsc-ms-item-sel {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-np-del {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark .mbsc-range-btn {
    border: 1px solid #4fccc4;
  }
  .mbsc-mobiscroll-dark.mbsc-range .mbsc-range-btn-t .mbsc-range-btn.mbsc-selected {
    background: #4fccc4;
    color: #263238;
  }
  .mbsc-mobiscroll-dark.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
    background: none;
    border-color: transparent;
    color: #f7f7f7;
  }
  .mbsc-mobiscroll-dark.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
    background: #4fccc4;
    border-color: #4fccc4;
    color: #263238;
  }
  .mbsc-mobiscroll-dark.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    background: rgba(79, 204, 196, 0.3);
  }
  .mbsc-mobiscroll-dark .mbsc-sel-gr-whl.mbsc-sel-one .mbsc-sc-itm-sel {
    color: #4fccc4;
  }
  .mbsc-mobiscroll-dark.mbsc-fr-pointer .mbsc-sel-one.mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #f7f7f7;
    background: rgba(79, 204, 196, 0.3);
  }
  .mbsc-windows-dark .mbsc-fr-w {
    background: #1a1a1a;
    color: #fff;
    border: 1px solid #404040;
  }
  .mbsc-windows-dark .mbsc-fr-hdr {
    border-bottom: 2px solid #404040;
  }
  .mbsc-windows-dark .mbsc-fr-btn-cont {
    border-top: 2px solid #404040;
  }
  .mbsc-windows-dark .mbsc-fr-btn-w {
    background: #1a1a1a;
  }
  .mbsc-windows-dark .mbsc-fr-btn {
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
  .mbsc-windows-dark .mbsc-fr-btn.mbsc-active {
    background: #343333;
  }
  .mbsc-windows-dark .mbsc-fr-arr {
    background: #1a1a1a;
    border: 1px solid #404040;
  }
  .mbsc-windows-dark .mbsc-sc-whl-gr-c {
    border-bottom: 2px solid #404040;
  }
  .mbsc-windows-dark .mbsc-sc-whl-w {
    border-right: 2px solid #404040;
  }
  .mbsc-windows-dark .mbsc-sc-lbl-v .mbsc-sc-whl-w {
    background: #1a1a1a;
  }
  .mbsc-windows-dark .mbsc-sc-lbl-v {
    background: #404040;
  }
  .mbsc-windows-dark .mbsc-sc-whl-l {
    background: rgba(0, 120, 215, 0.4);
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-windows-dark .mbsc-sc-itm:focus {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-windows-dark.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
    background: rgba(255, 255, 255, 0.2);
  }
  .mbsc-windows-dark .mbsc-sc-btn {
    background: #272727;
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-sc-btn:hover,
  .mbsc-windows-dark .mbsc-sc-btn.mbsc-active {
    background: #343333;
  }
  .mbsc-windows-dark .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #0078d7;
  }
  .mbsc-windows-dark .mbsc-cal-picker {
    background: #1a1a1a;
  }
  .mbsc-windows-dark .mbsc-cal-days > div {
    color: #b3b2b2;
  }
  .mbsc-windows-dark .mbsc-cal-today .mbsc-cal-day-date {
    color: #0078d7;
  }
  .mbsc-windows-dark .mbsc-cal-day-diff .mbsc-cal-day-i {
    background: #272727;
  }
  .mbsc-windows-dark .mbsc-cal-cell:focus .mbsc-cal-day-i {
    background: #343333;
  }
  .mbsc-windows-dark.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-day:not(.mbsc-disabled):hover .mbsc-cal-day-i,
  .mbsc-windows-dark.mbsc-no-touch .mbsc-cal-picker .mbsc-cal-cell:not(.mbsc-disabled):hover {
    background: #343333;
  }
  .mbsc-windows-dark .mbsc-cal .mbsc-cal-day.mbsc-selected:not(.mbsc-disabled) .mbsc-cal-cell-i.mbsc-cal-day-i,
  .mbsc-windows-dark .mbsc-cal .mbsc-cal-picker .mbsc-cal-cell.mbsc-selected,
  .mbsc-windows-dark.mbsc-no-touch .mbsc-cal-picker .mbsc-cal-cell.mbsc-selected:hover {
    background: rgba(0, 120, 215, 0.25);
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-cal-tab:hover {
    background: #343333;
    border-color: #343333;
  }
  .mbsc-windows-dark .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected,
  .mbsc-windows-dark.mbsc-no-touch .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected:hover {
    border-bottom-color: #0078d7;
    border-top-color: transparent;
    color: #0078d7;
    background: #1a1a1a;
  }
  .mbsc-windows-dark .mbsc-cal-tabs-c {
    border-bottom: 1px solid #404040;
  }
  .mbsc-windows-dark .mbsc-cal-mark {
    background: #fff;
  }
  .mbsc-windows-dark .mbsc-cal-txt {
    color: #fff;
    background: #484848;
  }
  .mbsc-windows-dark .mbsc-empty {
    color: #b3b2b2;
  }
  .mbsc-windows-dark.mbsc-page {
    background-color: #000;
    color: #fff;
  }
  .mbsc-windows-dark a {
    color: #0078d7;
  }
  .mbsc-windows-dark .mbsc-input input,
  .mbsc-windows-dark .mbsc-input textarea,
  .mbsc-windows-dark .mbsc-select input {
    border: 0.125em solid #737373;
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-input input:hover,
  .mbsc-windows-dark.mbsc-no-touch .mbsc-input textarea:hover,
  .mbsc-windows-dark.mbsc-no-touch .mbsc-select:hover input {
    border-color: #a6a6a6;
  }
  .mbsc-windows-dark .mbsc-input input::-webkit-input-placeholder {
    color: #5c5c5c;
  }
  .mbsc-windows-dark .mbsc-input input::-ms-input-placeholder {
    color: #5c5c5c;
  }
  .mbsc-windows-dark .mbsc-input input::-moz-placeholder {
    color: #5c5c5c;
  }
  .mbsc-windows-dark .mbsc-input .mbsc-input-wrap input:focus,
  .mbsc-windows-dark .mbsc-input .mbsc-input-wrap select:focus + input,
  .mbsc-windows-dark .mbsc-input .mbsc-input-wrap textarea:focus,
  .mbsc-windows-dark.mbsc-alert .mbsc-input input:focus {
    background: #fff;
    border-color: #0078d7;
    color: #262626;
  }
  .mbsc-windows-dark .mbsc-input .mbsc-input-wrap input:focus ~ .mbsc-input-ic,
  .mbsc-windows-dark .mbsc-input .mbsc-input-wrap select:focus + input ~ .mbsc-input-ic,
  .mbsc-windows-dark .mbsc-input .mbsc-input-wrap textarea:focus ~ .mbsc-input-ic,
  .mbsc-windows-dark.mbsc-alert .mbsc-input input:focus ~ .mbsc-input-ic {
    color: #262626;
  }
  .mbsc-windows-dark .mbsc-input .mbsc-input-wrap .mbsc-control:disabled + input,
  .mbsc-windows-dark .mbsc-input .mbsc-input-wrap textarea:disabled,
  .mbsc-windows-dark .mbsc-input .mbsc-input-wrap input:disabled {
    border-color: #404040;
  }
  .mbsc-windows-dark .mbsc-input.mbsc-err input,
  .mbsc-windows-dark .mbsc-input.mbsc-err textarea {
    border-color: #d30101;
  }
  .mbsc-windows-dark .mbsc-input-ic,
  .mbsc-windows-dark .mbsc-input .mbsc-label,
  .mbsc-windows-dark .mbsc-select .mbsc-input-wrap .mbsc-ic {
    color: #fff;
  }
  .mbsc-windows-dark .mbsc-err-msg {
    color: #d30101;
  }
  .mbsc-windows-dark.mbsc-progress .mbsc-label,
  .mbsc-windows-dark.mbsc-progress .mbsc-input-ic {
    color: #fff;
  }
  .mbsc-windows-dark .mbsc-progress-track {
    background: #1f1f1f;
  }
  .mbsc-windows-dark .mbsc-progress-bar {
    background: #0078d7;
  }
  .mbsc-windows-dark.mbsc-slider .mbsc-progress-track {
    background: #737373;
    border-top: 2px solid #1a1a1a;
    border-bottom: 2px solid #1a1a1a;
  }
  .mbsc-windows-dark.mbsc-form .mbsc-slider .mbsc-progress-track {
    border-top-color: #000;
    border-bottom-color: #000;
  }
  .mbsc-windows-dark.mbsc-slider:hover .mbsc-progress-track {
    background: #a6a6a6;
  }
  .mbsc-windows-dark.mbsc-slider:hover .mbsc-progress-track .mbsc-slider-handle {
    background: #fff;
  }
  .mbsc-windows-dark.mbsc-slider .mbsc-active ~ .mbsc-progress-cont .mbsc-progress-track {
    background: #737373;
  }
  .mbsc-windows-dark.mbsc-slider .mbsc-active ~ .mbsc-progress-cont .mbsc-progress-track .mbsc-slider-handle {
    background: #404040;
  }
  .mbsc-windows-dark .mbsc-slider-handle {
    background: #0078d7;
  }
  .mbsc-windows-dark .mbsc-slider-tooltip {
    border: 1px solid #404040;
    background: #1a1a1a;
    color: #fff;
  }
  .mbsc-windows-dark .mbsc-slider-step {
    background: #737373;
  }
  .mbsc-windows-dark.mbsc-rating .mbsc-progress-track {
    color: #0078d7;
  }
  .mbsc-windows-dark.mbsc-wdg.mbsc-fr-btn {
    background: #404040;
  }
  .mbsc-windows-dark.mbsc-snackbar .mbsc-snackbar-btn {
    color: #0078d7;
  }
  .mbsc-windows-dark.mbsc-toast .mbsc-toast-msg {
    background: #a6a6a6;
    color: #000;
  }
  .mbsc-windows-dark.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-windows-dark.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-windows-dark.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-windows-dark.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-windows-dark.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-windows-dark.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-windows-dark.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-windows-dark.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-windows-dark.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-windows-dark.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-windows-dark.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-windows-dark.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-windows-dark.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-windows-dark.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-windows-dark.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-windows-dark.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-windows-dark.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #efeff4;
  }
  .mbsc-windows-dark.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn {
    color: #000;
  }
  .mbsc-windows-dark.mbsc-form {
    background-color: #000;
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-form *::-moz-selection,
  .mbsc-windows-dark.mbsc-form *::selection {
    color: #fff;
    background: rgba(0, 120, 215, 0.9);
  }
  .mbsc-windows-dark .mbsc-checkbox-box,
  .mbsc-windows-dark .mbsc-radio-box {
    border: 0.125em solid #fff;
  }
  .mbsc-windows-dark .mbsc-checkbox-box:after {
    border: 0.125em solid #000;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-windows-dark .mbsc-checkbox input:checked + .mbsc-checkbox-box {
    background: #0078d7;
    border-color: #0078d7;
  }
  .mbsc-windows-dark .mbsc-checkbox input.mbsc-active + .mbsc-checkbox-box {
    border-color: #a6a6a6;
    background: #a6a6a6;
  }
  .mbsc-windows-dark .mbsc-radio-box:after {
    background: #fff;
  }
  .mbsc-windows-dark .mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #0078d7;
  }
  .mbsc-windows-dark .mbsc-radio input.mbsc-active + .mbsc-radio-box {
    border-color: rgba(255, 255, 255, 0.6);
  }
  .mbsc-windows-dark .mbsc-radio input.mbsc-active + .mbsc-radio-box:after {
    background: rgba(255, 255, 255, 0.6);
  }
  .mbsc-windows-dark .mbsc-btn {
    background: #404040;
    border: 0.125em solid #404040;
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
    border-color: #ababab;
  }
  .mbsc-windows-dark .mbsc-btn.mbsc-active {
    border-color: #ababab;
    background: #ababab;
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-btn-flat:not(:disabled):hover {
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows-dark .mbsc-btn.mbsc-btn-flat.mbsc-active:not(:disabled) {
    background: rgba(64, 64, 64, 0.25);
  }
  .mbsc-windows-dark .mbsc-btn.mbsc-btn-outline {
    background: transparent;
    border: 0.125em solid #737373;
    color: #737373;
  }
  .mbsc-windows-dark .mbsc-btn.mbsc-btn-outline.mbsc-active {
    background: #8d8d8d;
    border-color: #8d8d8d;
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-btn.mbsc-btn-outline:not(:disabled):hover {
    border-color: #8d8d8d;
  }
  .mbsc-windows-dark .mbsc-switch-track {
    border: 2px solid #fff;
  }
  .mbsc-windows-dark .mbsc-switch-track .mbsc-switch-handle {
    background: #fff;
  }
  .mbsc-windows-dark .mbsc-switch input:checked + .mbsc-switch-track {
    background: #0078d7;
    border-color: #0078d7;
  }
  .mbsc-windows-dark .mbsc-switch input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #1a1a1a;
  }
  .mbsc-windows-dark .mbsc-switch input:checked:disabled + .mbsc-switch-track {
    background: #fff;
    border-color: #fff;
  }
  .mbsc-windows-dark.mbsc-form .mbsc-switch input.mbsc-active + .mbsc-switch-track {
    background: #a6a6a6;
    border-color: #a6a6a6;
  }
  .mbsc-windows-dark.mbsc-form .mbsc-switch input.mbsc-active + .mbsc-switch-track .mbsc-switch-handle {
    background: #1a1a1a;
  }
  .mbsc-windows-dark .mbsc-segmented-content {
    color: #fff;
    background: #404040;
  }
  .mbsc-windows-dark .mbsc-segmented-content.mbsc-stepper-val {
    background: transparent;
    border-top: 2px solid #404040;
    border-bottom: 2px solid #404040;
  }
  .mbsc-windows-dark.mbsc-ltr .mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item {
    border-right: 1px solid #4a4a4a;
  }
  .mbsc-windows-dark.mbsc-rtl .mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item {
    border-left: 1px solid #4a4a4a;
  }
  .mbsc-windows-dark .mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item:last-child,
  .mbsc-windows-dark .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-plus,
  .mbsc-windows-dark .mbsc-stepper-val-left .mbsc-segmented input + .mbsc-segmented-item,
  .mbsc-windows-dark .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control {
    border: 0;
  }
  .mbsc-windows-dark.mbsc-ltr .mbsc-segmented .mbsc-stepper-control + .mbsc-stepper-control {
    border-left: 1px solid #4a4a4a;
  }
  .mbsc-windows-dark.mbsc-rtl .mbsc-segmented .mbsc-stepper-control + .mbsc-stepper-control {
    border-right: 1px solid #4a4a4a;
  }
  .mbsc-windows-dark .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #fff;
    color: #000;
  }
  .mbsc-windows-dark .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
  .mbsc-windows-dark .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content,
  .mbsc-windows-dark .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: #ababab;
  }
  .mbsc-windows-dark .mbsc-stepper .mbsc-segmented-item.mbsc-active .mbsc-segmented-content {
    background: #ababab;
  }
  .mbsc-windows-dark .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-windows-dark .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-windows-dark .mbsc-stepper .mbsc-segmented-item.mbsc-disabled .mbsc-segmented-content,
  .mbsc-windows-dark .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    background: #404040;
  }
  .mbsc-windows-dark .mbsc-stepper input:disabled {
    color: #4a4a4a;
    -webkit-text-fill-color: #4a4a4a;
  }
  .mbsc-windows-dark .mbsc-segmented .mbsc-segmented-item input:disabled:checked + .mbsc-segmented-content {
    color: #000;
    background: #4a4a4a;
  }
  .mbsc-windows-dark .mbsc-stepper .mbsc-active.mbsc-disabled .mbsc-segmented-content {
    background: transparent;
  }
  .mbsc-windows-dark .mbsc-stepper input {
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: #343333;
  }
  .mbsc-windows-dark.mbsc-card.mbsc-form {
    background: #202020;
    box-shadow: 0 0 1px 1px #393939;
  }
  .mbsc-windows-dark.mbsc-card .mbsc-lv-item {
    background: #202020;
  }
  .mbsc-windows-dark .mbsc-card-footer {
    border-color: #393939;
  }
  .mbsc-windows-dark .mbsc-color-active,
  .mbsc-windows-dark.mbsc-no-touch .mbsc-color-item-c:hover {
    box-shadow: inset 0 0 0 0.1875em #0078d7;
  }
  .mbsc-windows-dark .mbsc-color-clear-item {
    background: fade(#dfdede, 30%);
  }
  .mbsc-windows-dark.mbsc-color .mbsc-color-slider-cont .mbsc-progress-track {
    background: #4a4a4a;
  }
  .mbsc-windows-dark .mbsc-color-preview-item-empty,
  .mbsc-windows-dark .mbsc-color-preview-cont {
    border-color: #b3b3b3;
  }
  .mbsc-windows-dark.mbsc-form .mbsc-color-input {
    border: 0.125em solid #737373;
    color: #fff;
  }
  .mbsc-windows-dark .mbsc-lv {
    background: #000;
  }
  .mbsc-windows-dark .mbsc-lv-item,
  .mbsc-windows-dark .mbsc-lv-gr-title {
    background: #1a1a1a;
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
  .mbsc-windows-dark .mbsc-lv-item-actionable.mbsc-lv-item-active:before {
    background: rgba(255, 255, 255, 0.2);
  }
  .mbsc-windows-dark.mbsc-cal-events-popup .mbsc-fr-w {
    border: 0.375em solid rgba(255, 255, 255, 0.8);
  }
  .mbsc-windows-dark.mbsc-cal-events-popup .mbsc-fr-arr {
    background: rgba(255, 255, 255, 0.8);
  }
  .mbsc-windows-dark .mbsc-cal-events {
    background: rgba(255, 255, 255, 0.8);
  }
  .mbsc-windows-dark .mbsc-cal-event-color {
    background: #fff;
  }
  .mbsc-windows-dark .mbsc-event-list .mbsc-lv-item {
    background: #000;
  }
  .mbsc-windows-dark .mbsc-event-list .mbsc-lv-item:last-child {
    border-bottom: 1px solid #272727;
  }
  .mbsc-windows-dark .mbsc-event-list .mbsc-lv-gr-title {
    background: #000;
  }
  .mbsc-windows-dark .mbsc-event-color {
    background: #0078d7;
  }
  .mbsc-windows-dark.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-table {
    border-top: 1px solid #404040;
  }
  .mbsc-windows-dark.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    background: #000;
    border-left: 1px solid #404040;
    border-bottom: 1px solid #404040;
  }
  .mbsc-windows-dark.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-day-diff .mbsc-cal-day-i {
    background: #1a1a1a;
  }
  .mbsc-windows-dark .mbsc-lv-handle-bar {
    background: #888;
  }
  .mbsc-windows-dark .mbsc-lv-ic-m {
    color: #1a1a1a;
  }
  .mbsc-windows-dark.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #393939;
  }
  .mbsc-windows-dark .mbsc-lv-item.mbsc-lv-item-hl {
    background: #404040;
  }
  .mbsc-windows-dark .mbsc-lv-loading .mbsc-ic {
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(0, 120, 215, 0.2);
  }
  .mbsc-windows-dark.mbsc-ms-c {
    background: #1a1a1a;
    color: #fff;
  }
  .mbsc-windows-dark .mbsc-ms-item.mbsc-active,
  .mbsc-windows-dark.mbsc-no-touch .mbsc-ms-item.mbsc-active:not(.mbsc-disabled):hover {
    background: #404040;
  }
  .mbsc-windows-dark .mbsc-ms-item-sel,
  .mbsc-windows-dark .mbsc-ms-item-sel.mbsc-ms-ic:before {
    color: #0078d7;
  }
  .mbsc-windows-dark.mbsc-ms-b .mbsc-ms-item.mbsc-ms-item-sel {
    border-bottom-color: #0078d7;
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: #343333;
  }
  .mbsc-windows-dark .mbsc-ms-item.mbsc-menu-item-ic {
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-ms-more .mbsc-ms-item {
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-ms-more .mbsc-ms-item.mbsc-ms-item-sel {
    color: #0078d7;
  }
  .mbsc-windows-dark.mbsc-fr .mbsc-fr-w .mbsc-np-del.mbsc-active {
    background: #404040;
  }
  .mbsc-windows-dark .mbsc-np-del.mbsc-active:before {
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-fr .mbsc-fr-w .mbsc-np-btn.mbsc-active {
    background: #404040;
    color: #fff;
  }
  .mbsc-windows-dark .mbsc-np-btn.mbsc-disabled {
    color: #737373;
  }
  .mbsc-windows-dark .mbsc-range-btn {
    color: #fff;
    background: #404040;
    border: 2px solid #404040;
  }
  .mbsc-windows-dark.mbsc-no-touch .mbsc-range-btn.mbsc-fr-btn-e:not(.mbsc-disabled):hover {
    border-color: #000;
    background: #404040;
  }
  .mbsc-windows-dark .mbsc-range-btn.mbsc-selected,
  .mbsc-windows-dark.mbsc-no-touch .mbsc-range-btn.mbsc-selected:not(.mbsc-disabled):hover {
    background: #0078d7;
    border-color: #0078d7;
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-i,
  .mbsc-windows-dark.mbsc-range .mbsc-cal .mbsc-cal-today.mbsc-cal-day-hl .mbsc-cal-day-date {
    background: #0078d7;
    color: #1a1a1a;
  }
  .mbsc-windows-dark .mbsc-sel-gr-whl.mbsc-sel-one .mbsc-sc-itm-sel {
    color: #0078d7;
  }
  .mbsc-windows-dark .mbsc-sel-filter-clear {
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #fff;
    background: rgba(0, 120, 215, 0.2);
  }
  .mbsc-windows-dark.mbsc-timer .mbsc-sc-lbl {
    color: #fff;
  }
  .mbsc-windows-dark.mbsc-ts .mbsc-sc-lbl {
    color: #fff;
  }
  .mbsc-ios-gray.mbsc-fr-top .mbsc-fr-btn-cont,
  .mbsc-ios-gray.mbsc-fr-bottom .mbsc-fr-btn-cont {
    background: #474747;
  }
  .mbsc-ios-gray .mbsc-fr-overlay {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-ios-gray .mbsc-fr-w {
    background: #333;
    color: #fff;
  }
  .mbsc-ios-gray .mbsc-fr-hdr {
    color: #fff;
  }
  .mbsc-ios-gray.mbsc-fr-nobtn .mbsc-fr-hdr,
  .mbsc-ios-gray.mbsc-fr-center:not(.mbsc-cal-liq) .mbsc-fr-hdr,
  .mbsc-ios-gray .mbsc-fr-btn-cont {
    border-bottom: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-fr-btn {
    color: #f573d0;
  }
  .mbsc-ios-gray .mbsc-fr-arr {
    background: #333;
  }
  .mbsc-ios-gray.mbsc-fr-bottom .mbsc-fr-popup {
    border-top: 1px solid dimgray;
  }
  .mbsc-ios-gray.mbsc-fr-top .mbsc-fr-popup {
    border-bottom: 1px solid dimgray;
  }
  .mbsc-ios-gray.mbsc-fr-center .mbsc-fr-btn-w {
    border-top: 1px solid #666;
    border-left: 1px solid #666;
  }
  .mbsc-ios-gray.mbsc-fr-center .mbsc-rtl .mbsc-fr-btn-w {
    border-right: 1px solid #666;
  }
  .mbsc-ios-gray.mbsc-fr-center.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
  .mbsc-ios-gray.mbsc-fr-center .mbsc-fr-btn.mbsc-active {
    background: #525252;
  }
  .mbsc-ios-gray.mbsc-fr-inline .mbsc-fr-w {
    background: #3b3b3b;
    border-top: 1px solid #666;
  }
  .mbsc-ios-gray.mbsc-fr-inline .mbsc-fr-c {
    border-bottom: 1px solid #666;
  }
  .mbsc-ios-gray.mbsc-fr-no-overlay .mbsc-fr-arr {
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.8);
  }
  .mbsc-ios-gray.mbsc-fr-no-overlay .mbsc-fr-w {
    box-shadow: 0 0.125em 1em rgba(0, 0, 0, 0.8);
  }
  .mbsc-ios-gray.mbsc-fr-pointer .mbsc-fr-persp .mbsc-fr-popup .mbsc-fr-w,
  .mbsc-ios-gray.mbsc-fr-pointer .mbsc-fr-persp .mbsc-cal-picker {
    background: #5e5e5e;
  }
  .mbsc-ios-gray.mbsc-fr-pointer .mbsc-fr-hdr {
    border-color: #878787;
  }
  .mbsc-ios-gray.mbsc-sc.mbsc-fr-top .mbsc-fr-w,
  .mbsc-ios-gray.mbsc-sc.mbsc-fr-bottom .mbsc-fr-w {
    background: #333;
  }
  .mbsc-ios-gray.mbsc-calendar .mbsc-fr-persp .mbsc-fr-w {
    background: #333;
  }
  .mbsc-ios-gray.mbsc-calendar.mbsc-fr-top .mbsc-fr-btn-cont,
  .mbsc-ios-gray.mbsc-calendar.mbsc-fr-bottom .mbsc-fr-btn-cont {
    border-bottom: 1px solid #666;
  }
  .mbsc-ios-gray.mbsc-fr-top .mbsc-sc-whl-l,
  .mbsc-ios-gray.mbsc-fr-bottom .mbsc-sc-whl-l {
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-sc-whl-l,
  .mbsc-ios-gray.mbsc-calendar .mbsc-sc-whl-l {
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
  }
  .mbsc-ios-gray.mbsc-fr-top .mbsc-sc-whl-o,
  .mbsc-ios-gray.mbsc-fr-bottom .mbsc-sc-whl-o {
    background: -webkit-linear-gradient(#333, rgba(51, 51, 51, 0) 52%, rgba(51, 51, 51, 0) 48%, #333);
    background: linear-gradient(#333, rgba(51, 51, 51, 0) 52%, rgba(51, 51, 51, 0) 48%, #333);
  }
  .mbsc-ios-gray .mbsc-sc-whl-o,
  .mbsc-ios-gray.mbsc-calendar .mbsc-sc-whl-o {
    background: -webkit-linear-gradient(#333, rgba(51, 51, 51, 0) 52%, rgba(51, 51, 51, 0) 48%, #333);
    background: linear-gradient(#333, rgba(51, 51, 51, 0) 52%, rgba(51, 51, 51, 0) 48%, #333);
  }
  .mbsc-ios-gray.mbsc-fr-top .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c,
  .mbsc-ios-gray.mbsc-fr-bottom .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    background: #333;
  }
  .mbsc-ios-gray.mbsc-fr.mbsc-fr-inline .mbsc-sc-whl-o {
    background: -webkit-linear-gradient(#424242, rgba(66, 66, 66, 0) 52%, rgba(66, 66, 66, 0) 48%, #424242);
    background: linear-gradient(#424242, rgba(66, 66, 66, 0) 52%, rgba(66, 66, 66, 0) 48%, #424242);
  }
  .mbsc-ios-gray.mbsc-fr.mbsc-fr-inline .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    background: #424242;
  }
  .mbsc-ios-gray .mbsc-sc-lbl {
    color: #fff;
  }
  .mbsc-ios-gray .mbsc-sc-itm {
    color: #9e9e9e;
  }
  .mbsc-ios-gray.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-ios-gray .mbsc-sc-itm:focus {
    background: rgba(245, 115, 208, 0.15);
  }
  .mbsc-ios-gray.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
    background: rgba(245, 115, 208, 0.2);
  }
  .mbsc-ios-gray .mbsc-sc-itm-sel,
  .mbsc-ios-gray .mbsc-sc-whl-gr-3d .mbsc-sc-itm {
    color: #fff;
  }
  .mbsc-ios-gray .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c,
  .mbsc-ios-gray.mbsc-calendar .mbsc-sc-whl-gr-3d .mbsc-sc-whl-c {
    background: #333;
  }
  .mbsc-ios-gray .mbsc-sc-whl-gr-3d .mbsc-sc-itm-3d {
    color: #999;
  }
  .mbsc-ios-gray .mbsc-sc-btn {
    color: #f573d0;
  }
  .mbsc-ios-gray.mbsc-sel-multi .mbsc-sc-itm {
    color: #fff;
  }
  .mbsc-ios-gray .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #f573d0;
  }
  .mbsc-ios-gray.mbsc-fr-pointer .mbsc-sc-whl-l {
    border-color: #878787;
  }
  .mbsc-ios-gray.mbsc-fr-pointer .mbsc-sc-itm {
    color: #fff;
  }
  .mbsc-ios-gray.mbsc-fr-pointer .mbsc-sc-itm-sel {
    color: #f573d0;
  }
  .mbsc-ios-gray .mbsc-cal-picker {
    background: #333;
  }
  .mbsc-ios-gray .mbsc-cal-cell {
    border-top: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-cal-today {
    color: #f573d0;
  }
  .mbsc-ios-gray .mbsc-cal-cell:focus .mbsc-cal-cell-txt,
  .mbsc-ios-gray.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
  .mbsc-ios-gray.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover {
    background: rgba(245, 115, 208, 0.3);
  }
  .mbsc-ios-gray .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
    border-color: #f573d0;
    background: #f573d0;
    color: #000;
  }
  .mbsc-ios-gray .mbsc-cal-tab {
    border: 1px solid #f573d0;
    color: #f573d0;
  }
  .mbsc-ios-gray .mbsc-fr-popup .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
    background: #f573d0;
    color: #000;
  }
  .mbsc-ios-gray .mbsc-cal-mark {
    background: #666;
  }
  .mbsc-ios-gray .mbsc-cal-txt {
    background: #fff;
    color: #000;
  }
  .mbsc-ios-gray.mbsc-fr-inline .mbsc-cal-picker {
    background: #3b3b3b;
  }
  .mbsc-ios-gray .mbsc-fr-btn-cont {
    border-bottom: 1px solid #666;
  }
  .mbsc-ios-gray.mbsc-page {
    background: #4c4c4d;
    color: #fff;
  }
  .mbsc-ios-gray a {
    color: #f573d0;
  }
  .mbsc-ios-gray .mbsc-input {
    background: #424242;
  }
  .mbsc-ios-gray .mbsc-input .mbsc-control::-webkit-input-placeholder {
    color: #ccc;
  }
  .mbsc-ios-gray .mbsc-input .mbsc-control::-ms-input-placeholder {
    color: #ccc;
  }
  .mbsc-ios-gray .mbsc-input .mbsc-control::-moz-placeholder {
    color: #ccc;
  }
  .mbsc-ios-gray .mbsc-input-wrap input:-webkit-autofill ~ .mbsc-input-fill {
    background: #e8f0fe;
  }
  .mbsc-ios-gray .mbsc-input-wrap input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px #e8f0fe inset;
  }
  .mbsc-ios-gray .mbsc-input .mbsc-label,
  .mbsc-ios-gray .mbsc-progress .mbsc-label,
  .mbsc-ios-gray .mbsc-input input,
  .mbsc-ios-gray .mbsc-input textarea {
    color: #fff;
  }
  .mbsc-ios-gray .mbsc-err-msg {
    color: #d8332a;
  }
  .mbsc-ios-gray .mbsc-control-w:before,
  .mbsc-ios-gray .mbsc-control-w:after {
    border-top: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-control-w.mbsc-err:after,
  .mbsc-ios-gray .mbsc-control-w.mbsc-err + .mbsc-err:before,
  .mbsc-ios-gray .mbsc-control-w.mbsc-err + .mbsc-divider {
    border-top-color: #d8332a;
  }
  .mbsc-ios-gray .mbsc-input-box.mbsc-err,
  .mbsc-ios-gray .mbsc-input-outline.mbsc-err {
    border-color: #d8332a;
  }
  .mbsc-ios-gray .mbsc-input-box .mbsc-err-msg,
  .mbsc-ios-gray .mbsc-input-outline .mbsc-err-msg {
    color: #d8332a;
  }
  .mbsc-ios-gray .mbsc-input-outline.mbsc-control-w {
    border: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-input-outline.mbsc-err.mbsc-control-w {
    border-color: #d8332a;
  }
  .mbsc-ios-gray.mbsc-progress {
    background: #424242;
  }
  .mbsc-ios-gray .mbsc-progress-track {
    background: #787878;
  }
  .mbsc-ios-gray .mbsc-progress-bar {
    background: #f573d0;
  }
  .mbsc-ios-gray .mbsc-progress-value,
  .mbsc-ios-gray .mbsc-progress-step-label {
    color: #e6e5e5;
  }
  .mbsc-ios-gray .mbsc-slider-handle {
    background: #fff;
  }
  .mbsc-ios-gray .mbsc-slider-tooltip {
    color: #e6e5e5;
    background: #424242;
  }
  .mbsc-ios-gray .mbsc-slider-step {
    background: #787878;
  }
  .mbsc-ios-gray.mbsc-rating .mbsc-progress-track {
    color: #f573d0;
  }
  .mbsc-ios-gray.mbsc-alert .mbsc-input {
    border: 1px solid #ccc;
  }
  .mbsc-ios-gray.mbsc-snackbar .mbsc-fr-w {
    background: #646464;
    color: #fff;
  }
  .mbsc-ios-gray.mbsc-snackbar .mbsc-snackbar-btn {
    color: #f573d0;
  }
  .mbsc-ios-gray.mbsc-toast .mbsc-toast-msg {
    background: #646464;
    color: #fff;
  }
  .mbsc-ios-gray.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-ios-gray.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-ios-gray.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-ios-gray.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-ios-gray.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-ios-gray.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-ios-gray.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-ios-gray.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-ios-gray.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-ios-gray.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-ios-gray.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-ios-gray.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-ios-gray.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-ios-gray.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-ios-gray.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-ios-gray.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-ios-gray.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
  .mbsc-ios-gray.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #eee;
  }
  .mbsc-ios-gray.mbsc-form {
    background: #4c4c4d;
    color: #fff;
  }
  .mbsc-ios-gray.mbsc-form *::-moz-selection,
  .mbsc-ios-gray.mbsc-form *::selection {
    color: #ccc;
    background: #ff78d9;
  }
  .mbsc-ios-gray .mbsc-input-ic {
    color: #fff;
  }
  .mbsc-ios-gray .mbsc-divider,
  .mbsc-ios-gray .mbsc-form-group-title {
    border-bottom: 1px solid #666;
    color: #8f8f8f;
  }
  .mbsc-ios-gray .mbsc-divider {
    border-top: 1px solid #666;
  }
  .mbsc-ios-gray.mbsc-form .mbsc-checkbox,
  .mbsc-ios-gray.mbsc-form .mbsc-radio {
    background: #424242;
  }
  .mbsc-ios-gray .mbsc-checkbox-box {
    border: 0.125em solid #ff78d9;
  }
  .mbsc-ios-gray .mbsc-checkbox-box:after {
    border: 0.125em solid #ff78d9;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-ios-gray .mbsc-radio-box:after {
    border: 0.125em solid #ff78d9;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-ios-gray .mbsc-radio input:disabled ~ .mbsc-radio-label {
    color: #6e6e6e;
  }
  .mbsc-ios-gray .mbsc-btn {
    background: #6e6e6e;
    color: #f573d0;
  }
  .mbsc-ios-gray .mbsc-btn:not(.mbsc-btn-flat):disabled {
    background: #545454;
    color: #6e6e6e;
  }
  .mbsc-ios-gray .mbsc-btn.mbsc-btn-flat {
    background: transparent;
    border-color: transparent;
  }
  .mbsc-ios-gray .mbsc-btn-flat:disabled {
    background: transparent;
    color: #6e6e6e;
  }
  .mbsc-ios-gray .mbsc-btn-primary.mbsc-btn,
  .mbsc-ios-gray .mbsc-btn-secondary.mbsc-btn,
  .mbsc-ios-gray .mbsc-btn-success.mbsc-btn,
  .mbsc-ios-gray .mbsc-btn-danger.mbsc-btn,
  .mbsc-ios-gray .mbsc-btn-warning.mbsc-btn,
  .mbsc-ios-gray .mbsc-btn-info.mbsc-btn,
  .mbsc-ios-gray .mbsc-btn-dark.mbsc-btn {
    color: #eee;
  }
  .mbsc-ios-gray .mbsc-btn-light.mbsc-btn {
    color: #000;
  }
  .mbsc-ios-gray .mbsc-btn-flat.mbsc-btn {
    background: transparent;
  }
  .mbsc-ios-gray .mbsc-btn-outline.mbsc-btn {
    background: transparent;
    border: 1px solid #ff78d9;
    color: #ff78d9;
  }
  .mbsc-ios-gray .mbsc-btn-outline.mbsc-btn.mbsc-active {
    background: #ff78d9;
    color: #4c4c4d;
  }
  .mbsc-ios-gray .mbsc-btn.mbsc-btn-outline:disabled {
    color: #6e6e6e;
    border-color: #6e6e6e;
    background: transparent;
  }
  .mbsc-ios-gray.mbsc-form .mbsc-switch {
    background: #424242;
  }
  .mbsc-ios-gray .mbsc-switch-track {
    background: #fff;
    border: 0.0625em solid #5e5e5e;
  }
  .mbsc-ios-gray .mbsc-switch-track:after {
    background: #424242;
  }
  .mbsc-ios-gray .mbsc-switch-handle {
    background: #fff;
  }
  .mbsc-ios-gray .mbsc-switch input:checked + .mbsc-switch-track {
    background: #f573d0;
    border-color: #f573d0;
  }
  .mbsc-ios-gray .mbsc-segmented {
    background-color: #424242;
  }
  .mbsc-ios-gray .mbsc-segmented-content {
    color: #ff78d9;
    border: 0.07142857em solid #ff78d9;
    background: #424242;
  }
  .mbsc-ios-gray .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
  .mbsc-ios-gray .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #ff78d9;
    color: #424242;
  }
  .mbsc-ios-gray .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(245, 115, 208, 0.3);
    color: #ff78d9;
  }
  .mbsc-ios-gray .mbsc-stepper-cont {
    background: #424242;
  }
  .mbsc-ios-gray .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-ios-gray .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-ios-gray .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    color: #6e6e6e;
    border-color: #6e6e6e;
  }
  .mbsc-ios-gray .mbsc-stepper input:disabled {
    color: #6e6e6e;
    -webkit-text-fill-color: #6e6e6e;
  }
  .mbsc-ios-gray .mbsc-segmented input:disabled:checked + .mbsc-segmented-content {
    background: #545454;
  }
  .mbsc-ios-gray .mbsc-stepper input {
    color: #fff;
  }
  .mbsc-ios-gray.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(245, 115, 208, 0.15);
  }
  .mbsc-ios-gray.mbsc-card.mbsc-form {
    background: #3b3b3b;
    box-shadow: 0 0 1px 1px rgba(8, 8, 8, 0.2);
  }
  .mbsc-ios-gray .mbsc-card-footer {
    border-color: #666;
  }
  .mbsc-ios-gray.mbsc-card .mbsc-input-box.mbsc-control-w,
  .mbsc-ios-gray.mbsc-card .mbsc-input-box.mbsc-control-w {
    border: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-color-active,
  .mbsc-ios-gray.mbsc-no-touch .mbsc-color-item-c:hover {
    box-shadow: inset 0 0 0 0.1875em #f573d0;
  }
  .mbsc-ios-gray .mbsc-lv {
    background: #666;
  }
  .mbsc-ios-gray .mbsc-lv-item {
    background: #424242;
    border-color: #666;
    color: #fff;
  }
  .mbsc-ios-gray .mbsc-lv-item:before,
  .mbsc-ios-gray .mbsc-lv-item:after {
    border-top: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-lv-gr-title {
    background: #4c4c4d;
    color: #8f8f8f;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
  }
  .mbsc-ios-gray.mbsc-no-touch .mbsc-lv-item-actionable:hover:before {
    background: rgba(255, 255, 255, 0.05);
  }
  .mbsc-ios-gray .mbsc-lv-item-actionable.mbsc-lv-item-active:before {
    background: rgba(0, 0, 0, 0.15);
  }
  .mbsc-ios-gray .mbsc-empty {
    color: #fff;
  }
  .mbsc-ios-gray.mbsc-ev-cal .mbsc-fr-w {
    background: #333;
  }
  .mbsc-ios-gray.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-picker {
    background: #333;
  }
  .mbsc-ios-gray.mbsc-ev-cal.mbsc-fr-inline .mbsc-cal-scroll-c {
    background: #3b3b3b;
  }
  .mbsc-ios-gray.mbsc-cal-events-popup .mbsc-fr-w {
    border: 1px solid #878787;
  }
  .mbsc-ios-gray.mbsc-cal-events-popup .mbsc-fr-arr {
    background: #424242;
    border: 1px solid #878787;
  }
  .mbsc-ios-gray .mbsc-cal-events-i {
    background: #5e5e5e;
  }
  .mbsc-ios-gray .mbsc-cal-event-color {
    background: #94b8dd;
  }
  .mbsc-ios-gray .mbsc-cal-event-dur,
  .mbsc-ios-gray .mbsc-cal-event-time {
    color: #8e8e8e;
  }
  .mbsc-ios-gray .mbsc-event-list {
    background: #4c4c4d;
  }
  .mbsc-ios-gray.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    border-left: 1px solid #666;
  }
  .mbsc-ios-gray.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #545454;
  }
  .mbsc-ios-gray .mbsc-lv-item-hl,
  .mbsc-ios-gray .mbsc-lv .mbsc-lv-item.mbsc-lv-item-active {
    background: #525252;
  }
  .mbsc-ios-gray .mbsc-lv-handle-bar {
    background: #a1a1a1;
  }
  .mbsc-ios-gray .mbsc-lv-arr {
    color: #a1a1a1;
  }
  .mbsc-ios-gray.mbsc-lv-has-fixed-header .mbsc-lv-gr-title,
  .mbsc-ios-gray.mbsc-lv-fixed-header .mbsc-lv-gr-title {
    color: #fff;
  }
  .mbsc-ios-gray .mbsc-lv-ic-m {
    color: #fff;
  }
  .mbsc-ios-gray .mbsc-lv-loading .mbsc-ic {
    color: #fff;
  }
  .mbsc-ios-gray.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(245, 115, 208, 0.3);
  }
  .mbsc-ios-gray.mbsc-ms-c {
    background: #333;
    border-top: 1px solid #666;
    border-bottom: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-ms .mbsc-ms-item {
    color: #ababab;
  }
  .mbsc-ios-gray.mbsc-ms-nosel .mbsc-ms-item,
  .mbsc-ios-gray .mbsc-ms .mbsc-ms-item-sel {
    color: #f573d0;
  }
  .mbsc-ios-gray.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: rgba(245, 115, 208, 0.15);
  }
  .mbsc-ios-gray.mbsc-ms-more .mbsc-ms-item:after {
    border-top: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-np-btn {
    border-top: 1px solid #666;
    border-left: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-np-btn.mbsc-active,
  .mbsc-ios-gray .mbsc-np-btn-empty {
    background: #333;
  }
  .mbsc-ios-gray .mbsc-np-btn.mbsc-disabled {
    color: #545454;
  }
  .mbsc-ios-gray .mbsc-range-btn-end {
    border-bottom: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-range-btn-end .mbsc-range-btn:before {
    border-top: 1px solid #666;
  }
  .mbsc-ios-gray .mbsc-range-btn.mbsc-selected .mbsc-range-btn-v {
    color: #f573d0;
  }
  .mbsc-ios-gray.mbsc-range .mbsc-cal .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date,
  .mbsc-ios-gray.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    background: #f573d0;
  }
  .mbsc-ios-gray .mbsc-sc-whl .mbsc-sel-gr {
    color: #9d9d9d;
  }
  .mbsc-ios-gray .mbsc-sel-filter-cont.mbsc-input {
    background: #6e6e6e;
  }
  .mbsc-ios-gray.mbsc-fr-center .mbsc-sel-filter-cont {
    background: #525252;
  }
  .mbsc-ios-gray .mbsc-sel-filter-cont.mbsc-input input {
    background: #424242;
  }
  .mbsc-ios-gray .mbsc-sel-filter-clear {
    color: #8c8c8c;
  }
  .mbsc-ios-gray.mbsc-fr-pointer .mbsc-sc-whl .mbsc-sel-gr {
    color: #fff;
  }
  .mbsc-ios-gray.mbsc-fr-pointer.mbsc-sel .mbsc-ltr .mbsc-sc-whl-w + .mbsc-sc-whl-w {
    border-left: 1px solid #878787;
  }
  .mbsc-ios-gray.mbsc-fr-pointer.mbsc-sel .mbsc-rtl .mbsc-sc-whl-w + .mbsc-sc-whl-w {
    border-right: 1px solid #878787;
  }
  .mbsc-ios-gray.mbsc-timer .mbsc-sc-lbl {
    color: #f573d0;
  }
  .mbsc-ios-gray.mbsc-ts .mbsc-sc-lbl {
    color: #f573d0;
  }
  .mbsc-material-indigo .mbsc-fr-overlay {
    background: rgba(0, 0, 0, 0.6);
  }
  .mbsc-material-indigo .mbsc-fr-w {
    background: #303f9f;
    color: #fff;
    box-shadow: 0 0.25em 1.5em rgba(0, 0, 0, 0.3);
  }
  .mbsc-material-indigo .mbsc-fr-hdr {
    color: #ff4080;
  }
  .mbsc-material-indigo .mbsc-fr-btn {
    color: #ff4080;
  }
  .mbsc-material-indigo.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
  .mbsc-material-indigo .mbsc-fr-btn.mbsc-active {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-material-indigo .mbsc-fr-arr {
    box-shadow: 0 0 1.5em rgba(0, 0, 0, 0.2);
    background: #303f9f;
  }
  .mbsc-material-indigo .mbsc-sc-lbl {
    color: #ff4080;
  }
  .mbsc-material-indigo.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-material-indigo .mbsc-sc-itm:focus {
    background: rgba(0, 0, 0, 0.05);
  }
  .mbsc-material-indigo.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material-indigo .mbsc-sc-whl-l {
    border-top: 2px solid #ff4080;
    border-bottom: 2px solid #ff4080;
  }
  .mbsc-material-indigo .mbsc-sc-btn {
    color: #ff4080;
    background: #303f9f;
  }
  .mbsc-material-indigo.mbsc-no-touch .mbsc-sc-btn:hover,
  .mbsc-material-indigo .mbsc-sc-btn.mbsc-active {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material-indigo .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    color: #ff4080;
  }
  .mbsc-material-indigo .mbsc-cal-picker {
    background: #303f9f;
  }
  .mbsc-material-indigo .mbsc-cal-today,
  .mbsc-material-indigo .mbsc-cal-tab.mbsc-selected {
    color: #ff4080;
  }
  .mbsc-material-indigo.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
  .mbsc-material-indigo.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
  .mbsc-material-indigo .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-material-indigo .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
    background: rgba(255, 64, 128, 0.3);
  }
  .mbsc-material-indigo .mbsc-cal-c .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
    border-color: #ff4080;
  }
  .mbsc-material-indigo .mbsc-cal-mark {
    background: #ff4080;
  }
  .mbsc-material-indigo .mbsc-cal-txt {
    color: #303f9f;
    background: #fff;
  }
  .mbsc-material-indigo .mbsc-cal-icons {
    color: #fff;
  }
  .mbsc-material-indigo .mbsc-ripple {
    background: #fff;
  }
  .mbsc-material-indigo .mbsc-empty {
    color: #fff;
  }
  .mbsc-material-indigo.mbsc-page {
    background-color: #303f9f;
    color: #fff;
  }
  .mbsc-material-indigo a {
    color: #ff4080;
  }
  .mbsc-material-indigo .mbsc-input input,
  .mbsc-material-indigo .mbsc-input textarea {
    border-bottom: 1px solid #fff;
    color: #c2c2c2;
  }
  .mbsc-material-indigo .mbsc-input input:focus,
  .mbsc-material-indigo .mbsc-input textarea:focus,
  .mbsc-material-indigo .mbsc-input select:focus ~ input {
    border-bottom: 2px solid #ff4080;
  }
  .mbsc-material-indigo .mbsc-input.mbsc-err input,
  .mbsc-material-indigo .mbsc-input.mbsc-err textarea {
    border-bottom: 1px solid #de3226;
  }
  .mbsc-material-indigo .mbsc-select .mbsc-input-wrap:after {
    border: 5px solid #fff;
    border-color: #fff transparent transparent transparent;
  }
  .mbsc-material-indigo .mbsc-input-box.mbsc-input .mbsc-input-wrap {
    background: #384aba;
  }
  .mbsc-material-indigo .mbsc-input-outline.mbsc-input input,
  .mbsc-material-indigo .mbsc-input-outline.mbsc-input textarea {
    border: 1px solid #fff;
  }
  .mbsc-material-indigo .mbsc-input-outline.mbsc-input .mbsc-label {
    background: #303f9f;
  }
  .mbsc-material-indigo .mbsc-input-outline.mbsc-input input:focus,
  .mbsc-material-indigo .mbsc-input-outline.mbsc-input select:focus ~ input {
    border: 2px solid #ff4080;
  }
  .mbsc-material-indigo .mbsc-input-outline.mbsc-err input {
    border: 1px solid #de3226;
  }
  .mbsc-material-indigo .mbsc-input-outline.mbsc-err input:focus {
    border: 2px solid #de3226;
  }
  .mbsc-material-indigo .mbsc-input-outline.mbsc-input textarea:focus {
    border: 2px solid #ff4080;
  }
  .mbsc-material-indigo .mbsc-input-outline.mbsc-err textarea {
    border: 1px solid #de3226;
  }
  .mbsc-material-indigo .mbsc-input-outline.mbsc-err textarea:focus {
    border: 2px solid #de3226;
  }
  .mbsc-material-indigo .mbsc-progress-track {
    background: #5969cd;
  }
  .mbsc-material-indigo .mbsc-progress-bar {
    background: #ff4080;
  }
  .mbsc-material-indigo .mbsc-slider-step {
    background: #fff;
  }
  .mbsc-material-indigo .mbsc-slider-handle {
    border: 2px solid #ff4080;
    background: #ff4080;
  }
  .mbsc-material-indigo .mbsc-slider-start .mbsc-slider-handle {
    border-color: #5969cd;
    background: #303f9f;
  }
  .mbsc-material-indigo .mbsc-slider-tooltip {
    color: #303f9f;
    background-color: #ff4080;
  }
  .mbsc-material-indigo .mbsc-slider-tooltip:before {
    border-top: 1em solid #ff4080;
  }
  .mbsc-material-indigo.mbsc-slider-has-tooltip .mbsc-slider-start .mbsc-slider-tooltip,
  .mbsc-material-indigo.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-progress-bar {
    background: #5969cd;
  }
  .mbsc-material-indigo.mbsc-slider-has-tooltip .mbsc-slider-start .mbsc-slider-tooltip:before {
    border-top-color: #5969cd;
  }
  .mbsc-material-indigo.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-handle {
    background: #5969cd;
    border-color: #5969cd;
    box-shadow: 0 0 0 0.3125em #303f9f;
  }
  .mbsc-material-indigo.mbsc-slider input:disabled ~ .mbsc-progress-cont .mbsc-slider-start .mbsc-slider-handle {
    border-color: #5969cd;
    background: #303f9f;
  }
  .mbsc-material-indigo.mbsc-rating .mbsc-progress-track {
    color: #ff4080;
  }
  .mbsc-material-indigo.mbsc-snackbar .mbsc-fr-w {
    background: #5b5b5b;
    color: #fff;
  }
  .mbsc-material-indigo.mbsc-snackbar .mbsc-snackbar-btn {
    color: #ff4080;
  }
  .mbsc-material-indigo.mbsc-toast .mbsc-toast-msg {
    background: #505050;
    color: #fff;
  }
  .mbsc-material-indigo.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-material-indigo.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-material-indigo.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-material-indigo.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-material-indigo.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-material-indigo.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-material-indigo.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-material-indigo.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-material-indigo.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-material-indigo.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-material-indigo.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-material-indigo.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-material-indigo.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-material-indigo.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-material-indigo.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-material-indigo.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-material-indigo.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
  .mbsc-material-indigo.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #eee;
  }
  .mbsc-material-indigo.mbsc-form {
    background-color: #303f9f;
    color: #fff;
  }
  .mbsc-material-indigo.mbsc-form *::-moz-selection,
  .mbsc-material-indigo.mbsc-form *::selection {
    color: #fff;
    background: #ff4080;
  }
  .mbsc-material-indigo .mbsc-divider,
  .mbsc-material-indigo .mbsc-form-group-title {
    color: #ff4080;
  }
  .mbsc-material-indigo .mbsc-err-msg,
  .mbsc-material-indigo .mbsc-err .mbsc-label {
    color: #de3226;
  }
  .mbsc-material-indigo .mbsc-checkbox-box,
  .mbsc-material-indigo .mbsc-radio-box {
    border: 0.125em solid #fff;
  }
  .mbsc-material-indigo .mbsc-checkbox-box:before,
  .mbsc-material-indigo .mbsc-radio-box:before {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material-indigo .mbsc-checkbox-box:after {
    border: 0.125em solid #303f9f;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-material-indigo .mbsc-checkbox input:checked + .mbsc-checkbox-box {
    background: #ff4080;
    border-color: #ff4080;
  }
  .mbsc-material-indigo .mbsc-radio-box:after {
    background: #ff4080;
  }
  .mbsc-material-indigo .mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #ff4080;
  }
  .mbsc-material-indigo .mbsc-btn {
    background: #5969cd;
    color: #fff;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.25);
  }
  .mbsc-material-indigo.mbsc-no-touch .mbsc-btn:not(:disabled):hover,
  .mbsc-material-indigo .mbsc-btn.mbsc-active {
    background: #212b6c;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.4);
  }
  .mbsc-material-indigo.mbsc-no-touch .mbsc-btn-flat:not(:disabled):hover,
  .mbsc-material-indigo .mbsc-btn-flat.mbsc-active {
    background-color: #5969cd;
  }
  .mbsc-material-indigo .mbsc-btn:disabled {
    color: #cfcfcf;
  }
  .mbsc-material-indigo .mbsc-btn.mbsc-btn-outline:disabled {
    color: #cfcfcf;
    border-color: #cfcfcf;
  }
  .mbsc-material-indigo .mbsc-btn-light.mbsc-btn {
    color: #000;
  }
  .mbsc-material-indigo .mbsc-btn-dark.mbsc-btn {
    color: #eee;
  }
  .mbsc-material-indigo .mbsc-btn-flat.mbsc-btn {
    background: transparent;
    box-shadow: none;
  }
  .mbsc-material-indigo.mbsc-no-touch .mbsc-btn-flat:not(:disabled):hover,
  .mbsc-material-indigo .mbsc-btn-flat.mbsc-active {
    box-shadow: none;
  }
  .mbsc-material-indigo .mbsc-btn-outline.mbsc-btn {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    box-shadow: none;
  }
  .mbsc-material-indigo .mbsc-btn-outline.mbsc-btn.mbsc-active.mbsc-control {
    background: #fff;
    color: #303f9f;
    opacity: 1;
  }
  .mbsc-material-indigo .mbsc-switch-track {
    background: #5969cd;
  }
  .mbsc-material-indigo .mbsc-switch .mbsc-switch-handle {
    background: #e6e8f7;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .mbsc-material-indigo .mbsc-switch input:checked + .mbsc-switch-track {
    background-color: rgba(255, 64, 128, 0.3);
  }
  .mbsc-material-indigo .mbsc-switch input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #ff4080;
  }
  .mbsc-material-indigo .mbsc-switch input:disabled + .mbsc-switch-track {
    background: #5969cd;
  }
  .mbsc-material-indigo .mbsc-switch input:disabled + .mbsc-switch-track .mbsc-switch-handle {
    background: #5565cc;
  }
  .mbsc-material-indigo .mbsc-segmented-content {
    border: 0.142858em solid #ff4080;
    color: #fff;
  }
  .mbsc-material-indigo .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content,
  .mbsc-material-indigo .mbsc-segmented .mbsc-segmented-item input:checked ~ .mbsc-segmented-content {
    background: #ff4080;
    color: #303f9f;
  }
  .mbsc-material-indigo .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content,
  .mbsc-material-indigo .mbsc-segmented .mbsc-segmented-item .mbsc-active .mbsc-segmented-content {
    background: #212b6c;
    color: #fff;
  }
  .mbsc-material-indigo .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    color: #cfcfcf;
    border-color: #5969cd;
    background: transparent;
  }
  .mbsc-material-indigo .mbsc-segmented .mbsc-segmented-item input:disabled:checked + .mbsc-segmented-content {
    background: #5969cd;
    color: #cfcfcf;
    border-color: #5969cd;
  }
  .mbsc-material-indigo .mbsc-stepper .mbsc-segmented-content {
    border-color: #5969cd;
  }
  .mbsc-material-indigo .mbsc-stepper-control .mbsc-segmented-content {
    background: #5969cd;
  }
  .mbsc-material-indigo .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-material-indigo .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-material-indigo .mbsc-segmented .mbsc-segmented-item.mbsc-disabled .mbsc-segmented-content {
    background: #5969cd;
    color: #cfcfcf;
  }
  .mbsc-material-indigo .mbsc-segmented .mbsc-segmented-item input:disabled ~ .mbsc-segmented-item .mbsc-stepper-val {
    background: #303f9f;
  }
  .mbsc-material-indigo .mbsc-stepper input {
    color: #fff;
  }
  .mbsc-material-indigo .mbsc-stepper input:disabled {
    color: #cfcfcf;
    -webkit-text-fill-color: #cfcfcf;
  }
  .mbsc-material-indigo.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: #212b6c;
    color: #fff;
  }
  .mbsc-material-indigo .mbsc-stepper-primary.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303f9f;
  }
  .mbsc-material-indigo .mbsc-stepper-primary.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-indigo .mbsc-stepper-secondary.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303f9f;
  }
  .mbsc-material-indigo .mbsc-stepper-secondary.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-indigo .mbsc-stepper-success.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303f9f;
  }
  .mbsc-material-indigo .mbsc-stepper-success.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-indigo .mbsc-stepper-danger.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303f9f;
  }
  .mbsc-material-indigo .mbsc-stepper-danger.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-indigo .mbsc-stepper-warning.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303f9f;
  }
  .mbsc-material-indigo .mbsc-stepper-warning.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-indigo .mbsc-stepper-info.mbsc-stepper-cont .mbsc-stepper .mbsc-segmented-content {
    color: #303f9f;
  }
  .mbsc-material-indigo .mbsc-stepper-info.mbsc-stepper-cont .mbsc-stepper .mbsc-stepper-val {
    background: transparent;
  }
  .mbsc-material-indigo.mbsc-card.mbsc-form {
    background: #3646b2;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  }
  .mbsc-material-indigo.mbsc-card .mbsc-lv-item,
  .mbsc-material-indigo .mbsc-card-list .mbsc-card {
    background: #3646b2;
  }
  .mbsc-material-indigo .mbsc-card-footer {
    border-color: #999;
  }
  .mbsc-material-indigo.mbsc-card .mbsc-input.mbsc-input-outline .mbsc-label {
    background: #3646b2;
  }
  .mbsc-material-indigo .mbsc-color-item:before {
    color: #303f9f;
  }
  .mbsc-material-indigo.mbsc-form .mbsc-color-input {
    background-color: transparent;
    border-bottom: 1px solid #fff;
    color: #c2c2c2;
  }
  .mbsc-material-indigo .mbsc-lv {
    background: #5969cd;
  }
  .mbsc-material-indigo .mbsc-lv-item {
    background: #303f9f;
    color: #fff;
  }
  .mbsc-material-indigo .mbsc-lv-gr-title {
    background: #303f9f;
    color: #ff4080;
  }
  .mbsc-material-indigo.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
  .mbsc-material-indigo .mbsc-lv-item-actionable.mbsc-lv-item-active:before {
    background: rgba(255, 255, 255, 0.05);
  }
  .mbsc-material-indigo .mbsc-cal-events .mbsc-cal-event {
    color: #fff;
  }
  .mbsc-material-indigo .mbsc-cal-event-color {
    background: #ff4080;
  }
  .mbsc-material-indigo .mbsc-event-list .mbsc-lv-item {
    color: #3646b2;
  }
  .mbsc-material-indigo .mbsc-event-color {
    background: #ff4080;
  }
  .mbsc-material-indigo.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-table {
    border-top: 1px solid #5969cd;
  }
  .mbsc-material-indigo.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    border-left: 1px solid #5969cd;
    border-bottom: 1px solid #5969cd;
  }
  .mbsc-material-indigo .mbsc-lv-item-hl:before,
  .mbsc-material-indigo .mbsc-lv-item-dragging:before {
    background: rgba(255, 255, 255, 0.05);
  }
  .mbsc-material-indigo.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #3444ab;
  }
  .mbsc-material-indigo .mbsc-lv-ic-m {
    color: #fff;
  }
  .mbsc-material-indigo .mbsc-lv-arr {
    color: #ff4080;
  }
  .mbsc-material-indigo .mbsc-lv-handle-bar {
    background: #ff4080;
  }
  .mbsc-material-indigo p.mbsc-lv-txt {
    color: #fff;
  }
  .mbsc-material-indigo .mbsc-lv-loading .mbsc-ic {
    color: #fff;
  }
  .mbsc-material-indigo.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-material-indigo.mbsc-ms-c {
    background: #303f9f;
  }
  .mbsc-material-indigo .mbsc-ms .mbsc-ms-item {
    color: #fff;
  }
  .mbsc-material-indigo .mbsc-ms .mbsc-ms-item.mbsc-ms-item-sel {
    color: #ff4080;
  }
  .mbsc-material-indigo .mbsc-ms .mbsc-ms-item.mbsc-active {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-material-indigo.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-material-indigo.mbsc-ms-top {
    border-bottom: 1px solid #999;
  }
  .mbsc-material-indigo.mbsc-ms-bottom {
    border-top: 1px solid #999;
  }
  .mbsc-material-indigo.mbsc-ms-a .mbsc-ms-item.mbsc-ms-item-sel {
    color: #ff4080;
  }
  .mbsc-material-indigo.mbsc-ms-b .mbsc-ms-item.mbsc-ms-item-sel {
    border-bottom-color: #ff4080;
  }
  .mbsc-material-indigo.mbsc-ms-b.mbsc-ms-bottom .mbsc-ms-item.mbsc-ms-item-sel {
    border-top-color: #ff4080;
    border-bottom-color: transparent;
  }
  .mbsc-material-indigo .mbsc-np-del {
    color: #ff4080;
  }
  .mbsc-material-indigo .mbsc-range-btn-t {
    border-bottom: 1px solid #999;
  }
  .mbsc-material-indigo .mbsc-range-btn.mbsc-selected {
    border-color: #ff4080;
    color: #ff4080;
  }
  .mbsc-material-indigo.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
    background: none;
    border-color: transparent;
  }
  .mbsc-material-indigo.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
    background: #ff4080;
    border-color: #ff4080;
    color: #303f9f;
  }
  .mbsc-material-indigo.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    background: rgba(255, 64, 128, 0.1);
  }
  .mbsc-material-indigo .mbsc-sel-filter-cont {
    border-bottom: 1px solid #5969cd;
  }
  .mbsc-material-indigo.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-itm-sel {
    color: #ff4080;
  }
  .mbsc-material-indigo.mbsc-fr-pointer .mbsc-sel-one.mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-material-indigo.mbsc-fr-pointer .mbsc-sel-one.mbsc-no-touch.mbsc-sc-whl-multi .mbsc-sc-itm-sel:hover {
    background: rgba(0, 0, 0, 0.1);
  }
  .mbsc-mobiscroll-lime .mbsc-fr-w {
    background: #1a1a1a;
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-lime .mbsc-fr-hdr,
  .mbsc-mobiscroll-lime .mbsc-fr-btn {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-fr-btn.mbsc-active,
  .mbsc-mobiscroll-lime.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover {
    background: rgba(239, 255, 64, 0.3);
  }
  .mbsc-mobiscroll-lime .mbsc-fr-arr {
    background: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-sc-lbl {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-sc-whl-l {
    border-top: 1px solid #efff40;
    border-bottom: 1px solid #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-sc-btn {
    color: #efff40;
    background: #1a1a1a;
  }
  .mbsc-mobiscroll-lime.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-mobiscroll-lime .mbsc-sc-itm:focus {
    background: rgba(209, 209, 209, 0.1);
  }
  .mbsc-mobiscroll-lime.mbsc-no-touch .mbsc-sc-btn:hover:before,
  .mbsc-mobiscroll-lime.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active,
  .mbsc-mobiscroll-lime .mbsc-sc-btn.mbsc-active:before {
    background: rgba(239, 255, 64, 0.3);
  }
  .mbsc-mobiscroll-lime .mbsc-sc-whl-multi .mbsc-sc-itm-sel:before {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-cal-picker {
    background: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-cal-days > div {
    border-bottom: 1px solid #efff40;
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-cal-today {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-cell:not(.mbsc-disabled):hover .mbsc-cal-cell-txt,
  .mbsc-mobiscroll-lime.mbsc-no-touch .mbsc-cal-day:not(.mbsc-disabled) .mbsc-cal-day-date:hover,
  .mbsc-mobiscroll-lime .mbsc-cal-cell:focus .mbsc-cal-cell-txt {
    background: rgba(239, 255, 64, 0.3);
  }
  .mbsc-mobiscroll-lime .mbsc-cal-c .mbsc-cal .mbsc-cal-body .mbsc-cal-row .mbsc-selected .mbsc-cal-cell-txt {
    background: #efff40;
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-cal-day-colored.mbsc-selected .mbsc-cal-day-date {
    border-color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-cal-week-nr {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-cal-tab {
    border: 1px solid #efff40;
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-lime .mbsc-fr-w .mbsc-cal-tabs-c .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected {
    background: #efff40;
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-cal-mark {
    background: #d1d1d1;
  }
  .mbsc-mobiscroll-lime .mbsc-cal-txt {
    color: #1a1a1a;
    background: #d1d1d1;
  }
  .mbsc-mobiscroll-lime .mbsc-cal-icons {
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-lime .mbsc-empty {
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-lime.mbsc-page {
    background: #1a1a1a;
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-lime a {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-input input,
  .mbsc-mobiscroll-lime .mbsc-input textarea {
    border-bottom: 1px solid #fff;
    color: #ababab;
  }
  .mbsc-mobiscroll-lime .mbsc-input input:focus,
  .mbsc-mobiscroll-lime .mbsc-input select:focus ~ input .mbsc-input textarea:focus,
  .mbsc-mobiscroll-lime .mbsc-input textarea:focus {
    border-color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-input-ic {
    color: #fff;
  }
  .mbsc-mobiscroll-lime .mbsc-input-box.mbsc-input .mbsc-input-wrap {
    background: #343333;
  }
  .mbsc-mobiscroll-lime .mbsc-input-outline.mbsc-input input,
  .mbsc-mobiscroll-lime .mbsc-input-outline.mbsc-input textarea {
    border: 1px solid #fff;
  }
  .mbsc-mobiscroll-lime .mbsc-input-outline.mbsc-input .mbsc-label {
    background: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-input-outline.mbsc-input input:focus,
  .mbsc-mobiscroll-lime .mbsc-input-outline.mbsc-input select:focus ~ input,
  .mbsc-mobiscroll-lime .mbsc-input-outline.mbsc-err input {
    border-color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-input-outline.mbsc-input textarea:focus {
    border-color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-input.mbsc-err input,
  .mbsc-mobiscroll-lime .mbsc-input.mbsc-err textarea {
    border-color: #de3226;
  }
  .mbsc-mobiscroll-lime.mbsc-progress .mbsc-input-ic {
    color: #fff;
  }
  .mbsc-mobiscroll-lime .mbsc-progress-track {
    background: #2e2e2e;
  }
  .mbsc-mobiscroll-lime .mbsc-progress-bar {
    background: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-slider-step {
    background: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-slider-handle {
    background: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-slider-tooltip {
    color: #1a1a1a;
    background-color: #efff40;
  }
  .mbsc-mobiscroll-lime.mbsc-rating .mbsc-progress-track {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime.mbsc-snackbar .mbsc-fr-w,
  .mbsc-mobiscroll-lime.mbsc-toast .mbsc-toast-msg {
    background: #787878;
    color: #fff;
  }
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-mobiscroll-lime.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-mobiscroll-lime.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-mobiscroll-lime.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-mobiscroll-lime.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-mobiscroll-lime.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-mobiscroll-lime.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn,
  .mbsc-mobiscroll-lime.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime.mbsc-form {
    background: #1a1a1a;
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-lime.mbsc-form *::-moz-selection {
    background: #efff40;
  }
  .mbsc-mobiscroll-lime.mbsc-form *::selection {
    background: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-divider,
  .mbsc-mobiscroll-lime .mbsc-form-group-title {
    background: #1a1a1a;
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-err-msg,
  .mbsc-mobiscroll-lime .mbsc-err .mbsc-label {
    color: #de3226;
  }
  .mbsc-mobiscroll-lime .mbsc-checkbox-box {
    background: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-checkbox-box:after {
    border-bottom: 0.125em solid #1a1a1a;
    border-left: 0.125em solid #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-checkbox input:disabled + .mbsc-checkbox-box {
    background: #454545;
  }
  .mbsc-mobiscroll-lime .mbsc-radio-box {
    border: 0.125em solid #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-radio-box:after {
    background: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-radio input:checked + .mbsc-radio-box {
    background: transparent;
  }
  .mbsc-mobiscroll-lime .mbsc-radio input:disabled + .mbsc-radio-box {
    border-color: #454545;
  }
  .mbsc-mobiscroll-lime .mbsc-radio input:disabled + .mbsc-radio-box:after {
    background: #454545;
  }
  .mbsc-mobiscroll-lime .mbsc-btn {
    background: #efff40;
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-btn:disabled {
    background: #2e2e2e;
  }
  .mbsc-mobiscroll-lime.mbsc-no-touch .mbsc-btn-flat:not(:disabled):not(.mbsc-active):hover {
    background: rgba(26, 26, 26, 0.1);
  }
  .mbsc-mobiscroll-lime .mbsc-btn-flat {
    background: transparent;
    color: #efff40;
    border-color: transparent;
  }
  .mbsc-mobiscroll-lime .mbsc-btn-flat.mbsc-btn.mbsc-active {
    background: rgba(239, 255, 64, 0.3);
  }
  .mbsc-mobiscroll-lime .mbsc-btn-flat:disabled {
    color: #3e3e3e;
    background: transparent;
  }
  .mbsc-mobiscroll-lime .mbsc-btn-light.mbsc-btn {
    color: #000;
  }
  .mbsc-mobiscroll-lime .mbsc-btn-outline.mbsc-btn {
    border: 1px solid #efff40;
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-btn-outline.mbsc-btn.mbsc-active {
    background: #efff40;
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-btn-outline.mbsc-btn.mbsc-btn-primary.mbsc-active,
  .mbsc-mobiscroll-lime .mbsc-btn-outline.mbsc-btn.mbsc-btn-secondary.mbsc-active,
  .mbsc-mobiscroll-lime .mbsc-btn-outline.mbsc-btn.mbsc-btn-success.mbsc-active,
  .mbsc-mobiscroll-lime .mbsc-btn-outline.mbsc-btn.mbsc-btn-danger.mbsc-active,
  .mbsc-mobiscroll-lime .mbsc-btn-outline.mbsc-btn.mbsc-btn-warning.mbsc-active,
  .mbsc-mobiscroll-lime .mbsc-btn-outline.mbsc-btn.mbsc-btn-info.mbsc-active,
  .mbsc-mobiscroll-lime .mbsc-btn-outline.mbsc-btn.mbsc-btn-dark.mbsc-active {
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-btn.mbsc-btn-outline:disabled {
    color: #2e2e2e;
    border-color: #2e2e2e;
    background: transparent;
  }
  .mbsc-mobiscroll-lime .mbsc-switch-track {
    background: #2e2e2e;
  }
  .mbsc-mobiscroll-lime .mbsc-switch-handle {
    background: #3e3e3e;
  }
  .mbsc-mobiscroll-lime .mbsc-switch input:checked + .mbsc-switch-track {
    background: #f8fdc1;
  }
  .mbsc-mobiscroll-lime .mbsc-switch input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-switch input:disabled + .mbsc-switch-track {
    background: #454545;
  }
  .mbsc-mobiscroll-lime .mbsc-switch input:disabled + .mbsc-switch-track .mbsc-switch-handle {
    background: #2c2c2c;
  }
  .mbsc-mobiscroll-lime .mbsc-segmented-content {
    border: 0.142857em solid #efff40;
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-stepper input {
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-lime .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
  .mbsc-mobiscroll-lime .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #efff40;
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: rgba(239, 255, 64, 0.3);
  }
  .mbsc-mobiscroll-lime .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-mobiscroll-lime .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-mobiscroll-lime .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    color: #454545;
    border-color: #454545;
  }
  .mbsc-mobiscroll-lime .mbsc-stepper input:disabled {
    color: #454545;
    -webkit-text-fill-color: #454545;
  }
  .mbsc-mobiscroll-lime .mbsc-segmented input:disabled:checked + .mbsc-segmented-content {
    border-color: #2e2e2e;
    background: #2e2e2e;
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-stepper .mbsc-active.mbsc-disabled .mbsc-segmented-content {
    background: transparent;
    color: #454545;
  }
  .mbsc-mobiscroll-lime.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: rgba(239, 255, 64, 0.1);
  }
  .mbsc-mobiscroll-lime.mbsc-card.mbsc-form {
    background: #242424;
  }
  .mbsc-mobiscroll-lime.mbsc-card .mbsc-lv-item {
    background: #242424;
  }
  .mbsc-mobiscroll-lime .mbsc-card-footer {
    border-color: #454545;
  }
  .mbsc-mobiscroll-lime.mbsc-card .mbsc-input.mbsc-input-outline .mbsc-label {
    background: #242424;
  }
  .mbsc-mobiscroll-lime .mbsc-color-active,
  .mbsc-mobiscroll-lime.mbsc-no-touch .mbsc-color-item-c:hover {
    box-shadow: inset 0 0 0 0.1875em #efff40;
  }
  .mbsc-mobiscroll-lime.mbsc-form .mbsc-color-input {
    border-bottom: 1px solid #fff;
    color: #ababab;
  }
  .mbsc-mobiscroll-lime .mbsc-lv {
    background: #3e3e3e;
  }
  .mbsc-mobiscroll-lime .mbsc-lv-item {
    background: #1a1a1a;
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-lime .mbsc-lv-gr-title {
    background: #efff40;
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
  .mbsc-mobiscroll-lime .mbsc-lv-item-active.mbsc-lv-item-actionable:before {
    background: rgba(239, 255, 64, 0.15);
  }
  .mbsc-mobiscroll-lime.mbsc-cal-events-popup .mbsc-fr-w {
    border: 0.375em solid #fff;
  }
  .mbsc-mobiscroll-lime.mbsc-cal-events-popup .mbsc-fr-arr {
    background: #fff;
  }
  .mbsc-mobiscroll-lime .mbsc-cal-event-color {
    background: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-cal-event-time,
  .mbsc-mobiscroll-lime .mbsc-cal-event-dur {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-event-list .mbsc-lv-gr-title {
    background: #1a1a1a;
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-event-color {
    background: rgba(209, 209, 209, 0.3);
  }
  .mbsc-mobiscroll-lime.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-table {
    border-top: 1px solid #2e2e2e;
  }
  .mbsc-mobiscroll-lime.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    border-left: 1px solid #2e2e2e;
    border-bottom: 1px solid #2e2e2e;
  }
  .mbsc-mobiscroll-lime.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #101010;
  }
  .mbsc-mobiscroll-lime .mbsc-lv-item-hl:before {
    background: rgba(239, 255, 64, 0.15);
  }
  .mbsc-mobiscroll-lime .mbsc-lv-ic-m {
    color: #fff;
  }
  .mbsc-mobiscroll-lime .mbsc-lv-arr {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-lv-handle-bar {
    background: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-lv-item.mbsc-lv-item-dragging {
    background: #efff40;
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-lv-item-dragging .mbsc-lv-handle-bar {
    background: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-lv-item-dragging .mbsc-lv-arr {
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-lv-loading .mbsc-ic {
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-lime.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(239, 255, 64, 0.3);
  }
  .mbsc-mobiscroll-lime.mbsc-ms-c {
    background: #1a1a1a;
  }
  .mbsc-mobiscroll-lime .mbsc-ms .mbsc-ms-item {
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-lime.mbsc-ms-c .mbsc-scv-sc .mbsc-ms .mbsc-ms-item.mbsc-active {
    background: rgba(239, 255, 64, 0.3);
  }
  .mbsc-mobiscroll-lime.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: rgba(239, 255, 64, 0.1);
  }
  .mbsc-mobiscroll-lime.mbsc-ms-b .mbsc-ms-item-sel {
    border-bottom-color: #efff40;
  }
  .mbsc-mobiscroll-lime.mbsc-ms-b.mbsc-ms-top {
    border-bottom: 1px solid #efff40;
  }
  .mbsc-mobiscroll-lime.mbsc-ms-b.mbsc-ms-bottom {
    border-top: 1px solid #efff40;
  }
  .mbsc-mobiscroll-lime.mbsc-ms-b.mbsc-ms-bottom .mbsc-ms-item-sel {
    border-top-color: #efff40;
    border-bottom-color: transparent;
  }
  .mbsc-mobiscroll-lime.mbsc-ms-a.mbsc-ms-top {
    border-bottom: 1px solid #454545;
  }
  .mbsc-mobiscroll-lime.mbsc-ms-a.mbsc-ms-bottom {
    border-top: 1px solid #454545;
  }
  .mbsc-mobiscroll-lime.mbsc-ms-a .mbsc-ms-item-sel {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-np-del {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime .mbsc-range-btn {
    border: 1px solid #efff40;
  }
  .mbsc-mobiscroll-lime.mbsc-range .mbsc-range-btn-t .mbsc-range-btn.mbsc-selected {
    background: #efff40;
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-selected .mbsc-cal-day-date {
    background: none;
    border-color: transparent;
    color: #d1d1d1;
  }
  .mbsc-mobiscroll-lime.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-date {
    background: #efff40;
    border-color: #efff40;
    color: #1a1a1a;
  }
  .mbsc-mobiscroll-lime.mbsc-range .mbsc-cal-day.mbsc-selected:after {
    background: rgba(239, 255, 64, 0.3);
  }
  .mbsc-mobiscroll-lime .mbsc-sel-gr-whl.mbsc-sel-one .mbsc-sc-itm-sel {
    color: #efff40;
  }
  .mbsc-mobiscroll-lime.mbsc-fr-pointer .mbsc-sel-one.mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #d1d1d1;
    background: rgba(239, 255, 64, 0.3);
  }
  .mbsc-windows-yellow .mbsc-fr-w {
    background: #555;
    color: #fff;
    border: 1px solid #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-fr-hdr {
    border-bottom: 2px solid #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-fr-btn-cont {
    border-top: 2px solid #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-fr-btn-w {
    background: #555;
  }
  .mbsc-windows-yellow .mbsc-fr-btn {
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-fr-btn-e:not(.mbsc-disabled):hover,
  .mbsc-windows-yellow .mbsc-fr-btn.mbsc-active {
    background: #6f6e6e;
  }
  .mbsc-windows-yellow .mbsc-fr-arr {
    background: #555;
    border: 1px solid #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-sc-whl-gr-c {
    border-bottom: 2px solid #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-sc-whl-w {
    border-right: 2px solid #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-sc-lbl-v .mbsc-sc-whl-w {
    background: #555;
  }
  .mbsc-windows-yellow .mbsc-sc-lbl-v {
    background: #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-sc-whl-l {
    background: rgba(251, 193, 45, 0.4);
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-sc-itm.mbsc-btn-e:hover,
  .mbsc-windows-yellow .mbsc-sc-itm:focus {
    background: rgba(255, 255, 255, 0.1);
  }
  .mbsc-windows-yellow.mbsc-sc .mbsc-sc-whl .mbsc-sc-itm.mbsc-active {
    background: rgba(255, 255, 255, 0.2);
  }
  .mbsc-windows-yellow .mbsc-sc-btn {
    background: #626262;
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-sc-btn:hover,
  .mbsc-windows-yellow .mbsc-sc-btn.mbsc-active {
    background: #6f6e6e;
  }
  .mbsc-windows-yellow .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #fbc12d;
  }
  .mbsc-windows-yellow .mbsc-cal-picker {
    background: #555;
  }
  .mbsc-windows-yellow .mbsc-cal-days > div {
    color: #b3b2b2;
  }
  .mbsc-windows-yellow .mbsc-cal-today .mbsc-cal-day-date {
    color: #fbc12d;
  }
  .mbsc-windows-yellow .mbsc-cal-day-diff .mbsc-cal-day-i {
    background: #626262;
  }
  .mbsc-windows-yellow .mbsc-cal-cell:focus .mbsc-cal-day-i {
    background: #6f6e6e;
  }
  .mbsc-windows-yellow.mbsc-no-touch:not(.mbsc-ev-cal) .mbsc-cal-day:not(.mbsc-disabled):hover .mbsc-cal-day-i,
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-cal-picker .mbsc-cal-cell:not(.mbsc-disabled):hover {
    background: #6f6e6e;
  }
  .mbsc-windows-yellow .mbsc-cal .mbsc-cal-day.mbsc-selected:not(.mbsc-disabled) .mbsc-cal-cell-i.mbsc-cal-day-i,
  .mbsc-windows-yellow .mbsc-cal .mbsc-cal-picker .mbsc-cal-cell.mbsc-selected,
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-cal-picker .mbsc-cal-cell.mbsc-selected:hover {
    background: rgba(251, 193, 45, 0.25);
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-cal-tab:hover {
    background: #6f6e6e;
    border-color: #6f6e6e;
  }
  .mbsc-windows-yellow .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected,
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-cal-tabs .mbsc-cal-tab.mbsc-selected:hover {
    border-bottom-color: #fbc12d;
    border-top-color: transparent;
    color: #fbc12d;
    background: #555;
  }
  .mbsc-windows-yellow .mbsc-cal-tabs-c {
    border-bottom: 1px solid #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-cal-mark {
    background: #fff;
  }
  .mbsc-windows-yellow .mbsc-cal-txt {
    color: #fff;
    background: #838383;
  }
  .mbsc-windows-yellow .mbsc-empty {
    color: #b3b2b2;
  }
  .mbsc-windows-yellow.mbsc-page {
    background-color: #363636;
    color: #fff;
  }
  .mbsc-windows-yellow a {
    color: #fbc12d;
  }
  .mbsc-windows-yellow .mbsc-input input,
  .mbsc-windows-yellow .mbsc-input textarea,
  .mbsc-windows-yellow .mbsc-select input {
    border: 0.125em solid #aeaeae;
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-input input:hover,
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-input textarea:hover,
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-select:hover input {
    border-color: #e1e1e1;
  }
  .mbsc-windows-yellow .mbsc-input input::-webkit-input-placeholder {
    color: #5c5c5c;
  }
  .mbsc-windows-yellow .mbsc-input input::-ms-input-placeholder {
    color: #5c5c5c;
  }
  .mbsc-windows-yellow .mbsc-input input::-moz-placeholder {
    color: #5c5c5c;
  }
  .mbsc-windows-yellow .mbsc-input .mbsc-input-wrap input:focus,
  .mbsc-windows-yellow .mbsc-input .mbsc-input-wrap select:focus + input,
  .mbsc-windows-yellow .mbsc-input .mbsc-input-wrap textarea:focus,
  .mbsc-windows-yellow.mbsc-alert .mbsc-input input:focus {
    background: #fff;
    border-color: #fbc12d;
    color: #262626;
  }
  .mbsc-windows-yellow .mbsc-input .mbsc-input-wrap input:focus ~ .mbsc-input-ic,
  .mbsc-windows-yellow .mbsc-input .mbsc-input-wrap select:focus + input ~ .mbsc-input-ic,
  .mbsc-windows-yellow .mbsc-input .mbsc-input-wrap textarea:focus ~ .mbsc-input-ic,
  .mbsc-windows-yellow.mbsc-alert .mbsc-input input:focus ~ .mbsc-input-ic {
    color: #262626;
  }
  .mbsc-windows-yellow .mbsc-input .mbsc-input-wrap .mbsc-control:disabled + input,
  .mbsc-windows-yellow .mbsc-input .mbsc-input-wrap textarea:disabled,
  .mbsc-windows-yellow .mbsc-input .mbsc-input-wrap input:disabled {
    border-color: #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-input.mbsc-err input,
  .mbsc-windows-yellow .mbsc-input.mbsc-err textarea {
    border-color: #d30101;
  }
  .mbsc-windows-yellow .mbsc-input-ic,
  .mbsc-windows-yellow .mbsc-input .mbsc-label,
  .mbsc-windows-yellow .mbsc-select .mbsc-input-wrap .mbsc-ic {
    color: #fff;
  }
  .mbsc-windows-yellow .mbsc-err-msg {
    color: #d30101;
  }
  .mbsc-windows-yellow.mbsc-progress .mbsc-label,
  .mbsc-windows-yellow.mbsc-progress .mbsc-input-ic {
    color: #fff;
  }
  .mbsc-windows-yellow .mbsc-progress-track {
    background: #555;
  }
  .mbsc-windows-yellow .mbsc-progress-bar {
    background: #fbc12d;
  }
  .mbsc-windows-yellow.mbsc-slider .mbsc-progress-track {
    background: #aeaeae;
    border-top: 2px solid #555;
    border-bottom: 2px solid #555;
  }
  .mbsc-windows-yellow.mbsc-form .mbsc-slider .mbsc-progress-track {
    border-top-color: #363636;
    border-bottom-color: #363636;
  }
  .mbsc-windows-yellow.mbsc-slider:hover .mbsc-progress-track {
    background: #e1e1e1;
  }
  .mbsc-windows-yellow.mbsc-slider:hover .mbsc-progress-track .mbsc-slider-handle {
    background: #fff;
  }
  .mbsc-windows-yellow.mbsc-slider .mbsc-active ~ .mbsc-progress-cont .mbsc-progress-track {
    background: #aeaeae;
  }
  .mbsc-windows-yellow.mbsc-slider .mbsc-active ~ .mbsc-progress-cont .mbsc-progress-track .mbsc-slider-handle {
    background: #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-slider-handle {
    background: #fbc12d;
  }
  .mbsc-windows-yellow .mbsc-slider-tooltip {
    border: 1px solid #7b7b7b;
    background: #555;
    color: #fff;
  }
  .mbsc-windows-yellow .mbsc-slider-step {
    background: #aeaeae;
  }
  .mbsc-windows-yellow.mbsc-rating .mbsc-progress-track {
    color: #fbc12d;
  }
  .mbsc-windows-yellow.mbsc-wdg.mbsc-fr-btn {
    background: #7b7b7b;
  }
  .mbsc-windows-yellow.mbsc-snackbar .mbsc-snackbar-btn {
    color: #fbc12d;
  }
  .mbsc-windows-yellow.mbsc-toast .mbsc-toast-msg {
    background: #e1e1e1;
    color: #000;
  }
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-primary .mbsc-fr-w,
  .mbsc-windows-yellow.mbsc-toast.mbsc-primary .mbsc-toast-msg,
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-secondary .mbsc-fr-w,
  .mbsc-windows-yellow.mbsc-toast.mbsc-secondary .mbsc-toast-msg,
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-success .mbsc-fr-w,
  .mbsc-windows-yellow.mbsc-toast.mbsc-success .mbsc-toast-msg,
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-danger .mbsc-fr-w,
  .mbsc-windows-yellow.mbsc-toast.mbsc-danger .mbsc-toast-msg,
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-warning .mbsc-fr-w,
  .mbsc-windows-yellow.mbsc-toast.mbsc-warning .mbsc-toast-msg,
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-info .mbsc-fr-w,
  .mbsc-windows-yellow.mbsc-toast.mbsc-info .mbsc-toast-msg,
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-primary .mbsc-snackbar-btn,
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-secondary .mbsc-snackbar-btn,
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-success .mbsc-snackbar-btn,
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-danger .mbsc-snackbar-btn,
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-info .mbsc-snackbar-btn {
    color: #efeff4;
  }
  .mbsc-windows-yellow.mbsc-snackbar.mbsc-warning .mbsc-snackbar-btn {
    color: #000;
  }
  .mbsc-windows-yellow.mbsc-form {
    background-color: #363636;
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-form *::-moz-selection,
  .mbsc-windows-yellow.mbsc-form *::selection {
    color: #fff;
    background: rgba(251, 193, 45, 0.9);
  }
  .mbsc-windows-yellow .mbsc-checkbox-box,
  .mbsc-windows-yellow .mbsc-radio-box {
    border: 0.125em solid #fff;
  }
  .mbsc-windows-yellow .mbsc-checkbox-box:after {
    border: 0.125em solid #1f1600;
    border-top: 0;
    border-right: 0;
  }
  .mbsc-windows-yellow .mbsc-checkbox input:checked + .mbsc-checkbox-box {
    background: #fbc12d;
    border-color: #fbc12d;
  }
  .mbsc-windows-yellow .mbsc-checkbox input.mbsc-active + .mbsc-checkbox-box {
    border-color: #e1e1e1;
    background: #e1e1e1;
  }
  .mbsc-windows-yellow .mbsc-radio-box:after {
    background: #fff;
  }
  .mbsc-windows-yellow .mbsc-radio input:checked + .mbsc-radio-box {
    border-color: #fbc12d;
  }
  .mbsc-windows-yellow .mbsc-radio input.mbsc-active + .mbsc-radio-box {
    border-color: rgba(255, 255, 255, 0.6);
  }
  .mbsc-windows-yellow .mbsc-radio input.mbsc-active + .mbsc-radio-box:after {
    background: rgba(255, 255, 255, 0.6);
  }
  .mbsc-windows-yellow .mbsc-btn {
    background: #7b7b7b;
    border: 0.125em solid #7b7b7b;
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-btn:not(.mbsc-btn-flat):not(:disabled):hover {
    border-color: #ababab;
  }
  .mbsc-windows-yellow .mbsc-btn.mbsc-active {
    border-color: #ababab;
    background: #ababab;
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-btn-flat:not(:disabled):hover {
    background: transparent;
    border-color: transparent;
  }
  .mbsc-windows-yellow .mbsc-btn.mbsc-btn-flat.mbsc-active:not(:disabled) {
    background: rgba(123, 123, 123, 0.25);
  }
  .mbsc-windows-yellow .mbsc-btn.mbsc-btn-outline {
    background: transparent;
    border: 0.125em solid #aeaeae;
    color: #aeaeae;
  }
  .mbsc-windows-yellow .mbsc-btn.mbsc-btn-outline.mbsc-active {
    background: #c8c8c8;
    border-color: #c8c8c8;
    color: #000;
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-btn.mbsc-btn-outline:not(:disabled):hover {
    border-color: #c8c8c8;
  }
  .mbsc-windows-yellow .mbsc-switch-track {
    border: 2px solid #fff;
  }
  .mbsc-windows-yellow .mbsc-switch-track .mbsc-switch-handle {
    background: #fff;
  }
  .mbsc-windows-yellow .mbsc-switch input:checked + .mbsc-switch-track {
    background: #fbc12d;
    border-color: #fbc12d;
  }
  .mbsc-windows-yellow .mbsc-switch input:checked + .mbsc-switch-track .mbsc-switch-handle {
    background: #555;
  }
  .mbsc-windows-yellow .mbsc-switch input:checked:disabled + .mbsc-switch-track {
    background: #fff;
    border-color: #fff;
  }
  .mbsc-windows-yellow.mbsc-form .mbsc-switch input.mbsc-active + .mbsc-switch-track {
    background: #e1e1e1;
    border-color: #e1e1e1;
  }
  .mbsc-windows-yellow.mbsc-form .mbsc-switch input.mbsc-active + .mbsc-switch-track .mbsc-switch-handle {
    background: #555;
  }
  .mbsc-windows-yellow .mbsc-segmented-content {
    color: #fff;
    background: #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-segmented-content.mbsc-stepper-val {
    background: transparent;
    border-top: 2px solid #7b7b7b;
    border-bottom: 2px solid #7b7b7b;
  }
  .mbsc-windows-yellow.mbsc-ltr .mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item {
    border-right: 1px solid #858585;
  }
  .mbsc-windows-yellow.mbsc-rtl .mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item {
    border-left: 1px solid #858585;
  }
  .mbsc-windows-yellow .mbsc-segmented:not(.mbsc-stepper) .mbsc-segmented-item:last-child,
  .mbsc-windows-yellow .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-plus,
  .mbsc-windows-yellow .mbsc-stepper-val-left .mbsc-segmented input + .mbsc-segmented-item,
  .mbsc-windows-yellow .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control {
    border: 0;
  }
  .mbsc-windows-yellow.mbsc-ltr .mbsc-segmented .mbsc-stepper-control + .mbsc-stepper-control {
    border-left: 1px solid #858585;
  }
  .mbsc-windows-yellow.mbsc-rtl .mbsc-segmented .mbsc-stepper-control + .mbsc-stepper-control {
    border-right: 1px solid #858585;
  }
  .mbsc-windows-yellow .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content {
    background: #fff;
    color: #363636;
  }
  .mbsc-windows-yellow .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-active .mbsc-segmented-content,
  .mbsc-windows-yellow .mbsc-segmented .mbsc-segmented-item input:checked + .mbsc-segmented-content,
  .mbsc-windows-yellow .mbsc-segmented .mbsc-segmented-item input.mbsc-active + .mbsc-segmented-content {
    background: #ababab;
  }
  .mbsc-windows-yellow .mbsc-stepper .mbsc-segmented-item.mbsc-active .mbsc-segmented-content {
    background: #ababab;
  }
  .mbsc-windows-yellow .mbsc-segmented input:disabled ~ .mbsc-segmented-item .mbsc-segmented-content,
  .mbsc-windows-yellow .mbsc-segmented .mbsc-segmented-item.mbsc-stepper-control.mbsc-disabled .mbsc-segmented-content,
  .mbsc-windows-yellow .mbsc-stepper .mbsc-segmented-item.mbsc-disabled .mbsc-segmented-content,
  .mbsc-windows-yellow .mbsc-segmented .mbsc-segmented-item input:disabled + .mbsc-segmented-content {
    background: #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-stepper input:disabled {
    color: #858585;
    -webkit-text-fill-color: #858585;
  }
  .mbsc-windows-yellow .mbsc-segmented .mbsc-segmented-item input:disabled:checked + .mbsc-segmented-content {
    color: #363636;
    background: #858585;
  }
  .mbsc-windows-yellow .mbsc-stepper .mbsc-active.mbsc-disabled .mbsc-segmented-content {
    background: transparent;
  }
  .mbsc-windows-yellow .mbsc-stepper input {
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-segmented-item:hover .mbsc-segmented-content {
    background: #6f6e6e;
  }
  .mbsc-windows-yellow.mbsc-card.mbsc-form {
    background: #5b5b5b;
    box-shadow: 0 0 1px 1px #747474;
  }
  .mbsc-windows-yellow.mbsc-card .mbsc-lv-item {
    background: #5b5b5b;
  }
  .mbsc-windows-yellow .mbsc-card-footer {
    border-color: #747474;
  }
  .mbsc-windows-yellow .mbsc-color-active,
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-color-item-c:hover {
    box-shadow: inset 0 0 0 0.1875em #fbc12d;
  }
  .mbsc-windows-yellow .mbsc-color-clear-item {
    background: fade(#dfdede, 30%);
  }
  .mbsc-windows-yellow.mbsc-color .mbsc-color-slider-cont .mbsc-progress-track {
    background: #858585;
  }
  .mbsc-windows-yellow .mbsc-color-preview-item-empty,
  .mbsc-windows-yellow .mbsc-color-preview-cont {
    border-color: #eee;
  }
  .mbsc-windows-yellow.mbsc-form .mbsc-color-input {
    border: 0.125em solid #aeaeae;
    color: #fff;
  }
  .mbsc-windows-yellow .mbsc-lv {
    background: #000;
  }
  .mbsc-windows-yellow .mbsc-lv-item,
  .mbsc-windows-yellow .mbsc-lv-gr-title {
    background: #555;
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-lv-item-actionable:hover:before,
  .mbsc-windows-yellow .mbsc-lv-item-actionable.mbsc-lv-item-active:before {
    background: rgba(255, 255, 255, 0.2);
  }
  .mbsc-windows-yellow.mbsc-cal-events-popup .mbsc-fr-w {
    border: 0.375em solid rgba(255, 255, 255, 0.8);
  }
  .mbsc-windows-yellow.mbsc-cal-events-popup .mbsc-fr-arr {
    background: rgba(255, 255, 255, 0.8);
  }
  .mbsc-windows-yellow .mbsc-cal-events {
    background: rgba(255, 255, 255, 0.8);
  }
  .mbsc-windows-yellow .mbsc-cal-event-color {
    background: #fff;
  }
  .mbsc-windows-yellow .mbsc-event-list .mbsc-lv-item {
    background: #363636;
  }
  .mbsc-windows-yellow .mbsc-event-list .mbsc-lv-item:last-child {
    border-bottom: 1px solid #626262;
  }
  .mbsc-windows-yellow .mbsc-event-list .mbsc-lv-gr-title {
    background: #363636;
  }
  .mbsc-windows-yellow .mbsc-event-color {
    background: #fbc12d;
  }
  .mbsc-windows-yellow.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-table {
    border-top: 1px solid #7b7b7b;
  }
  .mbsc-windows-yellow.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-cell {
    background: #363636;
    border-left: 1px solid #7b7b7b;
    border-bottom: 1px solid #7b7b7b;
  }
  .mbsc-windows-yellow.mbsc-ev-cal.mbsc-fr-md .mbsc-cal-day-diff .mbsc-cal-day-i {
    background: #555;
  }
  .mbsc-windows-yellow .mbsc-lv-handle-bar {
    background: #888;
  }
  .mbsc-windows-yellow .mbsc-lv-ic-m {
    color: #555;
  }
  .mbsc-windows-yellow.mbsc-lv-alt-row .mbsc-lv-item:nth-child(even) {
    background: #747474;
  }
  .mbsc-windows-yellow .mbsc-lv-item.mbsc-lv-item-hl {
    background: #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-lv-loading .mbsc-ic {
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-lv-cont .mbsc-lv-item.mbsc-selected:before {
    background: rgba(251, 193, 45, 0.2);
  }
  .mbsc-windows-yellow.mbsc-ms-c {
    background: #555;
    color: #fff;
  }
  .mbsc-windows-yellow .mbsc-ms-item.mbsc-active,
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-ms-item.mbsc-active:not(.mbsc-disabled):hover {
    background: #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-ms-item-sel,
  .mbsc-windows-yellow .mbsc-ms-item-sel.mbsc-ms-ic:before {
    color: #fbc12d;
  }
  .mbsc-windows-yellow.mbsc-ms-b .mbsc-ms-item.mbsc-ms-item-sel {
    border-bottom-color: #fbc12d;
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-ms-item:not(.mbsc-disabled):hover {
    background: #6f6e6e;
  }
  .mbsc-windows-yellow .mbsc-ms-item.mbsc-menu-item-ic {
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-ms-more .mbsc-ms-item {
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-ms-more .mbsc-ms-item.mbsc-ms-item-sel {
    color: #fbc12d;
  }
  .mbsc-windows-yellow.mbsc-fr .mbsc-fr-w .mbsc-np-del.mbsc-active {
    background: #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-np-del.mbsc-active:before {
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-fr .mbsc-fr-w .mbsc-np-btn.mbsc-active {
    background: #7b7b7b;
    color: #fff;
  }
  .mbsc-windows-yellow .mbsc-np-btn.mbsc-disabled {
    color: #aeaeae;
  }
  .mbsc-windows-yellow .mbsc-range-btn {
    color: #fff;
    background: #7b7b7b;
    border: 2px solid #7b7b7b;
  }
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-range-btn.mbsc-fr-btn-e:not(.mbsc-disabled):hover {
    border-color: #2f2f2f;
    background: #7b7b7b;
  }
  .mbsc-windows-yellow .mbsc-range-btn.mbsc-selected,
  .mbsc-windows-yellow.mbsc-no-touch .mbsc-range-btn.mbsc-selected:not(.mbsc-disabled):hover {
    background: #fbc12d;
    border-color: #fbc12d;
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-range .mbsc-cal .mbsc-cal-row .mbsc-cal-day.mbsc-cal-day-hl .mbsc-cal-day-i,
  .mbsc-windows-yellow.mbsc-range .mbsc-cal .mbsc-cal-today.mbsc-cal-day-hl .mbsc-cal-day-date {
    background: #fbc12d;
    color: #555;
  }
  .mbsc-windows-yellow .mbsc-sel-gr-whl.mbsc-sel-one .mbsc-sc-itm-sel {
    color: #fbc12d;
  }
  .mbsc-windows-yellow .mbsc-sel-filter-clear {
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-fr-pointer .mbsc-sel-one .mbsc-sc-whl-multi .mbsc-sc-itm-sel {
    color: #fff;
    background: rgba(251, 193, 45, 0.2);
  }
  .mbsc-windows-yellow.mbsc-timer .mbsc-sc-lbl {
    color: #fff;
  }
  .mbsc-windows-yellow.mbsc-ts .mbsc-sc-lbl {
    color: #fff;
  }
  