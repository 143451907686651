table {
    border-collapse: collapse;
    text-align: left;
    width: 92%;
    margin: 10px;
    overflow: scroll;
    font-family: "San Francisco Display Medium";

    thead {
        //background-color: #283044;;
        color: black;
        line-height: 5;
        font-size: 10pt;
    }

    td {
        width: 65%;
        font-size: 10pt;
        padding-left: 5px;
        //border-left: 1px solid #cbcbcb;
        //border-right: 1px solid #cbcbcb;

        &:nth-child(even){
            text-align: center;
        }

        &:hover{
            background-color:rgb(235, 177, 160);
        }
    }

    th {
        padding-left: 5px;

        &:nth-child(even){
            text-align: center;
        }
    }

    tr {
        line-height: 1.5;
        //border-bottom: 1px solid #cbcbcb;

        &:nth-child(even){
            background-color: #f2f2f2;
        }
    }
}

/* top-left border-radius */
table tr:first-child th:first-child {
    border-top-left-radius: 8px;
  }
  
  /* top-right border-radius */
  table tr:first-child th:last-child {
    border-top-right-radius: 8px;
  }
